import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Prospect } from "../../../../../models/prospect";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getProspectsAction } from "../../../../../store/Crm/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface EditProspectProps {
  input: string;
  popupActive: Prospect;
  setPopupActive: React.Dispatch<React.SetStateAction<Prospect>>;
  getProspectsAction: typeof getProspectsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _EditProspect: React.FC<EditProspectProps> = ({
  input,
  popupActive,
  setPopupActive,
  getProspectsAction,
}) => {
  const editProspect = () => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/crm/prospects/edit/" + inputNameConst,
      {
        name: inputName,
        type: inputType,
        company: inputCompany,
        telNumber: inputTelNumber,
        email: inputEmail,
        address: inputAddress,
      }
    )
      .then(() => {
        getProspectsAction(input);
        setPopupActive({
          id: "",
          name: "",
          type: "",
          company: "",
          telNumber: "",
          email: "",
          address: "",
        });
      })
      .catch((err) => {
        SetEditFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputNameError(false);
    let result = true;
    if (inputName === "") {
      SetInputNameError(true);
      result = false;
    }
    if (!result) SetEditFailed("Champs manquants");
    return result;
  };

  const inputNameConst = popupActive.name;

  const [inputName, SetInputName] = useState<string>(popupActive.name);

  const [inputNameError, SetInputNameError] = useState(false);

  const [inputType, SetInputType] = useState(popupActive.type);

  const [inputCompany, SetInputCompany] = useState(popupActive.company);

  const [inputTelNumber, SetInputTelNumber] = useState(popupActive.telNumber);

  const [inputEmail, SetInputEmail] = useState(popupActive.email);

  const [inputAddress, SetInputAddress] = useState(popupActive.address);

  const [editFailed, SetEditFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive.name !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive({
          id: "",
          name: "",
          type: "",
          company: "",
          telNumber: "",
          email: "",
          address: "",
        });
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un prospect"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive({
                id: "",
                name: "",
                type: "",
                company: "",
                telNumber: "",
                email: "",
                address: "",
              });
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {editFailed !== "" && <Alert severity="error">{editFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => SetInputName(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="type"
                label="Particulier/Poste"
                value={inputType}
                onChange={(e) => SetInputType(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="company"
                label="Société"
                value={inputCompany}
                onChange={(e) => SetInputCompany(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="telNumber"
                label="N° de téléphone"
                value={inputTelNumber}
                onChange={(e) => SetInputTelNumber(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="email"
                label="Email"
                value={inputEmail}
                onChange={(e) => SetInputEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="address"
                label="Adresse"
                value={inputAddress}
                onChange={(e) => SetInputAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) editProspect();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() =>
            setPopupActive({
              id: "",
              name: "",
              type: "",
              company: "",
              telNumber: "",
              email: "",
              address: "",
            })
          }
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditProspect = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProspectsAction: getProspectsAction,
    },
    dispatch
  )
)(_EditProspect);

export default EditProspect;
