import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Vacation } from "../../../../../models/vacation";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getVacationsAction } from "../../../../../store/Rh/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface DeleteVacationProps {
  input: string;
  popupActive: Vacation;
  setPopupActive: React.Dispatch<React.SetStateAction<Vacation>>;
  getVacationsAction: typeof getVacationsAction;
}

const _DeleteVacation: React.FC<DeleteVacationProps> = (props) => {
  const deleteVacation = () => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/rh/vacations/delete/" + props.popupActive.id
    )
      .then(() => {
        props.getVacationsAction(props.input);
        props.setPopupActive({
          id: "",
          label: "",
          startDate: "",
          endDate: "",
          type: "",
          status: "",
          justificationFileNames: [],
          comment: "",
          supervisorId: "",
          originalFileNames: [],
        });
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={props.popupActive.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive({
          id: "",
          label: "",
          startDate: "",
          endDate: "",
          type: "",
          status: "",
          justificationFileNames: [],
          comment: "",
          supervisorId: "",
          originalFileNames: [],
        });
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer vacation?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer l'absence{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive.label}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          onClick={() => deleteVacation()}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() =>
            props.setPopupActive({
              id: "",
              label: "",
              startDate: "",
              endDate: "",
              type: "",
              status: "",
              justificationFileNames: [],
              comment: "",
              supervisorId: "",
              originalFileNames: [],
            })
          }
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteVacation = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVacationsAction: getVacationsAction,
    },
    dispatch
  )
)(_DeleteVacation);

export default DeleteVacation;
