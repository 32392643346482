import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../../../../store";
import { getDishesAction } from "../../../../../../store/Dish/actions";
import { getDishes } from "../../../../../../store/selectors";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import { Text14, Text36 } from "../../../../../../styles/Text";

export interface ArticlesProps {}

const _Articles: React.FC<ArticlesProps> = (props) => {
  useEffect(() => {}, []);

  return (
    <FlexContainer width="100%">
      <FlexContainer flex="3" padding="32px">
        <FlexContainer flexDirection="column">
          <FlexContainer justifyContent="flex-end">
            <IconButton color="primary" size="large">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton color="secondary" size="large">
              <CloseIcon fontSize="small" />
            </IconButton>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer margin="0 16px 0 0">
              <ImagePreview
                src={
                  "https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/Mocks/Article.jpg"
                }
                alt=""
                width="370px"
                height="250px"
              />
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <FlexContainer>
                <Text36 fontWeight="600" textAlign="justify">
                  4 conseils pour mieux choisir vos traiteur en fonction de vos
                  besoins
                </Text36>
              </FlexContainer>
              <FlexContainer>
                <Text14 textAlign="justify">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </Text14>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flex="1" height="600px" margin="32px 64px"></FlexContainer>
    </FlexContainer>
  );
};

export const Articles = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_Articles);

export default _Articles;
