import { Grid, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text12, Text14, Text16 } from "../../../../../styles/Text";
import {
  GLOBAL_TVA,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";

export interface GeneralParametersProps {}

const GeneralParameters: React.FC<GeneralParametersProps> = () => {
  const setGlobalTva = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: GLOBAL_TVA,
        parameterValue: inputGlobalTva,
      },
    ])
      .then(() => {
        setIsSetGlobalTvaLoading(false);
      })
      .catch(() => {});
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames: GLOBAL_TVA,
    }).then((res) => {
      setInputGlobalTva(getUserParameterValueByName(GLOBAL_TVA, res));
    });
  };

  const [isSetGlobalTvaLoading, setIsSetGlobalTvaLoading] = useState(false);

  const [inputGlobalTva, setInputGlobalTva] = useState("");

  useEffect(() => {
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer flexDirection="column" width="100%">
      <Text16 fontWeight="500">Général</Text16>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier une TVA globale si vous le souhaitez:
          </Text14>
          <Text12 color="black" opacity="0.7">
            Si vous utilisez une TVA précise sur tous ou la majorité des
            produits, ce champs va initialiser facilement vos formulaires avec
            celle-ci
          </Text12>
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="globalTva"
            label="TVA globale"
            value={inputGlobalTva}
            onChange={(e) => {
              setInputGlobalTva(e.target.value);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <FlexContainer>
            <ButtonMui
              color="primary"
              variant="contained"
              size="large"
              disabled={isSetGlobalTvaLoading}
              loading={isSetGlobalTvaLoading}
              onClick={() => {
                setIsSetGlobalTvaLoading(true);
                setGlobalTva();
              }}
            >
              Enregistrer
            </ButtonMui>
          </FlexContainer>
        </Grid>
      </Grid>
    </FlexContainer>
  );
};

export default GeneralParameters;
