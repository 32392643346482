import React from "react";
import { FlexContainer } from "../../styles/FlexContainer";
import LoginImageSection from "./LoginImageSection";
import { SignInForm } from "./SignInForm";

export interface SignInProps {}

const SignIn: React.FC<SignInProps> = () => {
  return (
    <FlexContainer height="100vh">
      <LoginImageSection />
      <SignInForm />
    </FlexContainer>
  );
};

export default SignIn;
