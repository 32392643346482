import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BaseMetric, CustomMetric } from "../../../../../../models/metric";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../../store";
import {
  getBaseMetricsAction,
  getCustomMetricsAction,
} from "../../../../../../store/Metric/actions";
import {
  getBaseMetrics,
  getCustomMetrics,
} from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../../styles/Utils";
import AutocompleteCustom from "../../../../../Reusable/Autocomplete";

export interface AddMetricProps {
  input: string;
  startDate: string;
  endDate: string;
  popupActive: boolean;
  baseMetrics: BaseMetric[];
  customMetrics: CustomMetric[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getBaseMetricsAction: typeof getBaseMetricsAction;
  getCustomMetricsAction: typeof getCustomMetricsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "550px",
    width: "620px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _AddMetric: React.FC<AddMetricProps> = (props) => {
  const addMetric = () => {
    AxiosHttpClient.post("api/v1/weeventpro/statistics/metrics/add", {
      name: inputName,
      value: "",
      unit: inputUnit,
      expression: inputExpression,
    })
      .then(() => {
        props.getBaseMetricsAction(props.input, props.startDate, props.endDate);
        props.getCustomMetricsAction(
          props.input,
          props.startDate,
          props.endDate
        );
        props.setPopupActive(false);
      })
      .catch((err) => {
        SetAddFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputNameError(false);
    let result = true;
    if (inputName === "") {
      SetInputNameError(true);
      result = false;
    }
    if (!result) SetAddFailed("Champs manquants");
    return result;
  };

  const setAllMetricsNames = () => {
    let metricsNames: string[] = [];
    for (var baseMetric of props.baseMetrics) {
      metricsNames.push(baseMetric.name);
    }
    for (var customMetric of props.customMetrics) {
      metricsNames.push(customMetric.name);
    }
    setAllMetrics(metricsNames);
  };

  useEffect(() => {
    getBaseMetricsAction(props.input, props.startDate, props.endDate);
    getCustomMetricsAction(props.input, props.startDate, props.endDate);
    setAllMetricsNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBaseMetricsAction, getCustomMetricsAction]);

  const [inputName, setInputName] = useState("");

  const [inputNameError, SetInputNameError] = useState(false);

  const [inputUnit, setInputUnit] = useState("");

  const [inputExpression, setInputExpression] = useState("");

  const [allMetrics, setAllMetrics] = useState<string[]>([]);

  const [addFailed, SetAddFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une métrique"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px 8px 8px 43px">
          {addFailed !== "" && <Alert severity="error">{addFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <BlockContainer margin="50px 0 0 0">
            <TextField
              variant="outlined"
              name="name"
              label="Nom"
              required
              error={inputNameError}
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">
                Unité de calcul
              </InputLabel>
              <Select
                native
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                required
                value={inputUnit}
                label="Unité de calcul"
                onChange={(e) =>
                  setInputUnit(
                    typeof e.target.value === "string" ? e.target.value : ""
                  )
                }
              >
                <option aria-label="None" value="" />
                <option value="%">%</option>
                <option value="€">€</option>
              </Select>
            </FormControl>
            <AutocompleteCustom
              inputExpression={inputExpression}
              setInputExpression={setInputExpression}
              name="expression"
              label="Relation mathématique"
              suggestions={allMetrics}
            />
          </BlockContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) addMetric();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddMetric = connect(
  (state: RootState) => ({
    baseMetrics: getBaseMetrics(state),
    customMetrics: getCustomMetrics(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getBaseMetricsAction: getBaseMetricsAction,
        getCustomMetricsAction: getCustomMetricsAction,
      },
      dispatch
    )
)(_AddMetric);

export default AddMetric;
