import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddQuote } from "./AddQuote";
import { QuoteTable } from "./QuoteTable";

export interface QuoteProps {}

const Quote: React.FC<QuoteProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Devis"
        subTitle="Liste des devis"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <QuoteTable />
      {popupActive && (
        <AddQuote
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Quote;
