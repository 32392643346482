import { RootAction } from "../types";
import { GET_ROUTES_SUCCESS} from "./actions";
import { RouteState } from "./types";

const initialState: RouteState = {
  routes: [],
};

export function routeReducer(
  state: RouteState = initialState,
  action: RootAction
): RouteState {
  switch (action.type) {
    case GET_ROUTES_SUCCESS:
      return {
        routes: action.routes
      };

    default:
      return state;
  }
}
