import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getSupplierOrdersArchiveAction } from "../../../../../store/SupplierOrder/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface DeleteSupplierOrderArchiveProps {
  input: string;
  popupActive: string;
  setPopupActive: React.Dispatch<React.SetStateAction<string>>;
  getSupplierOrdersArchiveAction: typeof getSupplierOrdersArchiveAction;
}

const _DeleteSupplierOrderArchive: React.FC<DeleteSupplierOrderArchiveProps> = (
  props
) => {
  const deleteSupplierOrderArchive = () => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/order/supplierOrder/archive/delete/" +
        props.popupActive
    )
      .then(() => {
        props.getSupplierOrdersArchiveAction(props.input);
        props.setPopupActive("");
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={props.popupActive !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive("");
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer facture finale?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer la commande{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          onClick={() => deleteSupplierOrderArchive()}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive("")}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteSupplierOrderArchive = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getSupplierOrdersArchiveAction: getSupplierOrdersArchiveAction,
    },
    dispatch
  )
)(_DeleteSupplierOrderArchive);

export default DeleteSupplierOrderArchive;
