import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export interface EditEmployeeProps {
  input: string;
  popupActive: Employee;
  setPopupActive: React.Dispatch<React.SetStateAction<Employee>>;
  getEmployeesAction: typeof getEmployeesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _EditEmployee: React.FC<EditEmployeeProps> = ({
  input,
  popupActive,
  setPopupActive,
  getEmployeesAction,
}) => {
  const editEmployee = () => {
    AxiosHttpClient.put("api/v1/weeventpro/rh/employees/edit/", {
      id: popupActive.id,
      firstName: inputFirstName,
      lastName: inputLastName,
      dateOfBirth: dayjs(inputDateOfBirth),
      telNumber: inputTelNumber,
      email: inputEmail,
      address: inputAddress,
      socialSituation: inputSocialSituation,
      salary: inputSalary,
      gender: inputGender,
      position: inputPosition,
      status: popupActive.status,
      city: popupActive.city,
      password: popupActive.password,
      entityId: popupActive.entityId,
    })
      .then(() => {
        getEmployeesAction(input);
        setPopupActive({
          id: "",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          telNumber: "",
          email: "",
          address: "",
          socialSituation: "",
          salary: "",
          gender: "",
          position: "",
          status: "",
          city: "",
          password: "",
          entityId: "",
        });
      })
      .catch((err) => {
        SetEditFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputFirstNameError(false);
    SetInputLastNameError(false);
    let result = true;
    if (inputFirstName === "") {
      SetInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      SetInputLastNameError(true);
      result = false;
    }
    if (!result) SetEditFailed("Champs manquants");
    return result;
  };

  const [inputFirstName, SetInputFirstName] = useState(popupActive.firstName);

  const [inputFirstNameError, SetInputFirstNameError] = useState(false);

  const [inputLastName, SetInputLastName] = useState(popupActive.lastName);

  const [inputLastNameError, SetInputLastNameError] = useState(false);

  const [inputDateOfBirth, setInputDateOfBirth] = useState<Dayjs | null>(
    popupActive!.dateOfBirth
  );

  const [inputTelNumber, SetInputTelNumber] = useState(popupActive.telNumber);

  const [inputEmail, SetInputEmail] = useState(popupActive.email);

  const [inputAddress, SetInputAddress] = useState(popupActive.address);

  const [inputSocialSituation, SetInputSocialSituation] = useState(
    popupActive.socialSituation
  );

  const [inputSalary, SetInputSalary] = useState(popupActive.salary);

  const [inputGender, SetInputGender] = useState(popupActive.gender);

  const [inputPosition, SetInputPosition] = useState(popupActive.position);

  const [editFailed, SetEditFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive({
          id: "",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          telNumber: "",
          email: "",
          address: "",
          socialSituation: "",
          salary: "",
          gender: "",
          position: "",
          status: "",
          city: "",
          password: "",
          entityId: "",
        });
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un employee"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive({
                id: "",
                firstName: "",
                lastName: "",
                dateOfBirth: null,
                telNumber: "",
                email: "",
                address: "",
                socialSituation: "",
                salary: "",
                gender: "",
                position: "",
                status: "",
                city: "",
                password: "",
                entityId: "",
              });
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {editFailed !== "" && <Alert severity="error">{editFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="firstName"
                label="Prénom"
                required
                error={inputFirstNameError}
                value={inputFirstName}
                onChange={(e) => SetInputFirstName(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="lastName"
                label="Nom"
                required
                error={inputLastNameError}
                value={inputLastName}
                onChange={(e) => SetInputLastName(e.target.value)}
              />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Sexe
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputGender}
                  onChange={(e) => SetInputGender(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Femelle"
                  />
                </RadioGroup>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de naissance"
                  inputFormat="DD/MM/YYYY"
                  value={inputDateOfBirth}
                  onChange={(value) => setInputDateOfBirth(value!)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                variant="outlined"
                name="socialSituation"
                label="Situation familiale"
                value={inputSocialSituation}
                onChange={(e) => SetInputSocialSituation(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="telNumber"
                label="N° de téléphone"
                value={inputTelNumber}
                onChange={(e) => SetInputTelNumber(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="email"
                label="Email"
                value={inputEmail}
                onChange={(e) => SetInputEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="address"
                label="Adresse"
                value={inputAddress}
                onChange={(e) => SetInputAddress(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="salary"
                label="Salaire"
                value={inputSalary}
                onChange={(e) => SetInputSalary(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="position"
                label="Poste"
                value={inputPosition}
                onChange={(e) => SetInputPosition(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) editEmployee();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() =>
            setPopupActive({
              id: "",
              firstName: "",
              lastName: "",
              dateOfBirth: null,
              telNumber: "",
              email: "",
              address: "",
              socialSituation: "",
              salary: "",
              gender: "",
              position: "",
              status: "",
              city: "",
              password: "",
              entityId: "",
            })
          }
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditEmployee = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEmployeesAction: getEmployeesAction,
    },
    dispatch
  )
)(_EditEmployee);

export default EditEmployee;
