import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";
import { InvoiceEmailTemplate } from "../../../../../../../models/invoiceEmailTemplate";

export interface DeleteInvoiceEmailTemplateProps {
  popupActive: InvoiceEmailTemplate | null;
  isDeleteInvoiceEmailTemplateLoading: boolean;
  setIsDeleteInvoiceEmailTemplateLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setPopupActive: React.Dispatch<
    React.SetStateAction<InvoiceEmailTemplate | null>
  >;
  deleteInvoiceEmailTemplate: (invoiceEmailTemplateId: string) => void;
}

const DeleteInvoiceEmailTemplate: React.FC<DeleteInvoiceEmailTemplateProps> = (
  props
) => {
  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={props.isDeleteInvoiceEmailTemplateLoading}
          loading={props.isDeleteInvoiceEmailTemplateLoading}
          onClick={() => {
            props.deleteInvoiceEmailTemplate(props.popupActive!.id);
            props.setIsDeleteInvoiceEmailTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteInvoiceEmailTemplate;
