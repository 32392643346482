import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  Collapse,
  Divider,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CustomField } from "../../../../../../models/customField";
import { UserParameter } from "../../../../../../models/userParameters";
import { View } from "../../../../../../models/view";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text12, Text14, Text16, Text20 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import DeleteCustomField from "./DeleteCustomField";
import DeleteView from "./DeleteView";
import EditCustomField from "./EditCustomField";
import EditView from "./EditView";
import { connect } from "react-redux";
import { Dispatch, RootState } from "../../../../../../store";
import {
  getEmployees,
  getInvoiceTemplates,
} from "../../../../../../store/selectors";
import { bindActionCreators } from "redux";
import { Template } from "../../../../../../models/template";
import { getInvoiceTemplatesAction } from "../../../../../../store/Invoice/actions";
import { styled } from "@mui/system";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import DeleteTemplate from "./DeleteTemplate";
import { InvoiceEmailTemplate } from "../../../../../../models/invoiceEmailTemplate";
import EditInvoiceEmailTemplate from "./EditInvoiceEmailTemplate";
import DeleteInvoiceEmailTemplate from "./DeleteInvoiceEmailTemplate";
import MailIcon from "@mui/icons-material/Mail";
import { createFilterOptions } from "@mui/core";
import { Employee } from "../../../../../../models/employee";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import {
  CC_EMAILS,
  INVOICE_FORMAT,
  INVOICE_INCREMENT,
  INVOICE_NUMBER_OF_DIGITS,
  getUserParameterValueByName,
} from "../../../../../Reusable/Utils";

export interface CustomizeInvoicesProps {
  invoiceTemplates: Template[];
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
  getInvoiceTemplatesAction: typeof getInvoiceTemplatesAction;
}

const Input = styled("input")({
  display: "none",
});

const _CustomizeInvoices: React.FC<CustomizeInvoicesProps> = ({
  invoiceTemplates,
  employees,
  getEmployeesAction,
  getInvoiceTemplatesAction,
}) => {
  const addCustomField = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/customFields/invoice", {
      id: "",
      name: inputName,
      type: inputType,
      values: inputValues,
    })
      .then(() => {
        getCustomFields();
        setCustomFieldAddActive(false);
        resetCustomFieldForm();
        setIsAddCustomFieldLoading(false);
        setAddCustomFieldFailed("");
      })
      .catch((err) => {
        setAddCustomFieldFailed(err.cause);
        setIsAddCustomFieldLoading(false);
      });
  };

  const addView = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/views/invoice", {
      id: "",
      name: inputViewName,
      fields: inputSelectedFields,
    })
      .then(() => {
        getViews();
        setViewAddActive(false);
        resetViewForm();
        setIsAddViewLoading(false);
        setAddViewFailed("");
      })
      .catch((err) => {
        setAddViewFailed(err.cause);
        setIsAddViewLoading(false);
      });
  };

  const addInvoiceEmailTemplate = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/parameters/template/invoice/email",
      {
        id: "",
        name: inputEmailTemplateName,
        used: false,
        subject: inputSubject,
        content: inputContent,
      }
    )
      .then(() => {
        getInvoiceEmailTemplates();
        setInvoiceEmailTemplateAddActive(false);
        resetInvoiceEmailTemplateForm();
        setIsAddInvoiceEmailTemplateLoading(false);
        setAddInvoiceEmailTemplateFailed("");
      })
      .catch((err) => {
        setAddInvoiceEmailTemplateFailed(err.cause);
        setIsAddInvoiceEmailTemplateLoading(false);
      });
  };

  const addInvoiceNumberFormat = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: INVOICE_FORMAT,
        parameterValue: inputInvoiceNumberFormat,
      },
      {
        parameterName: INVOICE_NUMBER_OF_DIGITS,
        parameterValue: inputNumberOfDigits,
      },
    ])
      .then(() => {})
      .catch((err) => {
        setAddInvoiceNumberFormatFailed(err.cause);
        setIsAddInvoiceNumberFormatLoading(false);
      });
  };

  const setCCEmails = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: CC_EMAILS,
        parameterValue: inputCCEmails
          .map((inputCCEmail) =>
            typeof inputCCEmail === "string" ? inputCCEmail : inputCCEmail.email
          )
          .join(","),
      },
    ])
      .then(() => {})
      .catch((err) => {
        setAddInvoiceNumberFormatFailed(err.cause);
        setIsAddInvoiceNumberFormatLoading(false);
      });
  };

  const addInvoiceTemplate = () => {
    const obj = {
      label: inputTemplateLabel,
      used: false,
    };
    const json = JSON.stringify(obj);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    data.append("template", inputTemplate);
    data.append("document", blob);
    setIsAddInvoiceTemplateLoading(true);

    AxiosHttpClient.post("api/v1/weeventpro/invoice/template", data)
      .then(() => {
        getInvoiceTemplatesAction();
        setInvoiceTemplateAddActive(false);
        setIsAddInvoiceTemplateLoading(false);
      })
      .catch((err) => {
        setAddInvoiceTemplateFailed(err.cause);
        setIsAddInvoiceTemplateLoading(false);
      });
  };

  const pickTemplate = (invoiceTemplate: Template) => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/invoice/template/pick/" + invoiceTemplate.label
    )
      .then(() => {
        getInvoiceTemplatesAction();
      })
      .catch((err) => {});
  };

  const editIncrementValue = () => {
    AxiosHttpClient.post("api/v1/weeventpro/parameters/user", [
      {
        parameterName: INVOICE_INCREMENT,
        parameterValue: inputIncrementValue,
      },
    ])
      .then(() => {})
      .catch((err) => {
        setAddInvoiceNumberFormatFailed(err.cause);
        setIsAddInvoiceNumberFormatLoading(false);
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        INVOICE_FORMAT +
        "," +
        INVOICE_NUMBER_OF_DIGITS +
        "," +
        INVOICE_INCREMENT +
        "," +
        CC_EMAILS,
    }).then((res) => {
      setInputInvoiceNumberFormat(
        getUserParameterValueByName(INVOICE_FORMAT, res)
      );
      setInputNumberOfDigits(
        getUserParameterValueByName(INVOICE_NUMBER_OF_DIGITS, res)
      );
      setInputIncrementValue(
        getUserParameterValueByName(INVOICE_INCREMENT, res)
      );
      setInputCCEmails(
        getUserParameterValueByName(CC_EMAILS, res)
          .split(",")
          .map((email) => {
            let employee = employees.find(
              (employee) => employee.email === email
            );
            if (employee) return employee;
            return email;
          })
      );
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/invoice"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/invoice")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getInvoiceEmailTemplates = () => {
    AxiosHttpClient.get<InvoiceEmailTemplate[]>(
      "api/v1/weeventpro/parameters/template/invoice/email"
    )
      .then((res) => {
        setInvoiceEmailTemplates(res);
      })
      .catch((err) => {});
  };

  const deleteCustomField = (customFieldId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/customFields/invoice/" + customFieldId
    )
      .then(() => {
        getCustomFields();
        setPopupDeleteActive(null);
        setIsDeleteCustomFieldLoading(false);
      })
      .catch((err) => {
        setIsDeleteCustomFieldLoading(false);
      });
  };

  const deleteView = (viewId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/views/invoice/" + viewId
    )
      .then(() => {
        getViews();
        setViewPopupDeleteActive(null);
        setIsDeleteViewLoading(false);
      })
      .catch((err) => {
        setIsDeleteViewLoading(false);
      });
  };

  const deleteInvoiceEmailTemplate = (invoiceEmailTemplateId: string) => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/parameters/template/invoice/email/" +
        invoiceEmailTemplateId
    )
      .then(() => {
        getInvoiceEmailTemplates();
        setInvoiceEmailTemplatePopupDeleteActive(null);
        setIsDeleteInvoiceEmailTemplateLoading(false);
      })
      .catch((err) => {
        setIsDeleteInvoiceEmailTemplateLoading(false);
      });
  };

  const checkUserHasInvoices = () => {
    AxiosHttpClient.get<boolean>("api/v1/weeventpro/invoices/hasInvoices")
      .then((res) => {
        setUserHasInvoices(res);
      })
      .catch((err) => {});
  };

  const [inputViewName, setInputViewName] = useState("");

  const [inputViewNameError, setInputViewNameError] = useState(false);

  const [views, setViews] = useState<View[]>([]);

  const [invoiceEmailTemplates, setInvoiceEmailTemplates] = useState<
    InvoiceEmailTemplate[]
  >([]);

  const [viewPopupDeleteActive, setViewPopupDeleteActive] =
    useState<View | null>(null);

  const [viewPopupEditActive, setViewPopupEditActive] = useState<View | null>(
    null
  );

  const [inputTemplate, setInputTemplate] = useState<any>();

  const [inputTemplateFileName, setInputTemplateFileName] = useState("");

  const [viewAddActive, setViewAddActive] = useState(false);

  const [inputTemplateLabel, setInputTemplateLabel] = useState("");

  const [inputTemplateFileNameError, setInputTemplateFileNameError] =
    useState(false);

  const [inputSelectedFields, setInputSelectedFields] = useState<string[]>([
    "Numéro de facture",
  ]);

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputType, setInputType] = useState("");

  const [inputTypeError, setInputTypeError] = useState(false);

  const [inputValue, setInputValue] = useState("");

  const [inputValueError, setInputValueError] = useState(false);

  const [inputTemplateLabelError, setInputTemplateLabelError] = useState(false);

  const [customFieldNameExpanded, setCustomFieldNameExpanded] = useState("");

  const [inputEmailTemplateName, setInputEmailTemplateName] = useState("");

  const [inputEmailTemplateNameError, setInputEmailTemplateNameError] =
    useState(false);

  const [inputSubject, setInputSubject] = useState("");

  const [inputSubjectError, setInputSubjectError] = useState(false);

  const [inputContent, setInputContent] = useState("");

  const [inputContentError, setInputContentError] = useState(false);

  const [inputValues, setInputValues] = useState<string[]>([]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldAddActive, setCustomFieldAddActive] = useState(false);

  const [invoiceTemplateAddActive, setInvoiceTemplateAddActive] =
    useState(false);

  const [invoiceEmailTemplateAddActive, setInvoiceEmailTemplateAddActive] =
    useState(false);

  const [popupDeleteActive, setPopupDeleteActive] =
    useState<CustomField | null>(null);

  const [popupEditActive, setPopupEditActive] = useState<CustomField | null>(
    null
  );

  const [
    invoiceEmailTemplatePopupEditActive,
    setInvoiceEmailTemplatePopupEditActive,
  ] = useState<InvoiceEmailTemplate | null>(null);

  const [
    invoiceEmailTemplatePopupDeleteActive,
    setInvoiceEmailTemplatePopupDeleteActive,
  ] = useState<InvoiceEmailTemplate | null>(null);

  const [addCustomFieldFailed, setAddCustomFieldFailed] = useState("");

  const [addViewFailed, setAddViewFailed] = useState("");

  const [addInvoiceTemplateFailed, setAddInvoiceTemplateFailed] = useState("");

  const [addInvoiceEmailTemplateFailed, setAddInvoiceEmailTemplateFailed] =
    useState("");

  const [addInvoiceNumberFormatFailed, setAddInvoiceNumberFormatFailed] =
    useState("");

  const [editInvoiceIncrementFailed, setEditInvoiceIncrementFailed] =
    useState("");

  const [isAddCustomFieldLoading, setIsAddCustomFieldLoading] = useState(false);

  const [isAddInvoiceTemplateLoading, setIsAddInvoiceTemplateLoading] =
    useState(false);

  const [isAddViewLoading, setIsAddViewLoading] = useState(false);

  const [isAddInvoiceNumberFormatLoading, setIsAddInvoiceNumberFormatLoading] =
    useState(false);

  const [
    isAddInvoiceEmailTemplateLoading,
    setIsAddInvoiceEmailTemplateLoading,
  ] = useState(false);

  const [isDeleteCustomFieldLoading, setIsDeleteCustomFieldLoading] =
    useState(false);

  const [isDeleteViewLoading, setIsDeleteViewLoading] = useState(false);

  const [
    isDeleteInvoiceEmailTemplateLoading,
    setIsDeleteInvoiceEmailTemplateLoading,
  ] = useState(false);

  const [inputInvoiceNumberFormat, setInputInvoiceNumberFormat] = useState("");

  const [inputNumberOfDigits, setInputNumberOfDigits] = useState("");

  const [inputIncrementValue, setInputIncrementValue] = useState("");

  const [popupDeleteTemplateActive, setPopupDeleteTemplateActive] =
    useState<Template | null>(null);

  const [inputCCEmails, setInputCCEmails] = useState<(Employee | string)[]>([]);

  const [inputCCEmailsText, setInputCCEmailsText] = useState("");

  const [userHasInvoices, setUserHasInvoices] = useState(true);

  const inputFileRef = useRef<any>(null);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const filterEmployeesEmailsOptions = createFilterOptions<Employee>({
    stringify: (option) => option.email,
  });

  const resetCustomFieldForm = () => {
    setInputName("");
    setInputType("");
    setInputValue("");
    setInputValues([]);
  };

  const resetInvoiceTemplateForm = () => {
    setInputTemplate(null);
    setInputTemplateLabel("");
    setInputTemplateFileName("");
    setAddInvoiceTemplateFailed("");
    setInputTemplateLabelError(false);
    setInputTemplateFileNameError(false);
    if (inputFileRef.current) {
      inputFileRef.current.value = null;
    }
  };

  const resetViewForm = () => {
    setInputViewName("");
    setInputSelectedFields(["Numéro de facture"]);
  };

  const resetInvoiceEmailTemplateForm = () => {
    setAddInvoiceEmailTemplateFailed("");
    setInputEmailTemplateName("");
    setInputSubject("");
    setInputContent("");
    setInputEmailTemplateNameError(false);
    setInputSubjectError(false);
    setInputContentError(false);
  };

  const validateInvoiceEmailTemplateForm = () => {
    setInputEmailTemplateNameError(false);
    setInputSubjectError(false);
    setInputContentError(false);
    let result = true;
    if (inputEmailTemplateName === "") {
      setInputEmailTemplateNameError(true);
      result = false;
    }
    if (inputSubject === "") {
      setInputSubjectError(true);
      result = false;
    }
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) {
      setAddInvoiceEmailTemplateFailed("Champs manquants");
    }
    return result;
  };

  const validateCustomFieldForm = () => {
    setInputNameError(false);
    setInputTypeError(false);
    setInputValueError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (inputType === "") {
      setInputTypeError(true);
      result = false;
    }
    if (
      (inputType === "Liste déroulante" ||
        inputType === "Boutton radio" ||
        inputType === "Checkbox") &&
      inputValues.length === 0
    ) {
      setInputValueError(true);
      result = false;
    }
    if (!result) {
      setAddCustomFieldFailed("Champs manquants");
    }
    return result;
  };

  const validateViewForm = () => {
    setInputViewNameError(false);
    let result = true;
    if (inputViewName === "") {
      setInputViewNameError(true);
      result = false;
    }
    if (!result) {
      setAddViewFailed("Champs manquants");
    }
    return result;
  };

  const validateInvoiceTemplateForm = () => {
    setInputTemplateLabelError(false);
    setInputTemplateFileNameError(false);
    let result = true;
    if (inputTemplateLabel === "") {
      setInputTemplateLabelError(true);
      result = false;
    }
    if (!inputTemplate) {
      setInputTemplateFileNameError(true);
      result = false;
    }
    if (!result) {
      setAddInvoiceTemplateFailed("Champs manquants");
    }
    return result;
  };

  const getAllFields = (): string[] => {
    let normalFields = [
      "Numéro de facture",
      "Type",
      "Description",
      "Client",
      "Remise",
      "Date facture",
      "Statut",
      "Acomptes associés",
      "Date d'échéance",
      "Moyens de paiement",
      "Ventes associées",
      "Avoirs associés",
      "Facture associée",
      "Produits",
      "Frais supplémentaires",
      "Montant payé HT",
      "Montant payé TTC",
      "Montant payé TVA",
      "Commentaires",
      "Montant total TTC",
      "Montant total HT",
      "Montant total TVA",
      "Reste à payer TTC",
      "Reste à payer HT",
      "Reste à payer TVA",
      "TVA globale",
    ];
    return [
      ...normalFields,
      ...customFields.map((customField) => "Custom::" + customField.id),
    ];
  };

  function descendingComparator<T>(a: T, b: T) {
    if (a["label"] === "Default") {
      return -1;
    }
    if (b["label"] < a["label"]) {
      return 1;
    }
    if (b["label"] > a["label"]) {
      return -1;
    }
    return 0;
  }

  function stableSort<T>(array: T[]) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = descendingComparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: string[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        inputSelectedFields,
        result.source.index,
        result.destination.index
      );

      setInputSelectedFields(items);
    }
  };

  useEffect(() => {
    getCustomFields();
    getViews();
    getUserParameters();
    getInvoiceTemplatesAction();
    getInvoiceEmailTemplates();
    getEmployeesAction("");
    checkUserHasInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlexContainer flexDirection="column" width="100%">
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Templates
        </Text20>
      </BlockContainer>

      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setInvoiceTemplateAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un template
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={invoiceTemplateAddActive}>
        {addInvoiceTemplateFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddInvoiceTemplateFailed("");
              }}
            >
              {addInvoiceTemplateFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="templateLabel"
                label="Libellé"
                error={inputTemplateLabelError}
                value={inputTemplateLabel}
                onChange={(e) => setInputTemplateLabel(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="templateLabel"
                label="Nom du fichier"
                disabled
                error={inputTemplateFileNameError}
                value={inputTemplateFileName}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              alignItems={"center"}
              display={"flex"}
            >
              <label htmlFor="contained-button-file">
                <Input
                  accept=".docx"
                  id="contained-button-file"
                  multiple
                  type="file"
                  ref={inputFileRef}
                  onChange={(e) => {
                    if (!e.target.files![0].name.endsWith(".docx"))
                      setAddInvoiceTemplateFailed(
                        "Le fichier choisi doit être de format word (*.docx)"
                      );
                    else {
                      setInputTemplate(e.target.files![0]);
                      setInputTemplateFileName(e.target.files![0].name);
                    }
                  }}
                />
                <ButtonMui
                  color="secondary"
                  variant="contained"
                  size="large"
                  $borderRadius="50px"
                  padding="8px 22px 8px 12px"
                  component="span"
                  margin="0 0 0 15px"
                >
                  <AddIcon fontSize="medium" />
                  Charger un fichier
                </ButtonMui>
              </label>
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddInvoiceTemplateLoading}
            loading={isAddInvoiceTemplateLoading}
            onClick={() => {
              setIsAddInvoiceTemplateLoading(true);
              if (validateInvoiceTemplateForm()) {
                addInvoiceTemplate();
                resetInvoiceTemplateForm();
              } else setIsAddInvoiceTemplateLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setInvoiceTemplateAddActive(false);
              resetInvoiceTemplateForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {stableSort(invoiceTemplates).map((invoiceTemplate, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                flexDirection="column"
                alignItems="center"
                $borderRadius="15px"
                elevation={3}
                width="255px"
                overflow="hidden"
              >
                <ImagePreview
                  src={invoiceTemplate.thumbnailLocation}
                  height="360px"
                  width="255px"
                  borderBottom="rgba(0, 0, 0, 0.10) solid 1px"
                  alt=""
                />
                <Text16>{invoiceTemplate.label}</Text16>
                <FlexContainer margin="10px">
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    disabled={invoiceTemplate.used}
                    onClick={() => pickTemplate(invoiceTemplate)}
                  >
                    {invoiceTemplate.used ? "Choisi" : "Choisir"}
                  </ButtonMui>
                  {invoiceTemplate.label !== "Default" && (
                    <ButtonMui
                      color="secondary"
                      variant="outlined"
                      margin="0 0 0 10px"
                      onClick={() =>
                        setPopupDeleteTemplateActive(invoiceTemplate)
                      }
                    >
                      Supprimer
                    </ButtonMui>
                  )}
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Templates des emails
        </Text20>
      </BlockContainer>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier les emails à toujours mettre en CC:
          </Text14>
        </Grid>
        <Grid item xs={1} sm={1} md={2}>
          <Autocomplete
            id="cc"
            filterOptions={filterEmployeesEmailsOptions}
            noOptionsText={"Pas de suggestions"}
            freeSolo
            fullWidth
            multiple
            filterSelectedOptions
            getOptionLabel={(option) => {
              return typeof option === "string" ? option : option.email;
            }}
            options={employees}
            value={inputCCEmails}
            onChange={(event, newValue) => {
              setInputCCEmails(newValue!);
            }}
            inputValue={inputCCEmailsText}
            onInputChange={(event, newInputValue) => {
              setInputCCEmailsText(newInputValue);
            }}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" name="cc" label="CC" />
            )}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            onClick={() => {
              setCCEmails();
            }}
          >
            Enregistrer
          </ButtonMui>
        </Grid>
      </Grid>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setInvoiceEmailTemplateAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un template
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={invoiceEmailTemplateAddActive}>
        {addInvoiceEmailTemplateFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddInvoiceEmailTemplateFailed("");
              }}
            >
              {addInvoiceEmailTemplateFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputEmailTemplateNameError}
                value={inputEmailTemplateName}
                onChange={(e) => setInputEmailTemplateName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="subject"
                label="Objet"
                error={inputSubjectError}
                value={inputSubject}
                onChange={(e) => setInputSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="content"
                label="Corps du message"
                multiline
                rows={5}
                error={inputContentError}
                value={inputContent}
                onChange={(e) => setInputContent(e.target.value)}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddInvoiceEmailTemplateLoading}
            loading={isAddInvoiceEmailTemplateLoading}
            onClick={() => {
              setIsAddInvoiceEmailTemplateLoading(true);
              if (validateInvoiceEmailTemplateForm()) addInvoiceEmailTemplate();
              else setIsAddInvoiceEmailTemplateLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setInvoiceEmailTemplateAddActive(false);
              resetInvoiceEmailTemplateForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 2, md: 3 }}
      >
        {invoiceEmailTemplates.map((invoiceEmailTemplate, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                flexDirection="column"
                alignItems="center"
                $borderRadius="15px"
                elevation={3}
                width="255px"
                overflow="hidden"
              >
                <MailIcon color="primary" style={{ fontSize: 100 }} />
                <Text16>{invoiceEmailTemplate.name}</Text16>
                <FlexContainer margin="10px">
                  <ButtonMui
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      setInvoiceEmailTemplatePopupEditActive(
                        invoiceEmailTemplate
                      )
                    }
                  >
                    Modifier
                  </ButtonMui>
                  <ButtonMui
                    color="secondary"
                    variant="outlined"
                    margin="0 0 0 10px"
                    onClick={() =>
                      setInvoiceEmailTemplatePopupDeleteActive(
                        invoiceEmailTemplate
                      )
                    }
                  >
                    Supprimer
                  </ButtonMui>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Champs personnalisés
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setCustomFieldAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un champ personnalisé
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={customFieldAddActive}>
        {addCustomFieldFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddCustomFieldFailed("");
              }}
            >
              {addCustomFieldFailed}
            </Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="custpomFieldTypeLabel">Type</InputLabel>
                <Select
                  labelId="custpomFieldTypeLabel"
                  id="custpomFieldType"
                  required
                  error={inputTypeError}
                  value={inputType}
                  label="Type"
                  onChange={(e) => setInputType(e.target.value)}
                >
                  <MenuItem value="Texte">Texte</MenuItem>
                  <MenuItem value="Liste déroulante">Liste déroulante</MenuItem>
                  <MenuItem value="Date">Date</MenuItem>
                  <MenuItem value="Boutton radio">Boutton radio</MenuItem>
                  <MenuItem value="Checkbox">Checkbox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {["Liste déroulante", "Boutton radio", "Checkbox"].includes(
              inputType
            ) && (
              <Grid item xs={1} sm={1} md={2} alignItems="center">
                <Text16>
                  Veuillez saisir les valeur possibles pour ce champs!
                </Text16>
                <FlexContainer alignItems="center">
                  <TextField
                    fullWidth
                    name="name"
                    label="Valeur"
                    sx={{ margin: "0 16px 0 0" }}
                    error={inputValueError}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <Fab
                    size="small"
                    color="secondary"
                    disabled={inputValue === ""}
                    onClick={() => {
                      setInputValues([...inputValues, inputValue]);
                      setInputValue("");
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </FlexContainer>
                <FlexContainer justifyContent="center" margin="16px 0">
                  <FlexContainer
                    flexDirection="column"
                    backgroundColor={CustomTheme.palette.primary.light}
                    elevation={3}
                    $borderRadius="4px"
                  >
                    {inputValues.map((value, index) => (
                      <FlexContainer justifyContent="space-between" key={index}>
                        <Text14 color={CustomTheme.palette.primary.main}>
                          {value}
                        </Text14>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            let newArr = [...inputValues];
                            newArr.splice(index, 1);
                            setInputValues(newArr);
                          }}
                          size="small"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </FlexContainer>
                    ))}
                  </FlexContainer>
                </FlexContainer>
              </Grid>
            )}
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddCustomFieldLoading}
            loading={isAddCustomFieldLoading}
            onClick={() => {
              setIsAddCustomFieldLoading(true);
              if (validateCustomFieldForm()) addCustomField();
              else setIsAddCustomFieldLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setCustomFieldAddActive(false);
              resetCustomFieldForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>

      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 5 }}
      >
        {customFields.map((customField, index) => (
          <Grid item xs={1} sm={1} md={1} key={index}>
            <FlexContainer
              flexDirection="column"
              elevation={3}
              $borderRadius="10px"
              alignItems="center"
              position="relative"
            >
              <FlexContainer
                position="absolute"
                top="0"
                right="0"
                padding="4px"
                alignItems="flex-end"
              >
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setPopupEditActive(customField)}
                >
                  <EditOutlinedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  size="small"
                  color="secondary"
                  onClick={() => setPopupDeleteActive(customField)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </FlexContainer>
              <FlexContainer padding="32px 0 16px 0">
                <Text14>{customField.name}</Text14>
              </FlexContainer>
              <FlexContainer
                padding="8px 0"
                width="100%"
                backgroundColor={CustomTheme.palette.primary.light}
                $borderBottomLeftRadius="10px"
                $borderBottomRightRadius="10px"
                flexDirection="column"
              >
                <FlexContainer
                  width="100%"
                  alignItems="center"
                  justifyContent="center"
                  $cursorHover={
                    ["Liste déroulante", "Boutton radio", "Checkbox"].includes(
                      customField.type
                    )
                      ? "pointer"
                      : "auto"
                  }
                  onClick={() => {
                    if (customFieldNameExpanded === customField.name)
                      setCustomFieldNameExpanded("");
                    else {
                      if (customField.values.length > 0)
                        setCustomFieldNameExpanded(customField.name);
                    }
                  }}
                >
                  <Text14
                    fontWeight="500"
                    margin="4px"
                    color={CustomTheme.palette.primary.main}
                  >
                    {customField.type}
                  </Text14>
                  {customField.values.length > 0 &&
                    customFieldNameExpanded !== customField.name && (
                      <ExpandMoreIcon color="primary" />
                    )}
                  {customField.values.length > 0 &&
                    customFieldNameExpanded === customField.name && (
                      <ExpandLessIcon color="primary" />
                    )}
                </FlexContainer>
                <Collapse
                  orientation="vertical"
                  in={customFieldNameExpanded === customField.name}
                >
                  {customField.values.map((value, index) => (
                    <FlexContainer key={index}>
                      <Text14 color={CustomTheme.palette.primary.main}>
                        {value}
                      </Text14>
                    </FlexContainer>
                  ))}
                </Collapse>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Vues personnalisées
        </Text20>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setViewAddActive(true);
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une vue personnalisée
          </Text16>
        </FlexContainer>
      </FlexContainer>

      <Collapse orientation="vertical" in={viewAddActive}>
        {addViewFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddViewFailed("");
              }}
            >
              {addViewFailed}
            </Alert>
          </BlockContainer>
        )}
        <BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputViewNameError}
                value={inputViewName}
                onChange={(e) => setInputViewName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={getAllFields()}
                disableCloseOnSelect
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={
                        option.includes("Custom::")
                          ? getCustomFieldNameByCustomFieldId(
                              option.substring(8)
                            )
                          : option
                      }
                      disabled={option === "Numéro de facture"}
                      key={index}
                    />
                  ));
                }}
                getOptionLabel={(option) => option}
                value={inputSelectedFields}
                getOptionDisabled={(option) =>
                  !inputSelectedFields.includes(option) &&
                  inputSelectedFields.length >= 7
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputSelectedFields.includes(option)}
                    />
                    {option.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(option.substring(8))
                      : option}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputSelectedFields([
                    "Numéro de facture",
                    ...values.filter(
                      (option) => option !== "Numéro de facture"
                    ),
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Champs"
                    helperText={
                      "Champs sélectionnés: " +
                      inputSelectedFields.length +
                      "/7"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <BlockContainer
            padding="16px 0"
            border="1px dashed black"
            margin="16px 0"
            $borderRadius="10px"
          >
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text16>Spécifiez l'ordre des champs</Text16>
            </FlexContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                direction={matches ? "horizontal" : "vertical"}
              >
                {(provided, snapshot) => (
                  <FlexContainer
                    padding="16px 8px 16px 8px"
                    flexDirection={matches ? "row" : "column"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {inputSelectedFields.map((field, index) => (
                      <Draggable
                        key={field + "id"}
                        draggableId={field + "id"}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <FlexContainer
                            height="56px"
                            backgroundColor={CustomTheme.palette.primary.main}
                            elevation={snapshot.isDragging ? 9 : 0}
                            flex="1"
                            alignItems="center"
                            justifyContent="center"
                            padding="16px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Text14
                              textAlign="center"
                              color="white"
                              margin="0"
                              lineHeight="14px"
                            >
                              {field.includes("Custom::")
                                ? getCustomFieldNameByCustomFieldId(
                                    field.substring(8)
                                  )
                                : field}
                            </Text14>
                          </FlexContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FlexContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BlockContainer>
        </BlockContainer>
        <FlexContainer justifyContent="center" margin="16px 0">
          <ButtonMui
            margin="16px 4px 0 0"
            disabled={isAddViewLoading}
            loading={isAddViewLoading}
            onClick={() => {
              setIsAddViewLoading(true);
              if (validateViewForm()) addView();
              else setIsAddViewLoading(false);
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setViewAddActive(false);
              resetViewForm();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {views.map((view, index) => (
        <BlockContainer padding="16px 0" key={index}>
          <FlexContainer
            flexDirection="column"
            elevation={3}
            $borderRadius="10px"
            alignItems="center"
            position="relative"
          >
            <FlexContainer
              position="absolute"
              top="0"
              right="0"
              padding="4px"
              alignItems="flex-end"
            >
              <IconButton
                size="small"
                color="primary"
                onClick={() => setViewPopupEditActive(view)}
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => setViewPopupDeleteActive(view)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text14>Vue: </Text14>
              <Text14 fontWeight="500" margin="4px 0">
                {view.name}
              </Text14>
            </FlexContainer>
            <FlexContainer
              padding="16px 8px 16px 8px"
              width="100%"
              flexWrap="wrap"
            >
              {view.fields.map((field, index) => (
                <FlexContainer
                  key={index}
                  height="56px"
                  backgroundColor={CustomTheme.palette.primary.main}
                  flex="1"
                  alignItems="center"
                  justifyContent="center"
                  padding="16px"
                >
                  <Text14 color="white" margin="0" lineHeight="14px">
                    {field.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(field.substring(8))
                      : field}
                  </Text14>
                </FlexContainer>
              ))}
            </FlexContainer>
          </FlexContainer>
        </BlockContainer>
      ))}
      <Divider sx={{ mt: "16px" }} />
      <BlockContainer margin="16px 0">
        <Text20 fontWeight="600" textAlign="left" margin="0">
          Format des numéros de factures
        </Text20>
      </BlockContainer>
      <Grid
        container
        spacing={{ xs: 1, sm: 1, md: 2 }}
        columns={{ xs: 1, sm: 1, md: 2 }}
      >
        <Grid item xs={1} sm={1} md={2}>
          <Text14>
            Veuillez spécifier le format du numérotage des facture finales:
          </Text14>
          <Text12 color="black" opacity="0.7">
            Exemple: si vos numéros de factures sont du format "FA0001",
            "FA0002", etc tapez "FA%increment%" pour le format et 4 dans le
            nombre de chiffres dans l'incrément
          </Text12>
        </Grid>
        {addInvoiceNumberFormatFailed !== "" && (
          <Grid item xs={1} sm={1} md={2}>
            <BlockContainer margin="0 0 8px 0">
              <Alert severity="error">{addInvoiceNumberFormatFailed}</Alert>
            </BlockContainer>
          </Grid>
        )}
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="invoiceNumberFormat"
            label="Format"
            value={inputInvoiceNumberFormat}
            onChange={(e) => {
              setInputInvoiceNumberFormat(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="numberOfDigits"
            label="Nombre de chiffres dans l'incrément"
            value={inputNumberOfDigits}
            onChange={(e) => {
              setInputNumberOfDigits(e.target.value);
            }}
          />
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems={"center"}
        >
          <FlexContainer>
            <ButtonMui
              color="primary"
              variant="contained"
              size="large"
              disabled={isAddInvoiceNumberFormatLoading}
              loading={isAddInvoiceNumberFormatLoading}
              onClick={() => {
                addInvoiceNumberFormat();
              }}
            >
              Enregistrer
            </ButtonMui>
          </FlexContainer>
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          <Text14>Veuillez spécifier la valeur actuelle de l'incrément:</Text14>
        </Grid>
        {editInvoiceIncrementFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditInvoiceIncrementFailed("");
              }}
            >
              {editInvoiceIncrementFailed}
            </Alert>
          </BlockContainer>
        )}
        <Grid item xs={1} sm={1} md={1}>
          <TextField
            fullWidth
            variant="outlined"
            name="incrementValue"
            label="Valeur de l'incrément"
            value={inputIncrementValue}
            onChange={(e) => {
              setInputIncrementValue(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1}>
          <ButtonMui
            color="primary"
            variant="contained"
            size="large"
            disabled={userHasInvoices}
            onClick={() => {
              editIncrementValue();
            }}
          >
            Enregistrer
          </ButtonMui>
        </Grid>
      </Grid>
      {popupEditActive !== null && (
        <EditCustomField
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getCustomFields={getCustomFields}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteCustomField
          popupActive={popupDeleteActive}
          isDeleteCustomFieldLoading={isDeleteCustomFieldLoading}
          setIsDeleteCustomFieldLoading={setIsDeleteCustomFieldLoading}
          setPopupActive={setPopupDeleteActive}
          deleteCustomField={deleteCustomField}
        />
      )}
      {viewPopupEditActive !== null && (
        <EditView
          popupActive={viewPopupEditActive}
          customFields={customFields}
          setPopupActive={setViewPopupEditActive}
          getViews={getViews}
        />
      )}
      {viewPopupDeleteActive !== null && (
        <DeleteView
          popupActive={viewPopupDeleteActive}
          isDeleteViewLoading={isDeleteViewLoading}
          setIsDeleteViewLoading={setIsDeleteViewLoading}
          setPopupActive={setViewPopupDeleteActive}
          deleteView={deleteView}
        />
      )}
      {popupDeleteTemplateActive !== null && (
        <DeleteTemplate
          popupActive={popupDeleteTemplateActive}
          setPopupActive={setPopupDeleteTemplateActive}
        />
      )}
      {invoiceEmailTemplatePopupEditActive !== null && (
        <EditInvoiceEmailTemplate
          popupActive={invoiceEmailTemplatePopupEditActive}
          setPopupActive={setInvoiceEmailTemplatePopupEditActive}
          getInvoiceEmailTemplates={getInvoiceEmailTemplates}
        />
      )}
      {invoiceEmailTemplatePopupDeleteActive !== null && (
        <DeleteInvoiceEmailTemplate
          popupActive={invoiceEmailTemplatePopupDeleteActive}
          isDeleteInvoiceEmailTemplateLoading={
            isDeleteInvoiceEmailTemplateLoading
          }
          setIsDeleteInvoiceEmailTemplateLoading={
            setIsDeleteInvoiceEmailTemplateLoading
          }
          setPopupActive={setInvoiceEmailTemplatePopupDeleteActive}
          deleteInvoiceEmailTemplate={deleteInvoiceEmailTemplate}
        />
      )}
    </FlexContainer>
  );
};

export const CustomizeInvoices = connect(
  (state: RootState) => ({
    invoiceTemplates: getInvoiceTemplates(state),
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getInvoiceTemplatesAction: getInvoiceTemplatesAction,
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_CustomizeInvoices);

export default CustomizeInvoices;
