import styled from "styled-components";
import { ThemeCustom } from "../Utils";

interface IHeadingStyled {
  width?: string;
  height?: string;
  margin?: string;
  $borderRadius?: string;
  padding?: string;
  background?: string;
}

export const ButtonCustom = styled.span<IHeadingStyled>`
  text-decoration: none;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  background: ${(props) => props.background};
  background-size: cover;
  border: 1px dashed ${ThemeCustom.colors.pink};
  border-radius: ${(props) => props.$borderRadius};
  margin: ${(props) => props.margin};
  outline: none;
  display: inline-block;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;
