import ArticleIcon from "@mui/icons-material/Article";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../styles/ImagePreview";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import NavigationPageHeader from "../../../../Reusable/NavigationPageHeader";
import { calculateAge } from "../../../../Reusable/Utils";

export interface EmployeeDetailsProps {}

const useStyles = makeStyles({
  paper: {
    width: "100%",
    height: "100%",
  },
});

const _EmployeeDetails: React.FC<EmployeeDetailsProps> = (props) => {
  const classes = useStyles();
  const [employee, setEmployee] = useState<Employee>();

  const mockDocuments = [
    {
      type: "Devis",
      name: "Mariage Aymane et Joanna",
    },
    {
      type: "Facture finale",
      name: "Mariage Aymane et Joanna",
    },
    {
      type: "Devis",
      name: "Pot de départ",
    },
    {
      type: "Facture finale",
      name: "Pot de départ",
    },
  ];

  useEffect(() => {
    getEmployeeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEmployeeDetails = () => {
    AxiosHttpClient.get<Employee>(
      "api/v1/weeventpro/rh/employees/get/" + params.employeeId
    ).then((response) => {
      setEmployee(response);
    });
  };

  const params = useParams();

  return (
    <BlockContainer>
      <NavigationPageHeader
        title="Employees"
        subTitle={employee ? employee.firstName + " " + employee.lastName : ""}
        backPath="/feature/employees"
      />
      <FlexContainer flexDirection="column" alignItems="center">
        <ImagePreview
          src={
            "https://" +
            process.env.REACT_APP_BUCKET_NAME! +
            ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
          }
          alt=""
          height="167px"
          width="125px"
        />
        <FlexContainer>
          <Text20 fontWeight="600">
            {employee?.firstName + " " + employee?.lastName}
          </Text20>
        </FlexContainer>
        <FlexContainer>
          <Text16>{calculateAge(employee?.dateOfBirth) + " ans"}</Text16>
        </FlexContainer>
        <FlexContainer>
          <Text16>{employee?.position}</Text16>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer margin="0 24px 24px 24px">
        <Paper elevation={3} className={classes.paper}>
          <FlexContainer
            flexDirection="column"
            alignItems="start"
            padding="24px"
          >
            <FlexContainer width="100%">
              <Text20 fontWeight="600">Informations</Text20>
            </FlexContainer>
            <FlexContainer
              borderBottom="1px solid rgba(0,0,0,0.2)"
              margin="8px 24px 0 0"
              width="calc(100% - 48px)"
            ></FlexContainer>
            <FlexContainer width="100%" margin="32px 0 8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Nom Complet:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">
                  {employee?.firstName + " " + employee?.lastName}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Poste:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.position}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Date de naissance:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.dateOfBirth}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>N° de teléphone:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.telNumber}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Adresse e-mail:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.email}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Adresse:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.address}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer width="100%" margin="8px 0">
              <FlexContainer flex="1" justifyContent="flex-end">
                <Text14>Salaire:</Text14>
              </FlexContainer>
              <FlexContainer flex="1">
                <Text14 fontWeight="600">{employee?.salary + " €/mois"}</Text14>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>

      <FlexContainer margin="0 24px 24px 24px" height="500px">
        <FlexContainer flex="1" margin="0 12px 0 0">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Congés</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 0 0"
                width="calc(100% - 48px)"
              ></FlexContainer>
            </FlexContainer>
          </Paper>
        </FlexContainer>
        <FlexContainer flex="1" margin="0 0 0 12px">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Documents</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 32px 0"
                width="calc(100% - 48px)"
              ></FlexContainer>
              <Grid
                container
                spacing={{ xs: 5, md: 7 }}
                columns={{ xs: 1, sm: 2, md: 3 }}
              >
                {mockDocuments.map((mockDocument) => (
                  <Grid item xs={1} sm={1} md={1} key={mockDocument.name}>
                    <FlexContainer
                      flex="1"
                      flexDirection="column"
                      alignItems="center"
                      $backgroundHover="rgb(255,0,0,0.1)"
                      $cursorHover="pointer"
                      padding="16px 0"
                    >
                      <ArticleIcon fontSize="large" color="primary" />
                      <Text14>
                        {mockDocument.type + ": " + mockDocument.name}
                      </Text14>
                    </FlexContainer>
                  </Grid>
                ))}
              </Grid>
            </FlexContainer>
          </Paper>
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
};

export const EmployeeDetails = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_EmployeeDetails);

export default _EmployeeDetails;
