import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../models/client";
import { Prospect } from "../../../../../models/prospect";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getClientsAction,
  getOpportunitiesAction,
  getProspectsAction,
} from "../../../../../store/Crm/actions";
import { getClients, getProspects } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getCurrentDate } from "../../../../Reusable/Utils";

export interface AddOpportunityProps {
  input: string;
  popupActive: boolean;
  clients: Client[];
  prospects: Prospect[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getOpportunitiesAction: typeof getOpportunitiesAction;
  getClientsAction: typeof getClientsAction;
  getProspectsAction: typeof getProspectsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _AddOpportunity: React.FC<AddOpportunityProps> = ({
  input,
  popupActive,
  clients,
  prospects,
  setPopupActive,
  getOpportunitiesAction,
  getClientsAction,
  getProspectsAction,
}) => {
  const addOpportunity = () => {
    AxiosHttpClient.put("api/v1/weeventpro/crm/opportunities/add", {
      contactType: "prospect",
      id: "",
      label: inputLabel,
      contactIsProspect: inputIsProspect,
      amount: inputAmount,
      probability: inputAmount,
      step: inputStep,
      targetDate: inputTargetDate,
      contact: null,
    })
      .then(() => {
        getOpportunitiesAction(input);
        setPopupActive(false);
      })
      .catch((err) => {
        SetAddFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputLabelError(false);
    let result = true;
    if (inputLabel === "") {
      SetInputLabelError(true);
      result = false;
    }
    if (!result) SetAddFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    getClientsAction("");
    getProspectsAction("");
  }, [getClientsAction, getProspectsAction]);

  const [inputLabel, SetInputLabel] = useState<string>("");

  const [inputLabelError, SetInputLabelError] = useState(false);

  const [inputIsProspect, SetInputIsProspect] = useState(true);

  const [inputAmount, SetInputAmount] = useState("");

  const [inputProbability, SetInputProbability] = useState("");

  const [inputStep, SetInputStep] = useState("");

  const [inputTargetDate, SetInputTargetDate] = useState(
    new Date(getCurrentDate()).toISOString()
  );

  const [addFailed, SetAddFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une opportunité"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {addFailed !== "" && <Alert severity="error">{addFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="label"
                label="Libelé"
                required
                error={inputLabelError}
                value={inputLabel}
                onChange={(e) => SetInputLabel(e.target.value)}
              />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Type du contact
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputIsProspect ? "prospect" : "client"}
                  onChange={(e) => {
                    e.target.value === "prospect"
                      ? SetInputIsProspect(true)
                      : SetInputIsProspect(false);
                  }}
                  row
                >
                  <FormControlLabel
                    value="prospect"
                    control={<Radio />}
                    label="Prospect"
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Client"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="outlined"
                name="amount"
                label="Gain"
                value={inputAmount}
                onChange={(e) => SetInputAmount(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="probability"
                label="Probabilité"
                value={inputProbability}
                onChange={(e) => SetInputProbability(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="step"
                label="Étape commerciale"
                value={inputStep}
                onChange={(e) => SetInputStep(e.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Du"
                  value={inputTargetDate}
                  onChange={(value) => SetInputTargetDate(value!)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) addOpportunity();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddOpportunity = connect(
  (state: RootState) => ({
    clients: getClients(state),
    prospects: getProspects(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getOpportunitiesAction: getOpportunitiesAction,
        getClientsAction: getClientsAction,
        getProspectsAction: getProspectsAction,
      },
      dispatch
    )
)(_AddOpportunity);

export default AddOpportunity;
