import React from "react";
import { Outlet } from "react-router-dom";
import { FlexContainer } from "../../../styles/FlexContainer";

export interface ContentSectionProps {
  sideNavShow: boolean;
}

const ContentSection: React.FC<ContentSectionProps> = ({ sideNavShow }) => {
  return (
    <FlexContainer
      backgroundColor="#f5f3f0"
      flexDirection="column"
      minHeight="calc(100vh - 65px)"
      sx={{
        width: {
          xs: "100vw",
          md: "calc(100vw - 258px)",
        },
        left: {
          xs: "0px",
          md: "258px",
        },
        margin: {
          xs: "65px 0 0 0",
          md: "65px 0 0 258px",
        },
      }}
    >
      <Outlet />
    </FlexContainer>
  );
};

export default ContentSection;
