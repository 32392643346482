import React, { useEffect, useState } from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text16 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface TabsDesktopProps {
  tabs: string[];
  components: JSX.Element[];
}

const TabsDesktop: React.FC<TabsDesktopProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState(props.tabs[0]);

  useEffect(() => {}, []);

  return (
    <FlexContainer flexDirection="column">
      <FlexContainer>
        <FlexContainer backgroundColor="white">
          {props.tabs.map((tab, index) => (
            <FlexContainer
              key={index}
              padding="8px 32px"
              $cursorHover="pointer"
              borderTop={
                selectedTab === tab
                  ? "2px solid " + CustomTheme.palette.primary.main
                  : undefined
              }
              elevation={selectedTab === tab ? 3 : undefined}
              $backgroundAfter={selectedTab === tab ? "white" : undefined}
              $heightAfter={selectedTab === tab ? "20px" : undefined}
              $contentAfter={selectedTab === tab ? "''" : undefined}
              $positionAfter={selectedTab === tab ? "absolute" : undefined}
              $bottomAfter={selectedTab === tab ? "-10px" : undefined}
              $leftAfter={selectedTab === tab ? "0px" : undefined}
              $rightAfter={selectedTab === tab ? "0px" : undefined}
              position={selectedTab === tab ? "relative" : undefined}
              zIndex={selectedTab === tab ? "1" : undefined}
              onClick={(e) => setSelectedTab(tab)}
            >
              <Text16>{tab}</Text16>
            </FlexContainer>
          ))}
        </FlexContainer>
      </FlexContainer>
      <BlockContainer backgroundColor="white" elevation={3}>
        {props.components[props.tabs.indexOf(selectedTab)]}
      </BlockContainer>
    </FlexContainer>
  );
};

export default TabsDesktop;
