import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { MaterialTable } from "./MaterialTable";

export interface MaterialStockProps {}

const MaterialStock: React.FC<MaterialStockProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Stock matériel"
        subTitle="Liste des materiels"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <MaterialTable />
    </BlockContainer>
  );
};

export default MaterialStock;
