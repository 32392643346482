import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish } from "../../../../../models/dish";
import { AdditionalFee, Invoice } from "../../../../../models/invoice";
import { SoldItem } from "../../../../../models/sale";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getInvoicesAction } from "../../../../../store/Invoice/actions";
import { getUpfrontInvoicesAction } from "../../../../../store/UpfrontInvoice/actions";
import { getDishes, getUpfrontInvoices } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  INVOICE_FORMAT,
  INVOICE_INCREMENT,
  INVOICE_NUMBER_OF_DIGITS,
  getClientDescription,
  getHTPriceFromTTC,
  getTTCPriceFromHT,
  getUserParameterValueByName,
  isDateValid,
  toFixed2,
} from "../../../../Reusable/Utils";

export interface GenerateCreditNoteProps {
  popupActive: Invoice;
  dishes: Dish[];
  upfrontInvoices: Invoice[];
  setPopupActive: React.Dispatch<React.SetStateAction<Invoice | null>>;
  getDishesAction: typeof getDishesAction;
  getUpfrontInvoices: typeof getUpfrontInvoicesAction;
  actionsOnGenerate?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "900px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _GenerateCreditNote: React.FC<GenerateCreditNoteProps> = ({
  popupActive,
  upfrontInvoices,
  setPopupActive,
  getDishesAction,
  getUpfrontInvoices,
  actionsOnGenerate,
}) => {
  /* TODO */
  const addInvoice = () => {
    let totalHt = getTotalHt();
    let totalTTC = getTotalTTC();
    AxiosHttpClient.post("api/v1/weeventpro/invoices", {
      id: "",
      invoiceNumber: inputInvoiceNumber,
      description: inputDescription,
      clientId: popupActive.client ? popupActive.client.id : "",
      soldItems: inputSoldItems,
      emailSent: false,
      date: dayjs(inputInvoiceDate),
      dueDate: dayjs(inputDueDate),
      paymentMethods: inputPaymentMethods,
      associatedSalesIds: [],
      additionalFees: inputAdditionalFees,
      comments: inputComments,
      discount: inputDiscount,
      discountCalculation: inputDiscountCalculation,
      documentUrl: "",
      associatedUpfrontInvoicesIds: inputAssociatedUpfrontInvoices.map(
        (upfrontInvoice) => upfrontInvoice.id
      ),
      associatedCreditNotesIds: [],
      associatedInvoiceId: popupActive.id,
      type: "Avoir",
      payments: [],
      totalPriceHT: totalHt,
      totalPriceTVA: toFixed2(parseFloat(totalTTC) - parseFloat(totalHt)),
      totalPriceTTC: totalTTC,
      remainingPriceHT: totalHt,
      remainingPriceTVA: toFixed2(parseFloat(totalTTC) - parseFloat(totalHt)),
      remainingPriceTTC: totalTTC,
      globalTVA: toFixed2(
        (parseFloat(totalTTC) / parseFloat(totalHt) - 1) * 100
      ),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnGenerate?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "generateCreditNoteDialogContentId",
        });
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        INVOICE_FORMAT +
        "," +
        INVOICE_NUMBER_OF_DIGITS +
        "," +
        INVOICE_INCREMENT,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(INVOICE_INCREMENT, res);
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(INVOICE_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        INVOICE_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputInvoiceNumber(invoiceNumber);
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/invoice"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getInvoicesLastDate = () => {
    AxiosHttpClient.get<string>("api/v1/weeventpro/invoices/lastDate").then(
      (res) => {
        setLastInvoiceDate(dayjs(res));
        if (dayjs(res).isAfter(dayjs())) {
          setInputInvoiceDate(dayjs(res));
        }
      }
    );
  };

  const paymentMethodsList = [
    "Espèces",
    "Chèque",
    "Virement bancaire",
    "Carte de crédit",
    "Carte de débit",
    "PayPal",
    "Portefeuille numérique",
    "Prélèvement automatique",
  ];

  const validateEditSoldItemForm = (index: number) => {
    setInputSaleOptionQuantityEditError(false);
    setEditSaleOptionItemFailed("");
    let result = true;
    if (
      Number(inputSaleOptionQuantityEdit) <
      -popupActive.soldItems[index].quantity
    ) {
      setEditSaleOptionItemFailed(
        "La quantité liée à l'avoir ne peut être supérieure à celle de la facture pour ce produit!"
      );
      return false;
    }
    if (inputSaleOptionQuantityEdit === "") {
      setInputSaleOptionQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditAdditionalFeeForm = (index: number) => {
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setEditAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(inputAdditionalFeePriceHTEdit) <
      -popupActive.additionalFees[index].priceHT
    ) {
      setEditAdditionalFeeItemFailed(
        "Le prix liée à l'avoir ne peut être supérieure à celui de la facture pour ce frais!"
      );
      return false;
    }
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHTEdit) *
            (1 + Number(inputAdditionalFeeTvaEdit) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTCEdit)
    ) {
      setEditAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeePriceHTEdit === "") {
      setInputAdditionalFeePriceHTEditError(true);
      result = false;
    }
    if (inputAdditionalFeeTvaEdit === "") {
      setInputAdditionalFeeTvaEditError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTCEdit === "") {
      setInputAdditionalFeePriceTTCEditError(true);
      result = false;
    }
    if (!result) {
      setEditAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputDescriptionError(false);
    setInputInvoiceDateError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (
      !isDateValid(inputInvoiceDate) ||
      inputInvoiceDate!.isBefore(lastInvoiceDate, "day")
    ) {
      setAddFailed("Date invalide!");
      setInputInvoiceDateError(true);
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "generateCreditNoteDialogContentId",
      });
      return false;
    }
    if (inputSoldItems.length === 0) {
      setAddFailed("Vous n'avez aucun produit vendu pour cette facture!");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "generateCreditNoteDialogContentId",
      });
      return false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "generateCreditNoteDialogContentId",
      });
    }
    return result;
  };

  const resetEditForm = () => {
    setEditSaleOptionItemFailed("");
    setInputSaleOptionQuantityEdit("");
    setInputSaleOptionQuantityEditError(false);
  };

  const resetAdditionalFeeEditForm = () => {
    setEditAdditionalFeeItemFailed("");
    setInputAdditionalFeePriceHTEdit("");
    setInputAdditionalFeeTvaEdit("");
    setInputAdditionalFeePriceTTCEdit("");
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  const [inputDescription, setInputDescription] = useState(
    "Avoir sur la facture " + popupActive.invoiceNumber
  );

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [inputInvoiceNumber, setInputInvoiceNumber] = useState("");

  const [inputInvoiceDate, setInputInvoiceDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputInvoiceDateError, setInputInvoiceDateError] = useState(false);

  const [inputDueDate, setInputDueDate] = useState<Dayjs | null>(null);

  const [inputPaymentMethods, setInputPaymentMethods] = useState<string[]>([]);

  const [inputComments, setInputComments] = useState("");

  const [inputAssociatedUpfrontInvoices, setInputAssociatedUpfrontInvoices] =
    useState<Invoice[]>([]);

  const [
    inputAssociatedUpfrontInvoicesText,
    setInputAssociatedUpfrontInvoicesText,
  ] = useState("");

  const [inputSoldItems, setInputSoldItems] = useState<SoldItem[]>(
    popupActive.soldItems.map((soldItem) => {
      return {
        ...soldItem,
        id: uuid(),
        quantity: -soldItem.quantity,
      };
    })
  );

  const [inputAdditionalFees, setInputAdditionalFees] = useState<
    AdditionalFee[]
  >(
    popupActive.additionalFees.map((additionalFee) => {
      return {
        ...additionalFee,
        id: uuid(),
        priceHT: -additionalFee.priceHT,
      };
    })
  );

  const [inputSaleOptionQuantityEdit, setInputSaleOptionQuantityEdit] =
    useState("");

  const [inputAdditionalFeePriceHTEdit, setInputAdditionalFeePriceHTEdit] =
    useState("");

  const [inputAdditionalFeeTvaEdit, setInputAdditionalFeeTvaEdit] =
    useState("");

  const [inputAdditionalFeePriceTTCEdit, setInputAdditionalFeePriceTTCEdit] =
    useState("");

  const [inputDiscount, setInputDiscount] = useState(
    popupActive.discount || Number(popupActive.discount) !== 0
      ? toFixed2(-popupActive.discount)
      : ""
  );

  const [inputDiscountCalculation, setInputDiscountCalculation] = useState(
    popupActive.discountCalculation
  );

  const [
    inputSaleOptionQuantityEditError,
    setInputSaleOptionQuantityEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceHTEditError,
    setInputAdditionalFeePriceHTEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceTTCEditError,
    setInputAdditionalFeePriceTTCEditError,
  ] = useState(false);

  const [inputAdditionalFeeTvaEditError, setInputAdditionalFeeTvaEditError] =
    useState(false);

  const [editSaleOptionItemFailed, setEditSaleOptionItemFailed] = useState("");

  const [editAdditionalFeeItemFailed, setEditAdditionalFeeItemFailed] =
    useState("");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [saleOptionItemEditActive, setSaleOptionItemEditActive] = useState("");

  const [additionalFeeItemEditActive, setAdditionalFeeItemEditActive] =
    useState("");

  const [lastInvoiceDate, setLastInvoiceDate] = useState<Dayjs | null>(null);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  let removeSaleOptionItem = (index: number) => {
    let newArr = [...inputSoldItems];
    newArr.splice(index, 1);
    setInputSoldItems(newArr);
  };

  let removeAdditionalFeeItem = (index: number) => {
    let newArr = [...inputAdditionalFees];
    newArr.splice(index, 1);
    setInputAdditionalFees(newArr);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterAssociatedUpfontInvicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
  });

  const getTotalHtText = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = -(totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return (
      toFixed2(totalHt) +
      (inputDiscount === ""
        ? ""
        : " - " +
          toFixed2(discountHT) +
          " = " +
          toFixed2(totalHt - discountHT)) +
      " €"
    );
  };

  const getTotalHt = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = -(totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return toFixed2(totalHt - discountHT);
  };

  const getTotalHtBeforeUpfront = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = -(totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return totalHt - discountHT;
  };

  const getTotalTTCBeforeDiscount = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    const totalTTC = totalSoldItems + totalAdditionalFees;

    return toFixed2(totalTTC);
  };

  const getTotalTTCText = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = -(totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return (
      toFixed2(totalTTC) +
      (inputDiscount === ""
        ? ""
        : " - " +
          toFixed2(discountTTC) +
          " = " +
          toFixed2(totalTTC - discountTTC)) +
      " €"
    );
  };

  const getTotalTTC = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = -(totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return toFixed2(totalTTC - discountTTC);
  };

  const getTotalTTCBeforeUpfront = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem!.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = -(totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return totalTTC - discountTTC;
  };

  const getTotalHtBeforeDiscount = () => {
    const totalSoldItems = inputSoldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    const totalHt = totalSoldItems + totalAdditionalFees;
    return toFixed2(totalHt);
  };

  const getRemainingPriceHTText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        return acc + priceHT;
      },
      0
    );
    const totalHtBeforeUpfront = getTotalHtBeforeUpfront();

    return (
      toFixed2(totalHtBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalHtBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  const getRemainingPriceTTCText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        const tvaRate = upfrontInvoice.globalTVA / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );
    const totalTTCBeforeUpfront = getTotalTTCBeforeUpfront();

    return (
      toFixed2(totalTTCBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalTTCBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  useEffect(() => {
    getDishesAction("");
    getInvoicesAction("");
    getUserParameters();
    getUpfrontInvoices("");
    getInvoicesLastDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDishesAction, getInvoicesAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Générer un avoir"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="generateCreditNoteDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="InvoiceNumber"
                label="Numéro de facture"
                disabled
                value={inputInvoiceNumber}
                onChange={(e) => {
                  setInputInvoiceNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                id="paymentMethods"
                disableCloseOnSelect
                options={paymentMethodsList}
                value={inputPaymentMethods}
                getOptionLabel={(option) => (option ? option : "")}
                onChange={(event, newValue) => {
                  setInputPaymentMethods(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Modes de paiement" />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de facturation"
                  inputFormat="DD/MM/YYYY"
                  minDate={lastInvoiceDate}
                  value={inputInvoiceDate}
                  onChange={(value) => setInputInvoiceDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputInvoiceDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date d'échéance"
                  inputFormat="DD/MM/YYYY"
                  value={inputDueDate}
                  onChange={(value) => setInputDueDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="comments"
                label="Notes/Commentaires"
                multiline
                rows={4}
                value={inputComments}
                onChange={(e) => {
                  setInputComments(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Produits vendus
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <TableContainer sx={{ maxWidth: "100vw" }}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="product">Produit</TableCell>
                    <TableCell key="code">Code</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="pu">P.U</TableCell>
                    <TableCell key="pht">Prix H.T</TableCell>
                    <TableCell key="tva">TVA</TableCell>
                    <TableCell key="pttc">Prix TTC</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputSoldItems.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={9}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Veuillez ajouter les produits vendus dans cette
                            vente!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputSoldItems.map((soldItem, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={soldItem.imageUrl}
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>{soldItem.name}</TableCell>
                        <TableCell>{soldItem.internalCode}</TableCell>
                        <TableCell>{soldItem.quantity}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {soldItem.priceHT + " €"}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {toFixed2(
                            Number(soldItem.quantity) * Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>{soldItem.tva}</TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {toFixed2(
                            (1 + Number(soldItem.tva) * 0.01) *
                              Number(soldItem.quantity) *
                              Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputSaleOptionQuantityEdit(
                                  toFixed2(soldItem.quantity)
                                );
                                setSaleOptionItemEditActive(soldItem.id);
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeSaleOptionItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + index}
                        sx={{
                          display:
                            saleOptionItemEditActive === soldItem.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={9}>
                          <Collapse
                            orientation="vertical"
                            in={saleOptionItemEditActive === soldItem.id}
                          >
                            {saleOptionItemEditActive === soldItem.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editSaleOptionItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editSaleOptionItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={2}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="saleOptionQuantity"
                                    label="Quantité"
                                    error={inputSaleOptionQuantityEditError}
                                    value={inputSaleOptionQuantityEdit}
                                    onChange={(e) => {
                                      if (
                                        /^-?\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        setInputSaleOptionQuantityEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditSoldItemForm(index)) {
                                          resetEditForm();
                                          let tmpArray = [...inputSoldItems];
                                          tmpArray[index] = {
                                            id: soldItem.id,
                                            name: soldItem.name,
                                            priceHT: soldItem.priceHT,
                                            tva: soldItem.tva,
                                            quantity: Number(
                                              inputSaleOptionQuantityEdit
                                            ),
                                            internalCode: soldItem.internalCode,
                                            imageUrl: soldItem.imageUrl,
                                          };
                                          setInputSoldItems(tmpArray);
                                          setSaleOptionItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetEditForm();
                                        setSaleOptionItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Frais supplémentaires
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="additionalFeeDescription">
                      Description
                    </TableCell>
                    <TableCell key="inputAdditionalFeePriceHT">
                      Prix HT
                    </TableCell>
                    <TableCell key="inputAdditionalFeeTva">TVA</TableCell>
                    <TableCell key="inputAdditionalFeePriceTTC">
                      Prix TTC
                    </TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputAdditionalFees.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={5}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Aucun frais supplémentaire ajouté!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputAdditionalFees.map((additionalFee, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>{additionalFee.description}</TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.priceHT)) + " €"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.tva)) + "%"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(additionalFee.priceHT) *
                              (1 + Number(additionalFee.tva) / 100)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputAdditionalFeePriceHTEdit(
                                  toFixed2(additionalFee.priceHT)
                                );
                                setInputAdditionalFeeTvaEdit(
                                  toFixed2(additionalFee.tva)
                                );
                                setInputAdditionalFeePriceTTCEdit(
                                  toFixed2(
                                    Number(additionalFee.priceHT) *
                                      (1 + Number(additionalFee.tva) / 100)
                                  )
                                );
                                setAdditionalFeeItemEditActive(
                                  additionalFee.id
                                );
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeAdditionalFeeItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + index}
                        sx={{
                          display:
                            additionalFeeItemEditActive === additionalFee.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={5}>
                          <Collapse
                            orientation="vertical"
                            in={
                              additionalFeeItemEditActive === additionalFee.id
                            }
                          >
                            {additionalFeeItemEditActive ===
                              additionalFee.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editAdditionalFeeItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editAdditionalFeeItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeeTva"
                                    label="TVA"
                                    error={inputAdditionalFeeTvaEditError}
                                    value={inputAdditionalFeeTvaEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(
                                              inputAdditionalFeePriceHTEdit
                                            )
                                          )
                                        ) {
                                          setInputAdditionalFeePriceTTCEdit(
                                            toFixed2(
                                              getTTCPriceFromHT(
                                                Number(
                                                  inputAdditionalFeePriceHTEdit
                                                ),
                                                Number(e.target.value)
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeeTvaEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceHT"
                                    label="Prix HT"
                                    error={inputAdditionalFeePriceHTEditError}
                                    value={inputAdditionalFeePriceHTEdit}
                                    onChange={(e) => {
                                      if (
                                        /^-?\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(inputAdditionalFeeTvaEdit)
                                          )
                                        ) {
                                          setInputAdditionalFeePriceTTCEdit(
                                            toFixed2(
                                              getTTCPriceFromHT(
                                                Number(e.target.value),
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeePriceHTEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceTTC"
                                    label="Prix TTC"
                                    error={inputAdditionalFeePriceTTCEditError}
                                    value={inputAdditionalFeePriceTTCEdit}
                                    onChange={(e) => {
                                      if (
                                        /^-?\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        if (
                                          !isNaN(Number(e.target.value)) &&
                                          !isNaN(
                                            Number(inputAdditionalFeeTvaEdit)
                                          )
                                        ) {
                                          setInputAdditionalFeePriceHTEdit(
                                            toFixed2(
                                              getHTPriceFromTTC(
                                                Number(e.target.value),
                                                Number(
                                                  inputAdditionalFeeTvaEdit
                                                )
                                              )
                                            )
                                          );
                                        }
                                        setInputAdditionalFeePriceTTCEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (
                                          validateEditAdditionalFeeForm(index)
                                        ) {
                                          resetAdditionalFeeEditForm();
                                          let tmpArray = [
                                            ...inputAdditionalFees,
                                          ];
                                          tmpArray[index] = {
                                            id: additionalFee.id,
                                            description:
                                              additionalFee.description,
                                            priceHT: Number(
                                              inputAdditionalFeePriceHTEdit
                                            ),
                                            tva: Number(
                                              inputAdditionalFeeTvaEdit
                                            ),
                                          };
                                          setInputAdditionalFees(tmpArray);
                                          setAdditionalFeeItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetAdditionalFeeEditForm();
                                        setAdditionalFeeItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Tarification
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14 display="inline-block" width="140px">
              Prix HT:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {getTotalHtText()}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <Text14 display="inline-block" width="140px">
              Prix TTC:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {getTotalTTCText()}
            </Text16>
          </Grid>
          {inputAssociatedUpfrontInvoices.length > 0 && (
            <>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="140px">
                  Reste à payer HT:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getRemainingPriceHTText()}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="140px">
                  Reste à payer TTC:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getRemainingPriceTTCText()}
                </Text16>
              </Grid>
            </>
          )}
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="discount"
              label="Remise"
              value={inputDiscount}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputDiscount(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      "& .MuiInputBase-root:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root:after": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root:hover:before": {
                        borderBottom: "none !important",
                      },
                    }}
                  >
                    <Select
                      variant="standard"
                      sx={{
                        "& .MuiSelect-select:focus": {
                          borderBottom: "none",
                          backgroundColor: "white",
                        },
                      }}
                      value={inputDiscountCalculation}
                      onChange={(e) =>
                        setInputDiscountCalculation(e.target.value)
                      }
                    >
                      <MenuItem key="percentage" value="percentage">
                        %
                      </MenuItem>
                      <MenuItem key="valueHT" value="valueHT">
                        Valeur HT
                      </MenuItem>
                      <MenuItem key="valueTTC" value="valueTTC">
                        Valeur TTC
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="associatedUpfrontInvoices"
              filterOptions={filterAssociatedUpfontInvicesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return (
                  option.invoiceNumber +
                  " - " +
                  getClientDescription(option.client)
                );
              }}
              options={upfrontInvoices}
              value={inputAssociatedUpfrontInvoices}
              onChange={(event, newValue) => {
                setInputAssociatedUpfrontInvoices(newValue!);
              }}
              inputValue={inputAssociatedUpfrontInvoicesText}
              onInputChange={(event, newInputValue) => {
                setInputAssociatedUpfrontInvoicesText(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="associatedUpfrontInvoicesTextfield"
                  label="Factures d'acompte"
                />
              )}
            />
          </Grid>
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        displayEmpty
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addInvoice();
            else setIsLoading(false);
          }}
        >
          Générer
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const GenerateCreditNote = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
    upfrontInvoices: getUpfrontInvoices(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
        getUpfrontInvoices: getUpfrontInvoicesAction,
      },
      dispatch
    )
)(_GenerateCreditNote);

export default GenerateCreditNote;
