import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../models/client";
import { Opportunity } from "../../../../../models/opportunity";
import { Prospect } from "../../../../../models/prospect";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getClientsAction,
  getOpportunitiesAction,
  getProspectsAction,
} from "../../../../../store/Crm/actions";
import { getClients, getProspects } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface EditOpportunityProps {
  input: string;
  popupActive: Opportunity;
  clients: Client[];
  prospects: Prospect[];
  setPopupActive: React.Dispatch<React.SetStateAction<Opportunity>>;
  getOpportunitiesAction: typeof getOpportunitiesAction;
  getClientsAction: typeof getClientsAction;
  getProspectsAction: typeof getProspectsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _EditOpportunity: React.FC<EditOpportunityProps> = ({
  input,
  popupActive,
  clients,
  prospects,
  setPopupActive,
  getOpportunitiesAction,
  getClientsAction,
  getProspectsAction,
}) => {
  const editOpportunity = () => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/crm/opportunities/edit/" + popupActive.id,
      {
        id: popupActive.id,
        label: inputLabel,
        contactIsProspect: inputIsProspect,
        amount: "inputAmount",
        probability: "inputAmount",
        step: inputStep,
        targetDate: inputTargetDate,
        contact: null,
      }
    )
      .then(() => {
        getOpportunitiesAction(input);
        setPopupActive({
          id: "",
          label: "",
          contactIsProspect: true,
          amount: "",
          probability: "",
          step: "",
          targetDate: "",
          contact: null,
        });
      })
      .catch((err) => {
        SetEditFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputLabelError(false);
    let result = true;
    if (inputLabel === "") {
      SetInputLabelError(true);
      result = false;
    }
    if (!result) SetEditFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    getClientsAction("");
    getProspectsAction("");
  }, [getClientsAction, getProspectsAction]);

  const [inputLabel, SetInputLabel] = useState<string>(popupActive.label);

  const [inputLabelError, SetInputLabelError] = useState(false);

  const [inputIsProspect, SetInputIsProspect] = useState(
    popupActive.contactIsProspect
  );

  const [inputProbability, SetInputProbability] = useState(
    popupActive.probability
  );

  const [inputStep, SetInputStep] = useState(popupActive.step);

  const [inputTargetDate, SetInputTargetDate] = useState(
    popupActive.targetDate
  );

  const [editFailed, SetEditFailed] = useState("");

  const classes = useStyles();
  return (
    <Dialog
      open={popupActive.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive({
          id: "",
          label: "",
          contactIsProspect: true,
          amount: "",
          probability: "",
          step: "",
          targetDate: "",
          contact: null,
        });
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un opportunity"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive({
                id: "",
                label: "",
                contactIsProspect: true,
                amount: "",
                probability: "",
                step: "",
                targetDate: "",
                contact: null,
              });
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {editFailed !== "" && <Alert severity="error">{editFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="label"
                label="Libelé"
                required
                error={inputLabelError}
                value={inputLabel}
                onChange={(e) => SetInputLabel(e.target.value)}
              />
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Type du contact
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputIsProspect ? "prospect" : "client"}
                  onChange={(e) => {
                    e.target.value === "prospect"
                      ? SetInputIsProspect(true)
                      : SetInputIsProspect(false);
                  }}
                >
                  <FormControlLabel
                    value="prospect"
                    control={<Radio />}
                    label="Prospect"
                  />
                  <FormControlLabel
                    value="client"
                    control={<Radio />}
                    label="Client"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="probability"
                label="Probabilité"
                value={inputProbability}
                onChange={(e) => SetInputProbability(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="step"
                label="Étape commerciale"
                value={inputStep}
                onChange={(e) => SetInputStep(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="targetDate"
                label="Date visée"
                value={inputTargetDate}
                onChange={(e) => SetInputTargetDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) editOpportunity();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() =>
            setPopupActive({
              id: "",
              label: "",
              contactIsProspect: true,
              amount: "",
              probability: "",
              step: "",
              targetDate: "",
              contact: null,
            })
          }
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditOpportunity = connect(
  (state: RootState) => ({
    clients: getClients(state),
    prospects: getProspects(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getOpportunitiesAction: getOpportunitiesAction,
        getClientsAction: getClientsAction,
        getProspectsAction: getProspectsAction,
      },
      dispatch
    )
)(_EditOpportunity);

export default EditOpportunity;
