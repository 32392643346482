import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import RouteTable from "./RouteTable";

export interface RouteModuleProps {}

const RouteModule: React.FC<RouteModuleProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Livraison"
        subTitle="Liste des tournées de livraisons"
        icon={<LocalShippingTwoToneIcon fontSize="large" color="primary" />}
      />
      <RouteTable />
    </BlockContainer>
  );
};

export default RouteModule;
