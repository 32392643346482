import { RootAction } from "../types";
import { GET_CLIENTS_SUCCESS, GET_PROSPECTS_SUCCESS, GET_OPPORTUNITIES_SUCCESS } from "./actions";
import { CrmState } from "./types";

const initialState: CrmState = {
  clients: [],
  prospects: [],
  opportunities: []
};

export function crmReducer(
  state: CrmState = initialState,
  action: RootAction
): CrmState {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        clients: action.clients,
        prospects: state.prospects,
        opportunities: state.opportunities
      };

    case GET_PROSPECTS_SUCCESS:
        return {
          clients: state.clients,
          prospects: action.prospects,
          opportunities: state.opportunities
        };

    case GET_OPPORTUNITIES_SUCCESS:
      return {
        clients: state.clients,
        prospects: state.prospects,
        opportunities: action.opportunities
      };

    default:
      return state;
  }
}
