import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  AppBar,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CartItem } from "../../../models/cartItem";
import { Employee } from "../../../models/employee";
import { AxiosHttpClient } from "../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../store";
import { getCartItemsAction } from "../../../store/Cart/actions";
import { logOutAction } from "../../../store/Signin/actions";
import { getCartItems, isUserSigninSucceed } from "../../../store/selectors";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import Cart from "./Cart";

export interface FeatureHeaderProps {
  sideNavShow: boolean;
  isUserSigninSucceed: boolean;
  logOut: typeof logOutAction;
  cartItems: CartItem[];
  getCartItemsAction: typeof getCartItemsAction;
  setSideNavShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const _FeatureHeader: React.FC<FeatureHeaderProps> = ({
  sideNavShow,
  isUserSigninSucceed,
  logOut,
  cartItems,
  getCartItemsAction,
  setSideNavShow,
}) => {
  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  useEffect(() => {
    getUserInfos();
    getCartItemsAction();
  }, [getCartItemsAction]);

  const [user, setUser] = useState<Employee | null>(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  let location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/signin" && !localStorage.getItem("jwtToken"))
      window.location.href = `${window.location.origin}/signin`;
  }, [location]);

  const navigate = useNavigate();

  const [popupCartActive, setPopupCartActive] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isUserSigninSucceed && <Navigate to="/signin" replace />}
      <AppBar
        sx={{
          backgroundColor: "#fafbfd",
          height: "65px",
          justifyContent: "center",
          width: {
            xs: "100vw",
            md: "calc(100vw - 258px)",
          },
          left: {
            xs: "0px",
            md: "258px",
          },
          paddingRight: "0 !important",
        }}
      >
        <Toolbar>
          <FlexContainer
            alignItems="stretch"
            height="9vh"
            flex="1"
            justifyContent={"space-between"}
          >
            <FlexContainer alignItems="center">
              <IconButton
                size="large"
                sx={{
                  display: {
                    xs: "block",
                    md: "none",
                  },
                }}
                onClick={() => setSideNavShow(!sideNavShow)}
              >
                <MenuIcon color="action" style={{ fontSize: 25 }} />
              </IconButton>
            </FlexContainer>
            <FlexContainer justifyContent="flex-end" alignItems="center">
              <FlexContainer justifyContent="center" alignItems="center">
                <IconButton style={{ fontSize: 35 }} size="large">
                  <Badge color="secondary">
                    <MailIcon color="action" style={{ fontSize: 25 }} />
                  </Badge>
                </IconButton>
                <IconButton style={{ fontSize: 35 }} size="large">
                  <Badge color="secondary">
                    <NotificationsIcon
                      color="action"
                      style={{ fontSize: 25 }}
                    />
                  </Badge>
                </IconButton>
                <IconButton
                  style={{ fontSize: 35 }}
                  size="large"
                  onClick={() => setPopupCartActive(true)}
                >
                  <Badge badgeContent={cartItems.length} color="secondary">
                    <ShoppingCartIcon color="action" style={{ fontSize: 25 }} />
                  </Badge>
                </IconButton>
              </FlexContainer>
              <ButtonMui
                aria-controls="simple-menu"
                aria-haspopup="true"
                height="50px"
                margin="0 0 0 16px"
                onClick={handleClick}
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="textPrimary">
                    {user == null ? "" : user.firstName + " " + user.lastName}
                  </Typography>
                  <AccountCircleIcon color="action" style={{ fontSize: 32 }} />
                </FlexContainer>
              </ButtonMui>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/feature/parameters", {
                      state: "0,0",
                    });
                  }}
                >
                  Paramètres
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    localStorage.clear();
                    logOut();
                  }}
                >
                  Se deconnecter
                </MenuItem>
              </Menu>
            </FlexContainer>
          </FlexContainer>
        </Toolbar>
      </AppBar>

      {popupCartActive && (
        <Cart
          popupActive={popupCartActive}
          setPopupActive={setPopupCartActive}
        />
      )}
    </>
  );
};

export const FeatureHeader = connect(
  (state: RootState) => ({
    isUserSigninSucceed: isUserSigninSucceed(state),
    cartItems: getCartItems(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        logOut: logOutAction,
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_FeatureHeader);

export default _FeatureHeader;
