import CloseIcon from "@mui/icons-material/Close";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { CustomerOrderArchive } from "../../../../../models/customerOrderArchive";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  commify,
  getISODateFromString,
  getTimeFromDate,
} from "../../../../Reusable/Utils";

export interface GainsDetailsProps {
  customerOrderArchiveList: CustomerOrderArchive[];
  popupActive: boolean;
  inputStartDate: string;
  inputEndDate: string;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const GainsDetails: React.FC<GainsDetailsProps> = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Détails de vos dépenses"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent>
        <FlexContainer flexDirection="column">
          {props.customerOrderArchiveList?.map(
            (customerOrderArchive, index) => (
              <FlexContainer flexDirection="column">
                {(index === 0 ||
                  getISODateFromString(
                    props.customerOrderArchiveList[index - 1].date
                  ) !== getISODateFromString(customerOrderArchive.date)) && (
                  <FlexContainer>
                    <Text16 fontWeight="600">
                      {getISODateFromString(customerOrderArchive.date)}
                    </Text16>
                  </FlexContainer>
                )}
                <FlexContainer
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  padding="7px 16px 7px 16px"
                  $backgroundHover="rgb(255,0,0,0.1)"
                  $cursorHover="pointer"
                >
                  <FlexContainer alignItems="center">
                    <FlexContainer>
                      <RestaurantIcon color="primary" />
                    </FlexContainer>
                    <FlexContainer flexDirection="column">
                      <FlexContainer>
                        <Text14>
                          {customerOrderArchive.eventType +
                            ": " +
                            customerOrderArchive.eventName}
                        </Text14>
                      </FlexContainer>
                      <FlexContainer>
                        <Text14>
                          {getTimeFromDate(customerOrderArchive.date)}
                        </Text14>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                  <FlexContainer>
                    <FlexContainer>
                      <Text16 color={CustomTheme.palette.secondary.main}>
                        {commify(parseFloat(customerOrderArchive.price))}
                        {" €"}
                      </Text16>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            )
          )}
        </FlexContainer>
      </DialogContent>
    </Dialog>
  );
};

export default GainsDetails;
