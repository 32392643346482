import { RootAction } from "../types";
import { GET_INGREDIENTS_SUCCESS, GET_MATERIALS_SUCCESS, GET_SUPPLIERS_SUCCESS } from "./actions";
import { StockState } from "./types";

const initialState: StockState = {
  ingredients: [],
  materials: [],
  suppliers: []
};

export function stockReducer(
  state: StockState = initialState,
  action: RootAction
): StockState {
  switch (action.type) {
    case GET_INGREDIENTS_SUCCESS:
      return {
        ingredients: action.ingredients,
        materials: state.materials,
        suppliers: state.suppliers
      };

    case GET_MATERIALS_SUCCESS:
        return {
          ingredients: state.ingredients,
          materials: action.materials,
          suppliers: state.suppliers
        };

    case GET_SUPPLIERS_SUCCESS:
      return {
        ingredients: state.ingredients,
        materials: state.materials,
        suppliers: action.suppliers
      };

    default:
      return state;
  }
}
