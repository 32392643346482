import AddIcon from "@mui/icons-material/Add";
import { Checkbox, DialogContentText, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../../../../store";
import { getClientsAction } from "../../../../../../store/Crm/actions";
import { getClients } from "../../../../../../store/selectors";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";

const Input = styled("input")({
  display: "none",
});

export interface UploadFileProps {
  inputTemplate: any;
  importDataFromDB: boolean;
  setInputTemplate: React.Dispatch<any>;
  setImportDataFromDB: React.Dispatch<React.SetStateAction<boolean>>;
}

const _UploadFile: React.FC<UploadFileProps> = (props) => {
  const [fileName, setFileName] = useState("");

  return (
    <React.Fragment>
      <DialogContentText textAlign="center">
        Vous pouvez télécharger un exemple de fichier d'importation:{" "}
        <Typography
          variant="subtitle1"
          color="secondary"
          display="inline"
          component="span"
          sx={{
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
          }}
        >
          <a
            href={
              "https://" +
              process.env.REACT_APP_BUCKET_NAME! +
              ".s3.eu-west-3.amazonaws.com/Samples/sample.xlsx"
            }
            download
            style={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            {"Excel"}
          </a>
        </Typography>
        {" - "}
        <Typography
          variant="subtitle1"
          color="secondary"
          display="inline"
          component="span"
          sx={{
            "&:hover": { textDecoration: "underline", cursor: "pointer" },
          }}
        >
          <a
            href={
              "https://" +
              process.env.REACT_APP_BUCKET_NAME! +
              ".s3.eu-west-3.amazonaws.com/Samples/sample.csv"
            }
            download
            style={{
              textDecoration: "none",
              color: "unset",
            }}
          >
            {"Csv"}
          </a>
        </Typography>
      </DialogContentText>
      <FlexContainer alignItems="center" flexDirection="column" margin="16px 0">
        <label htmlFor="contained-button-file">
          <Input
            accept=".xls,.xlsx"
            id="contained-button-file"
            multiple
            type="file"
            onChange={(e) => {
              props.setInputTemplate(e.target.files![0]);
              setFileName(e.target.files![0].name);
            }}
          />
          <ButtonMui
            color="secondary"
            variant="contained"
            size="large"
            $borderRadius="50px"
            padding="8px 22px 8px 12px"
            component="span"
            margin="0 0 0 15px"
          >
            <AddIcon fontSize="medium" />
            Choisir votre document
          </ButtonMui>
        </label>
        <Text16>{fileName}</Text16>
        <FlexContainer alignItems="center">
          <Checkbox
            color="secondary"
            size="small"
            checked={props.importDataFromDB}
            onChange={(event) => {
              props.setImportDataFromDB(!props.importDataFromDB);
            }}
          />
          <DialogContentText>
            Importer l'image, les allergenes, et les valeurs énergétiques depuis
            notre serveur
          </DialogContentText>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        flexDirection="column"
        border={"1px solid rgba(0,0,0,0.2)"}
        padding="16px"
        $borderRadius="4px"
      >
        <Text14>
          - Les noms des champs doivent être identiques à ceux mentionnés dans
          le fichier d'exemple et respecter la casse.
        </Text14>
        <Text14>
          - Pour la règle de gestion de "Catégorie", le champ doit comporter une
          des valeurs suivantes: Entrée, Plat, Dessert, Appéritif
        </Text14>
        <Text14>
          - Pour la règle de gestion des "Tags", le champ doit contenir une
          liste de tags séparés par des virgules.
        </Text14>
        <Text14>
          - Pour la règle de gestion "En vente", le champ doit comporter une des
          deux valeurs : "Vrai" ou "Faux".
        </Text14>
        <Text14>
          - Pour la règle de gestion "Stocké", le champ doit comporter une des
          deux valeurs : "Vrai" ou "Faux".
        </Text14>
        <Text14>
          - Pour la règle de gestion "Cuisiniers", le champ doit contenir les
          cuisiniers séparés par des virgules en spécifiant leurs prénoms et
          noms.
        </Text14>
        <Text14>
          - Les allergenes doivent faire partie de l'ensemble des allergenes
          définies et respecter la casse. Ils doivent être séparés par des
          virgules ( , ).
        </Text14>
        <Text14>
          - Pour la règle de gestion "Nomenclature", le champ doit contenir une
          liste d'ingrédients avec leurs quantités et leurs unités de mesure
          séparées par des points virgules (et des virgules pour les champs de
          l'ingrédient).
        </Text14>
        <ul style={{ margin: "0" }}>
          <li>
            <Text14>
              Exemple du format d'utilisation:
              ingrédient,quantité,unité;ingrédient,quantité,unité;...
            </Text14>
          </li>
          <li>
            <Text14>
              Si l'ingrédient est géré à l'unité ne pas spécifier de valeur pour
              l'unité sous le format:
              ingrédient,quantité;ingrédient,quantité;...
            </Text14>
          </li>
        </ul>
        <Text14>
          - Pour la règle de gestion "Options/Extras", le champ doit contenir
          une liste d'ingrédients avec leurs quantités, leurs unités de mesure
          et le prix du supplément séparés par des points virgules (et des
          virgules pour les champs de l'ingrédient).
        </Text14>
        <ul style={{ margin: "0" }}>
          <li>
            <Text14>
              Exemple du format d'utilisation:
              ingrédient,quantité,unité,prix;ingrédient,quantité,unité,prix;...
            </Text14>
          </li>
          <li>
            <Text14>
              Si l'ingrédient est géré à l'unité ne pas spécifier de valeur pour
              l'unité sous le format:
              ingrédient,quantité,prix;ingrédient,quantité,prix;...
            </Text14>
          </li>
        </ul>
        <Text14>
          - Pour la règle de gestion "Options de vente", le champ doit contenir
          une liste d'options de vente avec leurs noms, leurs quantités, leurs
          prix de vente et leurs valeurs de taxe séparés par des points virgules
          (et des virgules pour les champs de l'ingrédient) sous le format
          suivant: nom,quantité,prix;ingrédient,quantité,prix;...
        </Text14>
      </FlexContainer>
    </React.Fragment>
  );
};

export const UploadFile = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_UploadFile);

export default UploadFile;
