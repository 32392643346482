import { RootAction } from "../types";
import { GET_SUPPLIER_ORDERS_ARCHIVE_SUCCESS, GET_SUPPLIER_ORDERS_SUCCESS } from "./actions";
import { SupplierOrderArchiveState, SupplierOrderState } from "./types";

const initialSupplierOrderState: SupplierOrderState = {
  supplierOrders: [],
};

const initialSupplierOrderArchiveState: SupplierOrderArchiveState = {
  supplierOrdersArchive: [],
};


export function supplierOrderReducer(
  state: SupplierOrderState = initialSupplierOrderState,
  action: RootAction
): SupplierOrderState {
  switch (action.type) {
    case GET_SUPPLIER_ORDERS_SUCCESS:
      return {
        supplierOrders: action.supplierOrders
      };

    default:
      return state;
  }
}

export function supplierOrderArchiveReducer(
  state: SupplierOrderArchiveState = initialSupplierOrderArchiveState,
  action: RootAction
): SupplierOrderArchiveState {
  switch (action.type) {
    case GET_SUPPLIER_ORDERS_ARCHIVE_SUCCESS:
      return {
        supplierOrdersArchive: action.supplierOrdersArchive
      };

    default:
      return state;
  }
}
