import ArticleIcon from "@mui/icons-material/Article";
import DownloadIcon from "@mui/icons-material/Download";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Vacation } from "../../../../../models/vacation";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getVacationsAction } from "../../../../../store/Rh/actions";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14 } from "../../../../../styles/Text";

export interface JustificationsProps {
  input: string;
  popupActive: Vacation;
  setPopupActive: React.Dispatch<React.SetStateAction<Vacation>>;
  getVacationsAction: typeof getVacationsAction;
}

const _Justifications: React.FC<JustificationsProps> = (props) => {
  const downloadDocument = (index: number) => {
    AxiosHttpClient.getBlob(
      "api/v1/weeventpro/rh/vacations/download/" +
        props.popupActive.justificationFileNames[index]
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", justifications[index].name); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  const [justifications, SetJustifications] = useState<any[]>([]);

  useEffect(() => {
    let arrayTmp: any[] = [];
    props.popupActive.justificationFileNames.map(
      (justificationFileName, index) => {
        fetch(
          "https://" +
            process.env.REACT_APP_BUCKET_NAME! +
            ".s3.eu-west-3.amazonaws.com/" +
            justificationFileName
        )
          .then((r) => r.blob())
          .then((blobFile) => {
            arrayTmp.push(
              new File([blobFile], props.popupActive.originalFileNames[index])
            );
            if (
              props.popupActive.justificationFileNames.length ===
              arrayTmp.length
            )
              SetJustifications(arrayTmp);
          });
        return justificationFileName;
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={props.popupActive.id !== ""}
      onClose={(event, reason) => {
        props.setPopupActive({
          id: "",
          label: "",
          startDate: "",
          endDate: "",
          type: "",
          status: "",
          justificationFileNames: [],
          comment: "",
          supervisorId: "",
          originalFileNames: [],
        });
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Liste des justificatifs"}
      </DialogTitle>
      <DialogContent>
        {justifications!.map((justification, index) => (
          <FlexContainer
            elevation={3}
            margin="8px"
            height="48px"
            justifyContent="space-between"
            alignItems="center"
            $borderRadius="16px"
          >
            <FlexContainer margin="0 0 0 8px" alignItems="center">
              <ArticleIcon fontSize="large" color="primary" />
              <Text14>{justification.name}</Text14>
            </FlexContainer>
            <IconButton
              color="secondary"
              onClick={() => {
                downloadDocument(index);
              }}
              size="medium"
              style={{ padding: "4px", zIndex: "1" }}
            >
              <DownloadIcon fontSize="medium" />
            </IconButton>
          </FlexContainer>
        ))}
      </DialogContent>
    </Dialog>
  );
};

export const Justifications = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getVacationsAction: getVacationsAction,
    },
    dispatch
  )
)(_Justifications);

export default Justifications;
