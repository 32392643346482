import { InputSearch } from "../models/inputSearch";
import { SupplierOrder } from "../models/supplierOrder";
import { SupplierOrderArchive } from "../models/supplierOrderArchive";
import { AxiosHttpClient } from "./AxiosHttpService";

export class SupplierOrderService {
  static getSupplierOrders = (input: InputSearch) => {
    return AxiosHttpClient.post<SupplierOrder[]>(
      "api/v1/weeventpro/order/supplierOrder/get",
      input
    );
  };

  static getSupplierOrdersArchive = (input: InputSearch) => {
    return AxiosHttpClient.post<SupplierOrderArchive[]>(
      "api/v1/weeventpro/order/supplierOrder/archive/get",
      input
    );
  };
}
