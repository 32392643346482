import { AxiosHttpClient } from "./AxiosHttpService";

export class SignupService {
  static signUp = (request: any) => {
    return AxiosHttpClient.postForNonAuth<unknown>(
      "api/v1/weeventpro/user/register",
      request
    );
  };
}
