import SellTwoToneIcon from "@mui/icons-material/SellTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import SaleTable from "./SaleTable";

export interface SaleProps {}

const Sale: React.FC<SaleProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Ventes"
        subTitle="Liste des ventes"
        icon={<SellTwoToneIcon fontSize="large" color="primary" />}
      />
      <SaleTable />
    </BlockContainer>
  );
};

export default Sale;
