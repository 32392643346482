import { TextField } from "@mui/material";
import styled from "styled-components";

interface IHeadingStyled {
  height?: string;
  width?: string;
}

export const TextFieldMui = styled(TextField)<IHeadingStyled>`
  height: ${(props) => (props.height)};
  width: ${(props) => (props.width)};
`;
