import { createFilterOptions } from "@mui/core";
import AddCircleTwoToneIcon from "@mui/icons-material/AddCircleTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { Route } from "../../../../../models/route";
import { Sale } from "../../../../../models/sale";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getRoutesAction } from "../../../../../store/Route/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getClientDescription } from "../../../../Reusable/Utils";
import EditSale from "../../Sales/EditSale";

export interface EditRouteProps {
  popupActive: Route | null;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<Route | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getRoutesPage: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "850px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _EditRoute: React.FC<EditRouteProps> = ({
  popupActive,
  employees,
  setPopupActive,
  getEmployeesAction,
  getRoutesPage,
}) => {
  const editRoute = () => {
    AxiosHttpClient.put("api/v1/weeventpro/route", {
      id: popupActive?.id,
      routeNameId: popupActive?.routeName?.id,
      description: inputDescription,
      driverId: inputDriver?.id,
      vehicle: inputVehicle,
      clientsIds: sales
        .filter((sale) => sale.client !== null)
        .map((sale) => sale.client!.id),
      date: popupActive?.date,
      customFields: customFieldValues,
    })
      .then(() => {
        getRoutesPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editRouteDialogContentId",
        });
      });
  };

  const editSales = () => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/sales/multiple",
      sales.map((sale) => {
        return {
          id: sale.id,
          description: sale.description,
          saleNumber: sale.saleNumber,
          clientId: sale.client?.id,
          comments: sale.comments,
          deliverSale: sale.deliverSale,
          routeNameName: sale.route ? sale.route.routeName?.name : "",
          deliveryDate: dayjs(sale.deliveryDate)
            ? dayjs(sale.deliveryDate).tz("Europe/Paris").format("YYYY-MM-DD")
            : null,
          deliveryTime: dayjs(
            dayjs(sale.deliveryDate).format("YYYY-MM-DD") +
              "T" +
              dayjs(sale.deliveryTime).format("HH:mm:ss.SSS")
          ),
          deliveryAddress: sale.deliveryAddress,
          deliveryInstructions: sale.deliveryInstructions,
          soldItems: sale.soldItems,
          status: sale.status,
          date: sale.date,
          totalPriceHT: sale.totalPriceHT,
          totalPriceTVA: sale.totalPriceTVA,
          totalPriceTTC: sale.totalPriceTTC,
          globalTVA: sale.globalTVA,
          invoiceId: sale.invoice?.id,
          customFields: sale.customFields,
        };
      })
    )
      .then(() => {
        getRoutesPage();
      })
      .catch((err) => {});
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: Sale[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        sales,
        result.source.index,
        result.destination.index
      );

      setSales(items);
    }
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/route"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const filterOptions = createFilterOptions<Employee>({
    matchFrom: "any",
    stringify: (option) => option.firstName + " " + option.lastName,
  });

  const validateForm = () => {
    setInputDescriptionError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addRouteDialogContentId",
      });
    }
    return result;
  };

  useEffect(() => {
    getEmployeesAction("");
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [popupEditSaleFromRouteActive, setPopupEditSaleFromRouteActive] =
    useState<Sale | null>(null);

  const [draggedSale, setDraggedSale] = useState<Sale | null>(null);

  const [sales, setSales] = useState<Sale[]>(popupActive!.sales);

  const [inputDescription, setInputDescription] = useState(
    popupActive!.description
  );

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [inputDriver, setInputDriver] = useState<Employee | null>(
    popupActive!.driver
  );

  const [inputDriverText, setInputDriverText] = useState("");

  const [inputVehicle, setInputVehicle] = useState(popupActive!.vehicle);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [routeSaleItemDeleteActive, setRouteSaleItemDeleteActive] =
    useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (popupActive) {
      setSales(popupActive.sales);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupActive]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier une tournée"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editRouteDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="driver"
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                getOptionLabel={(option) => {
                  return option.firstName + " " + option.lastName;
                }}
                options={employees}
                value={inputDriver}
                onChange={(event, newValue) => {
                  setInputDriver(newValue!);
                }}
                inputValue={inputDriverText}
                onInputChange={(event, newInputValue) => {
                  setInputDriverText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                        }
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {option.firstName + " " + option.lastName}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="driver"
                    label="Livreur"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="vehicle"
                label="Vehicule"
                value={inputVehicle}
                onChange={(e) => {
                  setInputVehicle(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Programme de livraison
          </Text20>
        </BlockContainer>
        <Grid item xs={1} sm={1} md={2}>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell key="image">Client</TableCell>
                  <TableCell key="product">Heure</TableCell>
                  <TableCell key="code">Produits</TableCell>
                  <TableCell key="quantity">Instructions</TableCell>
                  <TableCell key="pu">Actions</TableCell>
                </TableRow>
              </TableHead>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction={"vertical"}>
                  {(provided, snapshot) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {draggedSale && (
                        <TableRow
                          sx={{
                            backgroundColor: "lightgrey",
                            visibility: "collapse",
                          }} // Style de fond pour indiquer le drag
                          key={draggedSale.id}
                        >
                          {/* Contenu de la ligne similaire à la ligne originale */}
                          <TableCell>
                            {getClientDescription(draggedSale.client)}
                          </TableCell>
                          <TableCell>
                            {draggedSale.deliveryTime
                              ? dayjs(draggedSale.deliveryTime).format("HH:mm")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {draggedSale.soldItems.map((item, index) => (
                              <FlexContainer textAlign={"center"} key={index}>
                                • {item.quantity + " " + item.name}
                              </FlexContainer>
                            ))}
                          </TableCell>
                          <TableCell>
                            {draggedSale.deliveryInstructions}
                          </TableCell>
                          <TableCell>
                            <FlexContainer justifyContent="center">
                              <IconButton
                                color="primary"
                                onClick={(e) => {}}
                                size="large"
                              >
                                <EditOutlinedIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                color="secondary"
                                onClick={() => {}}
                                size="large"
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </FlexContainer>
                          </TableCell>
                        </TableRow>
                      )}

                      {sales.map((sale, index) => (
                        <Draggable
                          key={sale.id}
                          draggableId={sale.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <>
                              <TableRow
                                sx={{
                                  backgroundColor: "white",
                                }}
                                key={index}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onMouseDown={() => {
                                  setDraggedSale(sale);
                                }}
                                onMouseUp={() => {
                                  setDraggedSale(null);
                                }}
                              >
                                {/* Contenu de la ligne */}
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {getClientDescription(sale.client)}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.deliveryTime
                                    ? dayjs(sale.deliveryTime).format("HH:mm")
                                    : ""}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.soldItems.map((item, index) => (
                                    <FlexContainer
                                      textAlign={"center"}
                                      key={index}
                                    >
                                      • {item.quantity + " " + item.name}
                                    </FlexContainer>
                                  ))}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    opacity: sale.deliverSale ? "1" : "0.3",
                                  }}
                                >
                                  {sale.deliveryInstructions}
                                </TableCell>
                                <TableCell>
                                  {sale.deliverSale && (
                                    <FlexContainer justifyContent="center">
                                      <IconButton
                                        color="primary"
                                        onClick={(e) => {
                                          setPopupEditSaleFromRouteActive(sale);
                                        }}
                                        size="large"
                                      >
                                        <EditOutlinedIcon fontSize="small" />
                                      </IconButton>
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          setRouteSaleItemDeleteActive(sale.id);
                                        }}
                                        size="large"
                                      >
                                        <CloseIcon fontSize="small" />
                                      </IconButton>
                                    </FlexContainer>
                                  )}
                                  {!sale.deliverSale && (
                                    <FlexContainer justifyContent="center">
                                      <IconButton
                                        color="secondary"
                                        onClick={() => {
                                          let tmpArray = [...sales];
                                          tmpArray[index] = {
                                            ...sale,
                                            deliverSale: true,
                                          };
                                          setSales(tmpArray);
                                        }}
                                        size="large"
                                      >
                                        <AddCircleTwoToneIcon fontSize="small" />
                                      </IconButton>
                                    </FlexContainer>
                                  )}
                                </TableCell>
                              </TableRow>

                              <TableRow
                                key={"delete-" + index}
                                sx={{
                                  display:
                                    routeSaleItemDeleteActive === sale.id
                                      ? "table-row"
                                      : "none",
                                }}
                              >
                                <TableCell colSpan={9}>
                                  <Collapse
                                    orientation="vertical"
                                    in={routeSaleItemDeleteActive === sale.id}
                                  >
                                    {routeSaleItemDeleteActive === sale.id && (
                                      <Grid
                                        container
                                        spacing={{ xs: 1, sm: 1, md: 2 }}
                                        columns={{ xs: 1, sm: 1, md: 2 }}
                                      >
                                        <Grid item xs={1} sm={1} md={2}>
                                          <Text16>
                                            Etes-vous sûr de vouloir passer
                                            cette vente en non livraison?
                                          </Text16>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={1}
                                          sm={1}
                                          md={2}
                                          display="flex"
                                          justifyContent="center"
                                        >
                                          <FlexContainer>
                                            <ButtonMui
                                              color="primary"
                                              variant="contained"
                                              size="large"
                                              disabled={isLoading}
                                              margin="0 4px 0 0"
                                              onClick={() => {
                                                let tmpArray = [...sales];
                                                tmpArray[index] = {
                                                  ...sale,
                                                  deliverSale: false,
                                                };
                                                setSales(tmpArray);
                                                setRouteSaleItemDeleteActive(
                                                  ""
                                                );
                                              }}
                                            >
                                              Confirmer
                                            </ButtonMui>
                                            <ButtonMui
                                              margin="0 0 0 4px"
                                              onClick={() => {
                                                setRouteSaleItemDeleteActive(
                                                  ""
                                                );
                                              }}
                                              color="primary"
                                              variant="outlined"
                                              size="large"
                                            >
                                              Annuler
                                            </ButtonMui>
                                          </FlexContainer>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </TableContainer>
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={index}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        displayEmpty
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem value={choice} key={index}>
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={index}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) {
              editRoute();
              editSales();
            } else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
      {popupEditSaleFromRouteActive !== null && (
        <EditSale
          popupActive={popupEditSaleFromRouteActive}
          setPopupActive={setPopupEditSaleFromRouteActive}
          actionsOnEdit={[getRoutesPage]}
        />
      )}
    </Dialog>
  );
};

export const EditRoute = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getRoutesAction: getRoutesAction,
      },
      dispatch
    )
)(_EditRoute);

export default EditRoute;
