import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DishItemQuantity } from "../../../../../models/dishItemQuantity";
import { DrinkItemQuantity } from "../../../../../models/drinkItemQuantity";
import { MaterialItemQuantity } from "../../../../../models/materialItemQuantity";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getCustomerOrdersArchiveAction } from "../../../../../store/CustomerOrder/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import CreateCustomerOrderArchiveStep1 from "./AddCustomerOrderArchiveStep1";
import CreateCustomerOrderArchiveStep2 from "./AddCustomerOrderArchiveStep2";
import CreateCustomerOrderArchiveStep3 from "./AddCustomerOrderArchiveStep3";
import CreateCustomerOrderArchiveStep4 from "./AddCustomerOrderArchiveStep4";
import CreateCustomerOrderArchiveStep5 from "./AddCustomerOrderArchiveStep5";

export interface AddCustomerOrderArchiveProps {
  input: string;
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getCustomerOrdersArchiveAction: typeof getCustomerOrdersArchiveAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
    "& .Mui-active": { color: CustomTheme.palette.secondary.main },
    "& .Mui-completed": { color: CustomTheme.palette.secondary.main },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _AddCustomerOrderArchive: React.FC<AddCustomerOrderArchiveProps> = (
  props
) => {
  const addCustomerOrderArchive = () => {
    AxiosHttpClient.post("api/v1/weeventpro/order/customerOrder/archive/add", {
      eventName: inputEventName,
      eventType: inputEventType,
      contactName: inputContactName,
      date: inputDate,
      guestsNumber: inputGuestsNumber,
      receptionPlace: inputReceptionPlace,
      telNumber: inputTelNumber,
      price: inputPrice,
      upfrontPrice: inputUpfrontPrice,
      dishes: inputDishes,
      drinks: inputDrinks,
      materials: inputMaterials,
    })
      .then(() => {
        props.getCustomerOrdersArchiveAction(props.input);
        props.setPopupActive(false);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const [inputEventName, setInputEventName] = useState("");

  const [inputEventType, setinputEventType] = useState("");

  const [inputContactName, setInputContactName] = useState("");

  const [inputDate, setInputDate] = useState("");

  const [inputGuestsNumber, setInputGuestsNumber] = useState("");

  const [inputReceptionPlace, setInputReceptionPlace] = useState("");

  const [inputTelNumber, setInputTelNumber] = useState("");

  const [inputPrice, setInputPrice] = useState("");

  const [inputUpfrontPrice, setInputUpfrontPrice] = useState("");

  const [inputDishes, setInputDishes] = useState<DishItemQuantity[]>([]);

  const [inputDrinks, setInputDrinks] = useState<DrinkItemQuantity[]>([]);

  const [inputMaterials, setInputMaterials] = useState<MaterialItemQuantity[]>(
    []
  );

  const steps = ["", "", "", "", ""];

  const [step, setStep] = useState(0);

  //const [addFailed, SetaddFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Créer un évènement"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={step} className={classes.root} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FlexContainer justifyContent="center" margin="20px 0 0 0">
          {step === 0 && (
            <CreateCustomerOrderArchiveStep1
              inputEventName={inputEventName}
              inputEventType={inputEventType}
              inputContactName={inputContactName}
              inputDate={inputDate}
              inputGuestsNumber={inputGuestsNumber}
              inputReceptionPlace={inputReceptionPlace}
              inputTelNumber={inputTelNumber}
              setInputEventName={setInputEventName}
              setInputEventType={setinputEventType}
              setInputContactName={setInputContactName}
              setInputDate={setInputDate}
              setInputGuestsNumber={setInputGuestsNumber}
              setInputReceptionPlace={setInputReceptionPlace}
              setInputTelNumber={setInputTelNumber}
            />
          )}
          {step === 1 && (
            <CreateCustomerOrderArchiveStep2
              dishItemsQuantities={inputDishes}
              setDishItemsQuantities={setInputDishes}
            />
          )}
          {step === 2 && (
            <CreateCustomerOrderArchiveStep3
              dishItemsQuantities={inputDrinks}
              setDishItemsQuantities={setInputDrinks}
            />
          )}
          {step === 3 && (
            <CreateCustomerOrderArchiveStep4
              materialItemsQuantities={inputMaterials}
              setMaterialItemsQuantities={setInputMaterials}
            />
          )}
          {step === 4 && (
            <CreateCustomerOrderArchiveStep5
              inputPrice={inputPrice}
              inputUpfrontPrice={inputUpfrontPrice}
              setInputPrice={setInputPrice}
              setInputUpfrontPrice={setInputUpfrontPrice}
            />
          )}
        </FlexContainer>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => setStep(step - 1)}
          color="primary"
          variant="contained"
          size="large"
          disabled={step === 0}
        >
          Précédent
        </ButtonMui>
        {step === 4 ? (
          <ButtonMui
            onClick={() => addCustomerOrderArchive()}
            color="primary"
            variant="contained"
            size="large"
            disabled={inputEventName === ""}
          >
            Créer
          </ButtonMui>
        ) : (
          <ButtonMui
            onClick={() => setStep(step + 1)}
            color="primary"
            variant="contained"
            size="large"
          >
            Suivant
          </ButtonMui>
        )}
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddCustomerOrderArchive = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCustomerOrdersArchiveAction: getCustomerOrdersArchiveAction,
    },
    dispatch
  )
)(_AddCustomerOrderArchive);

export default AddCustomerOrderArchive;
