import {
  Alert,
  Autocomplete,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getDocumentsAction,
  getEmployeesAction,
} from "../../../../../store/Rh/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import { Employee } from "../../../../../models/employee";
import { getEmployees } from "../../../../../store/selectors";

export interface AddDocumentProps {
  input: string;
  popupActive: boolean;
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getDocumentsAction: typeof getDocumentsAction;
}

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    height: "500px",
    width: "750px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _AddDocument: React.FC<AddDocumentProps> = (props) => {
  const addDocument = () => {
    const obj = {
      name: inputName,
      employeesIds: value ? value.map((employee) => employee.id) : [],
    };
    const json = JSON.stringify(obj);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    data.append("inputDocument", inputDocument);
    data.append("document", blob);
    setLoading(true);

    AxiosHttpClient.put("api/v1/weeventpro/rh/documents/add", data)
      .then(() => {
        props.getDocumentsAction(props.input);
        props.setPopupActive(false);
        setLoading(false);
      })
      .catch((err) => {
        SetAddFailed(err.cause);
        setLoading(false);
      });
  };

  const validateForm = () => {
    SetInputNameError(false);
    let result = true;
    if (inputName === "Default") {
      SetInputNameError(true);
      result = false;
      SetAddFailed("Nom non autorisé");
    }
    if (!fileName.endsWith(".docx")) {
      SetAddFailed("Veuillez choisir un fichier word docx valide");
      result = false;
    }
    if (fileName === "") {
      SetAddFailed("Veuillez choisir un fichier");
      result = false;
    }
    if (inputName === "") {
      SetInputNameError(true);
      result = false;
      SetAddFailed("Champs manquants");
    }
    return result;
  };

  const filterOptions = createFilterOptions<Employee>({
    stringify: (option) => option.firstName + " " + option.lastName,
  });

  const [loading, setLoading] = useState(false);

  const [inputName, setInputName] = useState("");

  const [inputNameError, SetInputNameError] = useState(false);

  const [fileName, setFileName] = useState("");

  const [inputDocument, setInputDocument] = useState<any>();

  const [inputSupervisor, setInputSupervisor] = useState("");

  const [value, setValue] = React.useState<Employee[] | null>();

  const [addFailed, SetAddFailed] = useState("");

  const classes = useStyles();

  useEffect(() => {
    getEmployeesAction("");
  }, []);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter un document"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {addFailed !== "" && <Alert severity="error">{addFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            margin="50px 0 0 0"
          >
            <BlockContainer width="400px" margin="10px">
              <TextField
                variant="outlined"
                name="name"
                label="Nom"
                required
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
              <Autocomplete
                id="employees"
                multiple
                filterOptions={filterOptions}
                options={props.employees}
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputSupervisor}
                onInputChange={(event, newInputValue) => {
                  setInputSupervisor(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="supervisor"
                    label="Superviseur"
                    value={inputSupervisor}
                    onChange={(e) => setInputSupervisor(e.target.value)}
                  />
                )}
              />
            </BlockContainer>
            <BlockContainer alignItems="center">
              <label htmlFor="contained-button-file">
                <Input
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    setInputDocument(e.target.files![0]);
                    setFileName(e.target.files![0].name);
                  }}
                />
                <ButtonMui
                  color="secondary"
                  variant="contained"
                  size="large"
                  $borderRadius="50px"
                  padding="8px 22px 8px 12px"
                  component="span"
                  margin="0 0 0 15px"
                >
                  <AddIcon fontSize="medium" />
                  Choisir votre document
                </ButtonMui>
              </label>
              <Text16>{fileName}</Text16>
            </BlockContainer>
          </FlexContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) addDocument();
          }}
          color="primary"
          variant="contained"
          size="large"
          loading={loading}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddDocument = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getDocumentsAction: getDocumentsAction,
      },
      dispatch
    )
)(_AddDocument);

export default AddDocument;
