import { Search } from "@mui/icons-material";
import ArticleIcon from "@mui/icons-material/Article";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Vacation } from "../../../../../models/vacation";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getVacationsAction } from "../../../../../store/Rh/actions";
import { getVacations } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { CustomTheme } from "../../../../../styles/Theme";
import Justifications from "../Justifications";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells: {
  id: keyof Vacation;
  label: string;
  disableSorting?: boolean;
}[] = [
  { id: "label", label: "Intitulé" },
  { id: "startDate", label: "Du" },
  { id: "endDate", label: "Au" },
  { id: "type", label: "Type" },
  { id: "status", label: "Status" },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(
  order: Order,
  orderBy: keyof Vacation
): (a: Vacation, b: Vacation) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface VacationRequestTableProps {
  vacations: Vacation[];
  getVacationsAction: typeof getVacationsAction;
}

const _VacationRequestTable: React.FC<VacationRequestTableProps> = ({
  vacations,
  getVacationsAction,
}) => {
  const getVacationRequests = () => {
    AxiosHttpClient.post<Vacation[]>(
      "api/v1/weeventpro/rh/vacations/getRequests",
      {
        input: inputSearch,
      }
    )
      .then((response) => {
        setVacationRequests(response);
      })
      .catch((err) => {});
  };

  const acceptVacationRequest = (vacationId: string) => {
    AxiosHttpClient.put("api/v1/weeventpro/rh/vacations/accept/" + vacationId)
      .then((response) => {
        getVacationRequests();
      })
      .catch((err) => {});
  };

  const refuseVacationRequest = (vacationId: string) => {
    AxiosHttpClient.put("api/v1/weeventpro/rh/vacations/refuse/" + vacationId)
      .then((response) => {
        getVacationRequests();
      })
      .catch((err) => {});
  };

  const getStatusBackgroundColor = (status: string) => {
    if (status === "En attente") return CustomTheme.palette.primary.dark;
    if (status === "Acceptée") return CustomTheme.palette.success.light;
    if (status === "Refusée") return CustomTheme.palette.secondary.main;
  };

  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Vacation>("label");
  const [inputSearch, SetInputSearch] = useState("");

  const [vacationRequests, setVacationRequests] = useState<Vacation[]>([]);

  const [popupJustificationsActive, setPopupJustificationsActive] =
    useState<Vacation>({
      id: "",
      label: "",
      startDate: "",
      endDate: "",
      type: "",
      status: "",
      justificationFileNames: [],
      comment: "",
      supervisorId: "",
      originalFileNames: [],
    });

  useEffect(() => {
    getVacationRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch]);

  const handleSortRequest = (cellId: keyof Vacation) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const exportVacations = () => {
    AxiosHttpClient.getBlob("api/v1/weeventpro/rh/vacations/export").then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "vacations.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  return (
    <BlockContainer padding="24px">
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher une absence"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => exportVacations()}
          >
            <Typography variant="button">Exporter</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell key="Actions">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(vacationRequests, getComparator(order, orderBy)).map(
              (item) => (
                <TableRow
                  component={Link}
                  to={`/feature/vacations/${item.id}`}
                  key={item.id}
                >
                  <TableCell>{item.label}</TableCell>
                  <TableCell>{item.startDate}</TableCell>
                  <TableCell>{item.endDate}</TableCell>
                  <TableCell>{item.type}</TableCell>
                  <TableCell>
                    <FlexContainer justifyContent="center">
                      <FlexContainer
                        justifyContent="center"
                        backgroundColor={getStatusBackgroundColor(item.status)}
                        color="white"
                        padding="4px 8px"
                        $borderRadius="50px"
                      >
                        {item.status}
                      </FlexContainer>
                    </FlexContainer>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      disabled={item.justificationFileNames.length === 0}
                      color={"primary"}
                      onClick={(e) => {
                        e.preventDefault();
                        setPopupJustificationsActive(item);
                      }}
                      size="large"
                    >
                      <ArticleIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        acceptVacationRequest(item.id);
                      }}
                      size="large"
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        refuseVacationRequest(item.id);
                      }}
                      size="large"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupJustificationsActive.id !== "" && (
        <Justifications
          input={inputSearch}
          popupActive={popupJustificationsActive}
          setPopupActive={setPopupJustificationsActive}
        />
      )}
    </BlockContainer>
  );
};

export const VacationRequestTable = connect(
  (state: RootState) => ({
    vacations: getVacations(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getVacationsAction: getVacationsAction,
      },
      dispatch
    )
)(_VacationRequestTable);

export default _VacationRequestTable;
