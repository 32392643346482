import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { UpfrontInvoiceService } from "../../services/UpfrontInvoiceService";
import { RootAction } from "../types";
import { GetUpfrontInvoicesActionFailure, getUpfrontInvoicesActionFailure, GetUpfrontInvoicesActionSuccess, getUpfrontInvoicesActionSuccess, getUpfrontInvoiceTemplatesActionFailure, GetUpfrontInvoiceTemplatesActionFailure, getUpfrontInvoiceTemplatesActionSuccess, GetUpfrontInvoiceTemplatesActionSuccess, GET_UPFRONT_INVOICES, GET_UPFRONT_INVOICE_TEMPLATES } from "./actions";

export const upfrontInvoiceListEpic: Epic<
  RootAction,
  GetUpfrontInvoicesActionSuccess | GetUpfrontInvoicesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_UPFRONT_INVOICES),
    switchMap((action) =>
      from(UpfrontInvoiceService.getUpfrontInvoices({searchInput: action.input, field: action.field ? action.field : ""})).pipe(
        mergeMap((res) => {
          return [getUpfrontInvoicesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getUpfrontInvoicesActionFailure()]))
        )
      )
    )
  );

  export const upfrontInvoiceTemplateEpic: Epic<
  RootAction,
  GetUpfrontInvoiceTemplatesActionSuccess | GetUpfrontInvoiceTemplatesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_UPFRONT_INVOICE_TEMPLATES),
    switchMap((action) =>
      from(UpfrontInvoiceService.getUpfrontInvoiceTemplates()).pipe(
        mergeMap((res) => {
          return [getUpfrontInvoiceTemplatesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getUpfrontInvoiceTemplatesActionFailure()]))
        )
      )
    )
  );

export const upfrontInvoiceEpic = combineEpics<any>(upfrontInvoiceListEpic, upfrontInvoiceTemplateEpic);