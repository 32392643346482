import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch } from "../../../../store";
import { setExpandedMenu } from "../../../../store/ExpandedMenuItem/actions";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import ExpensesOverview from "./ExpensesOverview";

export interface ExpensesProps {
  setExpandedMenu: typeof setExpandedMenu;
}

const _Expenses: React.FC<ExpensesProps> = ({ setExpandedMenu }) => {
  useEffect(() => {
    setExpandedMenu("panel8");
  }, [setExpandedMenu]);

  return (
    <BlockContainer>
      <PageHeader
        title="Dépenses"
        subTitle="Liste des dépenses"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <ExpensesOverview />
    </BlockContainer>
  );
};

export const Expenses = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setExpandedMenu: setExpandedMenu,
    },
    dispatch
  )
)(_Expenses);

export default Expenses;
