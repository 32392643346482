import styled from "styled-components";

interface IHeadingStyled {
  background: string;
  height: string;
  width: string;
  margin?: string;
  cursor?: string;
  border?: string;
}

export const Image = styled.div<IHeadingStyled>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: ${(props) => props.background};
  background-size: cover;
  background-repeat: no-repeat;
  border: ${(props) => props.border};
  margin: ${(props) => (props.margin ? props.margin : "8px")};
  &:hover {
    cursor: ${(props) => props.cursor};
  }
`;
