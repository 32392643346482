import { TableContainer } from "@mui/material";
import styled from "styled-components";
import { ThemeCustom } from "../Utils";

interface IHeadingStyled {
  $maxHeight?: string;
  margin?: string;
}

export const TableContainerMui = styled(TableContainer)<IHeadingStyled>`
  margin: ${(props) => (props.margin ? props.margin : "24px 0 0 0")};
  flex: 1;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${ThemeCustom.colors.lightOrange};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: ${ThemeCustom.colors.orange};
    border-radius: 10px;
  }
`;
