import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../../store";
import {
  getBaseMetricsAction,
  getCustomMetricsAction,
} from "../../../../../../store/Metric/actions";
import { ButtonMui } from "../../../../../../styles/ButtonMui";

export interface DeleteMetricProps {
  input: string;
  startDate: string;
  endDate: string;
  popupActive: string;
  setPopupActive: React.Dispatch<React.SetStateAction<string>>;
  getBaseMetricsAction: typeof getBaseMetricsAction;
  getCustomMetricsAction: typeof getCustomMetricsAction;
}

const _DeleteMetric: React.FC<DeleteMetricProps> = (props) => {
  const deleteMetric = () => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/statistics/metrics/delete/" + props.popupActive
    )
      .then(() => {
        props.getBaseMetricsAction(props.input, props.startDate, props.endDate);
        props.getCustomMetricsAction(
          props.input,
          props.startDate,
          props.endDate
        );
        props.setPopupActive("");
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={props.popupActive !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive("");
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer une métrique?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer la métrique{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          onClick={() => deleteMetric()}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive("")}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteMetric = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getBaseMetricsAction: getBaseMetricsAction,
      getCustomMetricsAction: getCustomMetricsAction,
    },
    dispatch
  )
)(_DeleteMetric);

export default DeleteMetric;
