import { createFilterOptions } from "@mui/core";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Fab,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dish } from "../../../../../../models/dish";
import { DishItemQuantity } from "../../../../../../models/dishItemQuantity";
import { Dispatch, RootState } from "../../../../../../store";
import { getDishesAction } from "../../../../../../store/Dish/actions";
import { getDishes } from "../../../../../../store/selectors";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Image } from "../../../../../../styles/Image";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text16 } from "../../../../../../styles/Text";

export interface EditCustomerOrderArchiveStep2Props {
  dishItemsQuantities: DishItemQuantity[];
  setDishItemsQuantities: React.Dispatch<
    React.SetStateAction<DishItemQuantity[]>
  >;
  dishes: Dish[];
  getDishesAction: typeof getDishesAction;
}

const _EditCustomerOrderArchiveStep2: React.FC<
  EditCustomerOrderArchiveStep2Props
> = ({
  dishItemsQuantities,
  setDishItemsQuantities,
  dishes,
  getDishesAction,
}) => {
  const [inputDish, setInputDish] = useState("");

  const [inputQuantity, setInputQuantity] = useState("");

  const [value, setValue] = useState<Dish | null>();

  const [dishTmp, setDishTmp] = useState<Dish[]>([]);

  let filterDishes = () => {
    if (dishTmp[0] !== undefined) {
      setDishTmp(
        dishTmp.filter(
          (dish) =>
            !dishItemsQuantities.some((dishItem) => dishItem.name === dish.name)
        )
      );
    }
  };

  useEffect(() => {
    getDishesAction("");
  }, [getDishesAction]);

  useEffect(() => {
    if (dishes[0] !== undefined) {
      setDishTmp([...dishes]);
      filterDishes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dishItemsQuantities, dishes]);

  const filterOptions = createFilterOptions<Dish>({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  let removeDishItemQuantity = (index: number) => {
    let newArr = [...dishItemsQuantities];
    newArr.splice(index, 1);
    setDishItemsQuantities(newArr);
  };

  const ref = React.useRef<HTMLInputElement>(null);

  return (
    <Grid item xs={6}>
      <Text16>Choississez la liste des plats</Text16>
      <FlexContainer alignItems="center">
        <Autocomplete
          id="Dishes"
          ref={ref}
          filterOptions={filterOptions}
          options={dishTmp.filter((dish) => dish.category !== "boisson")}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <li {...props}>
              <Image
                height="50px"
                width="75px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/" +
                  encodeURIComponent(option.imageUrl) +
                  ")"
                }
                margin="7px 10px"
                cursor="pointer"
              />
              {option.name}
            </li>
          )}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          inputValue={inputDish}
          onInputChange={(event, newInputValue) => {
            setInputDish(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              name="dish"
              label="Plat"
              value={inputDish}
              onChange={(e) => setInputDish(e.target.value)}
              style={{ width: 230 }}
            />
          )}
        />
        <TextField
          variant="outlined"
          name="quantity"
          label="Quantité"
          type="number"
          value={inputQuantity}
          onChange={(e) => setInputQuantity(e.target.value)}
          style={{ width: 130, margin: "8px" }}
        />
        <Fab
          size="small"
          color="secondary"
          aria-label="Ajouter"
          disabled={inputDish === "" || inputQuantity === "" || value === null}
          onClick={() => {
            setDishItemsQuantities([
              ...dishItemsQuantities,
              {
                name: inputDish,
                quantity: inputQuantity,
              },
            ]);
            setInputDish("");
            setInputQuantity("");
          }}
        >
          <AddIcon />
        </Fab>
      </FlexContainer>

      <TableContainerMui $maxHeight="430px" margin="0">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell key="dish">Plat</TableCell>
              <TableCell key="quantity">Quantité</TableCell>
              <TableCell key="delete">Supprimer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dishItemsQuantities.map((dishItemQuantity, index) => (
              <TableRow key={index}>
                <TableCell>{dishItemsQuantities[index].name}</TableCell>
                <TableCell>{dishItemsQuantities[index].quantity} </TableCell>
                <TableCell>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeDishItemQuantity(index);
                      setDishTmp([...dishes]);
                    }}
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
    </Grid>
  );
};

export const EditCustomerOrderArchiveStep2 = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_EditCustomerOrderArchiveStep2);

export default EditCustomerOrderArchiveStep2;
