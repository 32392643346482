import styled from "styled-components";

export const AuthenticationHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  margin: 0 50px;
`;
