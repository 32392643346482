import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Box,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BlockContainer } from "../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Text16, Text20 } from "../../../../styles/Text";
import { CustomTheme } from "../../../../styles/Theme";
import CustomizeIngredients from "./CustomizeSections/CustomizeIngredients";
import CustomizeMaterials from "./CustomizeSections/CustomizeMaterials";
import CustomizeSuppliers from "./CustomizeSections/CustomizeSuppliers";
import CustomizeDishes from "./CustomizeSections/CustomizeDishes";
import CustomizeInvoices from "./CustomizeSections/CustomizeInvoices";
import GeneralParameters from "./GeneralParameters";
import CustomizeUpfrontInvoices from "./CustomizeSections/CustomizeUpfrontInvoices";
import CustomizeSales from "./CustomizeSections/CustomizeSales";
import CustomizeClients from "./CustomizeSections/CustomizeClients";
import CustomizeRoutes from "./CustomizeSections/CustomizeRoutes";

export interface ParametersProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index1: number;
  index2: number;
  value1: number;
  value2: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value1, value2, index1, index2, ...other } = props;

  return (
    <FlexContainer
      role="tabpanel"
      width="100%"
      hidden={value1 !== index1 || value2 !== index2}
      id={`vertical-tabpanel-${index1}-${index2}`}
      aria-labelledby={`vertical-tab-${index1}-${index2}`}
      flex={value1 === index1 && value2 === index2 ? "1" : "0 1 auto"}
      {...other}
    >
      {value1 === index1 && value2 === index2 && (
        <FlexContainer
          sx={{ p: { xs: "0 24px 24px 24px", sm: 3 } }}
          width="100%"
        >
          {children}
        </FlexContainer>
      )}
    </FlexContainer>
  );
}

const Parameters: React.FC<ParametersProps> = (props) => {
  const params = useLocation();

  const path: string = params.state as string;

  const [value, setValue] = useState(0);

  const [value2, setValue2] = useState(0);

  const [menuOpened, setMenuOpened] = useState(false);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setValue2(0);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue2(newValue);
  };

  function DrawerWrapper(props) {
    return (
      <React.Fragment>
        <Drawer
          sx={{ display: { xs: "block", md: "none" } }}
          anchor="left"
          open={props.open}
          onClose={props.onClose}
        >
          {props.children}
        </Drawer>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          {props.children}
        </Box>
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (path !== null) {
      setValue(Number(path.split(",")[0]));
      if (path.split(",").length > 1) setValue2(Number(path.split(",")[1]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "8px",
          md: "32px",
        },
      }}
    >
      <FlexContainer>
        <Text20>Paramètres</Text20>
      </FlexContainer>
      <FlexContainer
        margin="16px 0"
        backgroundColor="white"
        sx={{
          padding: {
            xs: "0",
            sm: "32px 16px",
          },
        }}
        elevation={3}
        $borderRadius="4px"
      >
        <DrawerWrapper open={menuOpened} onClose={() => setMenuOpened(false)}>
          {!matches && (
            <FlexContainer margin="16px">
              <Text16 fontWeight="500">Paramètres</Text16>
            </FlexContainer>
          )}
          <FlexContainer width="240px">
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                width: value === 2 ? 60 : 240,
                transition: "width 0.5s",
                "& .MuiTab-root": { alignItems: "flex-start" },
              }}
            >
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <TuneIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== 2 && <React.Fragment>Général</React.Fragment>}
                  </FlexContainer>
                }
              />
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <EmailIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== 2 && <React.Fragment>Emails</React.Fragment>}
                  </FlexContainer>
                }
              />
              <Tab
                label={
                  <FlexContainer alignItems="center" height="30px">
                    <SettingsIcon sx={{ margin: "0 10px 0 0" }} />
                    {value !== 2 && <React.Fragment>Sections</React.Fragment>}
                  </FlexContainer>
                }
              />
            </Tabs>
            {value === 2 && (
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value2}
                onChange={handleChange2}
                aria-label="Vertical tabs example"
                sx={{
                  borderRight: 1,
                  flex: 1,
                  borderColor: "divider",
                  transition: "width 0.5s",
                  "& .MuiTab-root": { alignItems: "flex-start" },
                }}
              >
                <Tab label="Ingrédients" />
                <Tab label="Matériel" />
                <Tab label="Fournisseurs" />
                <Tab label="Recettes" />
                <Tab label="Factures finales" />
                <Tab label="Factures d'acompte" />
                <Tab label="Ventes" />
                <Tab label="Clients" />
                <Tab label="Livraisons" />
              </Tabs>
            )}
          </FlexContainer>
        </DrawerWrapper>
        <FlexContainer flex="1" width="100%" flexDirection="column">
          <FlexContainer alignItems="center" flexDirection="column">
            <IconButton
              size="small"
              sx={{
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
              onClick={() => setMenuOpened(!menuOpened)}
            >
              <MenuIcon color="action" style={{ fontSize: 25 }} />
            </IconButton>
          </FlexContainer>
          <TabPanel value1={value} value2={value2} index1={0} index2={0}>
            <GeneralParameters />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={1} index2={0}>
            Item Two
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={0}>
            <CustomizeIngredients />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={1}>
            <CustomizeMaterials />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={2}>
            <CustomizeSuppliers />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={3}>
            <CustomizeDishes />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={4}>
            <CustomizeInvoices />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={5}>
            <CustomizeUpfrontInvoices />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={6}>
            <CustomizeSales />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={7}>
            <CustomizeClients />
          </TabPanel>
          <TabPanel value1={value} value2={value2} index1={2} index2={8}>
            <CustomizeRoutes />
          </TabPanel>
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
};

export default Parameters;
