import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CustomField } from "../../../../../models/customField";
import { Material } from "../../../../../models/materials";
import { StorageArea } from "../../../../../models/storageArea";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getMaterials } from "../../../../../store/selectors";
import { getMaterialsAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import {
  getMaterialQuantity,
  getUnitNameById,
} from "../../../../Reusable/Utils";
import AddMaterial from "../AddMaterial";
import DeleteMaterial from "../DeleteMaterial";
import EditMaterial from "../EditMaterial";
import ExportMaterials from "../ExportMaterials";
import ImportMaterials from "../ImportMaterials";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

let mockStorageAreas: StorageArea[] = [
  {
    id: "storageId1",
    name: "storage 1",
    description: "description 1",
    address: "address 1",
    categories: ["cat11", "cat12"],
    manager: "manager1",
  },
  {
    id: "storageId2",
    name: "storage 2",
    description: "description 2",
    address: "address 2",
    categories: ["cat21", "cat22"],
    manager: "manager2",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MaterialTableProps {
  materials: Material[];
  getMaterialsAction: typeof getMaterialsAction;
}

const _MaterialTable: React.FC<MaterialTableProps> = ({
  materials,
  getMaterialsAction,
}) => {
  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/material")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/material"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("Nom");
  const [inputSearch, setInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Material | null>(null);

  const [popupImportActive, setPopupImportActive] = useState(false);

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [inputView, setInputView] = useState("defaultView");

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  useEffect(() => {
    getMaterialsAction(inputSearch);
  }, [inputSearch, getMaterialsAction]);

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "Image",
      "Nom",
      "Catégorie",
      "Quantité",
      "Espaces de stockage",
      "Prix",
    ],
  };

  const getViewById = (id: string) => {
    if (id === "defaultView") return defaultView;
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : null;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["Nom", true],
      ["Catégorie", true],
      ["Quantité", true],
      ["Espaces de stockage", false],
      ["Prix", true],
      ["Quantité minimale", true],
      ["Quantité idéale", true],
      ["Suggestion par évènement", true],
      ["À vendre", true],
      ["À louer", true],
      ["Durée d'expiration", true],
      ["Unités d'achat", false],
      ["Unités de stockage", false],
      ["Unités de production", false],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (material: Material, customFieldId: string) => {
    if (material.customFields !== null) {
      for (var customFieldValue of material.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, material: Material) => {
    let headValuesMap: Map<
      string,
      { value: string; comparableValue: string | number | undefined }
    > = new Map([
      [
        "Nom",
        {
          value: material.name,
          comparableValue: material.name.toLowerCase(),
        },
      ],
      [
        "Catégorie",
        {
          value: material.category,
          comparableValue: material.category.toLowerCase(),
        },
      ],
      [
        "Quantité",
        {
          value:
            getMaterialQuantity(material).toString() +
            " " +
            getUnitNameById(
              material.globalStorageUnitId,
              material.storageUnits,
              material.unitBased
            ),
          comparableValue: getMaterialQuantity(material),
        },
      ],
      [
        "Espaces de stockage",
        {
          value: mockStorageAreas
            .filter(
              (mockStorageArea) =>
                material.storageAreas.filter(
                  (storageArea) =>
                    storageArea.storageAreaId === mockStorageArea.id
                ).length > 0
            )
            .map((mockStorageArea) => mockStorageArea.name)
            .join(", "),
          comparableValue: undefined,
        },
      ],
      [
        "Prix",
        {
          value: "Prix",
          comparableValue: undefined,
        },
      ],
      [
        "Quantité minimale",
        {
          value:
            material.minimalQuantity +
            " " +
            getUnitNameById(
              material.globalStorageUnitId,
              material.storageUnits,
              material.unitBased
            ),
          comparableValue: Number(material.minimalQuantity),
        },
      ],
      [
        "Quantité idéale",
        {
          value:
            material.idealQuantity +
            " " +
            getUnitNameById(
              material.globalStorageUnitId,
              material.storageUnits,
              material.unitBased
            ),
          comparableValue: Number(material.idealQuantity),
        },
      ],
      [
        "Suggestion par évènement",
        {
          value: material.suggestionPerEvent ? "Oui" : "Non",
          comparableValue: material.suggestionPerEvent ? "Oui" : "Non",
        },
      ],
      [
        "À vendre",
        {
          value: material.materialForSale ? "Oui" : "Non",
          comparableValue: material.materialForSale ? "Oui" : "Non",
        },
      ],
      [
        "À louer",
        {
          value: material.materialForRent ? "Oui" : "Non",
          comparableValue: material.materialForRent ? "Oui" : "Non",
        },
      ],
      [
        "Durée d'expiration",
        {
          value:
            material.averageExpiryDelay !== ""
              ? material.averageExpiryDelay +
                " " +
                material.averageExpiryDelayUnit
              : "",
          comparableValue:
            material.averageExpiryDelayUnit === "Années"
              ? Number(material.averageExpiryDelay) * 365
              : material.averageExpiryDelayUnit === "Mois"
              ? Number(material.averageExpiryDelay) * 30
              : Number(material.averageExpiryDelay),
        },
      ],
      [
        "Unités d'achat",
        {
          value: material.purchaseUnits
            .map((purchaseUnit) =>
              purchaseUnit.unit === "Personnalisée"
                ? purchaseUnit.customUnit +
                  "(" +
                  purchaseUnit.customUnitCorrespondanceValue +
                  " " +
                  purchaseUnit.customUnitCorrespondanceUnit +
                  ")"
                : purchaseUnit.unit
            )
            .join(", "),
          comparableValue: undefined,
        },
      ],
      [
        "Unités de stockage",
        {
          value: material.storageUnits
            .map((storageUnit) =>
              storageUnit.unit === "Personnalisée"
                ? storageUnit.customUnit +
                  "(" +
                  storageUnit.customUnitCorrespondanceValue +
                  " " +
                  storageUnit.customUnitCorrespondanceUnit +
                  ")"
                : storageUnit.unit
            )
            .join(", "),
          comparableValue: undefined,
        },
      ],
      [
        "Unités de production",
        {
          value: material.productionUnits
            .map((productionUnit) =>
              productionUnit.unit === "Personnalisée"
                ? productionUnit.customUnit +
                  "(" +
                  productionUnit.customUnitCorrespondanceValue +
                  " " +
                  productionUnit.customUnitCorrespondanceUnit +
                  ")"
                : productionUnit.unit
            )
            .join(", "),
          comparableValue: undefined,
        },
      ],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, {
        value: getCustomFieldValues(material, customField.id),
        comparableValue:
          customField.type === "Checkbox"
            ? undefined
            : getCustomFieldValues(material, customField.id),
      });
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, material: Material) => {
    let headValuesMap: Map<
      string,
      { value: JSX.Element; comparableValue: string | undefined }
    > = new Map([
      [
        "Image",
        {
          value: (
            <BlockContainer
              sx={{
                width: {
                  xs: "60px",
                  sm: "100px",
                },
                height: {
                  xs: "60px",
                  sm: "100px",
                },
              }}
              border="rgba(0,0,0,0.1) solid 1px"
              justifyContent="center"
              position="relative"
              textAlign="center"
              backgroundColor="white"
              margin="auto"
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                }}
                alt=""
                src={material?.imageUrl}
              />
            </BlockContainer>
          ),
          comparableValue: undefined,
        },
      ],
      [
        "Actions",
        {
          value: (
            <FlexContainer justifyContent="center">
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPopupEditActive(material);
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPopupDeleteActive([material.id]);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          ),
          comparableValue: undefined,
        },
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getValueCells = (headValue: string, material: Material) => {
    let simpleValue = getSimpleCellValue(headValue, material)?.value;
    let complexValue = getComplexCellValue(headValue, material)?.value;
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  function descendingComparator(a: Material, b: Material, orderBy: string) {
    let simpleCellValueA = getSimpleCellValue(orderBy, a);
    let simpleCellValueB = getSimpleCellValue(orderBy, b);
    if (simpleCellValueB?.comparableValue === undefined) return -1;
    if (simpleCellValueA?.comparableValue === undefined) return 1;
    if (simpleCellValueB.comparableValue < simpleCellValueA.comparableValue) {
      return -1;
    }
    if (simpleCellValueB.comparableValue > simpleCellValueA.comparableValue) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator(
    order: Order,
    orderBy: string
  ): (a: Material, b: Material) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    getCustomFields();
    getViews();
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        height: {
          xs: "calc(100vh - 212px)",
          sm: "calc(100vh - 244px)",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="8px 10px 8px 8px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setPopupImportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "2,1",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton color="primary" size="large">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                setPopupDeleteActive([...selected]);
                setSelected([]);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          SetInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === materials.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = materials.map(
                        (material) => material.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(materials, getComparator(order, orderBy)).map(
              (item) => (
                <TableRow
                  key={item.name}
                  onClick={(e) => {
                    navigate(`/feature/materials/${item.id}`);
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={selected.indexOf(item.id) > -1}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let newArr = [...selected];
                        const index = selected.indexOf(item.id);
                        if (index > -1) {
                          newArr.splice(index, 1);
                          setSelected(newArr);
                        } else {
                          setSelected([...selected, item.id]);
                        }
                      }}
                    />
                  </TableCell>
                  {headCells.map((headCell, index) => (
                    <TableCell key={index}>
                      {getValueCells(headCell.label, item)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddMaterial
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          inputSearch={inputSearch}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteMaterial
          input={inputSearch}
          popupActive={popupDeleteActive}
          setPopupActive={setPopupDeleteActive}
        />
      )}
      {popupEditActive !== null && (
        <EditMaterial
          inputSearch={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
      {popupImportActive && (
        <ImportMaterials
          inputSearch={inputSearch}
          popupActive={popupImportActive}
          setPopupActive={setPopupImportActive}
        />
      )}
      {popupExportActive && (
        <ExportMaterials
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
    </Paper>
  );
};

export const MaterialTable = connect(
  (state: RootState) => ({
    materials: getMaterials(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getMaterialsAction: getMaterialsAction,
      },
      dispatch
    )
)(_MaterialTable);

export default MaterialTable;
