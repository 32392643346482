import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { QuoteService } from "../../services/QuoteService";
import { RootAction } from "../types";
import { GetQuotesActionFailure, getQuotesActionFailure, GetQuotesActionSuccess, getQuotesActionSuccess, getQuoteTemplatesActionFailure, GetQuoteTemplatesActionFailure, getQuoteTemplatesActionSuccess, GetQuoteTemplatesActionSuccess, GET_QUOTES, GET_QUOTE_TEMPLATES } from "./actions";

export const quoteListEpic: Epic<
  RootAction,
  GetQuotesActionSuccess | GetQuotesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_QUOTES),
    switchMap((action) =>
      from(QuoteService.getQuotes({input: action.input})).pipe(
        mergeMap((res) => {
          return [getQuotesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getQuotesActionFailure()]))
        )
      )
    )
  );

  export const quoteTemplateEpic: Epic<
  RootAction,
  GetQuoteTemplatesActionSuccess | GetQuoteTemplatesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_QUOTE_TEMPLATES),
    switchMap((action) =>
      from(QuoteService.getQuoteTemplates()).pipe(
        mergeMap((res) => {
          return [getQuoteTemplatesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getQuoteTemplatesActionFailure()]))
        )
      )
    )
  );

export const quoteEpic = combineEpics<any>(quoteListEpic, quoteTemplateEpic);