import { Paper, TextField, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { SupplierOrderArchive } from "../../../../../models/supplierOrderArchive";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { ContentContainerMui } from "../../../../../styles/ContentContainerMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import {
  commify,
  getCurrentDate,
  getDateAYearAgo,
} from "../../../../Reusable/Utils";
import ExpensesDetails from "../ExpensesDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "0 16px 0 0",
    },
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "45%",
  },
}));

export interface ExpensesOverviewProps {}

const ExpensesOverview: React.FC<ExpensesOverviewProps> = () => {
  const getExpensesDetails = () => {
    AxiosHttpClient.get<SupplierOrderArchive[]>(
      "api/v1/weeventpro/statistics/expenses/get",
      {
        startDate: inputStartDate,
        endDate: inputEndDate,
      }
    )
      .then((response) => {
        setSupplierOrderArchiveList(response);
      })
      .catch((err) => {});
  };

  const getFoodExpensesPrice = () => {
    let total = 0;
    supplierOrderArchiveList.forEach(
      (supplierOrderArchive) =>
        (total += parseFloat(supplierOrderArchive.price))
    );
    return total;
  };

  const getMaterialsExpensesPrice = () => {
    return 2000;
  };

  const getEmployeesExpensesPrice = () => {
    return 15000;
  };

  const getRentExpensesPrice = () => {
    return 10000;
  };

  const getOtherExpensesPrice = () => {
    return 3000;
  };

  const classes = useStyles();

  const [inputStartDate, setInputStartDate] = useState(
    new Date(getDateAYearAgo()).toISOString()
  );

  const [inputEndDate, setInputEndDate] = useState(
    new Date(getCurrentDate()).toISOString()
  );

  const [popupDetailsActive, setPopupDetailsActive] = useState(false);

  const [hoveredElement, setHoveredElement] = useState("none");

  const [supplierOrderArchiveList, setSupplierOrderArchiveList] = useState<
    SupplierOrderArchive[]
  >([]);

  useEffect(() => {
    getExpensesDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getExpensesDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDate, inputEndDate]);

  const series = [getFoodExpensesPrice(), 2000, 15000, 10000, 3000];
  const data: ApexOptions = {
    chart: {
      width: 380,
      type: "pie",
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          setHoveredElement(config.w.config.labels[config.dataPointIndex]);
          document.body.style.cursor = "pointer";
        },
        dataPointMouseLeave: (event, chartContext, config) => {
          setHoveredElement("none");
          document.body.style.cursor = "default";
        },
        dataPointSelection: (event, chartContext, config) => {
          setPopupDetailsActive(true);
        },
      },
    },
    labels: [
      "Alimentaire",
      "Matériel",
      "Personnel",
      "Loyer et charges",
      "Autres",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar className={classes.root}>
          <FlexContainer
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text16 margin="0 64px 0 0">Choisissez une période</Text16>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Du"
                value={inputStartDate}
                onChange={(value) => setInputStartDate(value!)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Au"
                value={inputEndDate}
                onChange={(value) => setInputEndDate(value!)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FlexContainer>
        </Toolbar>
      </Paper>
      <ContentContainerMui>
        <FlexContainer width="100%" textAlign="start">
          <FlexContainer flex="1" justifyContent="center">
            <Chart options={data} series={series} type="pie" width={480} />
          </FlexContainer>
          <FlexContainer flex="1">
            <FlexContainer
              flexDirection="column"
              width="100%"
              padding="0 64px 0 64px"
            >
              <FlexContainer width="100%" margin="0 0 16px 0">
                <FlexContainer>
                  <Text16 fontWeight="600">Total:</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(
                      getFoodExpensesPrice() +
                        getMaterialsExpensesPrice() +
                        getEmployeesExpensesPrice() +
                        getRentExpensesPrice() +
                        getOtherExpensesPrice()
                    )}{" "}
                    €
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Alimentaire" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
                onClick={(e) => setPopupDetailsActive(true)}
              >
                <FlexContainer>
                  <Text16>Alimentaire</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getFoodExpensesPrice()) + " "}€ (
                    {commify(
                      (getFoodExpensesPrice() * 100) /
                        (getFoodExpensesPrice() +
                          getMaterialsExpensesPrice() +
                          getEmployeesExpensesPrice() +
                          getRentExpensesPrice() +
                          getOtherExpensesPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Matériel" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Matériel</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getMaterialsExpensesPrice()) + " "}€ (
                    {commify(
                      (getMaterialsExpensesPrice() * 100) /
                        (getFoodExpensesPrice() +
                          getMaterialsExpensesPrice() +
                          getEmployeesExpensesPrice() +
                          getRentExpensesPrice() +
                          getOtherExpensesPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Personnel" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Personnel</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getEmployeesExpensesPrice()) + " "}€ (
                    {commify(
                      (getEmployeesExpensesPrice() * 100) /
                        (getFoodExpensesPrice() +
                          getMaterialsExpensesPrice() +
                          getEmployeesExpensesPrice() +
                          getRentExpensesPrice() +
                          getOtherExpensesPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={
                  hoveredElement === "Loyer et charges" ? "0 0 0 -8px" : "0"
                }
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Loyer et charges</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getRentExpensesPrice()) + " "}€ (
                    {commify(
                      (getRentExpensesPrice() * 100) /
                        (getFoodExpensesPrice() +
                          getMaterialsExpensesPrice() +
                          getEmployeesExpensesPrice() +
                          getRentExpensesPrice() +
                          getOtherExpensesPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Autres" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Autres</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getOtherExpensesPrice()) + " "}€ (
                    {commify(
                      (getOtherExpensesPrice() * 100) /
                        (getFoodExpensesPrice() +
                          getMaterialsExpensesPrice() +
                          getEmployeesExpensesPrice() +
                          getRentExpensesPrice() +
                          getOtherExpensesPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </ContentContainerMui>
      {popupDetailsActive && (
        <ExpensesDetails
          supplierOrderArchiveList={supplierOrderArchiveList}
          inputStartDate={inputStartDate}
          inputEndDate={inputEndDate}
          popupActive={popupDetailsActive}
          setPopupActive={setPopupDetailsActive}
        />
      )}
    </>
  );
};

export default ExpensesOverview;
