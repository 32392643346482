import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Quote } from "../../../../../models/quote";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getQuotesAction } from "../../../../../store/Quote/actions";
import { getQuotes } from "../../../../../store/selectors";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddQuote from "../../Quote/AddQuote";
import DeleteQuote from "../../Quote/DeleteQuote";
import EditQuote from "../../Quote/EditQuote";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells: {
  id: keyof Quote;
  label: string;
  disableSorting?: boolean;
}[] = [
  { id: "eventName", label: "Nom" },
  { id: "date", label: "Date" },
  { id: "price", label: "Prix (€)" },
  { id: "eventType", label: "Type" },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(
  order: Order,
  orderBy: keyof Quote
): (a: Quote, b: Quote) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface QuoteTableProps {
  quotes: Quote[];
  getQuotesAction: typeof getQuotesAction;
}

const _QuoteTable: React.FC<QuoteTableProps> = ({
  quotes,
  getQuotesAction,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<keyof Quote>("eventName");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  const [popupEditActive, setPopupEditActive] = useState<Quote>({
    eventName: "",
    eventType: "",
    contactName: "",
    date: "",
    guestsNumber: "",
    receptionPlace: "",
    telNumber: "",
    price: "",
    dishes: [],
    drinks: [],
    materials: [],
  });

  useEffect(() => {
    getQuotesAction(inputSearch);
  }, [inputSearch, getQuotesAction]);

  const handleSortRequest = (cellId: keyof Quote) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const downloadQuote = (eventName: string) => {
    AxiosHttpClient.getBlob(
      "api/v1/weeventpro/quote/download/" + eventName
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Quote.docx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher un devis"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Créer un devis</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell key="Actions">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(quotes, getComparator(order, orderBy)).map((quote) => (
              <TableRow key={quote.eventName}>
                <TableCell>{quote.eventName}</TableCell>
                <TableCell>{quote.date}</TableCell>
                <TableCell>{quote.price}</TableCell>
                <TableCell>{quote.eventType}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => downloadQuote(quote.eventName)}
                    size="large"
                  >
                    <DownloadIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="primary"
                    onClick={() => setPopupEditActive(quote)}
                    size="large"
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() => setPopupDeleteActive(quote.eventName)}
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  {/*
                  <CustomMenu
                    infos={{
                      eventName: quote.eventName,
                      eventType: quote.eventType,
                      contactName: quote.contactName,
                      date: quote.date,
                      guestsNumber: quote.guestsNumber,
                      receptionPlace: quote.receptionPlace,
                      telNumber: quote.telNumber,
                      price: quote.price,
                      upfrontPrice: "",
                      dishes: quote.dishes,
                      drinks: quote.drinks,
                      materials: quote.materials,
                    }}
                    generationOrigin="quote"
                    setPopupGenerateActive={setPopupGenerateActive}
                    setGenerationItem={setGenerationItem}
                  />*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddQuote
          setPopupActive={setPopupAddActive}
          popupActive={popupAddActive}
          input={inputSearch}
        />
      )}
      <DeleteQuote
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive.eventName !== "" && (
        <EditQuote
          input={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
      {/*popupGenerateActive.eventName !== "" && (
        <GenerationSteps
          input={inputSearch}
          popupActive={popupGenerateActive}
          setPopupActive={setPopupGenerateActive}
          generationItem={generationItem}
        />
      )*/}
    </Paper>
  );
};

export const QuoteTable = connect(
  (state: RootState) => ({
    quotes: getQuotes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getQuotesAction: getQuotesAction,
      },
      dispatch
    )
)(_QuoteTable);

export default _QuoteTable;
