import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";

export interface TabsMobileProps {
  tabs: string[];
  components: JSX.Element[];
}

const TabsMobile: React.FC<TabsMobileProps> = (props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {}, []);

  return (
    <FlexContainer flexDirection="column">
      <Tabs
        value={selectedTab}
        onChange={(event: React.SyntheticEvent, newValue: number) => {
          setSelectedTab(newValue);
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        {props.tabs.map((tab, index) => (
          <Tab label={tab} key={index} />
        ))}
      </Tabs>
      <BlockContainer backgroundColor="white" elevation={3}>
        {props.components[selectedTab]}
      </BlockContainer>
    </FlexContainer>
  );
};

export default TabsMobile;
