export const SIGN_IN = "SIGN_IN" as const;
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS" as const;
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE" as const;
export const REMOVE_SIGN_IN_FAILURE = "REMOVE_SIGN_IN_FAILURE" as const;
export const LOG_OUT = "LOG_OUT" as const;

export const signInAction = (email: string, password: string) => ({
  type: SIGN_IN,
  email: email,
  password: password,
});
export type SignInAction = ReturnType<typeof signInAction>;

export const signInActionSuccess = () => ({
  type: SIGN_IN_SUCCESS,
});
export type SignInActionSuccess = ReturnType<typeof signInActionSuccess>;

export const signInActionFailure = () => ({
  type: SIGN_IN_FAILURE,
});
export type SignInActionFailure = ReturnType<typeof signInActionFailure>;

export const removeSignInActionFailure = () => ({
  type: REMOVE_SIGN_IN_FAILURE,
});
export type RemoveSignInActionFailure = ReturnType<
  typeof removeSignInActionFailure
>;

export const logOutAction = () => ({
  type: LOG_OUT,
});
export type LogOutAction = ReturnType<typeof logOutAction>;
