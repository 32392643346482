import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BaseMetric, CustomMetric } from "../../../../../models/metric";
import { Dispatch, RootState } from "../../../../../store";
import {
  getBaseMetricsAction,
  getCustomMetricsAction,
} from "../../../../../store/Metric/actions";
import {
  getBaseMetrics,
  getCustomMetrics,
} from "../../../../../store/selectors";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ContentContainerMui } from "../../../../../styles/ContentContainerMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { getCurrentDate, getDateAYearAgo } from "../../../../Reusable/Utils";
import AddMetric from "./AddMetric";
import BaseMetricItem from "./BaseMetricItem";
import CustomMetricItem from "./CustomMetricItem";
import DeleteMetric from "./DeleteMetric";
import EditMetric from "./EditMetric";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "0 16px 0 0",
    },
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "45%",
  },
}));

function descendingComparator<T>(a: T, b: T) {
  if (a["name"] === "Default") {
    return -1;
  }
  if (b["name"] < a["name"]) {
    return 1;
  }
  if (b["name"] > a["name"]) {
    return -1;
  }
  return 0;
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = descendingComparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface MetricListProps {
  baseMetrics: BaseMetric[];
  customMetrics: CustomMetric[];
  getBaseMetricsAction: typeof getBaseMetricsAction;
  getCustomMetricsAction: typeof getCustomMetricsAction;
}

const _MetricList: React.FC<MetricListProps> = ({
  baseMetrics,
  customMetrics,
  getBaseMetricsAction,
  getCustomMetricsAction,
}) => {
  const classes = useStyles();

  const [inputStartDate, setInputStartDate] = useState(
    new Date(getDateAYearAgo()).toISOString()
  );

  const [inputEndDate, setInputEndDate] = useState(
    new Date(getCurrentDate()).toISOString()
  );

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupEditActive, setPopupEditActive] = useState<CustomMetric>({
    name: "",
    value: 0,
    unit: "",
    expression: "",
  });

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  const [inputSearch, SetInputSearch] = useState("");

  useEffect(() => {
    getBaseMetricsAction(
      inputSearch,
      new Date(inputStartDate).toISOString(),
      new Date(inputEndDate).toISOString()
    );
    getCustomMetricsAction(
      inputSearch,
      new Date(inputStartDate).toISOString(),
      new Date(inputEndDate).toISOString()
    );
  }, [
    inputSearch,
    inputStartDate,
    inputEndDate,
    getBaseMetricsAction,
    getCustomMetricsAction,
  ]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar className={classes.root}>
          <TextField
            variant="outlined"
            label="Rechercher une métrique"
            className={classes.searchInput}
            value={inputSearch}
            onChange={(e) => SetInputSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Du"
              value={inputStartDate}
              onChange={(value) => setInputStartDate(value!)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Au"
              value={inputEndDate}
              onChange={(value) => setInputEndDate(value!)}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
          <FlexContainer flex="1" justifyContent="flex-end">
            <ButtonMui
              color="primary"
              variant="outlined"
              margin="0 0 0 10px"
              startIcon={<AddIcon />}
              onClick={() => setPopupAddActive(true)}
            >
              <Typography variant="button">Créer une métrique</Typography>
            </ButtonMui>
          </FlexContainer>
        </Toolbar>
      </Paper>
      <ContentContainerMui>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 5, sm: 10, md: 15 }}
            style={{ marginBottom: "10px" }}
          >
            {stableSort(baseMetrics, (a, b) => descendingComparator(a, b)).map(
              (metric) => (
                <Grid item xs={5} sm={5} md={3} key={metric.name}>
                  <BaseMetricItem
                    metric={metric}
                    startDate={inputStartDate}
                    endDate={inputEndDate}
                  />
                </Grid>
              )
            )}
            {stableSort(customMetrics, (a, b) =>
              descendingComparator(a, b)
            ).map((metric) => (
              <Grid item xs={5} sm={5} md={3} key={metric.name}>
                <CustomMetricItem
                  metric={metric}
                  startDate={inputStartDate}
                  endDate={inputEndDate}
                  setPopupEditActive={setPopupEditActive}
                  setPopupDeleteActive={setPopupDeleteActive}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </ContentContainerMui>

      {popupAddActive && (
        <AddMetric
          input={inputSearch}
          startDate={inputStartDate}
          endDate={inputEndDate}
          setPopupActive={setPopupAddActive}
          popupActive={popupAddActive}
        />
      )}
      {popupEditActive.name !== "" && (
        <EditMetric
          input={inputSearch}
          startDate={inputStartDate}
          endDate={inputEndDate}
          setPopupActive={setPopupEditActive}
          popupActive={popupEditActive}
        />
      )}
      <DeleteMetric
        input={inputSearch}
        startDate={inputStartDate}
        endDate={inputEndDate}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
    </>
  );
};

export const MetricList = connect(
  (state: RootState) => ({
    baseMetrics: getBaseMetrics(state),
    customMetrics: getCustomMetrics(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getBaseMetricsAction: getBaseMetricsAction,
        getCustomMetricsAction: getCustomMetricsAction,
      },
      dispatch
    )
)(_MetricList);

export default _MetricList;
