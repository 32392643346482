import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddSupplierOrderArchive } from "./AddSupplierOrderArchive";
import { SupplierOrderArchiveTable } from "./SupplierOrderArchiveTable";

export interface SupplierOrderArchiveProps {}

const SupplierOrderArchive: React.FC<SupplierOrderArchiveProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Historiques des commandes"
        subTitle="Historique des commandes fournisseurs"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <SupplierOrderArchiveTable />
      {popupActive && (
        <AddSupplierOrderArchive
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default SupplierOrderArchive;
