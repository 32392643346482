import React from "react";
import { FlexContainer } from "../../../styles/FlexContainer";
import { TypographyMui } from "../../../styles/TypographyMui";

export interface LoginImageSectionProps {}

const LoginImageSection: React.FC<LoginImageSectionProps> = () => {
  return (
    <FlexContainer
      sx={{
        flex: "2",
        display: {
          md: "flex !important",
          sm: "none !important",
          xs: "none !important",
        },
        height: "100%",
        background:
          "linear-gradient(180deg,rgba(255, 255, 255, 0.8) 0%,rgba(255, 255, 255, 0.6) 100%), url(https://" +
          process.env.REACT_APP_BUCKET_NAME! +
          ".s3.eu-west-3.amazonaws.com/WebsiteImages/loginImagePro.jpg)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TypographyMui
        variant="h2"
        color="secondary"
        textAlign="center"
        sx={{ maxWidth: "900px" }}
      >
        Vos tâches journalières deviennent plus faciles avec WeEvent
      </TypographyMui>
    </FlexContainer>
  );
};

export default LoginImageSection;
