import { Invoice } from "../models/invoice";
import { Template } from "../models/template";
import { AxiosHttpClient } from "./AxiosHttpService";

export class UpfrontInvoiceService {
  static getUpfrontInvoices = (input: any) => {
    return AxiosHttpClient.get<Invoice[]>(
      "api/v1/weeventpro/upfrontInvoices",
      input
    );
  };

  static getUpfrontInvoiceTemplates = () => {
    return AxiosHttpClient.get<Template[]>(
      "api/v1/weeventpro/upfrontInvoice/template"
    );
  };
}
