import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CustomField } from "../../../../../../../models/customField";
import { View } from "../../../../../../../models/view";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../../../styles/Utils";

export interface EditViewProps {
  popupActive: View | null;
  customFields: CustomField[];
  setPopupActive: React.Dispatch<React.SetStateAction<View | null>>;
  getViews: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const EditView: React.FC<EditViewProps> = (props) => {
  const editViews = () => {
    AxiosHttpClient.put<View[]>(
      "api/v1/weeventpro/parameters/views/upfrontInvoice",
      {
        id: props.popupActive?.id,
        name: inputName,
        fields: inputFields,
      }
    )
      .then((res) => {
        props.getViews();
        props.setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setInputName("");
    setInputFields([]);
  };

  const [inputName, setInputName] = useState(props.popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputFields, setInputFields] = useState(props.popupActive!.fields);

  const [editFailed, setEditFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const classes = useStyles();

  const getAllFields = (): string[] => {
    let normalFields = [
      "Numéro de facture",
      "Description",
      "Client",
      "Remise",
      "Date facture",
      "Montant total TTC",
      "Montant total HT",
      "Montant total TVA",
      "Montant acompte TTC",
      "Montant acompte HT",
      "Montant acompte TVA",
      "TVA globale",
      "Date d'échéance",
      "Date de paiement",
      "Moyen de paiement",
      "Frais supplémentaires",
      "Commentaires",
      "Produits",
      "Statut",
      "Produits",
      "Ventes associées",
    ];
    return [
      ...normalFields,
      ...props.customFields.map((customField) => "Custom::" + customField.id),
    ];
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
    }
    return result;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of props.customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const reorder = (list, startIndex, endIndex) => {
    const result: string[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (result.source !== null && result.destination !== null) {
      const items = reorder(
        inputFields,
        result.source.index,
        result.destination.index
      );

      setInputFields(items);
    }
  };

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un champs personnalisé"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={getAllFields()}
                disableCloseOnSelect
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={
                        option.includes("Custom::")
                          ? getCustomFieldNameByCustomFieldId(
                              option.substring(8)
                            )
                          : option
                      }
                      disabled={option === "Numéro de facture"}
                      key={index}
                    />
                  ));
                }}
                getOptionLabel={(option) => option}
                value={inputFields}
                getOptionDisabled={(option) =>
                  !inputFields.includes(option) && inputFields.length >= 7
                }
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputFields.includes(option)}
                    />
                    {option.includes("Custom::")
                      ? getCustomFieldNameByCustomFieldId(option.substring(8))
                      : option}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputFields([
                    "Numéro de facture",
                    ...values.filter(
                      (option) => option !== "Numéro de facture"
                    ),
                  ]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Champs"
                    helperText={
                      "Champs sélectionnés: " + inputFields.length + "/7"
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <BlockContainer
            padding="16px 0"
            border="1px dashed black"
            margin="16px 0"
            $borderRadius="10px"
          >
            <FlexContainer width="100%" margin="8px 0 0 0">
              <Text16>Spécifiez l'ordre des champs</Text16>
            </FlexContainer>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                direction={matches ? "horizontal" : "vertical"}
              >
                {(provided, snapshot) => (
                  <FlexContainer
                    padding="16px 8px 16px 8px"
                    flexDirection={matches ? "row" : "column"}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {inputFields.map((field, index) => (
                      <Draggable
                        key={field + "id"}
                        draggableId={field + "id"}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <FlexContainer
                            height="56px"
                            backgroundColor={CustomTheme.palette.primary.main}
                            elevation={snapshot.isDragging ? 9 : 0}
                            flex="1"
                            alignItems="center"
                            justifyContent="center"
                            padding="16px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Text14
                              textAlign="center"
                              color="white"
                              margin="0"
                              lineHeight="14px"
                            >
                              {field.includes("Custom::")
                                ? getCustomFieldNameByCustomFieldId(
                                    field.substring(8)
                                  )
                                : field}
                            </Text14>
                          </FlexContainer>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </FlexContainer>
                )}
              </Droppable>
            </DragDropContext>
          </BlockContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) {
              editViews();
              resetForm();
            } else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            resetForm();
            props.setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default EditView;
