import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { RouteService } from "../../services/RouteService";
import { RootAction } from "../types";
import { GetRoutesActionFailure, getRoutesActionFailure, GetRoutesActionSuccess, getRoutesActionSuccess, GET_ROUTES } from "./actions";

  export const routesEpic: Epic<
  RootAction,
  GetRoutesActionSuccess | GetRoutesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_ROUTES),
    switchMap((action) =>
      from(RouteService.getRoutes({searchInput: action.input, field: action.field ? action.field : ""})).pipe(
        mergeMap((res) => {
          return [getRoutesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getRoutesActionFailure()]))
        )
      )
    )
  );

export const routeEpic = combineEpics(routesEpic);
