import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useState } from "react";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { Text14 } from "../../../../../styles/Text";
import { isDateValid } from "../../../../Reusable/Utils";

export interface ExportSalesJournalProps {
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const ExportSalesJournal: React.FC<ExportSalesJournalProps> = (props) => {
  const exportSalesJournal = () => {
    AxiosHttpClient.postBlob("api/v1/weeventpro/invoices/salesJournal", {
      range:
        inputStartDate?.format("DD/MM/YYYY") +
        "-" +
        inputEndDate?.format("DD/MM/YYYY"),
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SalesJournal.xlsx");
        document.body.appendChild(link);
        link.click();
        props.setPopupActive(false);
      })
      .catch((err) => {
        setExportFailed(err.cause);
        setIsLoading(false);
      });
  };

  const validateForm = () => {
    setInputStartDateError(false);
    setInputEndDateError(false);
    let result = true;
    if (!isDateValid(inputStartDate)) {
      setInputStartDateError(true);
      result = false;
    }
    if (!isDateValid(inputEndDate)) {
      setInputEndDateError(true);
      result = false;
    }
    if (!result) {
      setExportFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "exportSalesJournalDialogContentId",
      });
    }
    return result;
  };

  const [inputStartDate, setInputStartDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "year").startOf("month")
  );

  const [inputStartDateError, setInputStartDateError] = useState(false);

  const [inputEndDate, setInputEndDate] = useState<Dayjs | null>(
    dayjs().subtract(1, "month").endOf("month")
  );

  const [inputEndDateError, setInputEndDateError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [exportFailed, setExportFailed] = useState("");

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title">
        {"Exporter le journal des ventes"}
      </DialogTitle>
      <DialogContentMui id="exportSalesJournalDialogContentId" dividers>
        {exportFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setExportFailed("");
              }}
            >
              {exportFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <Text14>
                Veuillez sélectionner les dates pour lesquelles vous voulez
                votre journal de ventes:
              </Text14>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Du"
                  inputFormat="DD/MM/YYYY"
                  value={inputStartDate}
                  onChange={(value) => setInputStartDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputStartDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Au"
                  inputFormat="DD/MM/YYYY"
                  value={inputEndDate}
                  onChange={(value) => setInputEndDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputEndDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        </form>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) exportSalesJournal();
            else setIsLoading(false);
          }}
          color="primary"
        >
          Exporter
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(false)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default ExportSalesJournal;
