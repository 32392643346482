import { Dish } from "../models/dish";
import { AxiosHttpClient } from "./AxiosHttpService";

export class DishService {
  static getDishes = (input: any) => {
    return AxiosHttpClient.get<Dish[]>(
      "api/v1/weeventpro/catalog",
      input
    );
  };
}