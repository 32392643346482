import React from "react";
import { FlexContainer } from "../../styles/FlexContainer";
import LoginImageSection from "./LoginImageSection";
import { SignUpForm } from "./SignUpForm";

export interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = () => {
  return (
    <FlexContainer height="100vh">
      <LoginImageSection />
      <SignUpForm />
    </FlexContainer>
  );
};

export default SignUp;
