import SetMealTwoToneIcon from "@mui/icons-material/SetMealTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import DishTable from "./DishTable";

export interface DishProps {}

const Dish: React.FC<DishProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Recettes"
        subTitle="Liste des recettes et sous-recettes"
        icon={<SetMealTwoToneIcon fontSize="large" color="primary" />}
      />
      <DishTable />
    </BlockContainer>
  );
};

export default Dish;
