import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { CrmService } from "../../services/CrmService";
import { RootAction } from "../types";
import {
  GetClientsActionFailure, getClientsActionFailure, GetClientsActionSuccess, getClientsActionSuccess, GetProspectsActionFailure, getProspectsActionFailure, GetProspectsActionSuccess, getProspectsActionSuccess, GetOpportunitiesActionFailure, getOpportunitiesActionFailure, GetOpportunitiesActionSuccess, getOpportunitiesActionSuccess, GET_CLIENTS, GET_PROSPECTS, GET_OPPORTUNITIES
} from "./actions";

export const clientsEpic: Epic<
  RootAction,
  GetClientsActionSuccess | GetClientsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_CLIENTS),
    switchMap((action) =>
      from(CrmService.getClients({searchInput: action.input, field: action.field ? action.field : ""})).pipe(
        mergeMap((res) => {
          return [getClientsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getClientsActionFailure()]))
        )
      )
    )
  );

  export const prospectsEpic: Epic<
  RootAction,
  GetProspectsActionSuccess | GetProspectsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_PROSPECTS),
    switchMap((action) =>
      from(CrmService.getProspects({input: action.input})).pipe(
        mergeMap((res) => {
          return [getProspectsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getProspectsActionFailure()]))
        )
      )
    )
  );

  export const opportunitiesEpic: Epic<
  RootAction,
  GetOpportunitiesActionSuccess | GetOpportunitiesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_OPPORTUNITIES),
    switchMap((action) =>
      from(CrmService.getOpportunities({input: action.input})).pipe(
        mergeMap((res) => {
          return [getOpportunitiesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getOpportunitiesActionFailure()]))
        )
      )
    )
  );

export const CrmEpic = combineEpics<any>(clientsEpic,prospectsEpic,opportunitiesEpic);
