import StarIcon from "@mui/icons-material/Star";
import { Rating } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import { Text14, Text16, Text24, Text56 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface FeedbackProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MUIRichTextEditor-toolbar-18": {
      border: "1px solid red",
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      border: "1px solid green",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const Feedback: React.FC<FeedbackProps> = () => {
  const classes = useStyles();

  return (
    <FlexContainer className={classes.root}>
      <FlexContainer flex="2" padding="32px">
        <FlexContainer flexDirection="column">
          <FlexContainer margin="16px 0">
            <FlexContainer margin="0 16px 0 0">
              <ImagePreview
                src={
                  "https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/Mocks/Aymane.jpg"
                }
                alt=""
                height="60px"
                width="60px"
                $borderRadius="100px"
              />
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <FlexContainer>
                <Text14 fontWeight="600" margin="0 10px">
                  Aymane MASSIT
                </Text14>
              </FlexContainer>
              <FlexContainer>
                <Text14 margin="0 10px">Prestation faite le 02/08/2022</Text14>
              </FlexContainer>
              <FlexContainer alignItems="center">
                <Text14 margin="0 10px">4</Text14>
                <Rating name="size-medium" value={4} readOnly />
              </FlexContainer>
              <FlexContainer>
                <Text14 margin="0 10px">Le 16/08/2022</Text14>
              </FlexContainer>
              <FlexContainer>
                <Text16 fontWeight="600">Excellent traiteur </Text16>
              </FlexContainer>
              <FlexContainer>
                <Text14>
                  Nous avons été ravies moi et toute la famille des prestations
                  de ce traiteur
                </Text14>
              </FlexContainer>
              <FlexContainer>
                <FlexContainer $cursorHover="pointer">
                  <Text14
                    fontWeight="600"
                    color={CustomTheme.palette.primary.main}
                  >
                    Répondre
                  </Text14>
                </FlexContainer>
                <FlexContainer $cursorHover="pointer">
                  <Text14
                    fontWeight="600"
                    color={CustomTheme.palette.primary.main}
                  >
                    Signaler
                  </Text14>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer margin="16px 0">
            <FlexContainer margin="0 16px 0 0">
              <ImagePreview
                src={
                  "https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/Mocks/Youness.webp"
                }
                alt=""
                height="60px"
                width="60px"
                $borderRadius="100px"
              />
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <FlexContainer>
                <Text14 fontWeight="600" margin="0 10px">
                  Youness Zrigui
                </Text14>
              </FlexContainer>
              <FlexContainer>
                <Text14 margin="0 10px">Prestation faite le 02/08/2022</Text14>
              </FlexContainer>
              <FlexContainer alignItems="center">
                <Text14 margin="0 10px">5</Text14>
                <Rating name="size-medium" value={5} readOnly />
              </FlexContainer>
              <FlexContainer>
                <Text14 margin="0 10px">Le 16/08/2022</Text14>
              </FlexContainer>
              <FlexContainer>
                <Text16 fontWeight="600">Traiteur de grande qualité</Text16>
              </FlexContainer>
              <FlexContainer>
                <Text14>
                  Nous avons été ravies moi et toute la famille des prestations
                  de ce traiteur. Il a été attentif à nos besoins et il était
                  trop sympa
                </Text14>
              </FlexContainer>
              <FlexContainer>
                <FlexContainer $cursorHover="pointer">
                  <Text14
                    fontWeight="600"
                    color={CustomTheme.palette.primary.main}
                  >
                    Répondre
                  </Text14>
                </FlexContainer>
                <FlexContainer $cursorHover="pointer">
                  <Text14
                    fontWeight="600"
                    color={CustomTheme.palette.primary.main}
                  >
                    Signaler
                  </Text14>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flex="1" height="600px" margin="32px 64px">
        <FlexContainer
          height="400px"
          width="100%"
          border="1px dashed black"
          flexDirection="column"
          alignItems="center"
        >
          <FlexContainer>
            <Text24 margin="16px 0 0 0">Moyenne des avis</Text24>
          </FlexContainer>
          <FlexContainer>
            <Text56 margin="0" color={CustomTheme.palette.primary.main}>
              4.5
            </Text56>
          </FlexContainer>
          <FlexContainer>
            <Text14 margin="0">2 Avis au total</Text14>
          </FlexContainer>
          <FlexContainer>
            <Text14 margin="32px">Répartition des notes</Text14>
          </FlexContainer>
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="center"
            margin="2px 0"
          >
            <FlexContainer
              width="30%"
              height="10px"
              backgroundColor={CustomTheme.palette.background.default}
              $borderRadius="50px"
            >
              <FlexContainer
                width="50%"
                height="10px"
                backgroundColor={CustomTheme.palette.secondary.main}
                $borderRadius="50px"
              ></FlexContainer>
            </FlexContainer>
            <Text14 margin="0 4px 0 16px">5</Text14>
            <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
            <Text14 margin="0 4px 0 8px">(1 avis)</Text14>
          </FlexContainer>
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="center"
            margin="2px 0"
          >
            <FlexContainer
              width="30%"
              height="10px"
              backgroundColor={CustomTheme.palette.background.default}
              $borderRadius="50px"
            >
              <FlexContainer
                width="50%"
                height="10px"
                backgroundColor={CustomTheme.palette.secondary.main}
                $borderRadius="50px"
              ></FlexContainer>
            </FlexContainer>
            <Text14 margin="0 4px 0 16px">4</Text14>
            <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
            <Text14 margin="0 4px 0 8px">(1 avis)</Text14>
          </FlexContainer>
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="center"
            margin="2px 0"
          >
            <FlexContainer
              width="30%"
              height="10px"
              backgroundColor={CustomTheme.palette.background.default}
              $borderRadius="50px"
            >
              <FlexContainer
                width="0%"
                height="10px"
                backgroundColor={CustomTheme.palette.secondary.main}
                $borderRadius="50px"
              ></FlexContainer>
            </FlexContainer>
            <Text14 margin="0 4px 0 16px">3</Text14>
            <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
            <Text14 margin="0 4px 0 8px">(0 avis)</Text14>
          </FlexContainer>
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="center"
            margin="2px 0"
          >
            <FlexContainer
              width="30%"
              height="10px"
              backgroundColor={CustomTheme.palette.background.default}
              $borderRadius="50px"
            >
              <FlexContainer
                width="0%"
                height="10px"
                backgroundColor={CustomTheme.palette.secondary.main}
                $borderRadius="50px"
              ></FlexContainer>
            </FlexContainer>
            <Text14 margin="0 4px 0 16px">2</Text14>
            <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
            <Text14 margin="0 4px 0 8px">(0 avis)</Text14>
          </FlexContainer>
          <FlexContainer
            width="100%"
            alignItems="center"
            justifyContent="center"
            margin="2px 0"
          >
            <FlexContainer
              width="30%"
              height="10px"
              backgroundColor={CustomTheme.palette.background.default}
              $borderRadius="50px"
            >
              <FlexContainer
                width="0%"
                height="10px"
                backgroundColor={CustomTheme.palette.secondary.main}
                $borderRadius="50px"
              ></FlexContainer>
            </FlexContainer>
            <Text14 margin="0 4px 0 16px">1</Text14>
            <StarIcon fontSize="small" style={{ color: "#faaf00" }} />
            <Text14 margin="0 4px 0 8px">(0 avis)</Text14>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default Feedback;
