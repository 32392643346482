import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import WebPageContent from "./WebPageContent";

export interface WebPageProps {}

const WebPage: React.FC<WebPageProps> = () => {
  return (
    <BlockContainer>
      <PageHeader
        title="Page web"
        subTitle="Personnalisation de votre page web"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <WebPageContent />
    </BlockContainer>
  );
};

export default WebPage;
