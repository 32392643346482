import React, { useEffect, useState } from "react";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { SharedDocumentsTable } from "../SharedDocumentsTable";
import { DocumentTable } from "../DocumentTable";

export interface DocumentContentProps {}

const DocumentContent: React.FC<DocumentContentProps> = () => {
  const [selectedTab, setSelectedTab] = useState("documents");

  useEffect(() => {}, []);

  return (
    <FlexContainer flexDirection="column">
      <FlexContainer>
        <FlexContainer backgroundColor="white" margin="0 24px">
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "documents"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "documents" ? 3 : undefined}
            $backgroundAfter={selectedTab === "documents" ? "white" : undefined}
            $heightAfter={selectedTab === "documents" ? "20px" : undefined}
            $contentAfter={selectedTab === "documents" ? "''" : undefined}
            $positionAfter={
              selectedTab === "documents" ? "absolute" : undefined
            }
            $bottomAfter={selectedTab === "documents" ? "-10px" : undefined}
            $leftAfter={selectedTab === "documents" ? "0px" : undefined}
            $rightAfter={selectedTab === "documents" ? "0px" : undefined}
            position={selectedTab === "documents" ? "relative" : undefined}
            zIndex={selectedTab === "documents" ? "1" : undefined}
            onClick={(e) => setSelectedTab("documents")}
          >
            <Text16>Mes documents</Text16>
          </FlexContainer>
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "sharedWithMe"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "sharedWithMe" ? 3 : undefined}
            $backgroundAfter={
              selectedTab === "sharedWithMe" ? "white" : undefined
            }
            $heightAfter={selectedTab === "sharedWithMe" ? "20px" : undefined}
            $contentAfter={selectedTab === "sharedWithMe" ? "''" : undefined}
            $positionAfter={
              selectedTab === "sharedWithMe" ? "absolute" : undefined
            }
            $bottomAfter={selectedTab === "sharedWithMe" ? "-10px" : undefined}
            $leftAfter={selectedTab === "sharedWithMe" ? "0px" : undefined}
            $rightAfter={selectedTab === "sharedWithMe" ? "0px" : undefined}
            position={selectedTab === "sharedWithMe" ? "relative" : undefined}
            zIndex={selectedTab === "sharedWithMe" ? "1" : undefined}
            onClick={(e) => setSelectedTab("sharedWithMe")}
          >
            <Text16>Partagés avec moi</Text16>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <BlockContainer backgroundColor="white" margin="0 24px" elevation={3}>
        {selectedTab === "documents" && <DocumentTable />}
        {selectedTab === "sharedWithMe" && <SharedDocumentsTable />}
      </BlockContainer>
    </FlexContainer>
  );
};

export default DocumentContent;
