import { Grid, TextField } from "@mui/material";
import React from "react";
import { Text16 } from "../../../../../../styles/Text";

export interface CreateCustomerOrderArchiveStep5Props {
  inputPrice: string;
  inputUpfrontPrice: string;
  setInputPrice: React.Dispatch<React.SetStateAction<string>>;
  setInputUpfrontPrice: React.Dispatch<React.SetStateAction<string>>;
}

const CreateCustomerOrderArchiveStep5: React.FC<
  CreateCustomerOrderArchiveStep5Props
> = ({
  inputPrice,
  inputUpfrontPrice,
  setInputPrice,
  setInputUpfrontPrice,
}) => {
  return (
    <Grid item xs={6}>
      <Text16>Choisissez le montant de l'acompte</Text16>
      <TextField
        variant="outlined"
        name="Price"
        label="Montant d'acompte"
        value={inputUpfrontPrice}
        type="string"
        onChange={(e) => setInputUpfrontPrice(e.target.value)}
      />
      <Text16>Choisissez le prix de la préstation</Text16>
      <TextField
        variant="outlined"
        name="Price"
        label="Prix"
        value={inputPrice}
        type="string"
        onChange={(e) => setInputPrice(e.target.value)}
      />
    </Grid>
  );
};

export default CreateCustomerOrderArchiveStep5;
