import ArticleTwoToneIcon from "@mui/icons-material/ArticleTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import InvoiceTable from "./InvoiceTable";

export interface InvoiceProps {}

const Invoice: React.FC<InvoiceProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Factures finales"
        subTitle="Liste des factures finales"
        icon={<ArticleTwoToneIcon fontSize="large" color="primary" />}
      />
      <InvoiceTable />
    </BlockContainer>
  );
};

export default Invoice;
