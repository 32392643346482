import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddOpportunity } from "./AddOpportunity";
import { OpportunityTable } from "./OpportunityTable";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";

export interface OpportunitiesProps {}

const Opportunities: React.FC<OpportunitiesProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Opportunities"
        subTitle="Liste des opportunities"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <OpportunityTable />
      {popupActive && (
        <AddOpportunity
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Opportunities;
