import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { DishService } from "../../services/DishService";
import { RootAction } from "../types";
import { GetDishesActionFailure, getDishesActionFailure, GetDishesActionSuccess, getDishesActionSuccess, GET_DISHES } from "./actions";

  export const dishesEpic: Epic<
  RootAction,
  GetDishesActionSuccess | GetDishesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_DISHES),
    switchMap((action) =>
      from(DishService.getDishes({searchInput: action.input, field: action.field ? action.field : ""})).pipe(
        mergeMap((res) => {
          return [getDishesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getDishesActionFailure()]))
        )
      )
    )
  );

export const dishEpic = combineEpics(dishesEpic);
