import { Box, Grid, Paper } from "@mui/material";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BaseMetric, CustomMetric } from "../../../../../models/metric";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getBaseMetricsAction,
  getCustomMetricsAction,
} from "../../../../../store/Metric/actions";
import {
  getBaseMetrics,
  getCustomMetrics,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ContentContainerMui } from "../../../../../styles/ContentContainerMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16, Text24 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { getCurrentDate, getDateAYearAgo } from "../../../../Reusable/Utils";
import BaseMetricItem from "./BaseMetricItem";

export interface DashboardItemsProps {
  baseMetrics: BaseMetric[];
  customMetrics: CustomMetric[];
  getBaseMetricsAction: typeof getBaseMetricsAction;
  getCustomMetricsAction: typeof getCustomMetricsAction;
}

const _DashboardItems: React.FC<DashboardItemsProps> = ({
  baseMetrics,
  getBaseMetricsAction,
  getCustomMetricsAction,
}) => {
  const [categories, setCategories] = useState<string[]>([]);

  const [seriesReturned, setSeriesReturned] = useState<number[]>([]);

  const data: ApexOptions = {
    chart: {
      type: "area",
      height: "400px",

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
    },

    fill: {
      colors: ["#fff"],
      type: "gradient",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["black"],
      width: 1,
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
    grid: {
      show: true,
    },
    xaxis: {
      type: "datetime",
      categories: categories,
    },
  };

  const series = [
    {
      name: "Sales",
      data: seriesReturned,
    },
  ];

  const getMetricValues = () => {
    AxiosHttpClient.get<number[]>(
      "api/v1/weeventpro/statistics/metrics/getDetails",
      {
        metricName: "Chiffre d'affaire",
        startDate: new Date(getDateAYearAgo()).toISOString(),
        endDate: new Date(getCurrentDate()).toISOString(),
      }
    )
      .then((response) => {
        setSeriesReturned(response);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getMetricValues();
    getBaseMetricsAction(
      "",
      new Date(getDateAYearAgo()).toISOString(),
      new Date(getCurrentDate()).toISOString()
    );
    getCustomMetricsAction(
      "",
      new Date(getDateAYearAgo()).toISOString(),
      new Date(getCurrentDate()).toISOString()
    );
    let categoriesTmp: string[] = [];
    for (
      var d = new Date(new Date(getDateAYearAgo()).toISOString());
      d <= new Date(new Date(getCurrentDate()).toISOString());
      d.setDate(d.getDate() + 1)
    ) {
      categoriesTmp.push(new Date(d).toString());
    }
    setCategories([...categoriesTmp]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBaseMetricsAction, getCustomMetricsAction]);

  return (
    <>
      <ContentContainerMui margin="30px 0 0 0">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 20 }}
            style={{ marginBottom: "10px" }}
          >
            {baseMetrics
              .filter((metric) => metric.name !== "Marge brute")
              .map((metric) => (
                <Grid item xs={1} sm={2} md={5} key={metric.name}>
                  <BaseMetricItem
                    metric={metric}
                    startDate={new Date(getDateAYearAgo()).toISOString()}
                    endDate={new Date(getCurrentDate()).toISOString()}
                  />
                </Grid>
              ))}
            <Grid item xs={1} sm={1} md={8} key="aze">
              <Paper elevation={3}>
                <FlexContainer flexDirection="column" height="480px">
                  <FlexContainer margin="16px 0 0 16px">
                    <Text16 fontWeight="600">Marge brute</Text16>
                  </FlexContainer>
                  <FlexContainer
                    height="150px"
                    width="150px"
                    margin="16px 0 0 0"
                    alignSelf="center"
                  >
                    <CircularProgressbar
                      value={70}
                      text="70%"
                      strokeWidth={4}
                      styles={buildStyles({
                        // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                        strokeLinecap: "butt",

                        // Text size
                        textSize: "16px",

                        // How long animation takes to go from one percentage to another, in seconds
                        pathTransitionDuration: 0.5,

                        // Can specify path transition in more detail, or remove it entirely
                        // pathTransition: 'none',

                        // Colors
                        pathColor: CustomTheme.palette.secondary.main,
                        textColor: CustomTheme.palette.secondary.main,
                      })}
                    />
                  </FlexContainer>
                  <FlexContainer alignSelf="center" margin="24px 0 0 0">
                    <Text16>Marge sur ce mois</Text16>
                  </FlexContainer>
                  <FlexContainer alignSelf="center">
                    <Text24 fontWeight="600">10 000 €</Text24>
                  </FlexContainer>
                  <FlexContainer margin="32px 16px 0 16px">
                    <FlexContainer
                      flexDirection="column"
                      alignItems="center"
                      flex="1"
                    >
                      <FlexContainer>
                        <Text16>Avril 2022</Text16>
                      </FlexContainer>
                      <FlexContainer>
                        <Text16 fontWeight="600">25 500 €</Text16>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                      flexDirection="column"
                      alignItems="center"
                      flex="1"
                    >
                      <FlexContainer>
                        <Text16>Mars 2022</Text16>
                      </FlexContainer>
                      <FlexContainer>
                        <Text16 fontWeight="600">25 500 €</Text16>
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer
                      flexDirection="column"
                      alignItems="center"
                      flex="1"
                    >
                      <FlexContainer>
                        <Text16>Février 2022</Text16>
                      </FlexContainer>
                      <FlexContainer>
                        <Text16 fontWeight="600">25 500 €</Text16>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </Paper>
            </Grid>
            <Grid item xs={1} sm={1} md={12} key="qsd">
              <Paper elevation={3}>
                <FlexContainer flexDirection="column" height="480px">
                  <FlexContainer margin="16px 0 0 16px">
                    <Text16 fontWeight="600">Chiffres d'affaire</Text16>
                  </FlexContainer>
                  <BlockContainer height="400px" width="100%">
                    <Chart
                      height="100%"
                      width="100%"
                      options={data}
                      series={series}
                      type="area"
                    />
                  </BlockContainer>
                </FlexContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </ContentContainerMui>
    </>
  );
};

export const DashboardItems = connect(
  (state: RootState) => ({
    baseMetrics: getBaseMetrics(state),
    customMetrics: getCustomMetrics(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getBaseMetricsAction: getBaseMetricsAction,
        getCustomMetricsAction: getCustomMetricsAction,
      },
      dispatch
    )
)(_DashboardItems);

export default DashboardItems;
