import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CustomField } from "../../../../../models/customField";
import { Ingredient } from "../../../../../models/ingredients";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getIngredients } from "../../../../../store/selectors";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ThemeCustom } from "../../../../../styles/Utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "1000px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

export interface ExportIngredientsProps {
  inputSearch: string;
  popupActive: boolean;
  ingredients: Ingredient[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
}

const _ExportIngredients: React.FC<ExportIngredientsProps> = (props) => {
  const exportIngredients = () => {
    AxiosHttpClient.postBlob(
      "api/v1/weeventpro/stock/ingredients/export",
      selectedFields
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "ingredients.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/ingredient"
    )
      .then((res) => {
        setFields([...fields, ...res.map((customField) => customField.name)]);
        setSelectedFields([
          ...fields,
          ...res.map((customField) => customField.name),
        ]);
      })
      .catch((err) => {});
  };

  const [fields, setFields] = useState<string[]>([
    "Image",
    "Nom",
    "Catégorie",
    "Quantité",
    "Espaces de stockage",
    "Prix",
    "Allergies",
    "Quantité minimale",
    "Quantité idéale",
    "Suggestion par évènement",
    "À vendre",
    "Durée d'expiration",
    "Unités d'achat",
    "Unités de stockage",
    "Unités de production",
    "Options d'achat",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
  ]);

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const classes = useStyles();

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Exporter vos ingrédients"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui dividers>
        <DialogContentText sx={{ marginBottom: "16px" }}>
          Veuillez choisir les champs que vous voulez exporter
        </DialogContentText>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1 }}
          columns={{ xs: 1, sm: 1, md: 4 }}
        >
          {fields.map((field, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              <FlexContainer alignItems="center">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={selectedFields.indexOf(field) > -1}
                  onChange={(event) => {
                    let newArr = [...selectedFields];
                    const index = selectedFields.indexOf(field);
                    if (index > -1) {
                      newArr.splice(index, 1);
                      setSelectedFields(newArr);
                    } else {
                      setSelectedFields([...selectedFields, field]);
                    }
                  }}
                />
                <DialogContentText>{field}</DialogContentText>
              </FlexContainer>
            </Grid>
          ))}
        </Grid>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            exportIngredients();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Exporter
        </ButtonMui>
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const ExportIngredients = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_ExportIngredients);

export default ExportIngredients;
