import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getProspectsAction } from "../../../../../store/Crm/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface AddProspectProps {
  input: string;
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getProspectsAction: typeof getProspectsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _AddProspect: React.FC<AddProspectProps> = ({
  input,
  popupActive,
  setPopupActive,
  getProspectsAction,
}) => {
  const addProspect = () => {
    AxiosHttpClient.put("api/v1/weeventpro/crm/prospects/add", {
      name: inputName,
      type: inputType,
      company: inputCompany,
      telNumber: inputTelNumber,
      email: inputEmail,
      address: inputAddress,
    })
      .then(() => {
        getProspectsAction(input);
        setPopupActive(false);
      })
      .catch((err) => {
        SetAddFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputNameError(false);
    let result = true;
    if (inputName === "") {
      SetInputNameError(true);
      result = false;
    }
    if (!result) SetAddFailed("Champs manquants");
    return result;
  };

  const [inputName, SetInputName] = useState("");

  const [inputNameError, SetInputNameError] = useState(false);

  const [inputType, SetInputType] = useState("");

  const [inputCompany, SetInputCompany] = useState("");

  const [inputTelNumber, SetInputTelNumber] = useState("");

  const [inputEmail, SetInputEmail] = useState("");

  const [inputAddress, SetInputAddress] = useState("");

  const [addFailed, SetAddFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter un prospect"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {addFailed !== "" && <Alert severity="error">{addFailed}</Alert>}
        </BlockContainer>
        <form className={classes.root} autoComplete="off">
          <Grid container>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="name"
                label="Nom"
                required
                error={inputNameError}
                value={inputName}
                onChange={(e) => SetInputName(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="type"
                label="Particulier/Poste"
                value={inputType}
                onChange={(e) => SetInputType(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="company"
                label="Société"
                value={inputCompany}
                onChange={(e) => SetInputCompany(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                name="telNumber"
                label="N° de téléphone"
                value={inputTelNumber}
                onChange={(e) => SetInputTelNumber(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="email"
                label="Email"
                value={inputEmail}
                onChange={(e) => SetInputEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                name="address"
                label="Adresse"
                value={inputAddress}
                onChange={(e) => SetInputAddress(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) addProspect();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddProspect = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getProspectsAction: getProspectsAction,
    },
    dispatch
  )
)(_AddProspect);

export default AddProspect;
