import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import { StorageOption, Unit } from "../../../../../../models/materials";
import { StorageArea } from "../../../../../../models/storageArea";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getUnitNameById } from "../../../../../Reusable/Utils";

let mockStorageAreas: StorageArea[] = [
  {
    id: "storageId1",
    name: "storage 1",
    description: "description 1",
    address: "address 1",
    categories: ["cat11", "cat12"],
    manager: "manager1",
  },
  {
    id: "storageId2",
    name: "storage 2",
    description: "description 2",
    address: "address 2",
    categories: ["cat21", "cat22"],
    manager: "manager2",
  },
];

export interface StorageAreasProps {
  inputRefStorageProduct: React.RefObject<HTMLInputElement>;
  inputRefStorageUnit: React.RefObject<HTMLInputElement>;
  inputStorageOptions: StorageOption[];
  inputStorageUnits: Unit[];
  isUnitBased: boolean;
  handleExpiry: string;
  inputImageUrl: string;
  inputName: string;
  setInputStorageOptions: React.Dispatch<React.SetStateAction<StorageOption[]>>;
  setStorageUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStorageUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StorageAreas: React.FC<StorageAreasProps> = (props) => {
  const [inputStorageAreaId, setInputStorageAreaId] = useState("");

  const [inputStorageAreaNameError, setInputStorageAreaNameError] =
    useState(false);

  const [inputQuantity, setInputQuantity] = useState("");

  const [inputQuantityError, setInputQuantityError] = useState(false);

  const [inputStorageUnit, setInputStorageUnit] = useState("");

  const [inputStorageUnitError, setInputStorageUnitError] = useState(false);

  const [inputProductId, setInputProductId] = useState("");

  const [storageAreaAddActive, setStorageAreaAddActive] = useState(false);

  const [storageAreaEditActive, setStorageAreaEditActive] = useState("");

  const [inputExpirationDate, setInputExpirationDate] = useState<Dayjs | null>(
    null
  );

  const [selectStorageUnitOpen, setSelectStorageUnitOpen] = useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const [addFailed, setAddFailed] = useState("");

  const resetValidation = () => {
    setAddFailed("");
    setInputStorageAreaNameError(false);
    setInputQuantityError(false);
    setInputStorageUnitError(false);
  };

  const validateForm = () => {
    setInputStorageAreaNameError(false);
    setInputStorageUnitError(false);
    setInputQuantityError(false);
    let result = true;
    if (inputStorageAreaId === "") {
      setInputStorageAreaNameError(true);
      result = false;
    }
    if (inputStorageUnit === "" && !props.isUnitBased) {
      setInputStorageUnitError(true);
      result = false;
    }
    if (inputQuantity === "") {
      setInputQuantityError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    if (document.getElementById("storageUnit") !== null)
      setSelectWidth(document.getElementById("storageUnit")!.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("storageUnit")]);

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setStorageAreaEditActive("");
            setStorageAreaAddActive(true);
            if (mockStorageAreas.length !== 0)
              setInputStorageAreaId(mockStorageAreas[0].id);
            else setInputStorageAreaId("");
            if (props.inputStorageUnits.length !== 0)
              setInputStorageUnit(props.inputStorageUnits[0].id);
            else setInputStorageUnit("");
            setInputQuantity("");
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un espace de stockage
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={storageAreaAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputStorageAreaNameError}
              >
                <InputLabel id="demo-simple-select-label">
                  Espace de stockage
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="storageProduct"
                  required
                  value={inputStorageAreaId}
                  label="Espace de stockage"
                  onChange={(e) => {
                    setInputStorageAreaId(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  {mockStorageAreas.map((storageArea, index) => (
                    <MenuItem
                      value={storageArea.id}
                      key={index}
                      disabled={
                        props.handleExpiry !== "Yes" &&
                        inputProductId !== "" &&
                        props.inputStorageOptions.find(
                          (storageOption) =>
                            storageOption.storageAreaId === storageArea.id
                        ) !== undefined
                      }
                    >
                      {storageArea.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!props.isUnitBased && (
              <Grid item xs={1} sm={1} md={1}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={inputStorageUnitError}
                >
                  <InputLabel id="storageUnit">Unité de stockage</InputLabel>
                  <Select
                    labelId="storageUnit"
                    id="storageUnit"
                    required
                    open={selectStorageUnitOpen}
                    onClose={() => setSelectStorageUnitOpen(false)}
                    onOpen={() => setSelectStorageUnitOpen(true)}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          width: selectWidth,
                        },
                        "& .MuiButtonBase-root": {
                          whiteSpace: "normal",
                        },
                      },
                    }}
                    value={inputStorageUnit}
                    label="Unité de stockage"
                    onChange={(e) => setInputStorageUnit(e.target.value)}
                  >
                    {props.inputStorageUnits.length === 0 && (
                      <Link
                        to="storageUnitsTopId"
                        spy={true}
                        smooth={true}
                        duration={500}
                        containerId={"addMaterialDialogContentId"}
                      >
                        <MenuItem
                          sx={{ color: "red" }}
                          value=""
                          onClick={() => {
                            props.setStorageUnitAddActive(true);
                            setTimeout(function () {
                              setSelectStorageUnitOpen(false);
                              props.inputRefStorageUnit.current?.focus({
                                preventScroll: true,
                              });
                            }, 100);
                            setTimeout(function () {
                              props.setStorageUnitOpen(true);
                            }, 500);
                          }}
                        >
                          Aucune unité de stockage créée. Cliquez ici pour en
                          créer une!
                        </MenuItem>
                      </Link>
                    )}
                    {props.inputStorageUnits.map((storageUnit, index) => (
                      <MenuItem value={storageUnit.id} key={index}>
                        {storageUnit.unit === "Personnalisée"
                          ? storageUnit.customUnit
                          : storageUnit.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="quantity"
                label="Quantité"
                error={inputQuantityError}
                value={inputQuantity}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputQuantity(e.target.value);
                  }
                }}
              />
            </Grid>
            {props.handleExpiry === "Yes" && (
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date de péremption"
                    inputFormat="DD/MM/YYYY"
                    value={inputExpirationDate}
                    onChange={(value) => setInputExpirationDate(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputStorageOptions([
                  ...props.inputStorageOptions,
                  {
                    id: uuid(),
                    storageAreaId: inputStorageAreaId,
                    storageUnitId: props.isUnitBased ? "1" : inputStorageUnit,
                    quantity: inputQuantity,
                    expirationDate: inputExpirationDate,
                  },
                ]);
                setStorageAreaAddActive(false);
                setInputStorageAreaId("");
                setInputQuantity("");
                setInputStorageUnit("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setStorageAreaAddActive(false);
              setInputStorageAreaId("");
              setInputQuantity("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {mockStorageAreas.map((mockStorageArea, index) => (
        <FlexContainer flexDirection="column" key={index}>
          {props.inputStorageOptions.filter(
            (storageArea) => storageArea.storageAreaId === mockStorageArea.id
          ).length !== 0 && <Text16>{mockStorageArea.name}</Text16>}
          {props.inputStorageOptions.map(
            (storageOption, index) =>
              storageOption.storageAreaId === mockStorageArea.id && (
                <React.Fragment key={index}>
                  <FlexContainer
                    margin="16px 0"
                    elevation={3}
                    $borderRadius="10px"
                    border="1px solid rgba(0,0,0,0.1)"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="32px 16px"
                    sx={{
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                      },
                    }}
                  >
                    <FlexContainer>
                      <BlockContainer
                        width="150px"
                        height="150px"
                        border="rgba(0,0,0,0.1) solid 1px"
                        justifyContent="center"
                        position="relative"
                        textAlign="center"
                        backgroundColor="white"
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            margin: "auto",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                          }}
                          alt=""
                          src={
                            "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                          }
                        />
                      </BlockContainer>
                      <FlexContainer
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        <Text16 fontWeight="600">{props.inputName}</Text16>
                        <Text14 margin="4px 10px 0 10px">
                          {storageOption.quantity +
                            " " +
                            getUnitNameById(
                              storageOption.storageUnitId,
                              props.inputStorageUnits,
                              props.isUnitBased
                            )}
                        </Text14>
                        {storageOption.expirationDate !== null &&
                          props.handleExpiry === "Yes" && (
                            <Text14 margin="0px 10px 4px 10px">
                              {"Expire le: " +
                                dayjs(storageOption.expirationDate).format(
                                  "DD/MM/YYYY"
                                )}
                            </Text14>
                          )}
                      </FlexContainer>
                    </FlexContainer>
                    <FlexContainer>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          setStorageAreaEditActive(storageOption.id);
                          setStorageAreaAddActive(false);
                          setInputStorageAreaId(storageOption.storageAreaId);
                          setInputStorageUnit(storageOption.storageUnitId);
                          setInputQuantity(storageOption.quantity);
                          setInputExpirationDate(storageOption.expirationDate);
                          resetValidation();
                        }}
                        size="large"
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          let newArr = [...props.inputStorageOptions];
                          newArr.splice(index, 1);
                          props.setInputStorageOptions(newArr);
                        }}
                        size="large"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </FlexContainer>
                  </FlexContainer>
                  {/*Part Edit option*/}
                  <Collapse
                    orientation="vertical"
                    in={storageAreaEditActive === storageOption.id}
                  >
                    {addFailed !== "" && (
                      <BlockContainer margin="0 0 8px 0">
                        <Alert severity="error">{addFailed}</Alert>
                      </BlockContainer>
                    )}
                    <FlexContainer>
                      <Grid
                        container
                        spacing={{ xs: 1, sm: 1, md: 2 }}
                        columns={{ xs: 1, sm: 1, md: 2 }}
                      >
                        <Grid item xs={1} sm={1} md={1}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            error={inputStorageAreaNameError}
                          >
                            <InputLabel id="demo-simple-select-label">
                              Espace de stockage
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              required
                              value={inputStorageAreaId}
                              label="Espace de stockage"
                              onChange={(e) => {
                                setInputStorageAreaId(
                                  typeof e.target.value === "string"
                                    ? e.target.value
                                    : ""
                                );
                              }}
                            >
                              {mockStorageAreas.map((storageArea, index) => (
                                <MenuItem
                                  value={storageArea.id}
                                  key={index}
                                  disabled={
                                    props.handleExpiry !== "Yes" &&
                                    inputProductId !== "" &&
                                    props.inputStorageOptions
                                      .filter(
                                        (storageOptionTofilter) =>
                                          storageOption.id !==
                                          storageOptionTofilter.id
                                      )
                                      .find(
                                        (storageOption) =>
                                          storageOption.storageAreaId ===
                                          storageArea.id
                                      ) !== undefined
                                  }
                                >
                                  {storageArea.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {!props.isUnitBased && (
                          <Grid item xs={1} sm={1} md={1}>
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={inputStorageUnitError}
                            >
                              <InputLabel id="storageUnit">
                                Unité de stockage
                              </InputLabel>
                              <Select
                                labelId="storageUnit"
                                id="storageUnit"
                                required
                                value={inputStorageUnit}
                                label="Unité de stockage"
                                onChange={(e) =>
                                  setInputStorageUnit(e.target.value)
                                }
                              >
                                {props.inputStorageUnits.map(
                                  (storageUnit, index) => (
                                    <MenuItem
                                      value={storageUnit.id}
                                      key={index}
                                    >
                                      {storageUnit.unit === "Personnalisée"
                                        ? storageUnit.customUnit
                                        : storageUnit.unit}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                        )}
                        <Grid item xs={1} sm={1} md={1}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            name="quantity"
                            label="Quantité"
                            error={inputQuantityError}
                            value={inputQuantity}
                            onChange={(e) => {
                              if (
                                /^\d*\.?\d*$/.test(e.target.value) ||
                                e.target.value === ""
                              ) {
                                setInputQuantity(e.target.value);
                              }
                            }}
                          />
                        </Grid>
                        {props.handleExpiry === "Yes" && (
                          <Grid item xs={1} sm={1} md={1}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DesktopDatePicker
                                label="Date de péremption"
                                inputFormat="DD/MM/YYYY"
                                value={inputExpirationDate}
                                onChange={(value) =>
                                  setInputExpirationDate(value!)
                                }
                                renderInput={(params) => (
                                  <TextField fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        )}
                      </Grid>
                    </FlexContainer>
                    <FlexContainer justifyContent="center">
                      <ButtonMui
                        margin="16px 4px 0 0"
                        onClick={() => {
                          if (validateForm()) {
                            let tmpArray = [...props.inputStorageOptions];
                            tmpArray[index] = {
                              id: storageOption.id,
                              storageAreaId: inputStorageAreaId,
                              storageUnitId: props.isUnitBased
                                ? "1"
                                : inputStorageUnit,
                              quantity: inputQuantity,
                              expirationDate: inputExpirationDate,
                            };
                            props.setInputStorageOptions(tmpArray);

                            setInputProductId("");
                            setInputStorageAreaId("");
                            setInputQuantity("");
                            setInputStorageUnit("");
                            setStorageAreaEditActive("");
                            resetValidation();
                          }
                        }}
                        color="primary"
                        variant="contained"
                        size="large"
                      >
                        Modifier
                      </ButtonMui>
                      <ButtonMui
                        margin="16px 0 0 4px"
                        onClick={() => {
                          setInputStorageAreaId("");
                          setInputQuantity("");
                          setStorageAreaEditActive("");
                          resetValidation();
                        }}
                        color="primary"
                        variant="outlined"
                        size="large"
                      >
                        Annuler
                      </ButtonMui>
                    </FlexContainer>
                  </Collapse>
                </React.Fragment>
              )
          )}
        </FlexContainer>
      ))}
    </React.Fragment>
  );
};

export default StorageAreas;
