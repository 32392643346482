import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { SignupService } from "../../services/SignupService";
import { RootAction } from "../types";
import {
  SignUpActionFailure,
  signUpActionFailure, SignUpActionSuccess, signUpActionSuccess, SIGN_UP
} from "./actions";

export const signupEpic: Epic<
  RootAction,
  SignUpActionSuccess | SignUpActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(SIGN_UP),
    switchMap((action) =>
      from(
        SignupService.signUp({
          entityId: action.entityId,
          email: action.email,
          password: action.password,
          entityName: action.entityName,
          firstName: action.firstName,
          lastName: action.lastName,
        })
      ).pipe(
        mergeMap((res) => {
          return [signUpActionSuccess()];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [signUpActionFailure()]))
        )
      )
    )
  );
