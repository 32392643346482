import { createFilterOptions } from "@mui/core";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import {
  BaseIngredient,
  OffResponse,
  Product,
  PurchaseOption,
  StorageOption,
  Unit,
} from "../../../../../models/ingredients";
import { Supplier } from "../../../../../models/suppliers";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getSuppliers } from "../../../../../store/selectors";
import {
  getIngredientsAction,
  getSuppliersAction,
} from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import TextfieldTip from "../../../../Reusable/TextfieldTip";
import {
  convertUnitToUnit,
  getUnitById,
  getUnitNameById,
} from "../../../../Reusable/Utils";
import ProductionUnits from "./ProductionUnits";
import { PurchaseOptions } from "./PurchaseOptions";
import PurchaseUnits from "./PurchaseUnits";
import StorageAreas from "./StorageAreas";
import StorageUnits from "./StorageUnits";

const Input = styled("input")({
  display: "none",
});

export interface AddIngredientProps {
  inputSearch: string;
  popupActive: boolean;
  suppliers: Supplier[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
  getSuppliersAction: typeof getSuppliersAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _AddIngredient: React.FC<AddIngredientProps> = ({
  inputSearch,
  popupActive,
  suppliers,
  setPopupActive,
  getIngredientsAction,
  getSuppliersAction,
}) => {
  const addIngredient = () => {
    AxiosHttpClient.post("api/v1/weeventpro/stock/ingredients", {
      id: "",
      name: inputName,
      imageUrl: inputImageUrl,
      minimalQuantity: inputMinimalQuantity,
      idealQuantity: inputIdealQuantity,
      category: inputCategory,
      reference: inputReference,
      suggestionPerEvent: inputSuggestionPerEvent === "Yes" ? true : false,
      storageAreas: isUnitBased
        ? inputStorageAreas.map((storageOption) => {
            return {
              id: storageOption.id,
              storageAreaId: storageOption.storageAreaId,
              productId: storageOption.productId,
              quantity: storageOption.quantity,
              storageUnitId: "1",
              expirationDate: storageOption.expirationDate,
            };
          })
        : inputStorageAreas,
      ingredientForSale: inputIngredientForSale === "Yes" ? true : false,
      handleExpiry: inputHandleExpiry === "Yes" ? true : false,
      averageExpiryDelay: inputAverageExpiryDelay,
      averageExpiryDelayUnit: inputAverageExpiryDelayUnit,
      globalStorageUnitId: isUnitBased ? "1" : inputGlobalStorageUnitId,
      purchaseUnits: isUnitBased ? [] : inputPurchaseUnits,
      storageUnits: isUnitBased ? [] : inputStorageUnits,
      productionUnits: isUnitBased ? [] : inputProductionUnits,
      unitBased: isUnitBased,
      allergies: inputAllergies,
      purchaseOptions: isUnitBased
        ? inputPurchaseOptions.map((purchaseOption) => {
            return {
              id: purchaseOption.id,
              imageUrl: purchaseOption.imageUrl,
              name: purchaseOption.name,
              purchaseUnitId: "1",
              productCode: purchaseOption.productCode,
              supplierId: purchaseOption.supplierId,
              price: purchaseOption.price,
            };
          })
        : inputPurchaseOptions,
      handlePurchaseOptions:
        inputHandlePurchaseOptions === "Yes" ? true : false,
      energyKj: inputEnergyKj,
      energyKcal: inputEnergyKcal,
      proteins: inputProteins,
      carbohydrates: inputCarbohydrates,
      lipids: inputLipids,
      sugar: inputSugar,
      saturatedFattyAcids: inputSaturatedFattyAcids,
      salt: inputSalt,
      calcium: inputCalcium,
      vitaminD: inputVitaminD,
      dietaryFiber: inputDietaryFiber,
      cholesterol: inputCholesterol,
      customFields: customFieldValues,
    })
      .then(() => {
        getIngredientsAction(inputSearch);
        setPopupActive(false);
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addIngredientDialogContentId",
        });
      });
  };

  const getBaseIngredients = () => {
    AxiosHttpClient.get<BaseIngredient[]>(
      "api/v1/weeventpro/stock/baseIngredients/get"
    ).then((response) => {
      setBaseIngredients(response);
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/ingredient"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const filterOptions = createFilterOptions<BaseIngredient>({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  const validateForm = () => {
    setInputNameError(false);
    if (
      inputIdealQuantity !== "" &&
      inputMinimalQuantity !== "" &&
      Number(inputIdealQuantity) < Number(inputMinimalQuantity)
    ) {
      setAddFailed(
        "La valeur de la quantité minimale ne doit pas être inférieure ou égale à la quantité idéale"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addIngredientDialogContentId",
      });
      return false;
    }
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addIngredientDialogContentId",
      });
    }
    for (var purchaseOption of inputPurchaseOptions) {
      if (
        getUnitNameById(
          purchaseOption.purchaseUnitId,
          inputPurchaseUnits,
          isUnitBased
        ) === ""
      ) {
        setAddFailed("Unité(s) manquante(s) dans les options d'achat");
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addIngredientDialogContentId",
        });
        return false;
      }
    }
    for (var storageArea of inputStorageAreas) {
      if (
        getUnitNameById(
          storageArea.storageUnitId,
          inputStorageUnits,
          isUnitBased
        ) === ""
      ) {
        setAddFailed("Unité(s) manquante(s) dans les lots de stock");
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addIngredientDialogContentId",
        });
        return false;
      }
    }
    return result;
  };

  useEffect(() => {
    getSuppliersAction("");
    getBaseIngredients();
    getCustomFields();
  }, [getSuppliersAction]);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputName, setInputName] = useState("");

  const [inputNameError, setInputNameError] = useState(false);

  const [inputCategory, setInputCategory] = useState("");

  const [inputMinimalQuantity, setInputMinimalQuantity] = useState("");

  const [inputIdealQuantity, setInputIdealQuantity] = useState("");

  const [inputSuggestionPerEvent, setInputSuggestionPerEvent] = useState("No");

  const [inputIngredientForSale, setInputIngredientForSale] = useState("No");

  const [inputAverageExpiryDelay, setInputAverageExpiryDelay] = useState("");

  const [inputPurchaseUnits, setInputPurchaseUnits] = useState<Unit[]>([]);

  const [inputStorageUnits, setInputStorageUnits] = useState<Unit[]>([]);

  const [inputGlobalStorageUnitId, setInputGlobalStorageUnitId] = useState("");

  const [inputProductionUnits, setInputProductionUnits] = useState<Unit[]>([]);

  const [inputPurchaseOptions, setInputPurchaseOptions] = useState<
    PurchaseOption[]
  >([]);

  const [baseIngredients, setBaseIngredients] = useState<BaseIngredient[]>([]);

  const [inputBaseIngredient, setInputBaseIngredient] =
    useState<BaseIngredient | null>(null);

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    "https://" +
      process.env.REACT_APP_BUCKET_NAME! +
      ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
  );

  const [ingredientImageHovered, setIngredientImageHovered] = useState(false);

  const [inputEnergyKj, setInputEnergyKj] = useState("");

  const [inputEnergyKcal, setInputEnergyKcal] = useState("");

  const [inputProteins, setInputProteins] = useState("");

  const [inputCarbohydrates, setInputCarbohydrates] = useState("");

  const [inputLipids, setInputLipids] = useState("");

  const [inputSugar, setInputSugar] = useState("");

  const [inputSaturatedFattyAcids, setInputSaturatedFattyAcids] = useState("");

  const [inputSalt, setInputSalt] = useState("");

  const [inputCalcium, setInputCalcium] = useState("");

  const [inputVitaminD, setInputVitaminD] = useState("");

  const [inputDietaryFiber, setInputDietaryFiber] = useState("");

  const [inputCholesterol, setInputCholesterol] = useState("");

  const [inputStorageAreas, setInputStorageAreas] = useState<StorageOption[]>(
    []
  );

  const [inputStorageAreasTmp, setInputStorageAreasTmp] = useState<
    StorageOption[]
  >([]);

  const [inputAllergies, setInputAllergies] = useState<string[]>([]);

  const [inputProduct, setInputProduct] = useState<Product | null>(null);

  const [enabledUnitOptions, setEnabledUnitOptions] = useState<string[]>([
    "Kg",
    "g",
    "mg",
    "L",
    "mL",
  ]);

  const [inputAverageExpiryDelayUnit, setInputAverageExpiryDelayUnit] =
    useState("Jours");

  const [selectGlobalStorageUnitOpen, setSelectGlobalStorageUnitOpen] =
    useState(false);

  const [storageUnitOpen, setStorageUnitOpen] = useState(false);

  const [storageUnitAddActive, setStorageUnitAddActive] = useState(false);

  const [purchaseUnitOpen, setPurchaseUnitOpen] = useState(false);

  const [purchaseUnitAddActive, setPurchaseUnitAddActive] = useState(false);

  const [purchaseOptionAddActive, setPurchaseOptionAddActive] = useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const [addFailed, setAddFailed] = useState("");

  const [openedTooltip, setOpenedTooltip] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [importValue, setImportValue] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isUnitBased, setIsUnitBased] = useState(false);

  const [inputHandleExpiry, setInputHandleExpiry] = useState("Yes");

  const [inputHandlePurchaseOptions, setInputHandlePurchaseOptions] =
    useState("Yes");

  const [inputReference, setInputReference] = useState("");

  const classes = useStyles();

  const StorageUnitRef = useRef<HTMLInputElement>(null);

  const StorageProductRef = useRef<HTMLInputElement>(null);

  const purchaseUnitInputRef = useRef<HTMLInputElement>(null);

  const previousController = useRef<AbortController>();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const allergies = [
    "Arachides",
    "Céleri",
    "Crustacés",
    "Fruits à coques",
    "Gluten",
    "Lait",
    "Lupins",
    "Mollusques",
    "Moutarde",
    "Oeufs",
    "Poissons",
    "Soja",
    "Sulfites",
    "Sésame",
  ];

  let allergeneMap = {
    "en:gluten": "Gluten",
    "en:fish": "Poissons",
    "en:crustaceans": "Crustacés",
    "en:eggs": "Oeufs",
    "en:peanuts": "Arachides",
    "en:mustard": "Moutarde",
    "en:molluscs": "Mollusques",
    "en:soybeans": "Soja",
    "en:sulphur-dioxide-and-sulphites": "Sulfites",
    "en:sesame-seeds": "Sésame",
    "en:celery": "Céleri",
    "en:lupin": "Lupins",
    "en:milk": "Lait",
    "en:nuts": "Fruits à coques",
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  useEffect(() => {
    if (inputBaseIngredient !== null) {
      setInputImageUrl(inputBaseIngredient!.imageUrl);
      setInputCategory(inputBaseIngredient!.category);
    }
  }, [inputBaseIngredient]);

  useEffect(() => {
    if (
      inputBaseIngredient !== null &&
      inputName !== inputBaseIngredient.name
    ) {
      setInputBaseIngredient(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputName]);

  useEffect(() => {
    if (importValue === "" || importValue === null || importValue === undefined)
      return;
    if (importValue === "Basic") {
      setInputLipids(inputBaseIngredient!.lipids);
      setInputEnergyKj(inputBaseIngredient!.energyKj);
      setInputEnergyKcal(inputBaseIngredient!.energyKcal);
      setInputProteins(inputBaseIngredient!.proteins);
      setInputCarbohydrates(inputBaseIngredient!.carbohydrates);
      setInputSugar(inputBaseIngredient!.sugar);
      setInputSaturatedFattyAcids(inputBaseIngredient!.saturatedFattyAcids);
      setInputSalt(inputBaseIngredient!.salt);
      setInputCalcium(inputBaseIngredient!.calcium);
      setInputVitaminD(inputBaseIngredient!.vitaminD);
      setInputDietaryFiber(inputBaseIngredient!.dietaryFiber);
      setInputCholesterol(inputBaseIngredient!.cholesterol);
      if (
        inputBaseIngredient!.allergens !== undefined &&
        inputBaseIngredient!.allergens !== null
      )
        setInputAllergies(inputBaseIngredient!.allergens.split(","));
    } else {
      if (previousController.current) {
        previousController.current.abort();
      }
      const controller = new AbortController();
      const signal = controller.signal;
      previousController.current = controller;
      axios
        .get<OffResponse>(
          "https://world.openfoodfacts.org/api/v2/search?code=" +
            importValue +
            "&fields=product_name,code,brands,quantity,allergens,image_front_small_url,energy-kcal_100g,energy-kj_100g,fiber_100g,proteins_100g,saturated-fat_100g,carbohydrates_100g,salt_100g,sugars_100g,calcium_100g,vitamin-d_100g,cholesterol_100g&json=1",
          { signal: signal }
        )
        .then((response) => {
          if (response.data.products[0] !== null) {
            if (response.data.products[0]?.allergens! === "")
              setInputAllergies([]);
            else
              setInputAllergies(
                response.data.products[0]?.allergens! === undefined
                  ? []
                  : response.data.products[0]?.allergens
                      .split(",")
                      .filter((allergene) => allergene.includes("en:"))
                      .map((allergen) => {
                        return allergeneMap[allergen];
                      })!
              );
            setInputEnergyKj(
              response.data.products[0]!["energy-kj_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["energy-kj_100g"]!
            );
            setInputEnergyKcal(
              response.data.products[0]!["energy-kcal_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["energy-kcal_100g"]!
            );
            setInputDietaryFiber(
              response.data.products[0]!["fiber_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["fiber_100g"]!
            );
            setInputProteins(
              response.data.products[0]!["proteins_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["proteins_100g"]!
            );
            setInputSaturatedFattyAcids(
              response.data.products[0]!["saturated-fat_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["saturated-fat_100g"]!
            );
            setInputCarbohydrates(
              response.data.products[0]!["carbohydrates_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["carbohydrates_100g"]!
            );
            setInputSalt(
              response.data.products[0]!["salt_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["salt_100g"]!
            );
            setInputSugar(
              response.data.products[0]!["sugars_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["sugars_100g"]!
            );
            setInputCalcium(
              response.data.products[0]!["calcium_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["calcium_100g"]!
            );
            setInputVitaminD(
              response.data.products[0]!["vitamin-d_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["vitamin-d_100g"]!
            );
            setInputCholesterol(
              response.data.products[0]!["cholesterol_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["cholesterol_100g"]!
            );
            setInputCholesterol(
              response.data.products[0]!["cholesterol_100g"]! === undefined
                ? "N/A"
                : response.data.products[0]!["cholesterol_100g"]!
            );
            setInputLipids("N/A");
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importValue]);

  useEffect(() => {
    let gUnits = ["Kg", "g", "mg"];
    let lUnits = ["L", "mL"];
    if (inputPurchaseUnits.length > 0) {
      let unit =
        inputPurchaseUnits[0].unit === "Personnalisée"
          ? inputPurchaseUnits[0].customUnitCorrespondanceUnit
          : inputPurchaseUnits[0].unit;
      if (gUnits.indexOf(unit) > -1) setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(unit) > -1) setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Kg", "g", "mg", "L", "mL"]);
    } else if (inputStorageUnits.length > 0) {
      let unit =
        inputStorageUnits[0].unit === "Personnalisée"
          ? inputStorageUnits[0].customUnitCorrespondanceUnit
          : inputStorageUnits[0].unit;
      if (gUnits.indexOf(unit) > -1) setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(unit) > -1) setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Kg", "g", "mg", "L", "mL"]);
    } else if (inputProductionUnits.length > 0) {
      let unit =
        inputProductionUnits[0].unit === "Personnalisée"
          ? inputProductionUnits[0].customUnitCorrespondanceUnit
          : inputProductionUnits[0].unit;
      if (gUnits.indexOf(unit) > -1) setEnabledUnitOptions(["Kg", "g", "mg"]);
      else if (lUnits.indexOf(unit) > -1) setEnabledUnitOptions(["L", "mL"]);
      else setEnabledUnitOptions(["Kg", "g", "mg", "L", "mL"]);
    } else setEnabledUnitOptions(["Kg", "g", "mg", "L", "mL"]);
  }, [inputPurchaseUnits, inputStorageUnits, inputProductionUnits]);

  useEffect(() => {
    if (document.getElementById("globalStorageUnit") !== null)
      setSelectWidth(document.getElementById("globalStorageUnit")!.clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("globalStorageUnit")]);

  useEffect(() => {
    let hasChanged = false;
    if (inputHandleExpiry === "No") {
      let arr: StorageOption[] = [];

      var helper: Map<string, StorageOption> = new Map();
      inputStorageAreas.forEach((o) => {
        var key = o.productId + "-" + o.storageAreaId;

        if (!helper.has(key)) {
          helper.set(key, o);
        } else {
          let tmp = helper.get(key)!;

          helper.set(key, {
            ...tmp,
            quantity: (
              Number(tmp!.quantity) +
              Number(o.quantity) *
                convertUnitToUnit(
                  getUnitById(o.storageUnitId, inputStorageUnits)!,
                  getUnitById(tmp.storageUnitId, inputStorageUnits)!
                )
            ).toString(),
          });
          hasChanged = true;
        }
      });

      const iterator = helper.values();

      while (1) {
        let iteration = iterator.next();
        if (iteration.done) break;
        arr.push(iteration.value);
      }

      setInputStorageAreas(arr);

      if (hasChanged) setInputStorageAreasTmp(inputStorageAreas);
    } else {
      if (inputStorageAreasTmp.length !== 0) {
        setInputStorageAreas(inputStorageAreasTmp);
        setInputStorageAreasTmp([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHandleExpiry]);

  useEffect(() => {
    if (inputStorageUnits.length !== 0)
      setInputGlobalStorageUnitId(inputStorageUnits[0].id);
  }, [inputStorageUnits]);

  useEffect(() => {
    let hasChanged = false;
    if (inputHandlePurchaseOptions === "No") {
      let arr: StorageOption[] = [];

      var helper: Map<string, StorageOption> = new Map();
      inputStorageAreas.forEach((o) => {
        var key = o.storageAreaId;

        if (!helper.has(key)) {
          helper.set(key, { ...o, productId: "1" });
        } else {
          let tmp = helper.get(key)!;

          helper.set(key, {
            ...tmp,
            productId: "1",
            quantity: (
              Number(tmp!.quantity) +
              Number(o.quantity) *
                convertUnitToUnit(
                  getUnitById(o.storageUnitId, inputStorageUnits)!,
                  getUnitById(tmp.storageUnitId, inputStorageUnits)!
                )
            ).toString(),
          });
          hasChanged = true;
        }
      });

      const iterator = helper.values();

      while (1) {
        let iteration = iterator.next();
        if (iteration.done) break;
        arr.push(iteration.value);
      }

      setInputStorageAreas(arr);

      if (hasChanged) setInputStorageAreasTmp(inputStorageAreas);
    } else {
      if (inputStorageAreasTmp.length !== 0) {
        setInputStorageAreas(inputStorageAreasTmp);
        setInputStorageAreasTmp([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputHandlePurchaseOptions]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter un ingrédient"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addIngredientDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setIngredientImageHovered(true)}
                onMouseLeave={() => setIngredientImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {ingredientImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
          </Grid>

          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete<BaseIngredient, false, true | false, true>
                id="name"
                filterOptions={filterOptions}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                disableClearable
                freeSolo
                getOptionLabel={(option) => {
                  if (typeof option === "string") return option;
                  else return option.name;
                }}
                options={baseIngredients}
                value={
                  baseIngredients.find(
                    (option) => option.name === inputBaseIngredient?.name
                  ) ?? null
                }
                onChange={(event, newValue) => {
                  if (!(typeof newValue === "string"))
                    setInputBaseIngredient(newValue!);
                }}
                inputValue={inputName}
                onInputChange={(event, newInputValue) => {
                  setInputName(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">{option.name}</Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputNameError}
                    variant="outlined"
                    name="name"
                    label="Nom"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                <Select
                  required
                  fullWidth
                  displayEmpty
                  value={inputCategory}
                  label="Catégorie"
                  onChange={(e) => {
                    setInputCategory(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Légumes">Légumes</MenuItem>
                  <MenuItem value="Fruits">Fruits</MenuItem>
                  <MenuItem value="Produits laitiers">
                    Produits laitiers
                  </MenuItem>
                  <MenuItem value="Condiments">Condiments</MenuItem>
                  <MenuItem value="Pâtes">Pâtes</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="reference"
                label="Référence"
                value={inputReference}
                onChange={(e) => {
                  setInputReference(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Gérer les dates de péremption?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputHandleExpiry}
                  onChange={(e) => setInputHandleExpiry(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              {inputHandleExpiry === "Yes" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  name="averageExpiryDelay"
                  label="Durée d'expiration moyenne"
                  value={inputAverageExpiryDelay}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputAverageExpiryDelay(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          "& .MuiInputBase-root:before": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:after": {
                            borderBottom: "none",
                          },
                          "& .MuiInputBase-root:hover:before": {
                            borderBottom: "none !important",
                          },
                        }}
                      >
                        <Select
                          variant="standard"
                          sx={{
                            "& .MuiSelect-select:focus": {
                              borderBottom: "none",
                              backgroundColor: "white",
                            },
                          }}
                          value={inputAverageExpiryDelayUnit}
                          onChange={(e) =>
                            setInputAverageExpiryDelayUnit(e.target.value)
                          }
                        >
                          <MenuItem key="Jours" value="Jours">
                            Jours
                          </MenuItem>
                          <MenuItem key="Mois" value="Mois">
                            Mois
                          </MenuItem>
                          <MenuItem key="Années" value="Années">
                            Années
                          </MenuItem>
                        </Select>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Gérer les options d'achat
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputHandlePurchaseOptions}
                  onChange={(e) =>
                    setInputHandlePurchaseOptions(e.target.value)
                  }
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Suggestion de commande par évènement
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputSuggestionPerEvent}
                  onChange={(e) => setInputSuggestionPerEvent(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Vous vendez ce produit?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputIngredientForSale}
                  onChange={(e) => setInputIngredientForSale(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Unités
          </Text20>
        </BlockContainer>
        <FlexContainer>
          <Checkbox
            color="secondary"
            size="small"
            checked={isUnitBased}
            onChange={(event) => {
              setIsUnitBased(!isUnitBased);
            }}
          />
          <Text16 display="inline-block">
            Gérer les valeurs de cet ingrédient à l'unité
          </Text16>
        </FlexContainer>
        {!isUnitBased && (
          <>
            <PurchaseUnits
              inputRef={purchaseUnitInputRef}
              enabledUnitOptions={enabledUnitOptions}
              inputPurchaseUnits={inputPurchaseUnits}
              inputStorageUnits={inputStorageUnits}
              inputProductionUnits={inputProductionUnits}
              purchaseUnitAddActive={purchaseUnitAddActive}
              purchaseUnitOpen={purchaseUnitOpen}
              setInputPurchaseUnits={setInputPurchaseUnits}
              setInputStorageUnits={setInputStorageUnits}
              setInputProductionUnits={setInputProductionUnits}
              setPurchaseUnitOpen={setPurchaseUnitOpen}
              setPurchaseUnitAddActive={setPurchaseUnitAddActive}
            />
            <StorageUnits
              inputRef={StorageUnitRef}
              storageUnitOpen={storageUnitOpen}
              enabledUnitOptions={enabledUnitOptions}
              inputPurchaseUnits={inputPurchaseUnits}
              inputStorageUnits={inputStorageUnits}
              inputProductionUnits={inputProductionUnits}
              storageUnitAddActive={storageUnitAddActive}
              setInputPurchaseUnits={setInputPurchaseUnits}
              setInputStorageUnits={setInputStorageUnits}
              setInputProductionUnits={setInputProductionUnits}
              setStorageUnitOpen={setStorageUnitOpen}
              setStorageUnitAddActive={setStorageUnitAddActive}
            />
            <ProductionUnits
              enabledUnitOptions={enabledUnitOptions}
              inputPurchaseUnits={inputPurchaseUnits}
              inputStorageUnits={inputStorageUnits}
              inputProductionUnits={inputProductionUnits}
              setInputPurchaseUnits={setInputPurchaseUnits}
              setInputStorageUnits={setInputStorageUnits}
              setInputProductionUnits={setInputProductionUnits}
            />
          </>
        )}
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Alertes de stockage
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {!isUnitBased && (
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                open={openedTooltip === "globalStorageUnit"}
                disableFocusListener
                disableTouchListener
                placement="top"
                title="Unité utilisée pour le calcul des quantités minimales et idéales"
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="globalStorageUnitLabel">
                    Unité de stockage globale
                  </InputLabel>
                  <Select
                    labelId="globalStorageUnitLabel"
                    id="globalStorageUnit"
                    open={selectGlobalStorageUnitOpen}
                    onMouseEnter={() => {
                      setOpenedTooltip("globalStorageUnit");
                    }}
                    onMouseLeave={() => {
                      setOpenedTooltip("");
                    }}
                    onBlur={() => {
                      setOpenedTooltip("");
                    }}
                    onClose={() => {
                      setSelectGlobalStorageUnitOpen(false);
                      setOpenedTooltip("");
                    }}
                    onOpen={() => setSelectGlobalStorageUnitOpen(true)}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          width: selectWidth,
                        },
                        "& .MuiButtonBase-root": {
                          whiteSpace: "normal",
                        },
                      },
                    }}
                    value={inputGlobalStorageUnitId}
                    label="Unité de stockage globale"
                    onChange={(e) =>
                      setInputGlobalStorageUnitId(e.target.value)
                    }
                  >
                    {inputStorageUnits.length === 0 && (
                      <Link
                        to="storageUnitsTopId"
                        spy={true}
                        smooth={true}
                        duration={500}
                        containerId={"addIngredientDialogContentId"}
                      >
                        <MenuItem
                          sx={{ color: "red" }}
                          value=""
                          onClick={() => {
                            setStorageUnitAddActive(true);
                            setTimeout(function () {
                              setSelectGlobalStorageUnitOpen(false);
                              StorageUnitRef.current?.focus({
                                preventScroll: true,
                              });
                            }, 100);
                            setTimeout(function () {
                              setStorageUnitOpen(true);
                            }, 500);
                          }}
                        >
                          Aucune unité de stockage créée. Cliquez ici pour en
                          créer une!
                        </MenuItem>
                      </Link>
                    )}
                    {inputStorageUnits.map((storageUnit, index) => (
                      <MenuItem value={storageUnit.id} key={index}>
                        {storageUnit.unit === "Personnalisée"
                          ? storageUnit.customUnit
                          : storageUnit.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={1} sm={1} md={1}>
            <TextfieldTip
              tipColor="primary"
              tipPlacement="top"
              tipTitle="Quantité en dessous de laquelle vous recevez une alerte"
              name="minimalQuantity"
              label="Quantité minimale"
              value={inputMinimalQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputMinimalQuantity(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getUnitNameById(
                      inputGlobalStorageUnitId,
                      inputStorageUnits,
                      isUnitBased
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextfieldTip
              tipColor="primary"
              tipPlacement="top"
              tipTitle="Quantité désirée après remise à niveau du stock"
              name="idealQuantity"
              label="Quantité idéale"
              value={inputIdealQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputIdealQuantity(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {getUnitNameById(
                      inputGlobalStorageUnitId,
                      inputStorageUnits,
                      isUnitBased
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        {inputHandlePurchaseOptions === "Yes" && (
          <>
            <BlockContainer margin="16px 0" id="offSearch">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Options d'achat
              </Text20>
            </BlockContainer>
            <PurchaseOptions
              offSearchInputRef={StorageProductRef}
              purchaseUnitInputRef={purchaseUnitInputRef}
              purchaseOptionAddActive={purchaseOptionAddActive}
              inputPurchaseOptions={inputPurchaseOptions}
              inputPurchaseUnits={inputPurchaseUnits}
              inputProduct={inputProduct}
              isUnitBased={isUnitBased}
              setInputEnergyKj={setInputEnergyKj}
              setInputEnergyKcal={setInputEnergyKcal}
              setInputProteins={setInputProteins}
              setInputCarbohydrates={setInputCarbohydrates}
              setInputSugar={setInputSugar}
              setInputSaturatedFattyAcids={setInputSaturatedFattyAcids}
              setInputSalt={setInputSalt}
              setInputCalcium={setInputCalcium}
              setInputVitaminD={setInputVitaminD}
              setInputDietaryFiber={setInputDietaryFiber}
              setInputCholesterol={setInputCholesterol}
              setInputLipids={setInputLipids}
              setInputProduct={setInputProduct}
              setInputAllergies={setInputAllergies}
              setInputPurchaseUnits={setInputPurchaseUnits}
              setInputPurchaseOptions={setInputPurchaseOptions}
              setPurchaseUnitAddActive={setPurchaseUnitAddActive}
              setPurchaseUnitOpen={setPurchaseUnitOpen}
              setPurchaseOptionAddActive={setPurchaseOptionAddActive}
            />
            <Divider sx={{ mt: "16px" }} />
          </>
        )}
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Composition
          </Text20>
        </BlockContainer>
        <BlockContainer margin="16px 0">
          <Text16 textAlign="left" margin="0">
            Importer les allergenes et les valeurs nutritionnelles
          </Text16>
        </BlockContainer>

        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Importer depuis</InputLabel>
          <Select
            required
            fullWidth
            displayEmpty
            value={importValue}
            label="Importer depuis"
            onChange={(e) => {
              setImportValue(
                typeof e.target.value === "string" ? e.target.value : ""
              );
            }}
          >
            {inputBaseIngredient !== null && (
              <MenuItem value="Basic">{inputBaseIngredient.name}</MenuItem>
            )}
            {inputPurchaseOptions.map((purchaseOption, index) => (
              <MenuItem value={purchaseOption.productCode} key={index}>
                {purchaseOption.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <BlockContainer margin="16px 0">
          <Text16 textAlign="left" margin="0">
            Allergènes
          </Text16>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              fullWidth
              multiple
              id="allergies"
              options={allergies}
              filterSelectedOptions
              value={inputAllergies}
              getOptionLabel={(option) => (option ? option : "")}
              onChange={(event, newValue) => {
                setInputAllergies(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Liste des allergènes" />
              )}
            />
          </Grid>
        </Grid>
        <BlockContainer margin="16px 0 0 0">
          <Text16 textAlign="left" margin="0">
            Valeurs nutritionnelles
          </Text16>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 3 }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={3}
            display="flex"
            justifyContent="flex-end"
          >
            <ButtonMui
              onClick={() => {
                setInputEnergyKj("");
                setInputEnergyKcal("");
                setInputProteins("");
                setInputCarbohydrates("");
                setInputSugar("");
                setInputSaturatedFattyAcids("");
                setInputSalt("");
                setInputCalcium("");
                setInputVitaminD("");
                setInputDietaryFiber("");
                setInputCholesterol("");
                setInputLipids("");
              }}
              color="primary"
              variant="outlined"
              size="medium"
            >
              <RestartAltIcon sx={{ marginRight: "8px" }} />
              Vider les champs
            </ButtonMui>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="energyKj"
              label="Énergie (Kj)"
              value={inputEnergyKj}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputEnergyKj(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="energyKcal"
              label="Énergie (Kcal)"
              value={inputEnergyKcal}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputEnergyKcal(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="proteins"
              label="Protéines"
              value={inputProteins}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputProteins(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="carbohydrates"
              label="Glucides"
              value={inputCarbohydrates}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCarbohydrates(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="lipids"
              label="Lipides"
              value={inputLipids}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputLipids(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="sugar"
              label="Sucres"
              value={inputSugar}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSugar(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="saturatedFattyAcids"
              label="Acides gras saturés"
              value={inputSaturatedFattyAcids}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSaturatedFattyAcids(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="salt"
              label="Sel"
              value={inputSalt}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSalt(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="calcium"
              label="Calcium"
              value={inputCalcium}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCalcium(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="vitaminD"
              label="Vitamine D"
              value={inputVitaminD}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputVitaminD(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="dietaryFiber"
              label="Fibres alimentaires"
              value={inputDietaryFiber}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputDietaryFiber(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cholesterol"
              label="Cholestérol"
              value={inputCholesterol}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCholesterol(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Espaces de stockage
          </Text20>
        </BlockContainer>

        <StorageAreas
          inputRefStorageProduct={StorageProductRef}
          inputRefStorageUnit={StorageUnitRef}
          inputPurchaseOptions={inputPurchaseOptions}
          inputStorageOptions={inputStorageAreas}
          inputStorageUnits={inputStorageUnits}
          isUnitBased={isUnitBased}
          handleExpiry={inputHandleExpiry}
          handlePurchaseOptions={inputHandlePurchaseOptions}
          inputImageUrl={inputImageUrl}
          inputName={inputName}
          setInputStorageOptions={setInputStorageAreas}
          setStorageUnitAddActive={setStorageUnitAddActive}
          setStorageUnitOpen={setStorageUnitOpen}
          setPurchaseOptionAddActive={setPurchaseOptionAddActive}
        />
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Champs personnalisés
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {customFields.map((customField, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              {customField.type === "Texte" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  name={customField.name}
                  label={customField.name}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(
                          customField.id
                        )![0] === undefined
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(customField.id)![0]
                  }
                  onChange={(e) =>
                    setCustomFieldValuesByCustomFieldId(customField.id, [
                      e.target.value,
                    ])
                  }
                />
              )}
              {customField.type === "Date" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={customField.name}
                    inputFormat="DD/MM/YYYY"
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0 ||
                      getCustomFieldValuesByCustomFieldId(
                        customField.id
                      )![0] === ""
                        ? null
                        : dayjs(
                            getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0],
                            "DD/MM/YYYY"
                          )
                    }
                    onChange={(value) =>
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        value === null ? "" : value!.format("DD/MM/YYYY"),
                      ])
                    }
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              )}
              {customField.type === "Liste déroulante" && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {customField.name}
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    displayEmpty
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    label={customField.name}
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                  >
                    {customField.values.map((choice, index) => (
                      <MenuItem value={choice} key={index}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {customField.type === "Boutton radio" && (
                <FormControl>
                  <FormLabel>{customField.name}</FormLabel>
                  <RadioGroup
                    name={customField.name}
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                    row
                  >
                    {customField.values.map((choice, index) => (
                      <FormControlLabel
                        value={choice}
                        control={<Radio />}
                        label={choice}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {customField.type === "Checkbox" && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={customField.values}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? []
                      : getCustomFieldValuesByCustomFieldId(customField.id)!
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null
                            ? false
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )!.includes(option)
                            ? true
                            : false
                        }
                      />
                      {option}
                    </li>
                  )}
                  onChange={(e, values) => {
                    setCustomFieldValuesByCustomFieldId(customField.id, values);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={customField.name}
                    />
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addIngredient();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddIngredient = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_AddIngredient);

export default AddIngredient;
