import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { SupplierOrderArchive } from "../../../../../models/supplierOrderArchive";
import {
  AccordionDetailsMui,
  AccordionMui,
  AccordionSummaryMui,
} from "../../../../../styles/AccordionMui";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  commify,
  getISODateFromString,
  getTimeFromDate,
} from "../../../../Reusable/Utils";

export interface ExpensesDetailsProps {
  supplierOrderArchiveList: SupplierOrderArchive[];
  popupActive: boolean;
  inputStartDate: string;
  inputEndDate: string;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const ExpensesDetails: React.FC<ExpensesDetailsProps> = (props) => {
  const [expandedExpense, setExpandedExpense] = useState("");

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>) => {
    setExpandedExpense(panel === expandedExpense ? "none" : panel);
  };

  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Détails de vos dépenses"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent>
        <FlexContainer flexDirection="column">
          {props.supplierOrderArchiveList?.map(
            (supplierOrderArchive, index) => (
              <FlexContainer flexDirection="column">
                {(index === 0 ||
                  getISODateFromString(
                    props.supplierOrderArchiveList[index - 1].date
                  ) !== getISODateFromString(supplierOrderArchive.date)) && (
                  <FlexContainer>
                    <Text16 fontWeight="600">
                      {getISODateFromString(supplierOrderArchive.date)}
                    </Text16>
                  </FlexContainer>
                )}
                <FlexContainer>
                  <AccordionMui
                    square
                    expanded={expandedExpense === supplierOrderArchive.label}
                    onChange={handleChange(supplierOrderArchive.label)}
                  >
                    <AccordionSummaryMui
                      aria-controls="panel8d-content"
                      height="70px"
                      $maxHeight="70px"
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <FlexContainer
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <FlexContainer>
                          <FlexContainer alignItems="center">
                            <FlexContainer>
                              <RestaurantIcon color="primary" />
                            </FlexContainer>
                            <FlexContainer flexDirection="column">
                              <FlexContainer>
                                <Text14>
                                  {"Commande: " + supplierOrderArchive.label}
                                </Text14>
                              </FlexContainer>
                              <FlexContainer>
                                <Text14>
                                  {getTimeFromDate(supplierOrderArchive.date)}
                                </Text14>
                              </FlexContainer>
                            </FlexContainer>
                          </FlexContainer>
                        </FlexContainer>
                        <FlexContainer>
                          <FlexContainer>
                            <Text16 color={CustomTheme.palette.secondary.main}>
                              {commify(parseFloat(supplierOrderArchive.price))}
                              {" €"}
                            </Text16>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </AccordionSummaryMui>
                    <AccordionDetailsMui>
                      <FlexContainer
                        borderBottom="rgb(0,0,0,0.2) solid 1px"
                        padding="8px 0"
                      >
                        <FlexContainer flex="3">
                          <Text14 fontWeight="600">Ingrédient</Text14>
                        </FlexContainer>
                        <FlexContainer flex="1">
                          <Text14 fontWeight="600">Quantité</Text14>
                        </FlexContainer>
                        <FlexContainer flex="1">
                          <Text14 fontWeight="600">Prix U.</Text14>
                        </FlexContainer>
                        <FlexContainer flex="1">
                          <Text14 fontWeight="600">Prix</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {supplierOrderArchive.ingredientQuantityList.map(
                        (ingredientQuantity) => (
                          <FlexContainer
                            borderBottom="rgb(0,0,0,0.2) solid 1px"
                            padding="8px 0"
                          >
                            <FlexContainer flex="3">
                              <Text14>
                                {ingredientQuantity.ingredientName}
                              </Text14>
                            </FlexContainer>
                            <FlexContainer flex="1">
                              <Text14>
                                {ingredientQuantity.quantity +
                                  ingredientQuantity.unit}
                              </Text14>
                            </FlexContainer>
                            <FlexContainer flex="1">
                              <Text14>10€</Text14>
                            </FlexContainer>
                            <FlexContainer flex="1">
                              <Text14>100€</Text14>
                            </FlexContainer>
                          </FlexContainer>
                        )
                      )}
                      <FlexContainer padding="8px 0">
                        <FlexContainer flex="3"></FlexContainer>
                        <FlexContainer flex="1"></FlexContainer>
                        <FlexContainer flex="1">
                          <Text14 fontWeight="600">Total</Text14>
                        </FlexContainer>
                        <FlexContainer flex="1">
                          <Text14>{supplierOrderArchive.price}€</Text14>
                        </FlexContainer>
                      </FlexContainer>
                    </AccordionDetailsMui>
                  </AccordionMui>
                </FlexContainer>
              </FlexContainer>
            )
          )}
        </FlexContainer>
      </DialogContent>
    </Dialog>
  );
};

export default ExpensesDetails;
