import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";

export interface DeleteEmployeeProps {
  input: string;
  popupActive: Employee;
  setPopupActive: React.Dispatch<React.SetStateAction<Employee>>;
  getEmployeesAction: typeof getEmployeesAction;
}

const _DeleteEmployee: React.FC<DeleteEmployeeProps> = (props) => {
  const deleteEmployee = () => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/rh/employees/delete/" + props.popupActive.id
    )
      .then(() => {
        props.getEmployeesAction(props.input);
        props.setPopupActive({
          id: "",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          telNumber: "",
          email: "",
          address: "",
          socialSituation: "",
          salary: "",
          gender: "",
          position: "",
          status: "",
          city: "",
          password: "",
          entityId: "",
        });
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      open={props.popupActive.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive({
          id: "",
          firstName: "",
          lastName: "",
          dateOfBirth: null,
          telNumber: "",
          email: "",
          address: "",
          socialSituation: "",
          salary: "",
          gender: "",
          position: "",
          status: "",
          city: "",
          password: "",
          entityId: "",
        });
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer employee?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le matériel{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive.firstName + " " + props.popupActive.lastName}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          onClick={() => deleteEmployee()}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() =>
            props.setPopupActive({
              id: "",
              firstName: "",
              lastName: "",
              dateOfBirth: null,
              telNumber: "",
              email: "",
              address: "",
              socialSituation: "",
              salary: "",
              gender: "",
              position: "",
              status: "",
              city: "",
              password: "",
              entityId: "",
            })
          }
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteEmployee = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getEmployeesAction: getEmployeesAction,
    },
    dispatch
  )
)(_DeleteEmployee);

export default DeleteEmployee;
