import React from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { Button } from "../../../styles/Button";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text28, Text56 } from "../../../styles/Text";
import { ThemeCustom } from "../../../styles/Utils";

export interface HomePageImageSectionProps {}

const HomePageImageSection: React.FC<HomePageImageSectionProps> = () => {
  return (
    <BlockContainer
      sx={{ position: "relative", textAlign: "center", margin: "80px 0 0 0" }}
    >
      <img
        alt=""
        src={
          "https://" +
          process.env.REACT_APP_BUCKET_NAME! +
          ".s3.eu-west-3.amazonaws.com/WebsiteImages/homePagePro.png"
        }
        style={{
          opacity: 0.6,
          width: "100%",
          height: "auto",
        }}
      />
      <BlockContainer
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          backgroundColor="rgba(255,255,255,0.8)"
          padding="50px"
        >
          <div>
            <Text56 color={ThemeCustom.colors.pink}>
              Logiciel cloud de gestion ERP pour les professionnels
            </Text56>
          </div>
          <div>
            <Text28 color={ThemeCustom.colors.black}>
              Simplifiez votre quotidien au travail avec une prise en main
              facile
            </Text28>
          </div>
          <Button color={ThemeCustom.colors.linearButton} to="/">
            <Text28 color={ThemeCustom.colors.white}>
              Essayer gratuitement
            </Text28>
          </Button>
        </FlexContainer>
      </BlockContainer>
    </BlockContainer>
  );
};

export default HomePageImageSection;
