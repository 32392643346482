import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch } from "../../../../store";
import { setExpandedMenu } from "../../../../store/ExpandedMenuItem/actions";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { DashboardItems } from "./DashboardItems";

export interface DashboardProps {
  setExpandedMenu: typeof setExpandedMenu;
}

const _Dashboard: React.FC<DashboardProps> = ({ setExpandedMenu }) => {
  useEffect(() => {
    setExpandedMenu("panel8");
  }, [setExpandedMenu]);

  return (
    <BlockContainer>
      <PageHeader
        title="Tableau de bord"
        subTitle="Votre tableau de bord"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <DashboardItems />
    </BlockContainer>
  );
};

export const Dashboard = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setExpandedMenu: setExpandedMenu,
    },
    dispatch
  )
)(_Dashboard);

export default Dashboard;
