import { Grid, TextField } from "@mui/material";
import React from "react";
import { Text16 } from "../../../../../../styles/Text";

export interface CreateQuoteStep5Props {
  inputPrice: string;
  setInputPrice: React.Dispatch<React.SetStateAction<string>>;
}

const CreateQuoteStep5: React.FC<CreateQuoteStep5Props> = ({
  inputPrice,
  setInputPrice,
}) => {
  return (
    <Grid item xs={6}>
      <Text16>Choisissez le prix de la préstation</Text16>
      <TextField
        variant="outlined"
        name="Price"
        label="Prix"
        value={inputPrice}
        type="string"
        onChange={(e) => setInputPrice(e.target.value)}
      />
    </Grid>
  );
};

export default CreateQuoteStep5;
