import { combineReducers } from "redux";
import { cartReducer } from "./Cart";
import { crmReducer } from "./Crm";
import { customerOrderArchiveReducer, customerOrderReducer } from "./CustomerOrder";
import { expandedMenuItemReducer } from "./ExpandedMenuItem";
import { invoiceReducer, invoiceTemplateReducer } from "./Invoice";
import { metricReducer } from "./Metric";
import { quoteReducer, quoteTemplateReducer } from "./Quote";
import { dishReducer } from "./Dish";
import { rhReducer } from "./Rh";
import { signInReducer } from "./Signin";
import { stockReducer } from "./Stock";
import { supplierOrderArchiveReducer, supplierOrderReducer } from "./SupplierOrder";
import { RootState } from "./types";
import { upfrontInvoiceReducer, upfrontInvoiceTemplateReducer } from "./UpfrontInvoice";
import { saleReducer } from "./Sale";
import { routeReducer } from "./Route";
import { signUpReducer } from "./Signup";

export const rootReducer = combineReducers<RootState>({
  signUp: signUpReducer,
  signIn: signInReducer,
  stock: stockReducer,
  dish: dishReducer,
  quote: quoteReducer,
  quoteTemplates: quoteTemplateReducer,
  upfrontInvoice: upfrontInvoiceReducer,
  upfrontInvoiceTemplates: upfrontInvoiceTemplateReducer,
  invoice: invoiceReducer,
  invoiceTemplates: invoiceTemplateReducer,
  expandedMenuItem: expandedMenuItemReducer,
  customerOrder: customerOrderReducer,
  customerOrderArchive: customerOrderArchiveReducer,
  supplierOrder: supplierOrderReducer,
  supplierOrderArchive: supplierOrderArchiveReducer,
  cart: cartReducer,
  metric: metricReducer,
  crm: crmReducer,
  rh: rhReducer,
  sale: saleReducer,
  route: routeReducer,
});
