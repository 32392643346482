import { createFilterOptions } from "@mui/core";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish, SaleOption } from "../../../../../models/dish";
import { Employee } from "../../../../../models/employee";
import { Ingredient } from "../../../../../models/ingredients";
import { ExtraOption, Nomenclature } from "../../../../../models/nomenclature";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import { getEmployees, getIngredients } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  getIngredientById,
  getIngredientNameById,
  getUnitById,
  getUnitNameById,
  toFixed2,
} from "../../../../Reusable/Utils";
import { SellOptions } from "./SellOptions";

const Input = styled("input")({
  display: "none",
});

export interface EditDishProps {
  inputSearch: string;
  popupActive: Dish | null;
  employees: Employee[];
  ingredients: Ingredient[];
  setPopupActive: React.Dispatch<React.SetStateAction<Dish | null>>;
  getDishesPage: () => void;
  getIngredientsAction: typeof getIngredientsAction;
  getEmployeesAction: typeof getEmployeesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _EditDish: React.FC<EditDishProps> = ({
  inputSearch,
  popupActive,
  employees,
  ingredients,
  getIngredientsAction,
  setPopupActive,
  getDishesPage,
  getEmployeesAction,
}) => {
  const editDish = () => {
    AxiosHttpClient.put("api/v1/weeventpro/catalog", {
      id: popupActive!.id,
      imageUrl: inputImageUrl,
      name: inputName,
      category: inputCategory,
      tags: inputTags,
      internalCode: inputInternalCode,
      forSale: inputIsForSale === "Yes" ? true : false,
      stored: inputIsStored === "Yes" ? true : false,
      preparationTime: inputPreparationTime,
      instructions: inputInstructions,
      chefsIds: inputChefs.map((chef) => chef.id),
      productionQuantity: inputProductionQuantity,
      productionUnit: inputProductionUnit,
      cookingTime: inputCookingTime,
      nomenclature: inputNomenclature,
      additionalIngredients: inputExtraOptions,
      cookingTemperature: inputCookingTemperature,
      saleOptions: inputSaleOptions,
      energyKj: inputEnergyKj,
      energyKcal: inputEnergyKcal,
      proteins: inputProteins,
      carbohydrates: inputCarbohydrates,
      lipids: inputLipids,
      sugar: inputSugar,
      saturatedFattyAcids: inputSaturatedFattyAcids,
      salt: inputSalt,
      calcium: inputCalcium,
      vitaminD: inputVitaminD,
      dietaryFiber: inputDietaryFiber,
      cholesterol: inputCholesterol,
      allergens: inputAllergens,
      expiryDelay: "",
      customFields: customFieldValues,
    })
      .then(() => {
        getDishesPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setEditFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "editDishDialogContentId",
        });
      });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/dish"
    ).then((response) => {
      let customFieldValuesFetched: CustomFieldValue[] = [];
      setCustomFields(response);
      response.forEach((customField) => {
        let valuesFetched =
          getCustomFieldValuesByCustomFieldIdFromFetchedValues(customField.id);
        customFieldValuesFetched.push({
          id: uuid(),
          customFieldId: customField.id,
          values: valuesFetched,
        });
      });
      setCustomFieldValues(customFieldValuesFetched);
    });
  };

  const getCustomFieldValuesByCustomFieldIdFromFetchedValues = (id: string) => {
    for (var customFieldValue of popupActive!.customFields) {
      if (customFieldValue.customFieldId === id) return customFieldValue.values;
    }
    return [];
  };

  const validateAddNomenclatureItemForm = () => {
    setInputIngredientNameError(false);
    setInputIngredientUnitError(false);
    setInputIngredientQuantityError(false);
    setAddNomenclatureItemFailed("");
    let result = true;
    if (inputIngredient === null) {
      setInputIngredientNameError(true);
      result = false;
    }
    if (inputIngredientUnit === "") {
      setInputIngredientUnitError(true);
      result = false;
    }
    if (inputIngredientQuantity === "") {
      setInputIngredientQuantityError(true);
      result = false;
    }
    if (!result) {
      setAddNomenclatureItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditNomenclatureItemForm = () => {
    setInputIngredientUnitEditError(false);
    setInputIngredientQuantityEditError(false);
    setEditNomenclatureItemFailed("");
    let result = true;
    if (inputIngredientUnitEdit === "") {
      setInputIngredientUnitEditError(true);
      result = false;
    }
    if (inputIngredientQuantityEdit === "") {
      setInputIngredientQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditNomenclatureItemFailed("Champs manquants");
    }
    return result;
  };

  const validateAddExtraOptionItemForm = () => {
    setInputExtraOptionIngredientNameError(false);
    setInputExtraOptionIngredientUnitError(false);
    setInputExtraOptionIngredientQuantityError(false);
    setInputExtraOptionIngredientPriceError(false);
    setAddExtraOptionItemFailed("");
    let result = true;
    if (inputExtraOptionIngredient === null) {
      setInputExtraOptionIngredientNameError(true);
      result = false;
    }
    if (inputExtraOptionIngredientUnit === "") {
      setInputExtraOptionIngredientUnitError(true);
      result = false;
    }
    if (inputExtraOptionIngredientQuantity === "") {
      setInputExtraOptionIngredientQuantityError(true);
      result = false;
    }
    if (inputExtraOptionIngredientPrice === "") {
      setInputExtraOptionIngredientPriceError(true);
      result = false;
    }
    if (!result) {
      setAddExtraOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditExtraOptionItemForm = () => {
    setInputExtraOptionIngredientUnitEditError(false);
    setInputExtraOptionIngredientQuantityEditError(false);
    setInputExtraOptionIngredientPriceEditError(false);
    setEditNomenclatureItemFailed("");
    let result = true;
    if (inputExtraOptionIngredientUnitEdit === "") {
      setInputExtraOptionIngredientUnitEditError(true);
      result = false;
    }
    if (inputExtraOptionIngredientQuantityEdit === "") {
      setInputExtraOptionIngredientQuantityEditError(true);
      result = false;
    }
    if (inputExtraOptionIngredientPriceEdit === "") {
      setInputExtraOptionIngredientPriceEditError(true);
      result = false;
    }
    if (!result) {
      setEditExtraOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputNameError(false);
    let result = true;
    if (inputName === "") {
      setInputNameError(true);
      result = false;
    }
    if (!result) {
      setEditFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "editDishDialogContentId",
      });
    }
    return result;
  };

  const resetForm = () => {
    setAddNomenclatureItemFailed("");
    setInputIngredient(null);
    setInputIngredientName("");
    setInputIngredientUnit("");
    setInputIngredientQuantity("");
    setInputIngredientNameError(false);
    setInputIngredientUnitError(false);
    setInputIngredientQuantityError(false);
  };

  const resetEditForm = () => {
    setEditNomenclatureItemFailed("");
    setInputIngredientUnitEdit("");
    setInputIngredientQuantityEdit("");
    setInputIngredientUnitEditError(false);
    setInputIngredientQuantityEditError(false);
  };

  const resetExtraOptionForm = () => {
    setAddExtraOptionItemFailed("");
    setInputExtraOptionIngredient(null);
    setInputExtraOptionIngredientName("");
    setInputExtraOptionIngredientUnit("");
    setInputExtraOptionIngredientQuantity("");
    setInputExtraOptionIngredientPrice("");
    setInputExtraOptionIngredientNameError(false);
    setInputExtraOptionIngredientUnitError(false);
    setInputExtraOptionIngredientQuantityError(false);
    setInputExtraOptionIngredientPriceError(false);
  };

  const resetExtraOptionEditForm = () => {
    setEditExtraOptionItemFailed("");
    setInputExtraOptionIngredientUnitEdit("");
    setInputExtraOptionIngredientQuantityEdit("");
    setInputExtraOptionIngredientPriceEdit("");
    setInputExtraOptionIngredientUnitEditError(false);
    setInputExtraOptionIngredientQuantityEditError(false);
    setInputExtraOptionIngredientPriceEditError(false);
  };

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const [inputName, setInputName] = useState(popupActive!.name);

  const [inputNameError, setInputNameError] = useState(false);

  const [inputCategory, setInputCategory] = useState(popupActive!.category);

  const [inputTags, setInputTags] = useState<string[]>(popupActive!.tags);

  const [inputInternalCode, setInputInternalCode] = useState(
    popupActive!.internalCode
  );

  const [inputIsForSale, setInputIsForSale] = useState(
    popupActive!.forSale ? "Yes" : "No"
  );

  const [inputIsStored, setInputIsStored] = useState(
    popupActive!.stored ? "Yes" : "No"
  );

  const [inputPreparationTime, setInputPreparationTime] = useState(
    popupActive!.preparationTime
  );

  const [inputInstructions, setInputInstructions] = useState(
    popupActive!.instructions
  );

  const [inputChefs, setInputChefs] = useState<Employee[]>([]);

  const [inputChefsText, setInputChefsText] = useState("");

  const [inputProductionQuantity, setInputProductionQuantity] = useState(
    popupActive!.productionQuantity
  );

  const [inputProductionUnit, setInputProductionUnit] = useState(
    popupActive!.productionUnit
  );

  const [inputCookingTime, setInputCookingTime] = useState(
    popupActive!.cookingTime
  );

  const [inputNomenclature, setInputNomenclature] = useState<Nomenclature[]>(
    []
  );

  const [inputExtraOptions, setInputExtraOptions] = useState<ExtraOption[]>([]);

  const [inputCookingTemperature, setInputCookingTemperature] = useState(
    popupActive!.cookingTemperature
  );

  const [inputSaleOptions, setInputSaleOptions] = useState<SaleOption[]>(
    popupActive!.saleOptions
  );

  const [inputEnergyKj, setInputEnergyKj] = useState(popupActive!.energyKj);

  const [inputEnergyKcal, setInputEnergyKcal] = useState(
    popupActive!.energyKcal
  );

  const [inputProteins, setInputProteins] = useState(popupActive!.proteins);

  const [inputCarbohydrates, setInputCarbohydrates] = useState(
    popupActive!.carbohydrates
  );

  const [inputLipids, setInputLipids] = useState(popupActive!.lipids);

  const [inputSugar, setInputSugar] = useState(popupActive!.sugar);

  const [inputSaturatedFattyAcids, setInputSaturatedFattyAcids] = useState(
    popupActive!.saturatedFattyAcids
  );

  const [inputSalt, setInputSalt] = useState(popupActive!.salt);

  const [inputCalcium, setInputCalcium] = useState(popupActive!.calcium);

  const [inputVitaminD, setInputVitaminD] = useState(popupActive!.vitaminD);

  const [inputDietaryFiber, setInputDietaryFiber] = useState(
    popupActive!.dietaryFiber
  );

  const [inputCholesterol, setInputCholesterol] = useState(
    popupActive!.cholesterol
  );

  const [inputAllergens, setInputAllergens] = useState<string[]>(
    popupActive!.allergens
  );

  //const [inputExpiryDelay, setInputExpiryDelay] = useState("");

  const [ingredientTmp, setIngredientTmp] = useState<Ingredient[]>([]);

  const [inputIngredientName, setInputIngredientName] = useState("");

  const [inputIngredient, setInputIngredient] =
    React.useState<Ingredient | null>(null);

  const [inputIngredientQuantity, setInputIngredientQuantity] = useState("");

  const [inputIngredientUnit, setInputIngredientUnit] = useState("");

  const [inputIngredientQuantityEdit, setInputIngredientQuantityEdit] =
    useState("");

  const [inputIngredientUnitEdit, setInputIngredientUnitEdit] = useState("");

  const [inputIngredientNameError, setInputIngredientNameError] =
    useState(false);

  const [inputIngredientQuantityError, setInputIngredientQuantityError] =
    useState(false);

  const [inputIngredientUnitError, setInputIngredientUnitError] =
    useState(false);

  const [addNomenclatureItemFailed, setAddNomenclatureItemFailed] =
    useState("");

  const [inputIngredientUnitEditError, setInputIngredientUnitEditError] =
    useState(false);

  const [
    inputIngredientQuantityEditError,
    setInputIngredientQuantityEditError,
  ] = useState(false);

  const [editNomenclatureItemFailed, setEditNomenclatureItemFailed] =
    useState("");

  const [inputExtraOptionIngredientName, setInputExtraOptionIngredientName] =
    useState("");

  const [inputExtraOptionIngredient, setInputExtraOptionIngredient] =
    React.useState<Ingredient | null>(null);

  const [
    inputExtraOptionIngredientQuantity,
    setInputExtraOptionIngredientQuantity,
  ] = useState("");

  const [inputExtraOptionIngredientPrice, setInputExtraOptionIngredientPrice] =
    useState("");

  const [
    inputExtraOptionIngredientPriceEdit,
    setInputExtraOptionIngredientPriceEdit,
  ] = useState("");

  const [inputExtraOptionIngredientUnit, setInputExtraOptionIngredientUnit] =
    useState("");

  const [
    inputExtraOptionIngredientQuantityEdit,
    setInputExtraOptionIngredientQuantityEdit,
  ] = useState("");

  const [
    inputExtraOptionIngredientUnitEdit,
    setInputExtraOptionIngredientUnitEdit,
  ] = useState("");

  const [
    inputExtraOptionIngredientNameError,
    setInputExtraOptionIngredientNameError,
  ] = useState(false);

  const [
    inputExtraOptionIngredientQuantityError,
    setInputExtraOptionIngredientQuantityError,
  ] = useState(false);

  const [
    inputExtraOptionIngredientPriceError,
    setInputExtraOptionIngredientPriceError,
  ] = useState(false);

  const [
    inputExtraOptionIngredientUnitError,
    setInputExtraOptionIngredientUnitError,
  ] = useState(false);

  const [addExtraOptionItemFailed, setAddExtraOptionItemFailed] = useState("");

  const [
    inputExtraOptionIngredientUnitEditError,
    setInputExtraOptionIngredientUnitEditError,
  ] = useState(false);

  const [
    inputExtraOptionIngredientQuantityEditError,
    setInputExtraOptionIngredientQuantityEditError,
  ] = useState(false);

  const [
    inputExtraOptionIngredientPriceEditError,
    setInputExtraOptionIngredientPriceEditError,
  ] = useState(false);

  const [editExtraOptionItemFailed, setEditExtraOptionItemFailed] =
    useState("");

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    popupActive!.imageUrl
  );

  const [dishImageHovered, setDishImageHovered] = useState(false);

  const [editFailed, setEditFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [nomenclatureItemEditActive, setNomenclatureItemEditActive] =
    useState("");

  const [extraOptionItemEditActive, setExtraOptionItemEditActive] =
    useState("");

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const allergies = [
    "Arachides",
    "Céleri",
    "Crustacés",
    "Fruits à coques",
    "Gluten",
    "Lait",
    "Lupins",
    "Mollusques",
    "Moutarde",
    "Oeufs",
    "Poissons",
    "Soja",
    "Sulfites",
    "Sésame",
  ];

  let filterIngredients = () => {
    if (ingredientTmp[0] !== undefined) {
      setIngredientTmp(
        ingredientTmp.filter(
          (ingredient) =>
            !inputNomenclature.some(
              (nomenclatureItem) =>
                nomenclatureItem.ingredientId === ingredient.id
            )
        )
      );
    }
  };

  let removeNomenclatureItem = (index: number) => {
    let newArr = [...inputNomenclature];
    newArr.splice(index, 1);
    setInputNomenclature(newArr);
  };

  let removeExtraOptionItem = (index: number) => {
    let newArr = [...inputExtraOptions];
    newArr.splice(index, 1);
    setInputExtraOptions(newArr);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterOptions = createFilterOptions<Employee>({
    stringify: (option) => option.firstName + " " + option.lastName,
  });

  const filterIngredientOptions = createFilterOptions<Ingredient>({
    stringify: (option) => option.name,
  });

  useEffect(() => {
    getIngredientsAction("");
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIngredientsAction]);

  /*useEffect(() => {
    setInputEnergyKj(
      getElementsSum(
        inputNomenclature.map(
          (nomenclatureItem) =>
            getIngredientById(nomenclatureItem.ingredientId, ingredients)!
              .energyKj
        )
      )
    );
  }, [inputNomenclature]);*/

  useEffect(() => {
    if (ingredients[0] !== undefined) {
      setIngredientTmp([...ingredients]);
      filterIngredients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputNomenclature, ingredients]);

  useEffect(() => {
    if (employees && employees.length > 0) {
      setInputChefs(
        popupActive!.chefsIds
          .map((id) => employees.find((employee) => employee.id === id))
          .filter((employee) => employee !== null && employee !== undefined)
          .map((employee) => employee!)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une recette ou sous-recette"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="editDishDialogContentId" dividers>
        {editFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setEditFailed("");
              }}
            >
              {editFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setDishImageHovered(true)}
                onMouseLeave={() => setDishImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {dishImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
          </Grid>

          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="name"
                label="Nom"
                error={inputNameError}
                value={inputName}
                onChange={(e) => {
                  setInputName(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Catégorie</InputLabel>
                <Select
                  required
                  fullWidth
                  displayEmpty
                  value={inputCategory}
                  label="Catégorie"
                  onChange={(e) => {
                    setInputCategory(
                      typeof e.target.value === "string" ? e.target.value : ""
                    );
                  }}
                >
                  <MenuItem value="Entrée">Entrée</MenuItem>
                  <MenuItem value="Plat principal">Plat principal</MenuItem>
                  <MenuItem value="Dessert">Dessert</MenuItem>
                  <MenuItem value="Apéritif">Apéritif</MenuItem>
                  <MenuItem value="Pâtisseries et viennoiseries">
                    Pâtisseries et viennoiseries
                  </MenuItem>
                  <MenuItem value="Boisson">Boisson</MenuItem>
                  <MenuItem value="Autre">Autre</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="internalCode"
                label="Code interne"
                value={inputInternalCode}
                onChange={(e) => {
                  setInputInternalCode(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                options={[
                  "Bio",
                  "Halal",
                  "Végétarien",
                  "Végétalien (Vegan)",
                  "Sans gluten",
                  "Sans lactose",
                  "Sans sucre ajouté",
                  "Sans fruits à coque",
                  "Sans œufs",
                  "Sans allergènes",
                  "Traditionnel",
                  "Épicé",
                  "Sucré",
                  "Salé",
                  "Rapide",
                  "Sain",
                  "Gourmand",
                  "Léger",
                  "Exotique",
                  "Équilibré",
                  "Sans viande",
                  "Sans poisson",
                  "Sans porc",
                  "Fruits de mer",
                  "Plats chauds",
                  "Plats froids",
                  "Apéritif",
                  "Dessert",
                  "Petit-déjeuner",
                  "Déjeuner",
                  "Dîner",
                ]}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                value={inputTags}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Checkbox
                      style={{ marginRight: 8 }}
                      checked={inputTags.includes(option) ? true : false}
                    />
                    {option}
                  </li>
                )}
                onChange={(e, values) => {
                  setInputTags(values);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="tags/mots-clés"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Vous vendez ce produit?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputIsForSale}
                  onChange={(e) => setInputIsForSale(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">
                  Vous stockez ce produit?
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={inputIsStored}
                  onChange={(e) => setInputIsStored(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="No"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Préparation
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="preparationTime"
              label="Temps de préparation"
              value={inputPreparationTime}
              onChange={(e) => {
                setInputPreparationTime(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cookingTime"
              label="Temps de cuisson"
              value={inputCookingTime}
              onChange={(e) => {
                setInputCookingTime(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">min</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cookingTime"
              label="Température de cuisson"
              value={inputCookingTemperature}
              onChange={(e) => {
                setInputCookingTemperature(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">°C</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="chefs"
              filterOptions={filterOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              isOptionEqualToValue={(option, value) =>
                option && value ? option.id === value.id : false
              }
              getOptionLabel={(option) => {
                if (option) return option.firstName + " " + option.lastName;
                else return "";
              }}
              options={employees}
              value={inputChefs}
              onChange={(event, newValue) => {
                setInputChefs(newValue!);
              }}
              inputValue={inputChefsText}
              onInputChange={(event, newInputValue) => {
                setInputChefsText(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={
                        "https://" +
                        process.env.REACT_APP_BUCKET_NAME! +
                        ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                      }
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer flex="4">
                    <Text14 textAlign="left">
                      {option.firstName + " " + option.lastName}
                    </Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="chefs"
                  label="Cuisiniers"
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="instructions"
              label="Instructions de cuisson"
              value={inputInstructions}
              onChange={(e) => {
                setInputInstructions(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Nomenclature
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>Nomenclature pour:</Text14>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="productionQuantity"
              label="Quantité de production"
              value={inputProductionQuantity}
              onChange={(e) => {
                setInputProductionQuantity(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="productionUnit"
              label="Unité de production"
              value={inputProductionUnit}
              onChange={(e) => {
                setInputProductionUnit(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Ajoutez des ingrédients pour définir la nomenclature:
            </Text14>
          </Grid>
          <Grid item xs={1} sm={1} md={2}>
            {addNomenclatureItemFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addNomenclatureItemFailed}</Alert>
              </BlockContainer>
            )}
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <Autocomplete
              id="ingredients"
              filterOptions={filterIngredientOptions}
              options={ingredientTmp}
              getOptionLabel={(option) => option.name}
              value={inputIngredient}
              onChange={(event, newValue) => {
                setInputIngredient(newValue);
                setInputIngredientUnit(
                  newValue === null
                    ? ""
                    : newValue.productionUnits[0] === undefined
                    ? ""
                    : newValue.productionUnits[0].id
                );
              }}
              inputValue={inputIngredientName}
              onInputChange={(event, newInputValue) => {
                setInputIngredientName(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="ingredient"
                  label="Ingrédient"
                  error={inputIngredientNameError}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="ingredientQuantity"
              label="Quantité"
              error={inputIngredientQuantityError}
              value={inputIngredientQuantity}
              onChange={(e) => {
                setInputIngredientQuantity(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl
              fullWidth
              variant="outlined"
              error={inputIngredientUnitError}
            >
              <InputLabel id="storageUnit">Unité</InputLabel>
              <Select
                labelId="unit"
                id="unit"
                required
                value={inputIngredientUnit}
                label="Unité"
                onChange={(e) => setInputIngredientUnit(e.target.value)}
              >
                {inputIngredient !== null &&
                  inputIngredient !== undefined &&
                  inputIngredient!.productionUnits.map(
                    (productionUnit, index) => (
                      <MenuItem value={productionUnit.id} key={index}>
                        {productionUnit.unit === "Personnalisée"
                          ? productionUnit.customUnit
                          : productionUnit.unit}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddNomenclatureItemForm()) {
                    resetForm();
                    setInputNomenclature([
                      ...inputNomenclature,
                      {
                        id: uuid(),
                        ingredientId: inputIngredient!.id,
                        quantity: Number(inputIngredientQuantity),
                        unitId: inputIngredientUnit,
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="ingredient">Ingrédient</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputNomenclature.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={4}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Vous n'avez aucun ingrédient dans votre
                            nomenclature!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputNomenclature.map((nomenclatureItem, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={
                                getIngredientById(
                                  nomenclatureItem.ingredientId,
                                  ingredients
                                )?.imageUrl
                              }
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>
                          {getIngredientNameById(
                            nomenclatureItem.ingredientId,
                            ingredients
                          )}
                        </TableCell>
                        <TableCell>
                          {nomenclatureItem.quantity}{" "}
                          {getUnitNameById(
                            nomenclatureItem.unitId,
                            getIngredientById(
                              nomenclatureItem.ingredientId,
                              ingredients
                            )!.productionUnits,
                            getIngredientById(
                              nomenclatureItem.ingredientId,
                              ingredients
                            )!.unitBased
                          )}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputIngredientQuantityEdit(
                                  toFixed2(nomenclatureItem.quantity)
                                );
                                setInputIngredientUnitEdit(
                                  getUnitById(
                                    nomenclatureItem.unitId,
                                    getIngredientById(
                                      nomenclatureItem.ingredientId,
                                      ingredients
                                    )!.productionUnits
                                  ).id
                                );

                                setNomenclatureItemEditActive(
                                  nomenclatureItem.id
                                );
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeNomenclatureItem(index);
                                setIngredientTmp([...ingredients]);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + index}
                        sx={{
                          display:
                            nomenclatureItemEditActive === nomenclatureItem.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={4}>
                          <Collapse
                            orientation="vertical"
                            in={
                              nomenclatureItemEditActive === nomenclatureItem.id
                            }
                          >
                            {nomenclatureItemEditActive ===
                              nomenclatureItem.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editNomenclatureItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editNomenclatureItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="ingredientQuantity"
                                    label="Quantité"
                                    error={inputIngredientQuantityEditError}
                                    value={inputIngredientQuantityEdit}
                                    onChange={(e) => {
                                      setInputIngredientQuantityEdit(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  textAlign="left"
                                >
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={inputIngredientUnitEditError}
                                  >
                                    <InputLabel id="storageUnit">
                                      Unité
                                    </InputLabel>
                                    <Select
                                      labelId="unit"
                                      id="unit"
                                      required
                                      value={inputIngredientUnitEdit}
                                      label="Unité"
                                      onChange={(e) =>
                                        setInputIngredientUnitEdit(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {getIngredientById(
                                        nomenclatureItem.ingredientId,
                                        ingredients
                                      )!.productionUnits.map(
                                        (productionUnit, index) => (
                                          <MenuItem
                                            value={productionUnit.id}
                                            key={index}
                                          >
                                            {productionUnit.unit ===
                                            "Personnalisée"
                                              ? productionUnit.customUnit
                                              : productionUnit.unit}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (
                                          validateEditNomenclatureItemForm()
                                        ) {
                                          resetEditForm();
                                          let tmpArray = [...inputNomenclature];
                                          tmpArray[index] = {
                                            id: nomenclatureItem.id,
                                            ingredientId:
                                              nomenclatureItem.ingredientId,
                                            quantity: Number(
                                              inputIngredientQuantityEdit
                                            ),
                                            unitId: inputIngredientUnitEdit,
                                          };
                                          setInputNomenclature(tmpArray);
                                          setNomenclatureItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetEditForm();
                                        setNomenclatureItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Options/Extras
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            {addExtraOptionItemFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addExtraOptionItemFailed}</Alert>
              </BlockContainer>
            )}
          </Grid>

          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="ingredients"
              filterOptions={filterIngredientOptions}
              options={ingredientTmp}
              getOptionLabel={(option) => option.name}
              value={inputExtraOptionIngredient}
              onChange={(event, newValue) => {
                setInputExtraOptionIngredient(newValue);
                setInputExtraOptionIngredientUnit(
                  newValue === null
                    ? ""
                    : newValue.productionUnits[0] === undefined
                    ? ""
                    : newValue.productionUnits[0].id
                );
              }}
              inputValue={inputExtraOptionIngredientName}
              onInputChange={(event, newInputValue) => {
                setInputExtraOptionIngredientName(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="extraOptionIngredient"
                  label="Ingrédient"
                  error={inputExtraOptionIngredientNameError}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="extraOptionIngredientQuantity"
              label="Quantité"
              error={inputExtraOptionIngredientQuantityError}
              value={inputExtraOptionIngredientQuantity}
              onChange={(e) => {
                setInputExtraOptionIngredientQuantity(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl
              fullWidth
              variant="outlined"
              error={inputExtraOptionIngredientUnitError}
            >
              <InputLabel id="storageUnit">Unité</InputLabel>
              <Select
                labelId="unit"
                id="unit"
                required
                value={inputExtraOptionIngredientUnit}
                label="Unité"
                onChange={(e) =>
                  setInputExtraOptionIngredientUnit(e.target.value)
                }
              >
                {inputExtraOptionIngredient !== null &&
                  inputExtraOptionIngredient !== undefined &&
                  inputExtraOptionIngredient!.productionUnits.map(
                    (productionUnit, index) => (
                      <MenuItem value={productionUnit.id} key={index}>
                        {productionUnit.unit === "Personnalisée"
                          ? productionUnit.customUnit
                          : productionUnit.unit}
                      </MenuItem>
                    )
                  )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="extraOptionIngredientPrice"
              label="Prix"
              error={inputExtraOptionIngredientPriceError}
              value={inputExtraOptionIngredientPrice}
              onChange={(e) => {
                setInputExtraOptionIngredientPrice(e.target.value);
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                onClick={() => {
                  if (validateAddExtraOptionItemForm()) {
                    resetExtraOptionForm();
                    setInputExtraOptions([
                      ...inputExtraOptions,
                      {
                        id: uuid(),
                        ingredientId: inputExtraOptionIngredient!.id,
                        quantity: inputExtraOptionIngredientQuantity,
                        unitId: inputExtraOptionIngredientUnit,
                        price: inputExtraOptionIngredientPrice,
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="ingredient">Ingrédient</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="prix">Prix</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputExtraOptions.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={5}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Vous n'avez aucune option/extra pour ce plat!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputExtraOptions.map((extraOption, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={
                                getIngredientById(
                                  extraOption.ingredientId,
                                  ingredients
                                )?.imageUrl
                              }
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>
                          {getIngredientNameById(
                            extraOption.ingredientId,
                            ingredients
                          )}
                        </TableCell>
                        <TableCell>
                          {extraOption.quantity}{" "}
                          {getUnitNameById(
                            extraOption.unitId,
                            getIngredientById(
                              extraOption.ingredientId,
                              ingredients
                            )!.productionUnits,
                            getIngredientById(
                              extraOption.ingredientId,
                              ingredients
                            )!.unitBased
                          )}
                        </TableCell>
                        <TableCell>
                          {extraOption.price}
                          {" €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputExtraOptionIngredientQuantityEdit(
                                  extraOption.quantity
                                );
                                setInputExtraOptionIngredientUnitEdit(
                                  getUnitById(
                                    extraOption.unitId,
                                    getIngredientById(
                                      extraOption.ingredientId,
                                      ingredients
                                    )!.productionUnits
                                  ).id
                                );
                                setInputExtraOptionIngredientPriceEdit(
                                  extraOption.price
                                );

                                setExtraOptionItemEditActive(extraOption.id);
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeExtraOptionItem(index);
                                setIngredientTmp([...ingredients]);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + index}
                        sx={{
                          display:
                            extraOptionItemEditActive === extraOption.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={5}>
                          <Collapse
                            orientation="vertical"
                            in={extraOptionItemEditActive === extraOption.id}
                          >
                            {extraOptionItemEditActive === extraOption.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editExtraOptionItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editExtraOptionItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="ingredientQuantity"
                                    label="Quantité"
                                    error={
                                      inputExtraOptionIngredientQuantityEditError
                                    }
                                    value={
                                      inputExtraOptionIngredientQuantityEdit
                                    }
                                    onChange={(e) => {
                                      setInputExtraOptionIngredientQuantityEdit(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={1}
                                  textAlign="left"
                                >
                                  <FormControl
                                    fullWidth
                                    variant="outlined"
                                    error={
                                      inputExtraOptionIngredientUnitEditError
                                    }
                                  >
                                    <InputLabel id="storageUnit">
                                      Unité
                                    </InputLabel>
                                    <Select
                                      labelId="unit"
                                      id="unit"
                                      required
                                      value={inputExtraOptionIngredientUnitEdit}
                                      label="Unité"
                                      onChange={(e) =>
                                        setInputExtraOptionIngredientUnitEdit(
                                          e.target.value
                                        )
                                      }
                                    >
                                      {getIngredientById(
                                        extraOption.ingredientId,
                                        ingredients
                                      )!.productionUnits.map(
                                        (productionUnit, index) => (
                                          <MenuItem
                                            value={productionUnit.id}
                                            key={index}
                                          >
                                            {productionUnit.unit ===
                                            "Personnalisée"
                                              ? productionUnit.customUnit
                                              : productionUnit.unit}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="extraOptionIngredientPrice"
                                    label="Prix"
                                    error={
                                      inputExtraOptionIngredientPriceEditError
                                    }
                                    value={inputExtraOptionIngredientPriceEdit}
                                    onChange={(e) => {
                                      setInputExtraOptionIngredientPriceEdit(
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditExtraOptionItemForm()) {
                                          resetExtraOptionEditForm();
                                          let tmpArray = [...inputExtraOptions];
                                          tmpArray[index] = {
                                            id: extraOption.id,
                                            ingredientId:
                                              extraOption.ingredientId,
                                            quantity:
                                              inputExtraOptionIngredientQuantityEdit,
                                            unitId:
                                              inputExtraOptionIngredientUnitEdit,
                                            price:
                                              inputExtraOptionIngredientPriceEdit,
                                          };
                                          setInputExtraOptions(tmpArray);
                                          setExtraOptionItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetExtraOptionEditForm();
                                        setExtraOptionItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        {inputIsForSale === "Yes" && (
          <>
            <BlockContainer margin="16px 0" id="offSearch">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Options de vente
              </Text20>
            </BlockContainer>
            <SellOptions
              inputSellOptions={inputSaleOptions}
              inputSellUnit={inputProductionUnit}
              inputImageUrl={inputImageUrl}
              setInputSellOptions={setInputSaleOptions}
            />
            <Divider sx={{ mt: "16px" }} />
          </>
        )}
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Composition
          </Text20>
        </BlockContainer>
        <BlockContainer margin="16px 0">
          <Text16 textAlign="left" margin="0">
            Allergènes
          </Text16>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 1 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              fullWidth
              multiple
              id="allergies"
              options={allergies}
              filterSelectedOptions
              value={inputAllergens}
              getOptionLabel={(option) => (option ? option : "")}
              onChange={(event, newValue) => {
                setInputAllergens(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Liste des allergènes" />
              )}
            />
          </Grid>
        </Grid>
        <BlockContainer margin="16px 0 0 0">
          <Text16 textAlign="left" margin="0">
            Valeurs nutritionnelles
          </Text16>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 3 }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={3}
            display="flex"
            justifyContent="flex-end"
          >
            <ButtonMui
              onClick={() => {
                setInputEnergyKj("");
                setInputEnergyKcal("");
                setInputProteins("");
                setInputCarbohydrates("");
                setInputSugar("");
                setInputSaturatedFattyAcids("");
                setInputSalt("");
                setInputCalcium("");
                setInputVitaminD("");
                setInputDietaryFiber("");
                setInputCholesterol("");
                setInputLipids("");
              }}
              color="primary"
              variant="outlined"
              size="medium"
            >
              <RestartAltIcon sx={{ marginRight: "8px" }} />
              Vider les champs
            </ButtonMui>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="energyKj"
              label="Énergie (Kj)"
              value={inputEnergyKj}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputEnergyKj(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="energyKcal"
              label="Énergie (Kcal)"
              value={inputEnergyKcal}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputEnergyKcal(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="proteins"
              label="Protéines"
              value={inputProteins}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputProteins(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="carbohydrates"
              label="Glucides"
              value={inputCarbohydrates}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCarbohydrates(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="lipids"
              label="Lipides"
              value={inputLipids}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputLipids(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="sugar"
              label="Sucres"
              value={inputSugar}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSugar(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="saturatedFattyAcids"
              label="Acides gras saturés"
              value={inputSaturatedFattyAcids}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSaturatedFattyAcids(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="salt"
              label="Sel"
              value={inputSalt}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSalt(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="calcium"
              label="Calcium"
              value={inputCalcium}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCalcium(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="vitaminD"
              label="Vitamine D"
              value={inputVitaminD}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputVitaminD(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="dietaryFiber"
              label="Fibres alimentaires"
              value={inputDietaryFiber}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputDietaryFiber(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="cholesterol"
              label="Cholestérol"
              value={inputCholesterol}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputCholesterol(e.target.value);
                }
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Champs personnalisés
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          {customFields.map((customField, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              {customField.type === "Texte" && (
                <TextField
                  fullWidth
                  variant="outlined"
                  name={customField.name}
                  label={customField.name}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(
                          customField.id
                        )![0] === undefined
                      ? ""
                      : getCustomFieldValuesByCustomFieldId(customField.id)![0]
                  }
                  onChange={(e) =>
                    setCustomFieldValuesByCustomFieldId(customField.id, [
                      e.target.value,
                    ])
                  }
                />
              )}
              {customField.type === "Date" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={customField.name}
                    inputFormat="DD/MM/YYYY"
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0 ||
                      getCustomFieldValuesByCustomFieldId(
                        customField.id
                      )![0] === ""
                        ? null
                        : dayjs(
                            getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0],
                            "DD/MM/YYYY"
                          )
                    }
                    onChange={(value) =>
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        value === null ? "" : value!.format("DD/MM/YYYY"),
                      ])
                    }
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              )}
              {customField.type === "Liste déroulante" && (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {customField.name}
                  </InputLabel>
                  <Select
                    required
                    fullWidth
                    displayEmpty
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    label={customField.name}
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                  >
                    {customField.values.map((choice, index) => (
                      <MenuItem value={choice} key={index}>
                        {choice}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {customField.type === "Boutton radio" && (
                <FormControl>
                  <FormLabel>{customField.name}</FormLabel>
                  <RadioGroup
                    name={customField.name}
                    value={
                      getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null ||
                      getCustomFieldValuesByCustomFieldId(customField.id)
                        ?.length === 0
                        ? ""
                        : getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0]
                    }
                    onChange={(e) => {
                      setCustomFieldValuesByCustomFieldId(customField.id, [
                        e.target.value,
                      ]);
                    }}
                    row
                  >
                    {customField.values.map((choice, index) => (
                      <FormControlLabel
                        value={choice}
                        control={<Radio />}
                        label={choice}
                        key={index}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
              {customField.type === "Checkbox" && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={customField.values}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option}
                  value={
                    getCustomFieldValuesByCustomFieldId(customField.id) === null
                      ? []
                      : getCustomFieldValuesByCustomFieldId(customField.id)!
                  }
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Checkbox
                        style={{ marginRight: 8 }}
                        checked={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null
                            ? false
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )!.includes(option)
                            ? true
                            : false
                        }
                      />
                      {option}
                    </li>
                  )}
                  onChange={(e, values) => {
                    setCustomFieldValuesByCustomFieldId(customField.id, values);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={customField.name}
                    />
                  )}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) editDish();
            else setIsLoading(false);
          }}
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditDish = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
        getEmployeesAction: getEmployeesAction,
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_EditDish);

export default EditDish;
