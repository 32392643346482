import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddProspect } from "./AddProspect";
import { ProspectTable } from "./ProspectTable";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";

export interface ProspectsProps {}

const Prospects: React.FC<ProspectsProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Prospects"
        subTitle="Liste des prospects"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <ProspectTable />
      {popupActive && (
        <AddProspect
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Prospects;
