import CloseIcon from "@mui/icons-material/Close";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CustomField } from "../../../../../models/customField";
import { Dish } from "../../../../../models/dish";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getDishes } from "../../../../../store/selectors";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ThemeCustom } from "../../../../../styles/Utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "1000px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

export interface ExportDishesProps {
  inputSearch: string;
  popupActive: boolean;
  dishes: Dish[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getDishesAction: typeof getDishesAction;
}

const _ExportDishes: React.FC<ExportDishesProps> = (props) => {
  const exportDishes = () => {
    AxiosHttpClient.postBlob(
      "api/v1/weeventpro/catalog/export",
      selectedFields
    ).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "dishes.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/dishes"
    )
      .then((res) => {
        setFields([...fields, ...res.map((customField) => customField.name)]);
        setSelectedFields([
          ...fields,
          ...res.map((customField) => customField.name),
        ]);
      })
      .catch((err) => {});
  };
  const [fields, setFields] = useState<string[]>([
    "Image",
    "Nom",
    "Catégorie",
    "Tags",
    "Code interne",
    "En vente",
    "Stocké",
    "Temps de préparation",
    "Instructions de cuisson",
    "Cuisiniers",
    "Quantité de production",
    "Unité de production",
    "Temps de cuisson",
    "Température de cuisson",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
    "Allergènes",
    "Durée d'expiration",
    "Options de stockage",
  ]);

  const [selectedFields, setSelectedFields] = useState<string[]>([]);

  const classes = useStyles();

  useEffect(() => {
    getCustomFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Exporter vos recettes"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui dividers>
        <DialogContentText sx={{ marginBottom: "16px" }}>
          Veuillez choisir les champs que vous voulez exporter
        </DialogContentText>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 1 }}
          columns={{ xs: 1, sm: 1, md: 4 }}
        >
          {fields.map((field, index) => (
            <Grid item xs={1} sm={1} md={1} key={index}>
              <FlexContainer alignItems="center">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={selectedFields.indexOf(field) > -1}
                  onChange={(event) => {
                    let newArr = [...selectedFields];
                    const index = selectedFields.indexOf(field);
                    if (index > -1) {
                      newArr.splice(index, 1);
                      setSelectedFields(newArr);
                    } else {
                      setSelectedFields([...selectedFields, field]);
                    }
                  }}
                />
                <DialogContentText>{field}</DialogContentText>
              </FlexContainer>
            </Grid>
          ))}
        </Grid>
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            exportDishes();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Exporter
        </ButtonMui>
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const ExportDishes = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_ExportDishes);

export default ExportDishes;
