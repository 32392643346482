import React from "react";
import { FlexContainer } from "../../../styles/FlexContainer";
import { LinkCustom } from "../../../styles/LinkCustom";
import { Text20 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface NavigationPageHeaderProps {
  title: string;
  subTitle: string;
  backPath: string;
}

const NavigationPageHeader: React.FC<NavigationPageHeaderProps> = ({
  title,
  subTitle,
  backPath,
}) => {
  return (
    <FlexContainer padding="32px">
      <FlexContainer padding="16px">
        <LinkCustom to={backPath}>
          <Text20 fontWeight="600" color={CustomTheme.palette.primary.main}>
            {title}
          </Text20>
        </LinkCustom>
        <Text20 margin="4px 10px 4px 0">{" > " + subTitle}</Text20>
      </FlexContainer>
    </FlexContainer>
  );
};

export default NavigationPageHeader;
