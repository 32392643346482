import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { StockService } from "../../services/StockService";
import { RootAction } from "../types";
import {
  GetIngredientsActionFailure, getIngredientsActionFailure, GetIngredientsActionSuccess, getIngredientsActionSuccess, GetMaterialsActionFailure, getMaterialsActionFailure, GetMaterialsActionSuccess, getMaterialsActionSuccess, GetSuppliersActionFailure, getSuppliersActionFailure, GetSuppliersActionSuccess, getSuppliersActionSuccess, GET_INGREDIENTS, GET_MATERIALS, GET_SUPPLIERS
} from "./actions";

export const ingredientsEpic: Epic<
  RootAction,
  GetIngredientsActionSuccess | GetIngredientsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INGREDIENTS),
    switchMap((action) =>
      from(StockService.getIngredients({searchInput: action.input})).pipe(
        mergeMap((res) => {
          return [getIngredientsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getIngredientsActionFailure()]))
        )
      )
    )
  );

  export const materialsEpic: Epic<
  RootAction,
  GetMaterialsActionSuccess | GetMaterialsActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_MATERIALS),
    switchMap((action) =>
      from(StockService.getMaterials({searchInput: action.input})).pipe(
        mergeMap((res) => {
          return [getMaterialsActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getMaterialsActionFailure()]))
        )
      )
    )
  );

  export const suppliersEpic: Epic<
  RootAction,
  GetSuppliersActionSuccess | GetSuppliersActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_SUPPLIERS),
    switchMap((action) =>
      from(StockService.getSuppliers({searchInput: action.input})).pipe(
        mergeMap((res) => {
          return [getSuppliersActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getSuppliersActionFailure()]))
        )
      )
    )
  );

export const stockEpic = combineEpics<any>(ingredientsEpic,materialsEpic,suppliersEpic);
