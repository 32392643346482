export const SIGN_UP = "SIGN_UP" as const;
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS" as const;
export const SIGN_UP_FAILURE = "SIGN_UP_FAILURE" as const;
export const REMOVE_SIGN_UP_FAILURE = "REMOVE_SIGN_UP_FAILURE" as const;

export const signUpAction = (
  entityId: string,
  email: string,
  password: string,
  entityName: string,
  firstName: string,
  lastName: string,
) => ({
  type: SIGN_UP,
  entityId: entityId,
  email: email,
  password: password,
  entityName: entityName,
  firstName: firstName,
  lastName: lastName,
});
export type SignUpAction = ReturnType<typeof signUpAction>;

export const signUpActionSuccess = () => ({
  type: SIGN_UP_SUCCESS,
});
export type SignUpActionSuccess = ReturnType<typeof signUpActionSuccess>;

export const signUpActionFailure = () => ({
  type: SIGN_UP_FAILURE,
});
export type SignUpActionFailure = ReturnType<typeof signUpActionFailure>;

export const removeSignUpActionFailure = () => ({
  type: REMOVE_SIGN_UP_FAILURE,
});
export type RemoveSignUpActionFailure = ReturnType<
  typeof removeSignUpActionFailure
>;
