import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Template } from "../../../../../../../models/template";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";
import { AxiosHttpClient } from "../../../../../../../services/AxiosHttpService";
import { getInvoiceTemplatesAction } from "../../../../../../../store/Invoice/actions";
import { Dispatch } from "../../../../../../../store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

export interface DeleteTemplateProps {
  popupActive: Template | null;
  setPopupActive: React.Dispatch<React.SetStateAction<Template | null>>;
  getInvoiceTemplatesAction: typeof getInvoiceTemplatesAction;
}

const _DeleteTemplate: React.FC<DeleteTemplateProps> = (props) => {
  const deleteTemplate = (viewId: string) => {
    AxiosHttpClient.delete("api/v1/weeventpro/invoice/template/" + viewId)
      .then(() => {
        props.getInvoiceTemplatesAction();
        props.setPopupActive(null);
        setIsDeleteTemplateLoading(false);
      })
      .catch((err) => {
        setIsDeleteTemplateLoading(false);
      });
  };

  const [isDeleteTemplateLoading, setIsDeleteTemplateLoading] = useState(false);

  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le template?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le template{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.label}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isDeleteTemplateLoading}
          loading={isDeleteTemplateLoading}
          onClick={() => {
            deleteTemplate(props.popupActive!.id);
            setIsDeleteTemplateLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};
export const DeleteTemplate = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getInvoiceTemplatesAction: getInvoiceTemplatesAction,
    },
    dispatch
  )
)(_DeleteTemplate);

export default DeleteTemplate;
