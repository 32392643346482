import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SupplierOrder } from "../../../../../models/supplierOrder";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getSupplierOrders } from "../../../../../store/selectors";
import { getSupplierOrdersAction } from "../../../../../store/SupplierOrder/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddSupplierOrder from "../../SupplierOrder/AddSupplierOrder";
import DeleteSupplierOrder from "../../SupplierOrder/DeleteSupplierOrder";
import EditSupplierOrder from "../../SupplierOrder/EditSupplierOrder";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells: {
  id: keyof SupplierOrder;
  label: string;
  disableSorting?: boolean;
}[] = [
  { id: "id", label: "N° commande" },
  { id: "label", label: "Libellé" },
  { id: "date", label: "Date" },
  { id: "price", label: "Coût total (€)" },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(
  order: Order,
  orderBy: keyof SupplierOrder
): (a: SupplierOrder, b: SupplierOrder) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface SupplierOrderTableProps {
  supplierOrders: SupplierOrder[];
  getSupplierOrdersAction: typeof getSupplierOrdersAction;
}

const _SupplierOrderTable: React.FC<SupplierOrderTableProps> = ({
  supplierOrders,
  getSupplierOrdersAction,
}) => {
  const addSupplierOrderIngredientsToStock = (orderId: string) => {
    AxiosHttpClient.post("api/v1/weeventpro/order/supplierOrder/addToStock", {
      input: orderId,
    })
      .then(() => {
        getSupplierOrdersAction(inputSearch);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const addSupplierOrderArchive = (supplierOrder: SupplierOrder) => {
    AxiosHttpClient.post("api/v1/weeventpro/order/supplierOrder/archive/add", {
      id: supplierOrder.id,
      label: supplierOrder.label,
      date: supplierOrder.date,
      price: supplierOrder.price,
      ingredientQuantityList: supplierOrder.ingredientQuantityList,
    })
      .then(() => {})
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState<keyof SupplierOrder>("date");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  const [popupEditActive, setPopupEditActive] = useState<SupplierOrder>({
    id: "",
    label: "",
    date: "",
    price: "",
    ingredientQuantityList: [],
  });

  useEffect(() => {
    getSupplierOrdersAction(inputSearch);
  }, [inputSearch, getSupplierOrdersAction]);

  const handleSortRequest = (cellId: keyof SupplierOrder) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher une commande"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Créer une commande</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell key="Actions">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(supplierOrders, getComparator(order, orderBy)).map(
              (supplierOrder) => (
                <TableRow key={supplierOrder.id}>
                  <TableCell>{supplierOrder.id}</TableCell>
                  <TableCell>{supplierOrder.label}</TableCell>
                  <TableCell>{supplierOrder.date}</TableCell>
                  <TableCell>{supplierOrder.price}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        addSupplierOrderArchive(supplierOrder);
                        addSupplierOrderIngredientsToStock(supplierOrder.id);
                      }}
                      size="large"
                    >
                      <AddBusinessIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => setPopupEditActive(supplierOrder)}
                      size="large"
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => setPopupDeleteActive(supplierOrder.id)}
                      size="large"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddSupplierOrder
          setPopupActive={setPopupAddActive}
          popupActive={popupAddActive}
          input=""
        />
      )}
      <DeleteSupplierOrder
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive.id !== "" && (
        <EditSupplierOrder
          input={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </Paper>
  );
};

export const SupplierOrderTable = connect(
  (state: RootState) => ({
    supplierOrders: getSupplierOrders(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSupplierOrdersAction: getSupplierOrdersAction,
      },
      dispatch
    )
)(_SupplierOrderTable);

export default _SupplierOrderTable;
