import { CartItem } from "../models/cartItem";
import { AxiosHttpClient } from "./AxiosHttpService";

export class CartService {
  static getCartItems = () => {
    return AxiosHttpClient.get<CartItem[]>(
      "api/v1/weeventpro/order/cart/get"
    );
  };
}
