import React, { useEffect, useState } from "react";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { VacationRequestTable } from "../VacationRequestTable";
import { VacationTable } from "../VacationTable";

export interface VacationContentProps {}

const VacationContent: React.FC<VacationContentProps> = () => {
  const [selectedTab, setSelectedTab] = useState("vacations");

  useEffect(() => {}, []);

  return (
    <FlexContainer flexDirection="column">
      <FlexContainer>
        <FlexContainer backgroundColor="white" margin="0 24px">
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "vacations"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "vacations" ? 3 : undefined}
            $backgroundAfter={selectedTab === "vacations" ? "white" : undefined}
            $heightAfter={selectedTab === "vacations" ? "20px" : undefined}
            $contentAfter={selectedTab === "vacations" ? "''" : undefined}
            $positionAfter={
              selectedTab === "vacations" ? "absolute" : undefined
            }
            $bottomAfter={selectedTab === "vacations" ? "-10px" : undefined}
            $leftAfter={selectedTab === "vacations" ? "0px" : undefined}
            $rightAfter={selectedTab === "vacations" ? "0px" : undefined}
            position={selectedTab === "vacations" ? "relative" : undefined}
            zIndex={selectedTab === "vacations" ? "1" : undefined}
            onClick={(e) => setSelectedTab("vacations")}
          >
            <Text16>Mes absences</Text16>
          </FlexContainer>
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "requests"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "requests" ? 3 : undefined}
            $backgroundAfter={selectedTab === "requests" ? "white" : undefined}
            $heightAfter={selectedTab === "requests" ? "20px" : undefined}
            $contentAfter={selectedTab === "requests" ? "''" : undefined}
            $positionAfter={selectedTab === "requests" ? "absolute" : undefined}
            $bottomAfter={selectedTab === "requests" ? "-10px" : undefined}
            $leftAfter={selectedTab === "requests" ? "0px" : undefined}
            $rightAfter={selectedTab === "requests" ? "0px" : undefined}
            position={selectedTab === "requests" ? "relative" : undefined}
            zIndex={selectedTab === "requests" ? "1" : undefined}
            onClick={(e) => setSelectedTab("requests")}
          >
            <Text16>Demandes à valider</Text16>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <BlockContainer backgroundColor="white" margin="0 24px" elevation={3}>
        {selectedTab === "vacations" && <VacationTable />}
        {selectedTab === "requests" && <VacationRequestTable />}
      </BlockContainer>
    </FlexContainer>
  );
};

export default VacationContent;
