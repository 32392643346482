import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Ingredient } from "../../../../../models/ingredients";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getIngredients } from "../../../../../store/selectors";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import ProgressStateWithLabel from "../../../../Reusable/ProgressStateWithLabel";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { getIngredientQuantity } from "../../../../Reusable/Utils";
import { Questions } from "../../WebPage/WebPageContent/Questions";
import DeleteIngredient from "../DeleteIngredient";
import EditIngredient from "../EditIngredient";
import { Informations } from "./Informations";

export interface IngredientDetailsProps {}

const _IngredientDetails: React.FC<IngredientDetailsProps> = (props) => {
  const [ingredient, setIngredient] = useState<Ingredient>();

  const [quantity, setQuantity] = useState(0);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Ingredient | null>(
    null
  );

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getIngredientDetails = () => {
    AxiosHttpClient.get<Ingredient>(
      "api/v1/weeventpro/stock/ingredients/" + params.ingredientId
    ).then((response) => {
      setIngredient(response);
    });
  };

  const getUnitNameById = (id: string) => {
    if (ingredient?.storageUnits !== undefined) {
      for (var unit of ingredient?.storageUnits) {
        if (unit.id === id) {
          return unit.unit === "Personnalisée" ? unit.customUnit : unit.unit;
        }
      }
    }
    return "";
  };

  useEffect(() => {
    getIngredientDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (ingredient !== undefined && ingredient !== null) {
      let totalQuantity = getIngredientQuantity(ingredient!);
      if (quantity < totalQuantity) {
        setQuantity(quantity + totalQuantity / 40);
      } else if (quantity > totalQuantity) {
        setQuantity(totalQuantity);
      }
    }
  }, [ingredient, quantity]);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/foodStock">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>
              Ingrédients
            </Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={ingredient?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{ingredient?.name}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {ingredient?.category}
              </Text14>
              {!matches && (
                <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                  {"En stock: " +
                    (ingredient !== undefined && ingredient !== null
                      ? getIngredientQuantity(ingredient!)
                      : "0") +
                    " " +
                    getUnitNameById(ingredient?.globalStorageUnitId!)}
                </Text14>
              )}
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(ingredient!);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier l'ingrédient
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  setPopupDeleteActive([ingredient!.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer l'ingrédient
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <Text20>Aperçu du stock</Text20>
            {getIngredientQuantity(ingredient!) <
              Number(ingredient?.minimalQuantity) && (
              <FlexContainer
                alignSelf="center"
                alignItems="center"
                border="1px solid rgba(255,0,0,0.8)"
                $borderRadius="50px"
                padding="0 8px"
                margin="16px 0 0 0"
              >
                <ErrorOutlineIcon
                  sx={{ color: "rgba(255,0,0,0.8)" }}
                  fontSize="small"
                />
                <Text16 margin="0 0 0 4px" color="rgba(255,0,0,0.8)">
                  Insuffisant
                </Text16>
              </FlexContainer>
            )}
            {getIngredientQuantity(ingredient!) >=
              Number(ingredient?.minimalQuantity) &&
              getIngredientQuantity(ingredient!) <=
                Number(ingredient?.idealQuantity) && (
                <FlexContainer
                  alignSelf="center"
                  alignItems="center"
                  border="1px solid rgba(0,128,0,0.8)"
                  $borderRadius="50px"
                  padding="0 8px"
                  margin="16px 0 0 0"
                >
                  <CheckCircleOutlineIcon
                    sx={{ color: "rgba(0,128,0,0.8)" }}
                    fontSize="small"
                  />
                  <Text16 margin="0 0 0 4px" color="rgba(0,128,0,0.8)">
                    Normal
                  </Text16>
                </FlexContainer>
              )}
            {getIngredientQuantity(ingredient!) >
              Number(ingredient?.idealQuantity) && (
              <FlexContainer
                alignSelf="center"
                alignItems="center"
                border="1px solid rgba(242, 153, 74, 0.8)"
                $borderRadius="50px"
                padding="0 8px"
                margin="16px 0 0 0"
              >
                <WarningAmberIcon
                  sx={{ color: "rgba(242, 153, 74, 0.8)" }}
                  fontSize="small"
                />
                <Text16 margin="0 0 0 4px" color="rgba(242, 153, 74, 0.8)">
                  Surchargé
                </Text16>
              </FlexContainer>
            )}
            <FlexContainer margin="16px 0 0 0" alignSelf="center" width="100%">
              <ProgressStateWithLabel
                firstBreakpoint={Number(ingredient?.minimalQuantity)}
                secondBreakpoint={Number(ingredient?.idealQuantity)}
                value={quantity}
                firstBreakpointText={
                  "Quantité minimale: " +
                  ingredient?.minimalQuantity +
                  " " +
                  getUnitNameById(ingredient?.globalStorageUnitId!)
                }
                secondBreakpointText={
                  "Quantité idéale: " +
                  ingredient?.idealQuantity +
                  " " +
                  getUnitNameById(ingredient?.globalStorageUnitId!)
                }
                valueText={
                  "En stock: " +
                  quantity +
                  " " +
                  getUnitNameById(ingredient?.globalStorageUnitId!)
                }
                unit={getUnitNameById(ingredient?.globalStorageUnitId!)}
                showValueTooltip={
                  quantity === getIngredientQuantity(ingredient!)
                }
              />
            </FlexContainer>
            <FlexContainer alignSelf="center">
              <ButtonMui
                onClick={() => {}}
                color="primary"
                variant="outlined"
                size="large"
              >
                Mettre à jour
              </ButtonMui>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && (
        <TabsDesktop
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations ingredient={ingredient!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      {!matches && (
        <TabsMobile
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations ingredient={ingredient!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      <DeleteIngredient
        input={""}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive !== null && (
        <EditIngredient
          inputSearch={""}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </BlockContainer>
  );
};

export const IngredientDetails = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_IngredientDetails);

export default _IngredientDetails;
