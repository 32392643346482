import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import ClientTable from "./ClientTable";

export interface ClientProps {}

const Client: React.FC<ClientProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Clients"
        subTitle="Liste des clients"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <ClientTable />
    </BlockContainer>
  );
};

export default Client;
