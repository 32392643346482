import { Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dish } from "../../../../../../models/dish";
import { Dispatch, RootState } from "../../../../../../store";
import { getDishesAction } from "../../../../../../store/Dish/actions";
import { getDishes } from "../../../../../../store/selectors";
import {
  AccordionDetailsMui,
  AccordionMui,
  AccordionSummaryMui,
} from "../../../../../../styles/AccordionMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import { Text14, Text16, Text36 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface QuestionsProps {
  dishes: Dish[];
  getDishesAction: typeof getDishesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MUIRichTextEditor-toolbar-18": {
      border: "1px solid red",
    },
    "& .MUIRichTextEditor-editorContainer-10": {
      border: "1px solid green",
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _Questions: React.FC<QuestionsProps> = (props) => {
  const [expandedExpense, setExpandedExpense] = useState("");

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>) => {
    setExpandedExpense(panel === expandedExpense ? "none" : panel);
  };

  useEffect(() => {
    props.getDishesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDishesAction]);

  const classes = useStyles();

  return (
    <FlexContainer className={classes.root} width="100%">
      <FlexContainer flexDirection="column" padding="32px" width="100%">
        <AccordionMui
          square
          expanded={expandedExpense === "QuestionId1"}
          onChange={handleChange("QuestionId1")}
          style={{ position: "static" }}
        >
          <AccordionSummaryMui
            BackgroundColorHover="white"
            height="unset"
            $maxHeight="unset"
            aria-controls="panel8d-content"
          >
            <FlexContainer margin="16px 0" width="100%">
              <FlexContainer margin="0 16px 0 0">
                <ImagePreview
                  src={
                    "https://" +
                    process.env.REACT_APP_BUCKET_NAME! +
                    ".s3.eu-west-3.amazonaws.com/Mocks/Aymane.jpg"
                  }
                  alt=""
                  height="60px"
                  width="60px"
                  $borderRadius="100px"
                />
              </FlexContainer>
              <FlexContainer flexDirection="column" flex="1">
                <FlexContainer>
                  <Text14 margin="0 0 0 10px" display="inline">
                    Posée par&nbsp;
                  </Text14>
                  <Text14 fontWeight="600" margin="0" display="inline">
                    Aymane MASSIT
                  </Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14 margin="0 10px">Le 16/08/2022</Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text16>Déplacez-vous à Vigneux-Sur-Seine?</Text16>
                </FlexContainer>
                <FlexContainer>
                  <FlexContainer $cursorHover="pointer">
                    <Text14
                      fontWeight="600"
                      color={CustomTheme.palette.primary.main}
                    >
                      Répondre
                    </Text14>
                  </FlexContainer>
                  <FlexContainer $cursorHover="pointer">
                    <Text14
                      fontWeight="600"
                      color={CustomTheme.palette.primary.main}
                    >
                      Signaler
                    </Text14>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                $borderRadius="10px"
                height="95px"
                border={"1px solid " + CustomTheme.palette.primary.main}
              >
                <Text36
                  color={CustomTheme.palette.primary.main}
                  margin="0px 0 0 0"
                >
                  1
                </Text36>
                <Text14 color={CustomTheme.palette.primary.main}>
                  Réponse(s)
                </Text14>
              </FlexContainer>
            </FlexContainer>
          </AccordionSummaryMui>
          <AccordionDetailsMui>
            <FlexContainer
              margin="0 0 0 100px"
              padding="32px 0"
              backgroundColor={CustomTheme.palette.grey[100]}
              $borderRadius="16px"
            >
              <FlexContainer margin="0 16px">
                <ImagePreview
                  src={
                    "https://" +
                    process.env.REACT_APP_BUCKET_NAME! +
                    ".s3.eu-west-3.amazonaws.com/Mocks/rahhal.jpg"
                  }
                  alt=""
                  height="60px"
                  width="60px"
                  $borderRadius="100px"
                />
              </FlexContainer>
              <FlexContainer flexDirection="column" flex="1">
                <FlexContainer>
                  <Text14 fontWeight="600" margin="0 0 0 10px" display="inline">
                    Traiteur Flan
                  </Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14 margin="0 10px 8px 10px">Le 16/08/2022</Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14>Oui je me déplace sur toute l'ile-de-France</Text14>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </AccordionDetailsMui>
        </AccordionMui>
        <Divider />

        <AccordionMui
          square
          expanded={expandedExpense === "QuestionId2"}
          onChange={handleChange("QuestionId2")}
        >
          <AccordionSummaryMui
            BackgroundColorHover="white"
            height="unset"
            $maxHeight="unset"
            aria-controls="panel8d-content"
          >
            <FlexContainer margin="16px 0" width="100%">
              <FlexContainer margin="0 16px 0 0">
                <ImagePreview
                  src={
                    "https://" +
                    process.env.REACT_APP_BUCKET_NAME! +
                    ".s3.eu-west-3.amazonaws.com/Mocks/Youness.webp"
                  }
                  alt=""
                  height="60px"
                  width="60px"
                  $borderRadius="100px"
                />
              </FlexContainer>
              <FlexContainer flexDirection="column" flex="1">
                <FlexContainer>
                  <Text14 margin="0 0 0 10px" display="inline">
                    Posée par&nbsp;
                  </Text14>
                  <Text14 fontWeight="600" margin="0" display="inline">
                    Youness Zrigui
                  </Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14 margin="0 10px">Le 16/08/2022</Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text16>
                    Est-ce que vous faites la cuisson sur place ou vous emportez
                    tous vos plats à emporter?
                  </Text16>
                </FlexContainer>
                <FlexContainer>
                  <FlexContainer $cursorHover="pointer">
                    <Text14
                      fontWeight="600"
                      color={CustomTheme.palette.primary.main}
                    >
                      Répondre
                    </Text14>
                  </FlexContainer>
                  <FlexContainer $cursorHover="pointer">
                    <Text14
                      fontWeight="600"
                      color={CustomTheme.palette.primary.main}
                    >
                      Signaler
                    </Text14>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                $borderRadius="10px"
                height="95px"
                border={"1px solid " + CustomTheme.palette.primary.main}
              >
                <Text36
                  color={CustomTheme.palette.primary.main}
                  margin="0px 0 0 0"
                >
                  1
                </Text36>
                <Text14 color={CustomTheme.palette.primary.main}>
                  Réponse(s)
                </Text14>
              </FlexContainer>
            </FlexContainer>
          </AccordionSummaryMui>
          <AccordionDetailsMui>
            <FlexContainer
              margin="0 0 0 100px"
              padding="32px 0"
              backgroundColor={CustomTheme.palette.grey[100]}
              $borderRadius="16px"
            >
              <FlexContainer margin="0 16px">
                <ImagePreview
                  src={
                    "https://" +
                    process.env.REACT_APP_BUCKET_NAME! +
                    ".s3.eu-west-3.amazonaws.com/Mocks/rahhal.jpg"
                  }
                  alt=""
                  height="60px"
                  width="60px"
                  $borderRadius="100px"
                />
              </FlexContainer>
              <FlexContainer flexDirection="column" flex="1">
                <FlexContainer>
                  <Text14 fontWeight="600" margin="0 0 0 10px" display="inline">
                    Traiteur Flan
                  </Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14 margin="0 10px 8px 10px">Le 16/08/2022</Text14>
                </FlexContainer>
                <FlexContainer>
                  <Text14>Oui je me déplace sur toute l'ile-de-France</Text14>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </AccordionDetailsMui>
        </AccordionMui>
      </FlexContainer>
    </FlexContainer>
  );
};

export const Questions = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_Questions);

export default _Questions;
