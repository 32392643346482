import { SignInState } from "../Signin";
import { RootAction } from "../types";
import {
  LOG_OUT,
  REMOVE_SIGN_IN_FAILURE,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
} from "./actions";

const initialState: SignInState = {
  signedInSuccess: localStorage.getItem("jwtToken") ? true : false,
  signedInFailure: false,
};

export function signInReducer(
  state: SignInState = initialState,
  action: RootAction
): SignInState {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        signedInSuccess: true,
        signedInFailure: false,
      };
    case SIGN_IN_FAILURE:
      return {
        signedInSuccess: false,
        signedInFailure: true,
      };
    case REMOVE_SIGN_IN_FAILURE:
      return {
        signedInSuccess: state.signedInSuccess,
        signedInFailure: false,
      };

    case LOG_OUT:
      return {
        signedInSuccess: false,
        signedInFailure: false,
      };

    default:
      return state;
  }
}
