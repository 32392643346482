import { Employee } from "../../models/employee";
import { Vacation } from "../../models/vacation";
import { Document } from "../../models/document";

export const GET_EMPLOYEES = "GET_EMPLOYEES" as const;
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS" as const;
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE" as const;

export const GET_VACATIONS = "GET_VACATIONS" as const;
export const GET_VACATIONS_SUCCESS = "GET_VACATIONS_SUCCESS" as const;
export const GET_VACATIONS_FAILURE = "GET_VACATIONS_FAILURE" as const;

export const GET_DOCUMENTS = "GET_DOCUMENTS" as const;
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS" as const;
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE" as const;

export const getEmployeesAction = (input: string) => ({
  type: GET_EMPLOYEES,
  input: input,
});
export type GetEmployeesAction = ReturnType<typeof getEmployeesAction>;

export const getEmployeesActionSuccess = (employees: Employee[]) => ({
  type: GET_EMPLOYEES_SUCCESS,
  employees: employees,
});
export type GetEmployeesActionSuccess = ReturnType<
  typeof getEmployeesActionSuccess
>;

export const getEmployeesActionFailure = () => ({
  type: GET_EMPLOYEES_FAILURE,
});
export type GetEmployeesActionFailure = ReturnType<
  typeof getEmployeesActionFailure
>;

export const getVacationsAction = (input: string) => ({
  type: GET_VACATIONS,
  input: input,
});
export type GetVacationsAction = ReturnType<typeof getVacationsAction>;

export const getVacationsActionSuccess = (vacations: Vacation[]) => ({
  type: GET_VACATIONS_SUCCESS,
  vacations: vacations,
});
export type GetVacationsActionSuccess = ReturnType<
  typeof getVacationsActionSuccess
>;

export const getVacationsActionFailure = () => ({
  type: GET_VACATIONS_FAILURE,
});
export type GetVacationsActionFailure = ReturnType<
  typeof getVacationsActionFailure
>;

export const getDocumentsAction = (input: string) => ({
  type: GET_DOCUMENTS,
  input: input,
});
export type GetDocumentsAction = ReturnType<typeof getDocumentsAction>;

export const getDocumentsActionSuccess = (documents: Document[]) => ({
  type: GET_DOCUMENTS_SUCCESS,
  documents: documents,
});
export type GetDocumentsActionSuccess = ReturnType<
  typeof getDocumentsActionSuccess
>;

export const getDocumentsActionFailure = () => ({
  type: GET_DOCUMENTS_FAILURE,
});
export type GetDocumentsActionFailure = ReturnType<
  typeof getDocumentsActionFailure
>;