import { RootAction } from "../types";
import { GET_QUOTES_SUCCESS, GET_QUOTE_TEMPLATES_SUCCESS } from "./actions";
import { QuoteState, QuoteTemplatesState } from "./types";

const initialQuoteState: QuoteState = {
  quotes: [],
};

const initialQuoteTemplatesState: QuoteTemplatesState = {
  templates: [],
};

export function quoteReducer(
  state: QuoteState = initialQuoteState,
  action: RootAction
): QuoteState {
  switch (action.type) {
    case GET_QUOTES_SUCCESS:
      return {
        quotes: action.quotes
      };

    default:
      return state;
  }
}

export function quoteTemplateReducer(
  state: QuoteTemplatesState = initialQuoteTemplatesState,
  action: RootAction
): QuoteTemplatesState {
  switch (action.type) {

    case GET_QUOTE_TEMPLATES_SUCCESS:
      return {
        templates: action.templates
      };

    default:
      return state;
  }
}
