import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { FoodTable } from "./FoodTable";

export interface FoodStockProps {}

const FoodStock: React.FC<FoodStockProps> = (props) => {
  return (
    <BlockContainer>
      <PageHeader
        title="Stock alimentaire"
        subTitle="Liste des ingrédients"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <FoodTable />
    </BlockContainer>
  );
};

export default FoodStock;
