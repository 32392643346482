import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CalendarViewWeekIcon from "@mui/icons-material/CalendarViewWeek";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import GetAppTwoToneIcon from "@mui/icons-material/GetAppTwoTone";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublishTwoToneIcon from "@mui/icons-material/PublishTwoTone";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { CustomField } from "../../../../../models/customField";
import { Employee } from "../../../../../models/employee";
import { Supplier } from "../../../../../models/suppliers";
import { View } from "../../../../../models/view";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees, getSuppliers } from "../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../styles/InlineBlockContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddSupplier from "../AddSupplier";
import DeleteSupplier from "../DeleteSupplier";
import EditSupplier from "../EditSupplier";
import ExportSuppliers from "../ExportSuppliers";
import ImportSuppliers from "../ImportSuppliers";
import SearchInput from "./SearchInput";
import ViewInput from "./ViewInput";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface SupplierTableProps {
  suppliers: Supplier[];
  employees: Employee[];
  getSuppliersAction: typeof getSuppliersAction;
  getEmployeesAction: typeof getEmployeesAction;
}

const _SupplierTable: React.FC<SupplierTableProps> = ({
  suppliers,
  employees,
  getSuppliersAction,
  getEmployeesAction,
}) => {
  const getViews = () => {
    AxiosHttpClient.get<View[]>("api/v1/weeventpro/parameters/views/supplier")
      .then((res) => {
        setViews(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/supplier"
    )
      .then((res) => {
        setCustomFields(res);
      })
      .catch((err) => {});
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const [selected, setSelected] = useState<string[]>([]);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("Nom");
  const [inputSearch, setInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Supplier | null>(null);

  const [popupImportActive, setPopupImportActive] = useState(false);

  const [popupExportActive, setPopupExportActive] = useState(false);

  const [inputView, setInputView] = useState("defaultView");

  const [views, setViews] = useState<View[]>([]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [displayedTool, setDisplayedTool] = useState<string | null>(null);

  useEffect(() => {
    getSuppliersAction(inputSearch);
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputSearch, getSuppliersAction]);

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const defaultView: View = {
    id: "defaultView",
    name: "Vue par défaut",
    fields: [
      "Image",
      "Nom",
      "Catégorie",
      "Email",
      "Commande minimum",
      "Lead time",
      "Jours de livraison",
    ],
  };

  const getViewById = (id: string) => {
    if (id === "defaultView") return defaultView;
    let filteredView = views.filter((view) => view.id === id);
    return filteredView.length !== 0 ? filteredView[0] : null;
  };

  const getCustomFieldNameByCustomFieldId = (id: string) => {
    for (var customField of customFields) {
      if (customField.id === id) return customField.name;
    }
    return "";
  };

  const isFieldSorted = (headValue: string) => {
    let headValuesMap: Map<string, boolean> = new Map([
      ["Nom", true],
      ["Catégorie", true],
      ["Email", true],
      ["Commande minimum", true],
      ["Employés en charge", false],
      ["Superviseurs", false],
      ["Lead time", true],
      ["Jours de livraison", false],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, customField.type !== "Checkbox");
    }
    return headValuesMap.get(headValue);
  };

  const headCells = [
    ...getViewById(inputView)!.fields.map((field) => ({
      id: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      label: field.includes("Custom::")
        ? getCustomFieldNameByCustomFieldId(field.substring(8))
        : field,
      disableSorting: !isFieldSorted(
        field.includes("Custom::")
          ? getCustomFieldNameByCustomFieldId(field.substring(8))
          : field
      ),
    })),
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const getCustomFieldValues = (supplier: Supplier, customFieldId: string) => {
    if (supplier.customFields !== null) {
      for (var customFieldValue of supplier.customFields) {
        if (customFieldId === customFieldValue.customFieldId) {
          return customFieldValue.values.join(", ");
        }
      }
    }
    return "";
  };

  const getSimpleCellValue = (headValue: string, supplier: Supplier) => {
    let headValuesMap: Map<
      string,
      { value: string; comparableValue: string | number | undefined }
    > = new Map([
      [
        "Nom",
        {
          value: supplier.name,
          comparableValue: supplier.name ? supplier.name.toLowerCase() : "",
        },
      ],
      [
        "Catégorie",
        {
          value: supplier.category,
          comparableValue: supplier.category
            ? supplier.category.toLowerCase()
            : "",
        },
      ],
      [
        "Email",
        {
          value: supplier.email,
          comparableValue: supplier.email ? supplier.email.toLowerCase() : "",
        },
      ],
      [
        "Commande minimum",
        {
          value: supplier.minimumOrder + " €",
          comparableValue: supplier.minimumOrder
            ? supplier.minimumOrder.toLowerCase()
            : "",
        },
      ],
      [
        "Employés en charge",
        {
          value: supplier.inCharge
            .map((inCharge) => {
              let tmp = employees.find((employee) => employee.id === inCharge);
              if (tmp === undefined) return "Employé inconnu";
              return (
                employees.find((employee) => employee.id === inCharge)!
                  .firstName +
                " " +
                employees.find((employee) => employee.id === inCharge)!.lastName
              );
            })
            .join(", "),
          comparableValue: undefined,
        },
      ],
      [
        "Superviseurs",
        {
          value: supplier.supervisors
            .map((supervisor) => {
              let tmp = employees.find(
                (employee) => employee.id === supervisor
              );
              if (tmp === undefined) return "Employé inconnu";
              return (
                employees.find((employee) => employee.id === supervisor)!
                  .firstName +
                " " +
                employees.find((employee) => employee.id === supervisor)!
                  .lastName
              );
            })
            .join(", "),
          comparableValue: undefined,
        },
      ],
      [
        "Lead time",
        {
          value: supplier.leadTime + " jour(s)",
          comparableValue: supplier.leadTime
            ? supplier.leadTime.toLowerCase()
            : "",
        },
      ],
      [
        "Jours de livraison",
        {
          value: supplier.openWeekDays.join(", "),
          comparableValue: undefined,
        },
      ],
    ]);
    for (var customField of customFields) {
      headValuesMap.set(customField.name, {
        value: getCustomFieldValues(supplier, customField.id),
        comparableValue:
          customField.type === "Checkbox"
            ? undefined
            : getCustomFieldValues(supplier, customField.id),
      });
    }
    return headValuesMap.get(headValue);
  };

  const getComplexCellValue = (headValue: string, supplier: Supplier) => {
    let headValuesMap: Map<
      string,
      { value: JSX.Element; comparableValue: string | undefined }
    > = new Map([
      [
        "Image",
        {
          value: (
            <BlockContainer
              sx={{
                width: {
                  xs: "60px",
                  sm: "100px",
                },
                height: {
                  xs: "60px",
                  sm: "100px",
                },
              }}
              border="rgba(0,0,0,0.1) solid 1px"
              justifyContent="center"
              position="relative"
              textAlign="center"
              backgroundColor="white"
              margin="auto"
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                }}
                alt=""
                src={supplier?.imageUrl}
              />
            </BlockContainer>
          ),
          comparableValue: undefined,
        },
      ],
      [
        "Actions",
        {
          value: (
            <FlexContainer justifyContent="center">
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPopupEditActive(supplier);
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setPopupDeleteActive([supplier.id]);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          ),
          comparableValue: undefined,
        },
      ],
    ]);
    return headValuesMap.get(headValue);
  };

  const getValueCells = (headValue: string, supplier: Supplier) => {
    let simpleValue = getSimpleCellValue(headValue, supplier)?.value;
    let complexValue = getComplexCellValue(headValue, supplier)?.value;
    if (simpleValue !== undefined) return simpleValue;
    if (complexValue !== undefined) return complexValue;
    return "";
  };

  function descendingComparator(a: Supplier, b: Supplier, orderBy: string) {
    let simpleCellValueA = getSimpleCellValue(orderBy, a);
    let simpleCellValueB = getSimpleCellValue(orderBy, b);
    if (simpleCellValueB?.comparableValue === undefined) return -1;
    if (simpleCellValueA?.comparableValue === undefined) return 1;
    if (simpleCellValueB.comparableValue < simpleCellValueA.comparableValue) {
      return -1;
    }
    if (simpleCellValueB.comparableValue > simpleCellValueA.comparableValue) {
      return 1;
    }
    return 0;
  }

  type Order = "asc" | "desc";

  function getComparator(
    order: Order,
    orderBy: string
  ): (a: Supplier, b: Supplier) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    getCustomFields();
    getViews();
  }, []);

  return (
    <Paper
      sx={{
        margin: {
          md: "24px",
          sm: "16px",
          xs: "8px",
        },
        height: {
          xs: "calc(100vh - 212px)",
          sm: "calc(100vh - 244px)",
        },
        display: "flex",
        flexDirection: "column",
      }}
      className={classes.pageContent}
    >
      <Box
        alignItems="center"
        sx={{
          display: {
            xs: "block",
            sm: "flex",
          },
          flexDirection: {
            sm: "row-reverse",
          },
          padding: {
            sm: "0 24px",
          },
        }}
      >
        <FlexContainer
          sx={{
            margin: {
              sm: "0 0 0 auto",
            },
          }}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="flex-end"
        >
          <ToggleButtonGroup
            value={displayedTool}
            exclusive
            color="primary"
            size="small"
            sx={{
              display: {
                sm: "none",
              },
            }}
            onChange={(
              event: React.MouseEvent<HTMLElement>,
              newAlignment: string | null
            ) => setDisplayedTool(newAlignment)}
            aria-label="text alignment"
          >
            <ToggleButton value="search" aria-label="left aligned">
              <Search />
            </ToggleButton>
            <ToggleButton value="view" aria-label="centered">
              <CalendarViewWeekIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="8px 10px 8px 8px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
          <IconButton
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon color="primary" />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={(e) => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                marginX: "20px",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem
              onClick={() => {
                setPopupImportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <PublishTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Importer
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPopupExportActive(true);
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <GetAppTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Exporter
              </FlexContainer>
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/feature/parameters", {
                  state: "2,2",
                });
                setAnchorEl(null);
              }}
            >
              <FlexContainer alignItems="center">
                <SettingsTwoToneIcon
                  sx={{ mr: "8px" }}
                  fontSize="small"
                  color="primary"
                />
                Personnaliser
              </FlexContainer>
            </MenuItem>
          </Menu>
        </FlexContainer>
        <Collapse
          orientation="vertical"
          in={selected.length > 0}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <InlineBlockContainer
            elevation={3}
            $borderRadius="10px"
            backgroundColor="oldlace"
            padding="0 16px"
            margin="8px"
          >
            <IconButton color="primary" size="large">
              <EditOutlinedIcon fontSize="small" />
            </IconButton>
            <IconButton
              color="secondary"
              size="large"
              onClick={() => {
                setPopupDeleteActive([...selected]);
                setSelected([]);
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </InlineBlockContainer>
        </Collapse>
        <ViewInput
          inputView={inputView}
          views={views}
          displayedTool={displayedTool}
          setInputView={setInputView}
        />
        <SearchInput
          inputSearch={inputSearch}
          displayedTool={displayedTool}
          SetInputSearch={setInputSearch}
        />
      </Box>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={
                    selected.length > 0 && selected.length === suppliers.length
                  }
                  onChange={(event) => {
                    if (event.target.checked) {
                      const newSelecteds = suppliers.map(
                        (supplier) => supplier.id
                      );
                      setSelected(newSelecteds);
                      return;
                    }
                    setSelected([]);
                  }}
                  inputProps={{
                    "aria-label": "Tout selectionner",
                  }}
                />
              </TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(suppliers, getComparator(order, orderBy)).map(
              (item) => (
                <TableRow
                  key={item.name}
                  onClick={(e) => {
                    navigate(`/feature/suppliers/${item.id}`);
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="secondary"
                      size="small"
                      checked={selected.indexOf(item.id) > -1}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        let newArr = [...selected];
                        const index = selected.indexOf(item.id);
                        if (index > -1) {
                          newArr.splice(index, 1);
                          setSelected(newArr);
                        } else {
                          setSelected([...selected, item.id]);
                        }
                      }}
                    />
                  </TableCell>
                  {headCells.map((headCell, index) => (
                    <TableCell key={index}>
                      {getValueCells(headCell.label, item)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddSupplier
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          inputSearch={inputSearch}
        />
      )}
      {popupDeleteActive !== null && (
        <DeleteSupplier
          input={inputSearch}
          popupActive={popupDeleteActive}
          setPopupActive={setPopupDeleteActive}
        />
      )}
      {popupEditActive !== null && (
        <EditSupplier
          inputSearch={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
      {popupImportActive && (
        <ImportSuppliers
          inputSearch={inputSearch}
          popupActive={popupImportActive}
          setPopupActive={setPopupImportActive}
        />
      )}
      {popupExportActive && (
        <ExportSuppliers
          inputSearch={inputSearch}
          popupActive={popupExportActive}
          setPopupActive={setPopupExportActive}
        />
      )}
    </Paper>
  );
};

export const SupplierTable = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_SupplierTable);

export default SupplierTable;
