import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Unit } from "../../../../../../models/ingredients";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface ProductionUnitsProps {
  enabledUnitOptions: string[];
  inputPurchaseUnits: Unit[];
  inputStorageUnits: Unit[];
  inputProductionUnits: Unit[];
  setInputPurchaseUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputStorageUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputProductionUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
}

const ProductionUnits: React.FC<ProductionUnitsProps> = (props) => {
  const [inputProductionUnit, setInputProductionUnit] = useState("");

  const [inputProductionUnitError, setInputProductionUnitError] =
    useState(false);

  const [productionUnitAddActive, setProductionUnitAddActive] = useState(false);

  const [productionUnitEditActive, setProductionUnitEditActive] = useState("");

  const [inputCustomProductionUnit, setInputCustomProductionUnit] =
    useState("");

  const [inputCustomProductionUnitError, setInputCustomProductionUnitError] =
    useState(false);

  const [
    inputCustomProductionUnitCorrespondanceValue,
    setInputCustomProductionUnitCorrespondanceValue,
  ] = useState("");

  const [
    inputCustomProductionUnitCorrespondanceValueError,
    setInputCustomProductionUnitCorrespondanceValueError,
  ] = useState(false);

  const [
    inputCustomProductionUnitCorrespondanceUnit,
    setInputCustomProductionUnitCorrespondanceUnit,
  ] = useState("");

  const [
    inputCustomProductionUnitCorrespondanceUnitError,
    setInputCustomProductionUnitCorrespondanceUnitError,
  ] = useState(false);

  const [addToStorageUnits, setAddToStorageUnits] = useState(true);

  const [addToPurchaseUnits, setAddToPurchaseUnits] = useState(true);

  const [addFailed, setAddFailed] = useState("");

  const resetValidation = () => {
    setAddFailed("");
    setInputProductionUnitError(false);
    setInputCustomProductionUnitError(false);
    setInputCustomProductionUnitCorrespondanceValueError(false);
    setInputCustomProductionUnitCorrespondanceUnitError(false);
  };

  const validateForm = () => {
    if (
      props.inputProductionUnits.some((productionUnit) => {
        if (productionUnit.unit === "Personnalisée")
          return productionUnit.customUnit === inputCustomProductionUnit;
        else return productionUnit.unit === inputProductionUnit;
      })
    ) {
      setAddFailed(
        "L'unité de production \"" +
          (inputProductionUnit === "Personnalisée"
            ? inputCustomProductionUnit
            : inputProductionUnit) +
          '" existe déjà!'
      );
      return false;
    }

    if (
      props.inputPurchaseUnits.some((purchaseUnit) => {
        if (purchaseUnit.unit === "Personnalisée")
          return purchaseUnit.customUnit === inputCustomProductionUnit;
        else return purchaseUnit.unit === inputProductionUnit;
      }) &&
      addToPurchaseUnits
    ) {
      setAddFailed(
        "L'unité d'achat \"" +
          (inputProductionUnit === "Personnalisée"
            ? inputCustomProductionUnit
            : inputProductionUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités d'achat!"
      );
      return false;
    }

    if (
      props.inputStorageUnits.some((storageUnit) => {
        if (storageUnit.unit === "Personnalisée")
          return storageUnit.customUnit === inputCustomProductionUnit;
        else return storageUnit.unit === inputProductionUnit;
      }) &&
      addToStorageUnits
    ) {
      setAddFailed(
        "L'unité de stockage \"" +
          (inputProductionUnit === "Personnalisée"
            ? inputCustomProductionUnit
            : inputProductionUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de stockage!"
      );
      return false;
    }
    setInputProductionUnitError(false);
    setInputCustomProductionUnitError(false);
    setInputCustomProductionUnitCorrespondanceValueError(false);
    setInputCustomProductionUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputProductionUnit === "") {
      setInputProductionUnitError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnit === ""
    ) {
      setInputCustomProductionUnitError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnitCorrespondanceValue === ""
    ) {
      setInputCustomProductionUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnitCorrespondanceUnit === ""
    ) {
      setInputCustomProductionUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (productionUnit: Unit) => {
    if (
      productionUnit.unit !== inputProductionUnit ||
      productionUnit.customUnit !== inputCustomProductionUnit
    ) {
      if (
        props.inputProductionUnits.some((productionUnit) => {
          if (productionUnit.unit === "Personnalisée")
            return productionUnit.customUnit === inputCustomProductionUnit;
          else return productionUnit.unit === inputProductionUnit;
        })
      ) {
        setAddFailed(
          "L'unité de production \"" +
            (inputProductionUnit === "Personnalisée"
              ? inputCustomProductionUnit
              : inputProductionUnit) +
            '" existe déjà!'
        );
        return false;
      }
    }

    setInputProductionUnitError(false);
    setInputCustomProductionUnitError(false);
    setInputCustomProductionUnitCorrespondanceValueError(false);
    setInputCustomProductionUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputProductionUnit === "") {
      setInputProductionUnitError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnit === ""
    ) {
      setInputCustomProductionUnitError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnitCorrespondanceValue === ""
    ) {
      setInputCustomProductionUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputProductionUnit === "Personnalisée" &&
      inputCustomProductionUnitCorrespondanceUnit === ""
    ) {
      setInputCustomProductionUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const isUnitDisabled = (unit: string) => {
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const isUnitDisabledForEdit = (unit: string) => {
    if (
      props.inputPurchaseUnits.length +
        props.inputStorageUnits.length +
        props.inputProductionUnits.length <=
      1
    )
      return false;
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  useEffect(() => {
    if (inputProductionUnit !== "Personnalisée") {
      setInputCustomProductionUnit("");
      setInputCustomProductionUnitCorrespondanceValue("");
      setInputCustomProductionUnitCorrespondanceUnit("");
    }
  }, [inputProductionUnit]);

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0">
        <Text16 textAlign="left" margin="0">
          Unités de production
        </Text16>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setProductionUnitEditActive("");
            setInputProductionUnit("");
            setInputCustomProductionUnit("");
            setInputCustomProductionUnitCorrespondanceValue("");
            setInputCustomProductionUnitCorrespondanceUnit("");
            setProductionUnitAddActive(true);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une unité de production
          </Text16>
        </FlexContainer>
      </FlexContainer>
      <Collapse orientation="vertical" in={productionUnitAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="unitLabel">Unité</InputLabel>
                <Select
                  labelId="unitLabel"
                  id="unit"
                  required
                  error={inputProductionUnitError}
                  value={inputProductionUnit}
                  label="Age"
                  onChange={(e) => setInputProductionUnit(e.target.value)}
                >
                  <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                    Kg
                  </MenuItem>
                  <MenuItem value="g" disabled={isUnitDisabled("g")}>
                    g
                  </MenuItem>
                  <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                    mg
                  </MenuItem>
                  <MenuItem value="L" disabled={isUnitDisabled("L")}>
                    L
                  </MenuItem>
                  <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                    mL
                  </MenuItem>
                  <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Nom personnalisé de votre unité"
              >
                <TextField
                  fullWidth
                  disabled={inputProductionUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputProductionUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomProductionUnitError}
                  variant="outlined"
                  name="label"
                  label="Libellé"
                  value={inputCustomProductionUnit}
                  onChange={(e) => setInputCustomProductionUnit(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Valeur de votre unité personnalisée en unité internationale"
              >
                <TextField
                  fullWidth
                  disabled={inputProductionUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputProductionUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomProductionUnitCorrespondanceValueError}
                  variant="outlined"
                  name="customUnitProductionCorrespondanceValue"
                  label="Valeur de correspondance"
                  value={inputCustomProductionUnitCorrespondanceValue}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputCustomProductionUnitCorrespondanceValue(
                        e.target.value
                      );
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Unité internationale utilisée pour calculer la valeur de correspondance"
              >
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="customProductionUnitCorrespondanceUnitLabel">
                    Unité de correspondance
                  </InputLabel>
                  <Select
                    labelId="customProductionUnitCorrespondanceUnitLabel"
                    id="customProductionUnitCorrespondanceUnit"
                    required
                    disabled={inputProductionUnit !== "Personnalisée"}
                    sx={{
                      backgroundColor:
                        inputProductionUnit !== "Personnalisée"
                          ? "#e8e8e8"
                          : "white",
                    }}
                    error={inputCustomProductionUnitCorrespondanceUnitError}
                    value={inputCustomProductionUnitCorrespondanceUnit}
                    label="Unité de correspondance"
                    onChange={(e) =>
                      setInputCustomProductionUnitCorrespondanceUnit(
                        e.target.value
                      )
                    }
                  >
                    <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                      Kg
                    </MenuItem>
                    <MenuItem value="g" disabled={isUnitDisabled("g")}>
                      g
                    </MenuItem>
                    <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                      mg
                    </MenuItem>
                    <MenuItem value="L" disabled={isUnitDisabled("L")}>
                      L
                    </MenuItem>
                    <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                      mL
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>

            <FlexContainer flexDirection="column" padding="8px 0 0 16px">
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToPurchaseUnits}
                  onChange={(event) => {
                    setAddToPurchaseUnits(!addToPurchaseUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités d'achat
                </Text16>
              </FlexContainer>
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToStorageUnits}
                  onChange={(event) => {
                    setAddToStorageUnits(!addToStorageUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités de stockage
                </Text16>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputProductionUnits([
                  ...props.inputProductionUnits,
                  {
                    id: uuid(),
                    unit: inputProductionUnit,
                    customUnit: inputCustomProductionUnit,
                    customUnitCorrespondanceValue:
                      inputCustomProductionUnitCorrespondanceValue,
                    customUnitCorrespondanceUnit:
                      inputCustomProductionUnitCorrespondanceUnit,
                  },
                ]);
                if (addToStorageUnits)
                  props.setInputStorageUnits([
                    ...props.inputStorageUnits,
                    {
                      id: uuid(),
                      unit: inputProductionUnit,
                      customUnit: inputCustomProductionUnit,
                      customUnitCorrespondanceValue:
                        inputCustomProductionUnitCorrespondanceValue,
                      customUnitCorrespondanceUnit:
                        inputCustomProductionUnitCorrespondanceUnit,
                    },
                  ]);
                if (addToPurchaseUnits)
                  props.setInputPurchaseUnits([
                    ...props.inputPurchaseUnits,
                    {
                      id: uuid(),
                      unit: inputProductionUnit,
                      customUnit: inputCustomProductionUnit,
                      customUnitCorrespondanceValue:
                        inputCustomProductionUnitCorrespondanceValue,
                      customUnitCorrespondanceUnit:
                        inputCustomProductionUnitCorrespondanceUnit,
                    },
                  ]);
                setAddToPurchaseUnits(true);
                setAddToStorageUnits(true);
                setProductionUnitAddActive(false);
                setInputProductionUnit("");
                setInputCustomProductionUnit("");
                setInputCustomProductionUnitCorrespondanceValue("");
                setInputCustomProductionUnitCorrespondanceUnit("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setProductionUnitAddActive(false);
              setInputProductionUnit("");
              setInputCustomProductionUnit("");
              setInputCustomProductionUnitCorrespondanceValue("");
              setInputCustomProductionUnitCorrespondanceUnit("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {props.inputProductionUnits.map((productionUnit, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            border="1px solid rgba(0,0,0,0.1)"
            margin="8px 0"
            elevation={3}
            $borderRadius="10px"
            padding="8px 8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text16>
              {productionUnit.unit === "Personnalisée"
                ? productionUnit.customUnit +
                  " ( = " +
                  productionUnit.customUnitCorrespondanceValue +
                  " " +
                  productionUnit.customUnitCorrespondanceUnit +
                  " )"
                : productionUnit.unit}
            </Text16>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setProductionUnitAddActive(false);
                  setInputProductionUnit(productionUnit.unit);
                  setInputCustomProductionUnit(productionUnit.customUnit);
                  setInputCustomProductionUnitCorrespondanceValue(
                    productionUnit.customUnitCorrespondanceValue === undefined
                      ? ""
                      : productionUnit.customUnitCorrespondanceValue.toString()
                  );
                  setInputCustomProductionUnitCorrespondanceUnit(
                    productionUnit.customUnitCorrespondanceUnit
                  );
                  setProductionUnitEditActive(
                    productionUnit.unit === "Personnalisée"
                      ? productionUnit.customUnit
                      : productionUnit.unit
                  );
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputProductionUnits];
                  newArr.splice(index, 1);
                  props.setInputProductionUnits(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          <Collapse
            orientation="vertical"
            in={
              productionUnit.unit === "Personnalisée"
                ? productionUnitEditActive === productionUnit.customUnit
                : productionUnitEditActive === productionUnit.unit
            }
          >
            <BlockContainer margin="8px 0">
              {addFailed !== "" && (
                <BlockContainer margin="8px 0 16px 0">
                  <Alert severity="error">{addFailed}</Alert>
                </BlockContainer>
              )}
              <FlexContainer>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={inputProductionUnitError}
                    >
                      <InputLabel id="unitLabel">Unité</InputLabel>
                      <Select
                        labelId="unitLabel"
                        id="unit"
                        required
                        value={inputProductionUnit}
                        label="Age"
                        onChange={(e) => setInputProductionUnit(e.target.value)}
                      >
                        <MenuItem
                          value="Kg"
                          disabled={isUnitDisabledForEdit("Kg")}
                        >
                          Kg
                        </MenuItem>
                        <MenuItem
                          value="g"
                          disabled={isUnitDisabledForEdit("g")}
                        >
                          g
                        </MenuItem>
                        <MenuItem
                          value="mg"
                          disabled={isUnitDisabledForEdit("mg")}
                        >
                          mg
                        </MenuItem>
                        <MenuItem
                          value="L"
                          disabled={isUnitDisabledForEdit("L")}
                        >
                          L
                        </MenuItem>
                        <MenuItem
                          value="mL"
                          disabled={isUnitDisabledForEdit("mL")}
                        >
                          mL
                        </MenuItem>
                        <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Nom personnalisé de votre unité"
                    >
                      <TextField
                        fullWidth
                        disabled={inputProductionUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputProductionUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomProductionUnitError}
                        variant="outlined"
                        name="label"
                        label="Libellé"
                        value={inputCustomProductionUnit}
                        onChange={(e) =>
                          setInputCustomProductionUnit(e.target.value)
                        }
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Valeur de votre unité personnalisée en unité internationale"
                    >
                      <TextField
                        fullWidth
                        disabled={inputProductionUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputProductionUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={
                          inputCustomProductionUnitCorrespondanceValueError
                        }
                        variant="outlined"
                        name="customUnitProductionCorrespondanceValue"
                        label="Valeur de correspondance"
                        value={inputCustomProductionUnitCorrespondanceValue}
                        onChange={(e) => {
                          if (
                            /^\d*\.?\d*$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setInputCustomProductionUnitCorrespondanceValue(
                              e.target.value
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Unité internationale utilisée pour calculer la valeur de correspondance"
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={inputCustomProductionUnitCorrespondanceUnitError}
                      >
                        <InputLabel id="customProductionUnitCorrespondanceUnitLabel">
                          Unité de correspondance
                        </InputLabel>
                        <Select
                          disabled={inputProductionUnit !== "Personnalisée"}
                          sx={{
                            backgroundColor:
                              inputProductionUnit !== "Personnalisée"
                                ? "#e8e8e8"
                                : "white",
                          }}
                          labelId="customProductionUnitCorrespondanceUnitLabel"
                          id="customProductionUnitCorrespondanceUnit"
                          required
                          value={inputCustomProductionUnitCorrespondanceUnit}
                          label="Unité de correspondance"
                          onChange={(e) =>
                            setInputCustomProductionUnitCorrespondanceUnit(
                              e.target.value
                            )
                          }
                        >
                          <MenuItem
                            value="Kg"
                            disabled={isUnitDisabledForEdit("Kg")}
                          >
                            Kg
                          </MenuItem>
                          <MenuItem
                            value="g"
                            disabled={isUnitDisabledForEdit("g")}
                          >
                            g
                          </MenuItem>
                          <MenuItem
                            value="mg"
                            disabled={isUnitDisabledForEdit("mg")}
                          >
                            mg
                          </MenuItem>
                          <MenuItem
                            value="L"
                            disabled={isUnitDisabledForEdit("L")}
                          >
                            L
                          </MenuItem>
                          <MenuItem
                            value="mL"
                            disabled={isUnitDisabledForEdit("mL")}
                          >
                            mL
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FlexContainer>
              <FlexContainer justifyContent="center">
                <ButtonMui
                  margin="16px 4px 0 0"
                  onClick={() => {
                    if (validateEditForm(productionUnit)) {
                      let tmpArray = [...props.inputProductionUnits];
                      tmpArray[index] = {
                        id: productionUnit.id,
                        unit: inputProductionUnit,
                        customUnit: inputCustomProductionUnit,
                        customUnitCorrespondanceValue:
                          inputCustomProductionUnitCorrespondanceValue,
                        customUnitCorrespondanceUnit:
                          inputCustomProductionUnitCorrespondanceUnit,
                      };

                      props.setInputProductionUnits(tmpArray);
                      setProductionUnitAddActive(false);
                      setProductionUnitEditActive("");
                      setInputProductionUnit("");
                      setInputCustomProductionUnit("");
                      setInputCustomProductionUnitCorrespondanceValue("");
                      setInputCustomProductionUnitCorrespondanceUnit("");
                      resetValidation();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Modifier
                </ButtonMui>
                <ButtonMui
                  margin="16px 0 0 4px"
                  onClick={() => {
                    setProductionUnitEditActive("");
                    setInputProductionUnit("");
                    setInputCustomProductionUnit("");
                    setInputCustomProductionUnitCorrespondanceValue("");
                    setInputCustomProductionUnitCorrespondanceUnit("");
                    resetValidation();
                  }}
                  color="primary"
                  variant="outlined"
                  size="large"
                >
                  Annuler
                </ButtonMui>
              </FlexContainer>
            </BlockContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default ProductionUnits;
