import { Alert } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { AppName } from "../../../../../styles/AppName";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { TextFieldMui } from "../../../../../styles/TextFieldCustom";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface ActivateEmloyeeProps {}

const ActivateEmloyee: React.FC<ActivateEmloyeeProps> = (props) => {
  let navigate = useNavigate();
  const [activateFailed, setActivateFailed] = useState(false);

  const [employeeId, setEmployeeId] = useState("");

  const [email, setEmail] = useState("");

  const [inputPassword, SetInputPassword] = useState("");

  const [inputConfirmPassword, SetInputConfirmPassword] = useState("");

  const activateEmployeeAccount = (employeeId: string) => {
    AxiosHttpClient.putForNonAuth("api/v1/weeventpro/rh/employees/activate", {
      id: employeeId,
    })
      .then(() => {})
      .catch((err) => {
        setActivateFailed(true);
      });
  };

  const setEmployeePassword = () => {
    AxiosHttpClient.putForNonAuth(
      "api/v1/weeventpro/rh/employees/setPassword/" + employeeId,
      {
        input: inputPassword,
      }
    )
      .then(() => {
        navigate("/activate/success", {
          state: {
            employeeId: employeeId,
            email: email,
            password: inputPassword,
          },
        });
      })
      .catch((err) => {
        setActivateFailed(true);
      });
  };

  useEffect(() => {
    setEmployeeId(params.employeeIdAndEmail!.split("&email=")[0]);
    setEmail(params.employeeIdAndEmail!.split("&email=")[1]);
    activateEmployeeAccount(params.employeeIdAndEmail!.split("&email=")[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = useParams();

  return (
    <FlexContainer justifyContent="center" alignItems="center" height="100vh">
      <FlexContainer
        elevation={3}
        padding="40px"
        alignItems="center"
        flexDirection="column"
        backgroundColor="white"
        width="500px"
      >
        <FlexContainer margin="20px" alignSelf="center">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>
        {activateFailed && (
          <Alert severity="error">
            Une erreur est survenue! Veuillez contacter le support
          </Alert>
        )}
        <FlexContainer>
          <Text16 fontWeight="600">
            Votre compte a été activé avec succès!
          </Text16>
        </FlexContainer>
        <FlexContainer>
          <Text14>Veuillez choisir un mot de passe!</Text14>
        </FlexContainer>
        <TextFieldMui
          autoComplete="new-password"
          type="password"
          value={inputPassword}
          onChange={(e) => SetInputPassword(e.target.value)}
          label="Mot de passe"
          variant="outlined"
          margin="normal"
          fullWidth
          required
        />
        <TextFieldMui
          error={
            inputConfirmPassword !== "" &&
            inputPassword !== inputConfirmPassword
          }
          autoComplete="new-password"
          type="password"
          value={inputConfirmPassword}
          onChange={(e) => SetInputConfirmPassword(e.target.value)}
          label="Confirmer le mot de passe"
          variant="outlined"
          margin="normal"
          fullWidth
          helperText={
            inputConfirmPassword !== "" &&
            inputPassword !== inputConfirmPassword
              ? "Les mots de passe sont différents!"
              : ""
          }
          required
        />
        <FlexContainer margin="24px 0 16px" width="100%">
          <ButtonMui
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setEmployeePassword();
            }}
          >
            CONFIRMER
          </ButtonMui>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default ActivateEmloyee;
