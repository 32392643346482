import LoadingButton from '@mui/lab/LoadingButton';
import styled from "styled-components";

interface IHeadingStyled {
  width?: string;
  height?: string;
  margin?: string;
  $borderRadius?: string;
  $backgroundColorHover?: string;
  padding?: string;
  component?: string;
}

export const ButtonMui = styled(LoadingButton)<IHeadingStyled>`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.$borderRadius};
  &:hover {
    background-color: ${(props) => props.$backgroundColorHover};
    cursor: pointer;
  }
`;
