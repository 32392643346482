import { createFilterOptions } from "@mui/core";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Employee } from "../../../../../models/employee";
import { Vacation } from "../../../../../models/vacation";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import {
  getEmployeesAction,
  getVacationsAction,
} from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface EditVacationProps {
  input: string;
  popupActive: Vacation;
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
  setPopupActive: React.Dispatch<React.SetStateAction<Vacation>>;
  getVacationsAction: typeof getVacationsAction;
}

const Input = styled("input")({
  display: "none",
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {},
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _EditVacation: React.FC<EditVacationProps> = ({
  input,
  popupActive,
  employees,
  getEmployeesAction,
  setPopupActive,
  getVacationsAction,
}) => {
  const editVacation = () => {
    const obj = {
      id: popupActive.id,
      label: inputLabel,
      startDate: inputStartDate,
      endDate: inputEndDate,
      type: inputType,
      status: "En attente",
      comment: inputComment,
      supervisorId: inputSupervisor?.id,
    };
    const json = JSON.stringify(obj);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const data = new FormData();
    inputJustification.map((justification) =>
      data.append("justifications", justification)
    );
    data.append("document", blob);

    AxiosHttpClient.put("api/v1/weeventpro/rh/vacations/edit/", data)
      .then(() => {
        getVacationsAction(input);
        setPopupActive({
          id: "",
          label: "",
          startDate: "",
          endDate: "",
          type: "",
          status: "",
          justificationFileNames: [],
          comment: "",
          supervisorId: "",
          originalFileNames: [],
        });
      })
      .catch((err) => {
        SetEditFailed(err.cause);
      });
  };

  const validateForm = () => {
    SetInputLabelError(false);
    SetInputTypeError(false);
    let result = true;
    if (inputLabel === "") {
      SetInputLabelError(true);
      result = false;
    }
    if (inputType === "") {
      SetInputTypeError(true);
      result = false;
    }
    if (!result) SetEditFailed("Champs manquants");
    return result;
  };

  const filterOptions = createFilterOptions<Employee>({
    stringify: (option) => option.firstName + " " + option.lastName,
  });

  let removeDocument = (index: number) => {
    let newArr = [...inputJustification];
    newArr.splice(index, 1);
    SetInputJustification(newArr);
  };

  let getEmployeeById = (id: string) => {
    for (var i = 0; i < employees.length; i++)
      if (employees[i].id === id) return employees[i];
    return null;
  };

  const [inputLabel, SetInputLabel] = useState(popupActive.label);

  const [inputLabelError, SetInputLabelError] = useState(false);

  const [inputStartDate, SetInputStartDate] = useState(popupActive.startDate);

  const [inputEndDate, SetInputEndDate] = useState(popupActive.endDate);

  const [inputType, SetInputType] = useState(popupActive.type);

  const [inputTypeError, SetInputTypeError] = useState(false);

  const [inputComment, SetInputComment] = useState(popupActive.comment);

  const [inputSupervisor, setInputSupervisor] = useState<Employee | null>(
    getEmployeeById(popupActive.supervisorId)!
  );

  const [inputSupervisorName, setInputSupervisorName] = useState("aze");

  const [inputJustification, SetInputJustification] = useState<any[]>([]);

  const [editFailed, SetEditFailed] = useState("");

  const classes = useStyles();

  useEffect(() => {
    getEmployeesAction("");
    let arrayTmp: any[] = [];
    popupActive.justificationFileNames.map((justificationFileName, index) =>
      fetch(
        "https://" +
          process.env.REACT_APP_BUCKET_NAME! +
          ".s3.eu-west-3.amazonaws.com/" +
          justificationFileName
      )
        .then((r) => r.blob())
        .then((blobFile) => {
          arrayTmp.push(
            new File([blobFile], popupActive.originalFileNames[index])
          );
          if (popupActive.justificationFileNames.length === arrayTmp.length)
            SetInputJustification(arrayTmp);
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  useEffect(() => {
    setInputSupervisorName(
      getEmployeeById(popupActive.supervisorId)?.firstName +
        " " +
        getEmployeeById(popupActive.supervisorId)?.lastName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [inputSupervisorName]);

  return (
    <Dialog
      open={popupActive.id !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive({
          id: "",
          label: "",
          startDate: "",
          endDate: "",
          type: "",
          status: "",
          justificationFileNames: [],
          comment: "",
          supervisorId: "",
          originalFileNames: [],
        });
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier une absence"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive({
                id: "",
                label: "",
                startDate: "",
                endDate: "",
                type: "",
                status: "",
                justificationFileNames: [],
                comment: "",
                supervisorId: "",
                originalFileNames: [],
              });
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <BlockContainer margin="8px">
          {editFailed !== "" && <Alert severity="error">{editFailed}</Alert>}
        </BlockContainer>

        <form className={classes.root} autoComplete="off">
          <FlexContainer flexDirection="column">
            <TextField
              variant="outlined"
              name="label"
              label="Intitulé"
              required
              style={{ margin: "8px" }}
              error={inputLabelError}
              value={inputLabel}
              onChange={(e) => SetInputLabel(e.target.value)}
            />

            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 2 }}
              padding="8px"
              spacing={2}
            >
              <Grid item xs={1} sm={1} md={1} flex={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Du"
                    value={inputStartDate}
                    onChange={(value) => SetInputStartDate(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} sm={1} md={1} flex={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Au"
                    value={inputEndDate}
                    onChange={(value) => SetInputEndDate(value!)}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid
              container
              columns={{ xs: 1, sm: 1, md: 2 }}
              padding="8px"
              spacing={2}
            >
              <Grid item xs={1} sm={1} md={1} flex={1}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    native
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    required
                    error={inputTypeError}
                    value={inputType}
                    label="Age"
                    onChange={(e) =>
                      SetInputType(
                        typeof e.target.value === "string" ? e.target.value : ""
                      )
                    }
                  >
                    <option aria-label="" value="" />
                    <option value="Congé payé">Congé payé</option>
                    <option value="RTT">RTT</option>
                    <option value="Arrêt maladie">Arrêt maladie</option>
                    <option value="Congé non payé">Congé non payé</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={1} sm={1} md={1} flex={1}>
                <Autocomplete
                  id="employees"
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  filterOptions={filterOptions}
                  options={employees}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName
                  }
                  onChange={(event, newValue) => {
                    setInputSupervisor(newValue);
                  }}
                  value={inputSupervisor}
                  inputValue={inputSupervisorName}
                  onInputChange={(event, newInputValue) => {
                    setInputSupervisorName(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="supervisor"
                      label="Superviseur"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <FlexContainer
              flexDirection="column"
              border="2px dashed rgba(0,0,0,0.1)"
              margin="8px"
            >
              {inputJustification!.map((justification, index) => (
                <FlexContainer
                  elevation={3}
                  margin="8px"
                  height="48px"
                  justifyContent="space-between"
                  alignItems="center"
                  $borderRadius="16px"
                >
                  <FlexContainer margin="0 0 0 8px" alignItems="center">
                    <ArticleIcon fontSize="large" color="primary" />
                    <Text14>{justification.name}</Text14>
                  </FlexContainer>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeDocument(index);
                    }}
                    size="medium"
                    style={{ padding: "4px", zIndex: "1" }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </FlexContainer>
              ))}
              {inputJustification.length === 0 && (
                <FlexContainer margin="8px" height="48px">
                  <Text16 color="rgba(0,0,0,0.5)">
                    Aucun fichier sélectionné
                  </Text16>
                </FlexContainer>
              )}
            </FlexContainer>
            <FlexContainer>
              <label htmlFor="contained-button-file">
                <Input
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    let arrayTmp: File[] = [];

                    for (var i = 0; i < e.target.files!.length; i++) {
                      arrayTmp.push(e.target.files![i]);
                    }
                    SetInputJustification([...inputJustification, ...arrayTmp]);
                  }}
                />
                <ButtonMui
                  color="secondary"
                  variant="contained"
                  size="large"
                  $borderRadius="50px"
                  padding="8px 22px 8px 12px"
                  component="span"
                  margin="8px 0 8px 15px"
                >
                  <AddIcon fontSize="medium" />
                  Ajouter un justificatif
                </ButtonMui>
              </label>
            </FlexContainer>
            <TextField
              variant="outlined"
              name="comment"
              label="Commentaire"
              multiline
              rows={5}
              style={{ margin: "8px" }}
              value={inputComment}
              onChange={(e) => SetInputComment(e.target.value)}
            />
          </FlexContainer>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            if (validateForm()) editVacation();
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() =>
            setPopupActive({
              id: "",
              label: "",
              startDate: "",
              endDate: "",
              type: "",
              status: "",
              justificationFileNames: [],
              comment: "",
              supervisorId: "",
              originalFileNames: [],
            })
          }
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditVacation = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
        getVacationsAction: getVacationsAction,
      },
      dispatch
    )
)(_EditVacation);

export default EditVacation;
