import { createFilterOptions } from "@mui/core";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Ingredient } from "../../../../../models/ingredients";
import { IngredientQuantity } from "../../../../../models/supplierOrder";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getIngredients } from "../../../../../store/selectors";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import { getSupplierOrdersAction } from "../../../../../store/SupplierOrder/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import { TextFieldMui } from "../../../../../styles/TextFieldCustom";
import { ThemeCustom } from "../../../../../styles/Utils";

export interface AddSupplierOrderProps {
  input: string;
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getSupplierOrdersAction: typeof getSupplierOrdersAction;
  ingredients: Ingredient[];
  getIngredientsAction: typeof getIngredientsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _AddSupplierOrder: React.FC<AddSupplierOrderProps> = ({
  input,
  popupActive,
  setPopupActive,
  getSupplierOrdersAction,
  ingredients,
  getIngredientsAction,
}) => {
  const addSupplierOrder = () => {
    AxiosHttpClient.post("api/v1/weeventpro/order/supplierOrder/add", {
      id: "",
      label: label,
      date: "",
      price: "",
      ingredientQuantityList: ingredientQuantityList,
    })
      .then(() => {
        getSupplierOrdersAction(input);
        setPopupActive(false);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [label, setLabel] = useState("");

  const [inputIngredient, setInputIngredient] = useState("");

  const [inputQuantity, setInputQuantity] = useState("");

  const [value, setValue] = React.useState<Ingredient | null>();

  const [ingredientTmp, setIngredientTmp] = useState<Ingredient[]>([]);

  const [ingredientQuantityList, setIngredientQuantityList] = useState<
    IngredientQuantity[]
  >([]);

  useEffect(() => {
    getIngredientsAction("");
  }, [getIngredientsAction]);

  useEffect(() => {
    if (ingredients[0] !== undefined) {
      setIngredientTmp([...ingredients]);
      filterIngredients();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ingredientQuantityList, ingredients]);

  let filterIngredients = () => {
    if (ingredientTmp[0] !== undefined) {
      setIngredientTmp(
        ingredientTmp.filter(
          (ingredient) =>
            !ingredientQuantityList.some(
              (ingredientQuantity) =>
                ingredientQuantity.ingredientName === ingredient.name
            )
        )
      );
    }
  };

  const filterOptions = createFilterOptions<Ingredient>({
    matchFrom: "start",
    stringify: (option) => option.name,
  });

  let removeNomenclatureItem = (index: number) => {
    let newArr = [...ingredientQuantityList];
    newArr.splice(index, 1);
    setIngredientQuantityList(newArr);
  };

  //const [addFailed, SetaddFailed] = useState("");

  const ref = React.useRef<HTMLInputElement>(null);

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Créer une commande"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers className={classes.root}>
        <FlexContainer flexDirection="column" alignItems="center">
          <FlexContainer alignItems="center" width="100%">
            <TextField
              id="label"
              label="Libellé"
              fullWidth
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </FlexContainer>
          <FlexContainer
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Autocomplete
              id="ingredients"
              ref={ref}
              style={{ width: "60%" }}
              filterOptions={filterOptions}
              options={ingredientTmp}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputIngredient}
              onInputChange={(event, newInputValue) => {
                setInputIngredient(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="ingredient"
                  label="Ingrédient"
                  fullWidth
                  value={inputIngredient}
                  onChange={(e) => setInputIngredient(e.target.value)}
                />
              )}
            />
            <TextFieldMui
              variant="outlined"
              name="quantity"
              label="Quantité"
              fullWidth
              type="number"
              value={inputQuantity}
              onChange={(e) => setInputQuantity(e.target.value)}
              style={{ width: "30%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {value?.productionUnits[0].unit}
                  </InputAdornment>
                ),
              }}
            />
            <Fab
              size="small"
              color="secondary"
              aria-label="Ajouter à la nomenclature"
              disabled={
                inputIngredient === "" || inputQuantity === "" || value === null
              }
              style={{ width: "40px", height: "40px" }}
              onClick={() => {
                setIngredientQuantityList([
                  ...ingredientQuantityList,
                  {
                    ingredientName: inputIngredient,
                    quantity: inputQuantity,
                    unit: value!.productionUnits[0].unit,
                  },
                ]);
                setInputIngredient("");
                setInputQuantity("");
                let clickable: HTMLElement =
                  ref.current!.getElementsByClassName(
                    "MuiAutocomplete-clearIndicator"
                  )[0] as HTMLElement;
                clickable.click();
                setValue(null);
              }}
            >
              <AddIcon />
            </Fab>
          </FlexContainer>
          <TableContainerMui $maxHeight="430px" margin="0">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell key="ingredient">Ingrédient</TableCell>
                  <TableCell key="quantity">Quantité</TableCell>
                  <TableCell key="delete">Supprimer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ingredientQuantityList.map((nomenclatureItem, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {ingredientQuantityList[index].ingredientName}
                    </TableCell>
                    <TableCell>
                      {ingredientQuantityList[index].quantity}{" "}
                      {ingredientQuantityList[index].unit}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="secondary"
                        onClick={() => {
                          removeNomenclatureItem(index);
                          setIngredientTmp([...ingredients]);
                        }}
                        size="large"
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerMui>
        </FlexContainer>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => addSupplierOrder()}
          color="primary"
          variant="contained"
          size="large"
          disabled={ingredientQuantityList.length === 0}
        >
          Commander
        </ButtonMui>

        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddSupplierOrder = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
        getSupplierOrdersAction: getSupplierOrdersAction,
      },
      dispatch
    )
)(_AddSupplierOrder);

export default AddSupplierOrder;
