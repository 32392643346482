import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Opportunity } from "../../../../../models/opportunity";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getOpportunities } from "../../../../../store/selectors";
import { getOpportunitiesAction } from "../../../../../store/Crm/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddOpportunity from "../AddOpportunity";
import DeleteOpportunity from "../DeleteOpportunity";
import EditOpportunity from "../EditOpportunity";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells = [
  { id: "label", label: "Libelé" },
  { id: "clientName", label: "Nom du contact" },
  { id: "contactIsProspect", label: "Prospect/Client", disableSorting: true },
  { id: "step", label: "Étape" },
  { id: "amount", label: "Valeur" },
  { id: "probability", label: "Probability" },
  { id: "targetDate", label: "Date visée" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export interface OpportunityTableProps {
  opportunities: Opportunity[];
  getOpportunitiesAction: typeof getOpportunitiesAction;
}

const _OpportunityTable: React.FC<OpportunityTableProps> = ({
  opportunities,
  getOpportunitiesAction,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  const [popupEditActive, setPopupEditActive] = useState<Opportunity>({
    id: "",
    label: "",
    contactIsProspect: true,
    amount: "",
    probability: "",
    step: "",
    targetDate: "",
    contact: null,
  });

  useEffect(() => {
    getOpportunitiesAction(inputSearch);
  }, [inputSearch, getOpportunitiesAction]);

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const exportOpportunities = () => {
    AxiosHttpClient.getBlob("api/v1/weeventpro/crm/opportunities/export").then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "opportunities.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher un opportunity"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => exportOpportunities()}
          >
            <Typography variant="button">Exporter</Typography>
          </ButtonMui>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {opportunities.map((item) => (
              <TableRow
                component={Link}
                to={`/feature/opportunities/${item.id}`}
                key={item.label}
              >
                <TableCell>{item.label}</TableCell>
                <TableCell>{"item.contact?.name"}</TableCell>
                <TableCell>
                  {item.contactIsProspect ? "Prospect" : "Client"}
                </TableCell>
                <TableCell>{item.step}</TableCell>
                <TableCell>{item.amount}</TableCell>
                <TableCell>{item.probability}</TableCell>
                <TableCell>{item.targetDate}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupEditActive(item);
                    }}
                    size="large"
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      setPopupDeleteActive(item.id);
                    }}
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddOpportunity
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          input=""
        />
      )}
      <DeleteOpportunity
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive.label !== "" && (
        <EditOpportunity
          input={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </Paper>
  );
};

export const OpportunityTable = connect(
  (state: RootState) => ({
    opportunities: getOpportunities(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getOpportunitiesAction: getOpportunitiesAction,
      },
      dispatch
    )
)(_OpportunityTable);

export default _OpportunityTable;
