import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DishItemQuantity } from "../../../../../models/dishItemQuantity";
import { DrinkItemQuantity } from "../../../../../models/drinkItemQuantity";
import { MaterialItemQuantity } from "../../../../../models/materialItemQuantity";
import { Quote } from "../../../../../models/quote";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../store";
import { getQuotesAction } from "../../../../../store/Quote/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import EditQuoteStep1 from "./EditQuoteStep1";
import EditQuoteStep2 from "./EditQuoteStep2";
import EditQuoteStep3 from "./EditQuoteStep3";
import EditQuoteStep4 from "./EditQuoteStep4";
import EditQuoteStep5 from "./EditQuoteStep5";

export interface EditQuoteProps {
  input: string;
  popupActive: Quote;
  setPopupActive: React.Dispatch<React.SetStateAction<Quote>>;
  getQuotesAction: typeof getQuotesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
    "& .Mui-active": { color: CustomTheme.palette.secondary.main },
    "& .Mui-completed": { color: CustomTheme.palette.secondary.main },
  },
  dialogWrapper: {
    padding: CustomTheme.spacing(2),
    position: "absolute",
    top: CustomTheme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const _EditQuote: React.FC<EditQuoteProps> = (props) => {
  const editQuote = () => {
    AxiosHttpClient.put("api/v1/weeventpro/quote/edit", {
      eventName: inputEventName,
      eventType: inputEventType,
      contactName: inputContactName,
      date: inputDate,
      guestsNumber: inputGuestsNumber,
      receptionPlace: inputReceptionPlace,
      telNumber: inputTelNumber,
      price: inputPrice,
      dishes: inputDishes,
      drinks: inputDrinks,
      materials: inputMaterials,
    })
      .then(() => {
        props.getQuotesAction(props.input);
        props.setPopupActive({
          eventName: "",
          eventType: "",
          contactName: "",
          date: "",
          guestsNumber: "",
          receptionPlace: "",
          telNumber: "",
          price: "",
          dishes: [],
          drinks: [],
          materials: [],
        });
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const [inputEventName, setInputEventName] = useState(
    props.popupActive.eventName
  );

  const [inputEventType, setinputEventType] = useState(
    props.popupActive.eventType
  );

  const [inputContactName, setInputContactName] = useState(
    props.popupActive.contactName
  );

  const [inputDate, setInputDate] = useState(props.popupActive.date);

  const [inputGuestsNumber, setInputGuestsNumber] = useState(
    props.popupActive.guestsNumber
  );

  const [inputReceptionPlace, setInputReceptionPlace] = useState(
    props.popupActive.receptionPlace
  );

  const [inputTelNumber, setInputTelNumber] = useState(
    props.popupActive.telNumber
  );

  const [inputPrice, setInputPrice] = useState(props.popupActive.price);

  const [inputDishes, setInputDishes] = useState<DishItemQuantity[]>(
    props.popupActive.dishes
  );

  const [inputDrinks, setInputDrinks] = useState<DrinkItemQuantity[]>(
    props.popupActive.drinks
  );

  const [inputMaterials, setInputMaterials] = useState<MaterialItemQuantity[]>(
    props.popupActive.materials
  );

  const steps = ["", "", "", "", ""];

  const [step, setStep] = useState(0);

  //const [addFailed, SetaddFailed] = useState("");

  const classes = useStyles();

  return (
    <Dialog
      open={props.popupActive.eventName !== ""}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive({
          eventName: "",
          eventType: "",
          contactName: "",
          date: "",
          guestsNumber: "",
          receptionPlace: "",
          telNumber: "",
          price: "",
          dishes: [],
          drinks: [],
          materials: [],
        });
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Modifier un devis"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive({
                eventName: "",
                eventType: "",
                contactName: "",
                date: "",
                guestsNumber: "",
                receptionPlace: "",
                telNumber: "",
                price: "",
                dishes: [],
                drinks: [],
                materials: [],
              });
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ width: "100%" }}>
          <Stepper activeStep={step} className={classes.root} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <FlexContainer justifyContent="center" margin="20px 0 0 0">
          {step === 0 && (
            <EditQuoteStep1
              inputEventName={inputEventName}
              inputEventType={inputEventType}
              inputContactName={inputContactName}
              inputDate={inputDate}
              inputGuestsNumber={inputGuestsNumber}
              inputReceptionPlace={inputReceptionPlace}
              inputTelNumber={inputTelNumber}
              setInputEventName={setInputEventName}
              setInputEventType={setinputEventType}
              setInputContactName={setInputContactName}
              setInputDate={setInputDate}
              setInputGuestsNumber={setInputGuestsNumber}
              setInputReceptionPlace={setInputReceptionPlace}
              setInputTelNumber={setInputTelNumber}
            />
          )}
          {step === 1 && (
            <EditQuoteStep2
              dishItemsQuantities={inputDishes}
              setDishItemsQuantities={setInputDishes}
            />
          )}
          {step === 2 && (
            <EditQuoteStep3
              dishItemsQuantities={inputDrinks}
              setDishItemsQuantities={setInputDrinks}
            />
          )}
          {step === 3 && (
            <EditQuoteStep4
              materialItemsQuantities={inputMaterials}
              setMaterialItemsQuantities={setInputMaterials}
            />
          )}
          {step === 4 && (
            <EditQuoteStep5
              inputPrice={inputPrice}
              setInputPrice={setInputPrice}
            />
          )}
        </FlexContainer>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => setStep(step - 1)}
          color="primary"
          variant="contained"
          size="large"
          disabled={step === 0}
        >
          Précédent
        </ButtonMui>
        {step === 4 ? (
          <ButtonMui
            onClick={() => editQuote()}
            color="primary"
            variant="contained"
            size="large"
            disabled={inputEventName === ""}
          >
            Créer
          </ButtonMui>
        ) : (
          <ButtonMui
            onClick={() => setStep(step + 1)}
            color="primary"
            variant="contained"
            size="large"
          >
            Suivant
          </ButtonMui>
        )}
        <ButtonMui
          onClick={() =>
            props.setPopupActive({
              eventName: "",
              eventType: "",
              contactName: "",
              date: "",
              guestsNumber: "",
              receptionPlace: "",
              telNumber: "",
              price: "",
              dishes: [],
              drinks: [],
              materials: [],
            })
          }
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const EditQuote = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getQuotesAction: getQuotesAction,
    },
    dispatch
  )
)(_EditQuote);

export default EditQuote;
