import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Supplier } from "../../../../../models/suppliers";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getSuppliers } from "../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { Questions } from "../../WebPage/WebPageContent/Questions";
import DeleteSupplier from "../DeleteSupplier";
import EditSupplier from "../EditSupplier";
import { Informations } from "./Informations";

export interface SupplierDetailsProps {}

const _SupplierDetails: React.FC<SupplierDetailsProps> = (props) => {
  const [supplier, setSupplier] = useState<Supplier>();

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Supplier | null>(null);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getSupplierDetails = () => {
    AxiosHttpClient.get<Supplier>(
      "api/v1/weeventpro/stock/suppliers/" + params.supplierId
    ).then((response) => {
      setSupplier(response);
    });
  };

  useEffect(() => {
    getSupplierDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/suppliers">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>
              Fournisseurs
            </Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={supplier?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{supplier?.name}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {supplier?.category}
              </Text14>
              {!matches && (
                <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                  {"En stock: "}
                </Text14>
              )}
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(supplier!);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier le fournisseur
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  setPopupDeleteActive([supplier!.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer le fournisseur
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <Text20>Commandes en cours</Text20>
            <FlexContainer alignSelf="center">
              <ButtonMui
                onClick={() => {}}
                color="primary"
                variant="outlined"
                size="large"
              >
                Mettre à jour
              </ButtonMui>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && (
        <TabsDesktop
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations supplier={supplier!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      {!matches && (
        <TabsMobile
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations supplier={supplier!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      <DeleteSupplier
        input={""}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive !== null && (
        <EditSupplier
          inputSearch={""}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </BlockContainer>
  );
};

export const SupplierDetails = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_SupplierDetails);

export default _SupplierDetails;
