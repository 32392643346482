import { Ingredient } from "../../models/ingredients";
import { Material } from "../../models/materials";
import { Supplier } from "../../models/suppliers";

export const GET_INGREDIENTS = "GET_INGREDIENTS" as const;
export const GET_INGREDIENTS_SUCCESS = "GET_INGREDIENTS_SUCCESS" as const;
export const GET_INGREDIENTS_FAILURE = "GET_INGREDIENTS_FAILURE" as const;
export const ADD_INGREDIENT = "ADD_INGREDIENT" as const;

export const GET_MATERIALS = "GET_MATERIALS" as const;
export const GET_MATERIALS_SUCCESS = "GET_MATERIALS_SUCCESS" as const;
export const GET_MATERIALS_FAILURE = "GET_MATERIALS_FAILURE" as const;

export const GET_SUPPLIERS = "GET_SUPPLIERS" as const;
export const GET_SUPPLIERS_SUCCESS = "GET_SUPPLIERS_SUCCESS" as const;
export const GET_SUPPLIERS_FAILURE = "GET_SUPPLIERS_FAILURE" as const;

export const getIngredientsAction = (input: string) => ({
  type: GET_INGREDIENTS,
  input: input,
});
export type GetIngredientsAction = ReturnType<typeof getIngredientsAction>;

export const getIngredientsActionSuccess = (ingredients: Ingredient[]) => ({
  type: GET_INGREDIENTS_SUCCESS,
  ingredients: ingredients,
});
export type GetIngredientsActionSuccess = ReturnType<
  typeof getIngredientsActionSuccess
>;

export const getIngredientsActionFailure = () => ({
  type: GET_INGREDIENTS_FAILURE,
});
export type GetIngredientsActionFailure = ReturnType<
  typeof getIngredientsActionFailure
>;

export const addIngredientAction = () => ({
  type: ADD_INGREDIENT,
});
export type AddIngredientAction = ReturnType<typeof addIngredientAction>;


export const getMaterialsAction = (input: string) => ({
  type: GET_MATERIALS,
  input: input,
});
export type GetMaterialsAction = ReturnType<typeof getMaterialsAction>;

export const getMaterialsActionSuccess = (materials: Material[]) => ({
  type: GET_MATERIALS_SUCCESS,
  materials: materials,
});
export type GetMaterialsActionSuccess = ReturnType<
  typeof getMaterialsActionSuccess
>;

export const getMaterialsActionFailure = () => ({
  type: GET_MATERIALS_FAILURE,
});
export type GetMaterialsActionFailure = ReturnType<
  typeof getMaterialsActionFailure
>;


export const getSuppliersAction = (input: string) => ({
  type: GET_SUPPLIERS,
  input: input,
});
export type GetSuppliersAction = ReturnType<typeof getSuppliersAction>;

export const getSuppliersActionSuccess = (suppliers: Supplier[]) => ({
  type: GET_SUPPLIERS_SUCCESS,
  suppliers: suppliers,
});
export type GetSuppliersActionSuccess = ReturnType<
  typeof getSuppliersActionSuccess
>;

export const getSuppliersActionFailure = () => ({
  type: GET_SUPPLIERS_FAILURE,
});
export type GetSuppliersActionFailure = ReturnType<
  typeof getSuppliersActionFailure
>;