import { InputSearch } from "../models/inputSearch";
import { Quote } from "../models/quote";
import { Template } from "../models/template";
import { AxiosHttpClient } from "./AxiosHttpService";

export class QuoteService {
  static getQuotes = (input: InputSearch) => {
    return AxiosHttpClient.post<Quote[]>(
      "api/v1/weeventpro/quote/get",
      input
    );
  };

  static getQuoteTemplates = () => {
    return AxiosHttpClient.post<Template[]>(
      "api/v1/weeventpro/quote/template/get"
    );
  };
}
