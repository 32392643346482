import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddEmployee } from "./AddEmployee";
import { EmployeeTable } from "./EmployeeTable";
import BadgeTwoToneIcon from "@mui/icons-material/BadgeTwoTone";

export interface EmployeesProps {}

const Employees: React.FC<EmployeesProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Employés"
        subTitle="Liste des employés"
        icon={<BadgeTwoToneIcon fontSize="large" color="primary" />}
      />
      <EmployeeTable />
      {popupActive && (
        <AddEmployee
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Employees;
