import { Grid, IconButton, TextField, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ButtonCustom } from "../../../../../../styles/ButtonCustom";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import { Text14, Text16, Text20 } from "../../../../../../styles/Text";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTheme } from "../../../../../../styles/Theme";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { makeStyles } from "@mui/styles";
import { CustomToolbar } from "../../../../../Reusable/Quill";
import { connect } from "react-redux";
import { Dispatch, RootState } from "../../../../../../store";
import { getDishes } from "../../../../../../store/selectors";
import { bindActionCreators } from "redux";
import { getDishesAction } from "../../../../../../store/Dish/actions";
import { Dish } from "../../../../../../models/dish";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EditDish from "../../../Catalog/EditDish";
import KebabDiningTwoToneIcon from "@mui/icons-material/KebabDiningTwoTone";
import SetMealTwoToneIcon from "@mui/icons-material/SetMealTwoTone";
import CakeTwoToneIcon from "@mui/icons-material/CakeTwoTone";
import DeckTwoToneIcon from "@mui/icons-material/DeckTwoTone";
import LocalBarTwoToneIcon from "@mui/icons-material/LocalBarTwoTone";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import SaveIcon from "@mui/icons-material/Save";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";

const ReactGridLayout = WidthProvider(RGL);

export interface InformationsProps {
  dishes: Dish[];
  getDishesAction: typeof getDishesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .quill ": {
      minHeight: "200px",
    },
    "& .ql-container ": {
      minHeight: "200px",
    },
    "& .ql-editor": {
      minHeight: "200px",
    },
    "& .react-grid-item.react-grid-placeholder": {
      backgroundColor: "white",
      border: "1px dashed black",
    },
  },
  dialogWrapper: {
    position: "absolute",
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const Input = styled("input")({
  display: "none",
});

let modules = {
  toolbar: {
    container: "#toolbar",
  },
  clipboard: {
    matchVisual: false,
  },
};

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
let formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

const _Informations: React.FC<InformationsProps> = (props) => {
  const [inputName, setInputName] = useState("");
  const [inputDepartments, setInputDepartments] = useState("");
  const [inputServices, setInputServices] = useState("");
  const [inputSpecialitites, setInputSpecialitites] = useState("");
  const [hoveredDish, setHoveredDish] = useState("");

  const [value, setValue] = useState("");

  const [inputImages, setInputImages] = useState<any>([]);

  const [imagesPreviews, setImagesPreviews] = useState<any>([]);

  const [popupEditActive, setPopupEditActive] = useState<Dish | null>(null);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagesPreviews([...imagesPreviews, reader.result]);
      }
    };
    reader.readAsDataURL(file);
  };

  let removeImage = (index: number) => {
    let newArr = [...imagesPreviews];
    newArr.splice(index, 1);
    setImagesPreviews(newArr);
  };

  const items = [
    {
      content: "Informations générales",
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      i: 0,
    },
    {
      content: "Description",
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      i: 1,
    },
    {
      content: "Photos",
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      i: 2,
    },
    {
      content: "Menu",
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      i: 3,
    },
    {
      content: "Formules",
      x: 0,
      y: 0,
      h: 1,
      w: 1,
      i: 4,
    },
  ];

  useEffect(() => {
    props.getDishesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getDishesAction]);

  const classes = useStyles();

  return (
    <FlexContainer className={classes.root}>
      <FlexContainer flex="3" padding="32px">
        <FlexContainer flexDirection="column" width="100%">
          <FlexContainer margin="16px 0">
            <Text16 fontWeight="600">Informations générales</Text16>
          </FlexContainer>
          <FlexContainer margin="2px 0">
            <Text14 margin="1em 32px 1em 0">Nom du traiteur</Text14>
            <TextField
              variant="outlined"
              name="name"
              label="Nom"
              size="small"
              value={inputName}
              onChange={(e) => setInputName(e.target.value)}
            />
          </FlexContainer>
          <FlexContainer margin="2px 0">
            <Text14 margin="1em 32px 1em 0">
              Villes et départements de service
            </Text14>
            <TextField
              variant="outlined"
              name="name"
              label="Nom"
              size="small"
              value={inputDepartments}
              onChange={(e) => setInputDepartments(e.target.value)}
            />
          </FlexContainer>
          <FlexContainer margin="2px 0">
            <Text14 margin="1em 32px 1em 0">Services proposés</Text14>
            <TextField
              variant="outlined"
              name="name"
              label="Nom"
              size="small"
              value={inputServices}
              onChange={(e) => setInputServices(e.target.value)}
            />
          </FlexContainer>
          <FlexContainer margin="2px 0">
            <Text14 margin="1em 32px 1em 0">Spécialités</Text14>
            <TextField
              variant="outlined"
              name="name"
              label="Nom"
              size="small"
              value={inputSpecialitites}
              onChange={(e) => setInputSpecialitites(e.target.value)}
            />
          </FlexContainer>

          <FlexContainer margin="16px 0">
            <Text16 fontWeight="600">Description</Text16>
          </FlexContainer>

          <FlexContainer>
            <div className="text-editor">
              <CustomToolbar />
              <ReactQuill
                value={value}
                onChange={(text) => {
                  setValue(text);
                }}
                modules={modules}
                formats={formats}
                theme={"snow"} // pass false to use minimal theme
              />
            </div>
          </FlexContainer>

          <FlexContainer margin="16px 0">
            <Text16 fontWeight="600">Photos</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 3, md: 5 }}
            style={{ marginBottom: "10px" }}
          >
            <Grid item xs={1} sm={1} md={1} key="addWidget">
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  multiple
                  type="file"
                  onChange={(e) => {
                    setInputImages([...inputImages, e.target.files![0]]);
                    imagePreviewHandler(e.target.files![0]);
                  }}
                />
                <ButtonCustom height="250px" width="100%">
                  <FlexContainer
                    height="100%"
                    width="100%"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <AddPhotoAlternateIcon
                      color="primary"
                      style={{ fontSize: "100px" }}
                    />
                    <Text16>Ajouter une photo</Text16>
                  </FlexContainer>
                </ButtonCustom>
              </label>
            </Grid>

            {imagesPreviews.map((imagePreview, index) => (
              <Grid item xs={1} sm={1} md={1} key={index} position="relative">
                <ImagePreview
                  src={imagePreview}
                  alt=""
                  border={"1px solid " + CustomTheme.palette.secondary.main}
                  height="250px"
                  width="100%"
                />
                <FlexContainer
                  position="absolute"
                  top="8px"
                  right="-15px"
                  backgroundColor="white"
                  $borderRadius="100px"
                  border="1px red solid"
                >
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      removeImage(index);
                    }}
                    size="large"
                    style={{ padding: "4px", zIndex: "1" }}
                  >
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </FlexContainer>
              </Grid>
            ))}
          </Grid>

          <FlexContainer margin="16px 0">
            <Text16 fontWeight="600">Menu</Text16>
          </FlexContainer>

          <FlexContainer margin="16px 0" alignItems="center">
            <DeckTwoToneIcon color="primary" />
            <Text16 fontWeight="600">Entrées</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
            style={{ marginBottom: "10px" }}
          >
            {props.dishes.map(
              (dish, index) =>
                dish.category === "entree" && (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    key={index}
                    position="relative"
                  >
                    <FlexContainer
                      flexDirection="column"
                      elevation={3}
                      elevationHover={9}
                      $cursorHover="pointer"
                      $borderRadius="10px"
                      onMouseEnter={(e) => setHoveredDish(index.toString())}
                      onMouseLeave={(e) => setHoveredDish("")}
                    >
                      <ImagePreview
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/" +
                          encodeURIComponent(dish.imageUrl)
                        }
                        alt=""
                        height="200px"
                        width="100%"
                      />
                      <FlexContainer flexDirection="column">
                        <FlexContainer>
                          <Text16 fontWeight="600">{dish.name}</Text16>
                        </FlexContainer>
                        <FlexContainer>
                          <Text14>{" €"}</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="45px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => setPopupEditActive(dish)}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="5px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              removeImage(index);
                            }}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <VisibilityIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Grid>
                )
            )}
          </Grid>

          <FlexContainer margin="16px 0" alignItems="center">
            <SetMealTwoToneIcon color="primary" />
            <Text16 fontWeight="600">Plats</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
            style={{ marginBottom: "10px" }}
          >
            {props.dishes.map(
              (dish, index) =>
                dish.category === "plat" && (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    key={index}
                    position="relative"
                  >
                    <FlexContainer
                      flexDirection="column"
                      elevation={3}
                      elevationHover={9}
                      $cursorHover="pointer"
                      $borderRadius="10px"
                      onMouseEnter={(e) => setHoveredDish(index.toString())}
                      onMouseLeave={(e) => setHoveredDish("")}
                    >
                      <ImagePreview
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/" +
                          encodeURIComponent(dish.imageUrl)
                        }
                        alt=""
                        height="200px"
                        width="100%"
                      />
                      <FlexContainer flexDirection="column">
                        <FlexContainer>
                          <Text16 fontWeight="600">{dish.name}</Text16>
                        </FlexContainer>
                        <FlexContainer>
                          <Text14>{" €"}</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="45px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => setPopupEditActive(dish)}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="5px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              removeImage(index);
                            }}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <VisibilityIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Grid>
                )
            )}
          </Grid>

          <FlexContainer margin="16px 0" alignItems="center">
            <CakeTwoToneIcon color="primary" />
            <Text16 fontWeight="600">Desserts</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
            style={{ marginBottom: "10px" }}
          >
            {props.dishes.map(
              (dish, index) =>
                dish.category === "dessert" && (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    key={index}
                    position="relative"
                  >
                    <FlexContainer
                      flexDirection="column"
                      elevation={3}
                      elevationHover={9}
                      $cursorHover="pointer"
                      $borderRadius="10px"
                      onMouseEnter={(e) => setHoveredDish(index.toString())}
                      onMouseLeave={(e) => setHoveredDish("")}
                    >
                      <ImagePreview
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/" +
                          encodeURIComponent(dish.imageUrl)
                        }
                        alt=""
                        height="200px"
                        width="100%"
                      />
                      <FlexContainer flexDirection="column">
                        <FlexContainer>
                          <Text16 fontWeight="600">{dish.name}</Text16>
                        </FlexContainer>
                        <FlexContainer>
                          <Text14>{" €"}</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="45px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => setPopupEditActive(dish)}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="5px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              removeImage(index);
                            }}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <VisibilityIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Grid>
                )
            )}
          </Grid>

          <FlexContainer margin="16px 0" alignItems="center">
            <KebabDiningTwoToneIcon color="primary" />
            <Text16 fontWeight="600">Apéritifs</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
            style={{ marginBottom: "10px" }}
          >
            {props.dishes.map(
              (dish, index) =>
                dish.category === "aperitifs" && (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    key={index}
                    position="relative"
                  >
                    <FlexContainer
                      flexDirection="column"
                      elevation={3}
                      elevationHover={9}
                      $cursorHover="pointer"
                      $borderRadius="10px"
                      onMouseEnter={(e) => setHoveredDish(index.toString())}
                      onMouseLeave={(e) => setHoveredDish("")}
                    >
                      <ImagePreview
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/" +
                          encodeURIComponent(dish.imageUrl)
                        }
                        alt=""
                        height="200px"
                        width="100%"
                      />
                      <FlexContainer flexDirection="column">
                        <FlexContainer>
                          <Text16 fontWeight="600">{dish.name}</Text16>
                        </FlexContainer>
                        <FlexContainer>
                          <Text14>{" €"}</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="45px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => setPopupEditActive(dish)}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="5px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              removeImage(index);
                            }}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <VisibilityIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Grid>
                )
            )}
          </Grid>

          <FlexContainer margin="16px 0" alignItems="center">
            <LocalBarTwoToneIcon color="primary" />
            <Text16 fontWeight="600">Boissons</Text16>
          </FlexContainer>
          <Grid
            container
            spacing={{ xs: 3, md: 3 }}
            columns={{ xs: 1, sm: 2, md: 4 }}
            style={{ marginBottom: "10px" }}
          >
            {props.dishes.map(
              (dish, index) =>
                dish.category === "boisson" && (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    key={index}
                    position="relative"
                  >
                    <FlexContainer
                      flexDirection="column"
                      elevation={3}
                      elevationHover={9}
                      $cursorHover="pointer"
                      $borderRadius="10px"
                      onMouseEnter={(e) => setHoveredDish(index.toString())}
                      onMouseLeave={(e) => setHoveredDish("")}
                    >
                      <ImagePreview
                        src={
                          "https://" +
                          process.env.REACT_APP_BUCKET_NAME! +
                          ".s3.eu-west-3.amazonaws.com/" +
                          encodeURIComponent(dish.imageUrl)
                        }
                        alt=""
                        height="200px"
                        width="100%"
                      />
                      <FlexContainer flexDirection="column">
                        <FlexContainer>
                          <Text16 fontWeight="600">{dish.name}</Text16>
                        </FlexContainer>
                        <FlexContainer>
                          <Text14>{" €"}</Text14>
                        </FlexContainer>
                      </FlexContainer>
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="45px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => setPopupEditActive(dish)}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <EditOutlinedIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                      {hoveredDish === index.toString() && (
                        <FlexContainer
                          position="absolute"
                          top="28px"
                          right="5px"
                          backgroundColor="white"
                          $borderRadius="100px"
                          border="1px red solid"
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              removeImage(index);
                            }}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <VisibilityIcon fontSize="medium" />
                          </IconButton>
                        </FlexContainer>
                      )}
                    </FlexContainer>
                  </Grid>
                )
            )}
          </Grid>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer
        flex="1"
        height="600px"
        margin="32px 64px"
        position="sticky"
        top="80px"
      >
        <FlexContainer
          height="500px"
          width="100%"
          elevation={9}
          $borderRadius="10px"
          flexDirection="column"
        >
          <FlexContainer>
            <Text20 fontWeight="600" margin="16px 0 16px 16px">
              Sections
            </Text20>
          </FlexContainer>

          <DragDropContext onDragEnd={() => {}}>
            <Droppable key="aze" droppableId="aze">
              {(provided, snapshot) => (
                <ReactGridLayout
                  key={"gridlayout"}
                  isResizable={false}
                  rowHeight={40}
                  cols={1}
                  style={{ overflow: "hidden" }}
                >
                  {items.map((item, index) => (
                    <FlexContainer
                      height="60px"
                      key={"item" + index}
                      data-grid={{
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                      }}
                      border="1px solid black"
                      $borderRadius="10px"
                      alignItems="center"
                      $cursorHover="grab"
                      backgroundColor="white"
                    >
                      <Text14>{item.content}</Text14>
                    </FlexContainer>
                  ))}
                </ReactGridLayout>
              )}
            </Droppable>
          </DragDropContext>
          <FlexContainer flexDirection="column" alignItems="center">
            <FlexContainer margin="48px 0 8px 0">
              <ButtonMui
                color="primary"
                variant="outlined"
                startIcon={<AddIcon />}
                width="250px"
                onClick={() => {}}
              >
                <Typography variant="button">Ajouter une section</Typography>
              </ButtonMui>
            </FlexContainer>
            <FlexContainer margin="8px 0 8px 0">
              <ButtonMui
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                width="250px"
                onClick={() => {}}
              >
                <Typography variant="button">
                  Enregistrer les changements
                </Typography>
              </ButtonMui>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {popupEditActive !== null && (
        <EditDish
          inputSearch={""}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getDishesPage={() => {}}
        />
      )}
    </FlexContainer>
  );
};

export const Informations = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_Informations);

export default _Informations;
