import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddSupplierOrder } from "./AddSupplierOrder";
import { SupplierOrderTable } from "./SupplierOrderTable";

export interface SupplierOrderProps {}

const SupplierOrder: React.FC<SupplierOrderProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Commandes en cours"
        subTitle="Liste des commandes en cours"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <SupplierOrderTable />
      {popupActive && (
        <AddSupplierOrder
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default SupplierOrder;
