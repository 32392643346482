import React, { useEffect, useState } from "react";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { Articles } from "./Blog";
import Feedback from "./Feedback";
import { Informations } from "./Informations";
import { Questions } from "./Questions";

export interface WebPageContentProps {}

const WebPageContent: React.FC<WebPageContentProps> = () => {
  const [selectedTab, setSelectedTab] = useState("informations");

  useEffect(() => {}, []);

  return (
    <FlexContainer flexDirection="column">
      <FlexContainer>
        <FlexContainer backgroundColor="white" margin="0 24px">
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "informations"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "informations" ? 3 : undefined}
            $backgroundAfter={
              selectedTab === "informations" ? "white" : undefined
            }
            $heightAfter={selectedTab === "informations" ? "20px" : undefined}
            $contentAfter={selectedTab === "informations" ? "''" : undefined}
            $positionAfter={
              selectedTab === "informations" ? "absolute" : undefined
            }
            $bottomAfter={selectedTab === "informations" ? "-10px" : undefined}
            $leftAfter={selectedTab === "informations" ? "0px" : undefined}
            $rightAfter={selectedTab === "informations" ? "0px" : undefined}
            position={selectedTab === "informations" ? "relative" : undefined}
            zIndex={selectedTab === "informations" ? "1" : undefined}
            onClick={(e) => setSelectedTab("informations")}
          >
            <Text16>Informations</Text16>
          </FlexContainer>
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "feedback"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "feedback" ? 3 : undefined}
            $backgroundAfter={selectedTab === "feedback" ? "white" : undefined}
            $heightAfter={selectedTab === "feedback" ? "20px" : undefined}
            $contentAfter={selectedTab === "feedback" ? "''" : undefined}
            $positionAfter={selectedTab === "feedback" ? "absolute" : undefined}
            $bottomAfter={selectedTab === "feedback" ? "-10px" : undefined}
            $leftAfter={selectedTab === "feedback" ? "0px" : undefined}
            $rightAfter={selectedTab === "feedback" ? "0px" : undefined}
            position={selectedTab === "feedback" ? "relative" : undefined}
            zIndex={selectedTab === "feedback" ? "1" : undefined}
            onClick={(e) => setSelectedTab("feedback")}
          >
            <Text16>Avis</Text16>
          </FlexContainer>
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "q&a"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "q&a" ? 3 : undefined}
            $backgroundAfter={selectedTab === "q&a" ? "white" : undefined}
            $heightAfter={selectedTab === "q&a" ? "20px" : undefined}
            $contentAfter={selectedTab === "q&a" ? "''" : undefined}
            $positionAfter={selectedTab === "q&a" ? "absolute" : undefined}
            $bottomAfter={selectedTab === "q&a" ? "-10px" : undefined}
            $leftAfter={selectedTab === "q&a" ? "0px" : undefined}
            $rightAfter={selectedTab === "q&a" ? "0px" : undefined}
            position={selectedTab === "q&a" ? "relative" : undefined}
            zIndex={selectedTab === "q&a" ? "1" : undefined}
            onClick={(e) => setSelectedTab("q&a")}
          >
            <Text16>Questions/Réponses</Text16>
          </FlexContainer>
          <FlexContainer
            padding="8px 32px"
            $cursorHover="pointer"
            borderTop={
              selectedTab === "blog"
                ? "2px solid " + CustomTheme.palette.primary.main
                : undefined
            }
            elevation={selectedTab === "blog" ? 3 : undefined}
            $backgroundAfter={selectedTab === "blog" ? "white" : undefined}
            $heightAfter={selectedTab === "blog" ? "20px" : undefined}
            $contentAfter={selectedTab === "blog" ? "''" : undefined}
            $positionAfter={selectedTab === "blog" ? "absolute" : undefined}
            $bottomAfter={selectedTab === "blog" ? "-10px" : undefined}
            $leftAfter={selectedTab === "blog" ? "0px" : undefined}
            $rightAfter={selectedTab === "blog" ? "0px" : undefined}
            position={selectedTab === "blog" ? "relative" : undefined}
            zIndex={selectedTab === "blog" ? "1" : undefined}
            onClick={(e) => setSelectedTab("blog")}
          >
            <Text16>Blog</Text16>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
      <BlockContainer backgroundColor="white" margin="0 24px" elevation={3}>
        {selectedTab === "informations" && <Informations />}
        {selectedTab === "feedback" && <Feedback />}
        {selectedTab === "q&a" && <Questions />}
        {selectedTab === "blog" && <Articles />}
      </BlockContainer>
    </FlexContainer>
  );
};

export default WebPageContent;
