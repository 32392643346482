import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch } from "../../../../store";
import { setExpandedMenu } from "../../../../store/ExpandedMenuItem/actions";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { MetricList } from "./MetricsList";

export interface MetricProps {
  setExpandedMenu: typeof setExpandedMenu;
}

const _Metrics: React.FC<MetricProps> = ({ setExpandedMenu }) => {
  useEffect(() => {
    setExpandedMenu("panel8");
  }, [setExpandedMenu]);

  return (
    <BlockContainer>
      <PageHeader
        title="Métriques statistiques"
        subTitle="Liste des métriques statistiques"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <MetricList />
    </BlockContainer>
  );
};

export const Metrics = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setExpandedMenu: setExpandedMenu,
    },
    dispatch
  )
)(_Metrics);

export default Metrics;
