import { BaseMetric, CustomMetric } from "../models/metric";
import { AxiosHttpClient } from "./AxiosHttpService";

export class MetricService {
  static getBaseMetrics = (input: string, startDate: string, endDate: string) => {
    return AxiosHttpClient.post<BaseMetric[]>(
      "api/v1/weeventpro/statistics/metrics/getBase",
      {input: input, startDate: startDate, endDate: endDate}
    );
  };
  static getCustomMetrics = (input: string, startDate: string, endDate: string) => {
    return AxiosHttpClient.post<CustomMetric[]>(
      "api/v1/weeventpro/statistics/metrics/getCustom",
      {input: input,startDate: startDate, endDate: endDate}
    );
  };
}