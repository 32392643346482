import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { bindActionCreators } from "redux";
import { Material } from "../../../../../models/materials";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getMaterials } from "../../../../../store/selectors";
import { getMaterialsAction } from "../../../../../store/Stock/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { getMaterialNameById } from "../../../../Reusable/Utils";

export interface DeleteMaterialProps {
  input: string;
  popupActive: string[];
  materials: Material[];
  setPopupActive: React.Dispatch<React.SetStateAction<string[]>>;
  getMaterialsAction: typeof getMaterialsAction;
}

const _DeleteMaterial: React.FC<DeleteMaterialProps> = (props) => {
  const deleteMaterial = () => {
    AxiosHttpClient.delete(
      "api/v1/weeventpro/stock/materials/",
      props.popupActive
    )
      .then(() => {
        props.getMaterialsAction(props.input);
        props.setPopupActive([]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  return (
    <Dialog
      open={props.popupActive.length !== 0}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive([]);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer materiel?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          {props.popupActive.length === 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer le materiel{" "}
              <Typography
                variant="subtitle1"
                color="secondary"
                display="inline"
                component="span"
              >
                {getMaterialNameById(props.popupActive[0], props.materials)}
              </Typography>
              ?<br></br>
              Ceci le supprimera de
              <Typography
                variant="subtitle1"
                color="secondary"
                display="inline"
                component="span"
              >
                {" toutes les listes de nomenclatures "}
              </Typography>
              dans le catalogue des plats.
            </React.Fragment>
          )}
          {props.popupActive.length > 1 && (
            <React.Fragment>
              Etes-vous sûr de vouloir supprimer les materiels sélectionnés?
              <br></br>
              Ceci les supprimera de
              <Typography
                variant="subtitle1"
                color="secondary"
                display="inline"
                component="span"
              >
                {" toutes les listes de nomenclatures "}
              </Typography>
              dans le catalogue des plats.
            </React.Fragment>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            navigate("/feature/materialStock");
            setIsLoading(true);
            deleteMaterial();
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive([])}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteMaterial = connect(
  (state: RootState) => ({
    materials: getMaterials(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getMaterialsAction: getMaterialsAction,
      },
      dispatch
    )
)(_DeleteMaterial);

export default DeleteMaterial;
