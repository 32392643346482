import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import { DateRange } from "rsuite/esm/DateRangePicker";
import "./style.css";

export interface CustomDateRangePickerProps {
  value: [Date, Date] | null;
  onChange:
    | ((
        value: DateRange | null,
        event: React.SyntheticEvent<Element, Event>
      ) => void)
    | undefined;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = (props) => {
  return (
    <DateRangePicker
      value={props.value}
      onChange={props.onChange}
      placeholder="jj/mm/aaaa ~ jj/mm/aaaa"
      format="dd/MM/yyyy"
    />
  );
};
export default CustomDateRangePicker;
