import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, MenuItem, Paper, Select, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../models/client";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getClients } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text20, Text36 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { getClientDescription, toFixed2 } from "../../../../Reusable/Utils";
import DeleteClient from "../DeleteClient";
import GenerateInvoice from "./GenerateInvoice";
import { Informations } from "./Informations";
import EditClient from "../EditClient";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
import SaleTable from "../../Sales/SaleTable";
import InvoiceTable from "../../Invoice/InvoiceTable";

export interface ClientDetailsProps {}

const _ClientDetails: React.FC<ClientDetailsProps> = () => {
  const [client, setClient] = useState<Client | null>(null);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Client | null>(null);

  const [popupGenerateInvoiceActive, setPopupGenerateInvoiceActive] =
    useState<Client | null>(null);

  const [triggerGetTables, setTriggerGetTables] = useState(false);

  const [amountMode, setAmountMode] = useState("TTC");

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getClientDetails = () => {
    AxiosHttpClient.get<Client>(
      "api/v1/weeventpro/clients/" + params.clientId
    ).then((response) => {
      setClient(response);
    });
  };

  useEffect(() => {
    if (!popupEditActive && popupDeleteActive.length === 0) getClientDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupEditActive, popupDeleteActive]);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/clients">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>Clients</Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={client?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{getClientDescription(client)}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {client?.clientCode}
              </Text14>
              {!matches && (
                <>
                  <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                    {"Reste à payer: " +
                      toFixed2(
                        amountMode === "TTC"
                          ? client?.remainingPriceTTC
                          : amountMode === "HT"
                          ? client?.remainingPriceHT
                          : client?.remainingPriceTVA
                      ) +
                      " €"}
                  </Text14>
                  <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                    {"Non facturé: " +
                      toFixed2(
                        amountMode === "TTC"
                          ? client?.nonBilledPriceTTC
                          : amountMode === "HT"
                          ? client?.nonBilledPriceHT
                          : client?.nonBilledPriceTVA
                      ) +
                      " €"}
                  </Text14>
                </>
              )}
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(client);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier le client
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  if (client) setPopupDeleteActive([client.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer le client
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
            width: "33%",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <FlexContainer flexDirection="column" flex="1">
              <FlexContainer
                justifyContent={"space-between"}
                sx={{
                  "& .MuiSelect-select:focus": {
                    borderBottom: "none",
                    backgroundColor: "white",
                  },
                  "& .MuiInputBase-root:before": {
                    borderBottom: "none",
                  },
                  "& .MuiInputBase-root:after": {
                    borderBottom: "none",
                  },
                  "& .MuiInputBase-root:hover:before": {
                    borderBottom: "none !important",
                  },
                }}
              >
                <Text20>Impayés</Text20>
                <Select
                  variant="standard"
                  value={amountMode}
                  onChange={(e) => setAmountMode(e.target.value)}
                >
                  <MenuItem key={"TTC"} value={"TTC"}>
                    TTC
                  </MenuItem>
                  <MenuItem key={"HT"} value={"HT"}>
                    HT
                  </MenuItem>
                  <MenuItem key={"TVA"} value={"TVA"}>
                    TVA
                  </MenuItem>
                </Select>
              </FlexContainer>

              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <FlexContainer flexDirection={"column"}>
                    <Text14
                      color="rgba(0,0,0,0.8)"
                      margin="0 10px"
                      textAlign="center"
                    >
                      Reste à payer
                    </Text14>
                    <Text36
                      margin="0"
                      textAlign="center"
                      color={CustomTheme.palette.secondary.main}
                    >
                      {toFixed2(
                        amountMode === "TTC"
                          ? client?.remainingPriceTTC
                          : amountMode === "HT"
                          ? client?.remainingPriceHT
                          : client?.remainingPriceTVA
                      ) + " €"}
                    </Text36>
                  </FlexContainer>
                </SwiperSlide>
                <SwiperSlide>
                  <FlexContainer flexDirection={"column"}>
                    <Text14
                      color="rgba(0,0,0,0.8)"
                      margin="0 10px"
                      textAlign="center"
                    >
                      Non facturé
                    </Text14>
                    <Text36
                      margin="0"
                      textAlign="center"
                      color={CustomTheme.palette.secondary.main}
                    >
                      {toFixed2(
                        amountMode === "TTC"
                          ? client?.nonBilledPriceTTC
                          : amountMode === "HT"
                          ? client?.nonBilledPriceHT
                          : client?.nonBilledPriceTVA
                      ) + " €"}
                    </Text36>
                  </FlexContainer>
                </SwiperSlide>
              </Swiper>
            </FlexContainer>
            <FlexContainer alignSelf="center">
              <ButtonMui
                onClick={() => {
                  setPopupGenerateInvoiceActive(client);
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Générer une facture
              </ButtonMui>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && client && (
        <TabsDesktop
          tabs={["Informations", "Ventes associées", "Factures associées"]}
          components={[
            <Informations client={client!} />,
            <SaleTable
              additionalCriterias={"client_id=" + client.id}
              actionsOnAdd={[getClientDetails]}
              actionsOnEdit={[getClientDetails]}
              actionsOnDelete={[getClientDetails]}
              actionsOnGenerate={[getClientDetails]}
              triggerGetTable={triggerGetTables}
            />,
            <InvoiceTable
              additionalCriterias={"client_id=" + client.id}
              actionsOnAdd={[getClientDetails]}
              actionsOnEdit={[getClientDetails]}
              actionsOnGenerate={[getClientDetails]}
              triggerGetTable={triggerGetTables}
            />,
          ]}
        />
      )}
      {!matches && client && (
        <TabsMobile
          tabs={["Informations", "Ventes associées", "Factures associées"]}
          components={[
            <Informations client={client!} />,
            <SaleTable
              additionalCriterias={"client_id=" + client.id}
              actionsOnAdd={[getClientDetails]}
              actionsOnEdit={[getClientDetails]}
              actionsOnDelete={[getClientDetails]}
              actionsOnGenerate={[getClientDetails]}
              triggerGetTable={triggerGetTables}
            />,
            <InvoiceTable
              additionalCriterias={"client_id=" + client.id}
              actionsOnAdd={[getClientDetails]}
              actionsOnEdit={[getClientDetails]}
              actionsOnGenerate={[getClientDetails]}
              triggerGetTable={triggerGetTables}
            />,
          ]}
        />
      )}
      <DeleteClient
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
        getClientsPage={() => {}}
      />
      {popupEditActive !== null && (
        <EditClient
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
          getClientsPage={() => {}}
        />
      )}
      {popupGenerateInvoiceActive !== null && client && (
        <GenerateInvoice
          popupActive={popupGenerateInvoiceActive}
          client={client!}
          triggerGetTables={triggerGetTables}
          setTriggerGetTables={setTriggerGetTables}
          setPopupActive={setPopupGenerateInvoiceActive}
          getClientDetails={getClientDetails}
        />
      )}
    </BlockContainer>
  );
};

export const ClientDetails = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_ClientDetails);

export default _ClientDetails;
