import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  DesktopDatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../models/client";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish, SaleOption } from "../../../../../models/dish";
import { RouteName } from "../../../../../models/route";
import { SoldItem } from "../../../../../models/sale";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getSalesAction } from "../../../../../store/Sale/actions";
import {
  getClients,
  getDishes,
  getSales,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  SALE_FORMAT,
  SALE_INCREMENT,
  SALE_NUMBER_OF_DIGITS,
  getClientDescription,
  getDishBySaleOptionId,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../Reusable/Utils";
dayjs.extend(utc);
dayjs.extend(timezone);

export interface AddSaleProps {
  popupActive: boolean;
  dishes: Dish[];
  clients: Client[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getDishesAction: typeof getDishesAction;
  getClientsAction: typeof getClientsAction;
  actionsOnAdd?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));
const _AddSale: React.FC<AddSaleProps> = ({
  popupActive,
  dishes,
  clients,
  setPopupActive,
  getDishesAction,
  getClientsAction,
  actionsOnAdd,
}) => {
  /* TODO */
  const addSale = () => {
    let totalHt = getTotalHt();
    let totalTTC = getTotalTTC();
    AxiosHttpClient.post("api/v1/weeventpro/sales", {
      id: "",
      description: inputDescription,
      saleNumber: inputSaleNumber,
      clientId: inputClient?.id,
      comments: inputComments,
      deliverSale: inputDeliverSale === "Oui",
      routeNameName: inputRouteNameText,
      deliveryDate: inputDeliveryDate
        ? inputDeliveryDate.tz("Europe/Paris").format("YYYY-MM-DD")
        : null,
      deliveryTime: dayjs(
        dayjs(inputDeliveryDate).format("YYYY-MM-DD") +
          "T" +
          dayjs(inputDeliveryTime).format("HH:mm:ss.SSS")
      ),
      deliveryAddress: inputDeliveryAddress,
      deliveryInstructions: inputDeliveryInstructions,
      soldItems: inputSoldItems,
      status: inputStatus,
      date: dayjs(),
      totalPriceHT: toFixed2(totalHt),
      totalPriceTVA: toFixed2(totalTTC - totalHt),
      totalPriceTTC: toFixed2(totalTTC),
      globalTVA: toFixed2((totalTTC / totalHt - 1) * 100),
      invoiceId: null,
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(false);
        actionsOnAdd?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addSaleDialogContentId",
        });
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        SALE_FORMAT + "," + SALE_NUMBER_OF_DIGITS + "," + SALE_INCREMENT,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(SALE_INCREMENT, res);
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(SALE_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(SALE_FORMAT, res).replace(
        /%increment%/g,
        incrementPadding
      );

      setInputSaleNumber(invoiceNumber);
    });
  };

  const getRouteNames = () => {
    AxiosHttpClient.get<RouteName[]>("api/v1/weeventpro/route/names").then(
      (res) => {
        setRouteNames(res);
      }
    );
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/sale"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const validateAddSoldItemForm = () => {
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
    setAddSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOption === null) {
      setInputSaleOptionNameError(true);
      result = false;
    }
    if (inputSaleOptionQuantity === "") {
      setInputSaleOptionQuantityError(true);
      result = false;
    }
    if (!result) {
      setAddSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditSoldItemForm = () => {
    setInputSaleOptionQuantityEditError(false);
    setEditSaleOptionItemFailed("");
    let result = true;
    if (inputSaleOptionQuantityEdit === "") {
      setInputSaleOptionQuantityEditError(true);
      result = false;
    }
    if (!result) {
      setEditSaleOptionItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputDescriptionError(false);
    setInputSaleNumberError(false);
    setInputRouteNameError(false);
    setInputDeliveryDateError(false);
    setInputClientError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (inputSaleNumber === "") {
      setInputSaleNumberError(true);
      result = false;
    }
    if (inputSoldItems.length === 0) {
      setAddFailed(
        "Vous n'avez aucun produit vendu pour cette vente. Veuillez en ajouter!"
      );
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addSaleDialogContentId",
      });
      return false;
    }
    if (inputDeliverSale === "Oui" && !inputClient) {
      setInputClientError(true);
      result = false;
    }
    if (inputDeliverSale === "Oui" && inputRouteNameText === "") {
      setInputRouteNameError(true);
      result = false;
    }
    if (inputDeliverSale === "Oui" && inputDeliveryDate === null) {
      setInputDeliveryDateError(true);
      result = false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addSaleDialogContentId",
      });
    }
    return result;
  };

  const resetForm = () => {
    setAddSaleOptionItemFailed("");
    setInputSaleOption(null);
    setInputSaleOptionName("");
    setInputSaleOptionQuantity("");
    setInputSaleOptionNameError(false);
    setInputSaleOptionQuantityError(false);
  };

  const resetEditForm = () => {
    setEditSaleOptionItemFailed("");
    setInputSaleOptionQuantityEdit("");
    setInputSaleOptionQuantityEditError(false);
  };

  useEffect(() => {
    getCustomFields();
    getRouteNames();
  }, []);

  const [inputDescription, setInputDescription] = useState("");

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [inputSaleNumber, setInputSaleNumber] = useState("");

  const [inputSaleNumberError, setInputSaleNumberError] = useState(false);

  const [inputRouteNameError, setInputRouteNameError] = useState(false);

  const [inputClient, setInputClient] = useState<Client | null>(null);

  const [inputClientError, setInputClientError] = useState(false);

  const [inputClientText, setInputClientText] = useState("");

  const [inputComments, setInputComments] = useState("");

  const [inputDeliverSale, setInputDeliverSale] = useState("Non");

  const [inputRouteName, setInputRouteName] = useState<RouteName | string>("");

  const [inputRouteNameText, setInputRouteNameText] = useState("");

  const [inputDeliveryDate, setInputDeliveryDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputDeliveryDateError, setInputDeliveryDateError] = useState(false);

  const [inputDeliveryTime, setInputDeliveryTime] = useState<Dayjs | null>(
    null
  );

  const [inputDeliveryAddress, setInputDeliveryAddress] = useState("");

  const [inputDeliveryInstructions, setInputDeliveryInstructions] =
    useState("");

  const [inputSoldItems, setInputSoldItems] = useState<SoldItem[]>([]);

  const [saleOptionsTmp, setSaleOptionsTmp] = useState<SaleOption[]>([]);

  const [routeNames, setRouteNames] = useState<RouteName[]>([]);

  const [inputSaleOptionName, setInputSaleOptionName] = useState("");

  const [inputSaleOption, setInputSaleOption] =
    React.useState<SaleOption | null>(null);

  const [inputSaleOptionQuantity, setInputSaleOptionQuantity] = useState("");

  const [inputSaleOptionQuantityEdit, setInputSaleOptionQuantityEdit] =
    useState("");

  const [inputSaleOptionNameError, setInputSaleOptionNameError] =
    useState(false);

  const [inputSaleOptionQuantityError, setInputSaleOptionQuantityError] =
    useState(false);

  const [inputStatus, setInputStatus] = useState("Validée");

  const [addSaleOptionItemFailed, setAddSaleOptionItemFailed] = useState("");

  const [
    inputSaleOptionQuantityEditError,
    setInputSaleOptionQuantityEditError,
  ] = useState(false);

  const [editSaleOptionItemFailed, setEditSaleOptionItemFailed] = useState("");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [saleItemEditActive, setSaleItemEditActive] = useState("");

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  const getTotalHt = () => {
    return inputSoldItems
      .map((soldItem) => Number(soldItem.quantity) * Number(soldItem.priceHT))
      .reduce((total, currentValue) => (total = total + currentValue), 0);
  };

  const getTotalTTC = () => {
    return inputSoldItems
      .map(
        (soldItem) =>
          (1 + Number(soldItem.tva) * 0.01) *
          Number(soldItem.quantity) *
          Number(soldItem.priceHT)
      )
      .reduce((total, currentValue) => (total = total + currentValue), 0);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  let removeSaleOptionItem = (index: number) => {
    let newArr = [...inputSoldItems];
    newArr.splice(index, 1);
    setInputSoldItems(newArr);
  };

  const filterOptions = createFilterOptions<Client>({
    stringify: (option) => getClientDescription(option),
    limit: 20,
  });

  const filterSaleOptionOptions = (options: SaleOption[], { inputValue }) => {
    if (inputValue === null || inputValue === undefined) return [];
    const searchTokens = inputValue.toLowerCase().split(" ");
    return options
      .filter((option) =>
        searchTokens.every((token) => option.name.toLowerCase().includes(token))
      )
      .slice(0, 20);
  };

  const filterRouteNameOptions = createFilterOptions<RouteName>({
    stringify: (option) => option.name,
    limit: 20,
  });

  useEffect(() => {
    getDishesAction("");
    getClientsAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDishesAction, getClientsAction]);

  useEffect(() => {
    let saleOptions: SaleOption[] = [];

    for (var dish of dishes) {
      dish.saleOptions.forEach((saleOption) => saleOptions.push(saleOption));
    }

    if (saleOptions[0] !== undefined) {
      lastSaleOptions.current = saleOptions;
    }

    // Utilisez lastSaleOptions.current comme valeur pour setSaleOptionsTmp
    setSaleOptionsTmp(lastSaleOptions.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dishes]);

  // À l'extérieur du composant, définissez une référence pour stocker la dernière valeur de saleOptions
  const lastSaleOptions = useRef<SaleOption[]>([]);

  useEffect(() => {
    let generatedDescription = "";

    if (inputClient !== null)
      generatedDescription += getClientDescription(inputClient) + " - ";

    if (inputSoldItems.length > 0)
      generatedDescription += inputSoldItems
        .map((soldItem) => soldItem.quantity + ' "' + soldItem.name + '"')
        .join(", ");

    setInputDescription(generatedDescription);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputClient, inputSoldItems]);

  useEffect(() => {
    if (inputClient) {
      setInputDeliveryAddress(inputClient.address);
      setInputDeliveryInstructions(inputClient.deliveryInstructions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputClient]);

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Ajouter une vente"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addSaleDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="saleNumber"
                label="Numéro de vente"
                error={inputSaleNumberError}
                value={inputSaleNumber}
                onChange={(e) => {
                  setInputSaleNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                id="clients"
                filterOptions={filterOptions}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                noOptionsText={"Pas de suggestions"}
                fullWidth
                getOptionLabel={(option) => {
                  return getClientDescription(option);
                }}
                options={clients}
                value={inputClient}
                onChange={(event, newValue) => {
                  setInputClient(newValue!);
                }}
                inputValue={inputClientText}
                onInputChange={(event, newInputValue) => {
                  setInputClientText(newInputValue);
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      height: "100px",
                    }}
                    {...props}
                  >
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={option.imageUrl}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {getClientDescription(option)}
                      </Text14>
                    </FlexContainer>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={inputClientError}
                    variant="outlined"
                    name="client"
                    label="Client"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="comments"
                label="Notes/Commentaires"
                multiline
                rows={4}
                value={inputComments}
                onChange={(e) => {
                  setInputComments(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Livraison
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={1}>
            <FormControl component="fieldset">
              <FormLabel>Faire une livraison pour cette vente</FormLabel>
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={inputDeliverSale}
                onChange={(e) => setInputDeliverSale(e.target.value)}
                row
              >
                <FormControlLabel value="Oui" control={<Radio />} label="Oui" />
                <FormControlLabel value="Non" control={<Radio />} label="Non" />
              </RadioGroup>
            </FormControl>
          </Grid>
          {inputDeliverSale === "Oui" && (
            <>
              <Grid item xs={1} sm={1} md={2}>
                <Autocomplete
                  id="route"
                  filterOptions={filterRouteNameOptions}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  noOptionsText={"Pas de suggestions"}
                  fullWidth
                  disableClearable
                  freeSolo
                  options={routeNames}
                  value={inputRouteName}
                  onChange={(event, newValue) => {
                    setInputRouteName(newValue!);
                  }}
                  inputValue={inputRouteNameText}
                  onInputChange={(event, newInputValue) => {
                    setInputRouteNameText(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={inputRouteNameError}
                      variant="outlined"
                      name="route"
                      label="Tournée"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="Date de livraison"
                    inputFormat="DD/MM/YYYY"
                    value={inputDeliveryDate}
                    onChange={(value) => setInputDeliveryDate(value!)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        error={inputDeliveryDateError}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} sm={1} md={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    ampm={false}
                    label="Heure de livraison"
                    value={inputDeliveryTime}
                    onChange={(newValue) => {
                      setInputDeliveryTime(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="deliveryAdress"
                  label="Adresse de livraison"
                  multiline
                  rows={4}
                  value={inputDeliveryAddress}
                  onChange={(e) => {
                    setInputDeliveryAddress(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={1} sm={1} md={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="deliveryDriver"
                  label="Insrtuctions pour le livreur"
                  multiline
                  rows={4}
                  value={inputDeliveryInstructions}
                  onChange={(e) => {
                    setInputDeliveryInstructions(e.target.value);
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Produits vendus
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Sélectionnez les items vendus avec leurs quantités respectives:
            </Text14>
          </Grid>
          {addSaleOptionItemFailed !== "" && (
            <Grid item xs={1} sm={1} md={2}>
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addSaleOptionItemFailed}</Alert>
              </BlockContainer>
            </Grid>
          )}

          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="saleOptions"
              filterOptions={filterSaleOptionOptions}
              options={saleOptionsTmp}
              getOptionLabel={(option) => option.name}
              value={inputSaleOption}
              onChange={(event, newValue) => {
                setInputSaleOption(newValue);
              }}
              inputValue={inputSaleOptionName}
              onInputChange={(event, newInputValue) => {
                setInputSaleOptionName(newInputValue);
              }}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{
                    height: "100px",
                  }}
                  {...props}
                >
                  <FlexContainer
                    flex="1"
                    justifyContent="center"
                    marginRight="16px"
                    maxWidth="90px"
                  >
                    <img
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                      loading="lazy"
                      src={option.imageUrl}
                      alt=""
                    />
                  </FlexContainer>
                  <FlexContainer>
                    <Text14 textAlign="left">{option.name}</Text14>
                  </FlexContainer>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="saleOption"
                  label="Produit"
                  error={inputSaleOptionNameError}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="ingredientQuantity"
              label="Quantité"
              error={inputSaleOptionQuantityError}
              value={inputSaleOptionQuantity}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputSaleOptionQuantity(e.target.value);
                }
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddSoldItemForm()) {
                    resetForm();
                    let dish = getDishBySaleOptionId(
                      inputSaleOption!.id,
                      dishes
                    )!;
                    setInputSoldItems([
                      ...inputSoldItems,
                      {
                        id: uuid(),
                        name: dish.name,
                        priceHT: inputSaleOption!.price,
                        tva: inputSaleOption!.tva,
                        quantity: Number(inputSaleOptionQuantity),
                        internalCode: dish.internalCode,
                        imageUrl: dish.imageUrl,
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="product">Produit</TableCell>
                    <TableCell key="code">Code</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="pu">P.U</TableCell>
                    <TableCell key="pht">Prix H.T</TableCell>
                    <TableCell key="tva">TVA</TableCell>
                    <TableCell key="pttc">Prix TTC</TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputSoldItems.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={9}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Veuillez ajouter les produits vendus dans cette
                            vente!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputSoldItems.map((soldItem, index) => (
                    <React.Fragment key={"fragment" + soldItem.id}>
                      <TableRow key={soldItem.id}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={soldItem.imageUrl}
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>{soldItem.name}</TableCell>
                        <TableCell>{soldItem.internalCode}</TableCell>
                        <TableCell>{soldItem.quantity}</TableCell>
                        <TableCell>{soldItem.priceHT + " €"}</TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(soldItem.quantity) * Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>{soldItem.tva}</TableCell>
                        <TableCell>
                          {toFixed2(
                            (1 + Number(soldItem.tva) * 0.01) *
                              Number(soldItem.quantity) *
                              Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputSaleOptionQuantityEdit(
                                  toFixed2(soldItem.quantity)
                                );
                                setSaleItemEditActive(soldItem.id);
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeSaleOptionItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + soldItem.id}
                        sx={{
                          display:
                            saleItemEditActive === soldItem.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={9}>
                          <Collapse
                            orientation="vertical"
                            in={saleItemEditActive === soldItem.id}
                          >
                            {saleItemEditActive === soldItem.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editSaleOptionItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editSaleOptionItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={2}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="saleOptionQuantity"
                                    label="Quantité"
                                    error={inputSaleOptionQuantityEditError}
                                    value={inputSaleOptionQuantityEdit}
                                    onChange={(e) => {
                                      if (
                                        /^\d*\.?\d*$/.test(e.target.value) ||
                                        e.target.value === ""
                                      ) {
                                        setInputSaleOptionQuantityEdit(
                                          e.target.value
                                        );
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditSoldItemForm()) {
                                          resetEditForm();
                                          let tmpArray = [...inputSoldItems];
                                          tmpArray[index] = {
                                            id: soldItem.id,
                                            name: soldItem.name,
                                            priceHT: soldItem.priceHT,
                                            tva: soldItem.tva,
                                            quantity: Number(
                                              inputSaleOptionQuantityEdit
                                            ),
                                            internalCode: soldItem.internalCode,
                                            imageUrl: soldItem.imageUrl,
                                          };
                                          setInputSoldItems(tmpArray);
                                          setSaleItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetEditForm();
                                        setSaleItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Tarification
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14 display="inline-block" width="80px">
              Prix HT:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {toFixed2(
                inputSoldItems
                  .map(
                    (soldItem) =>
                      Number(soldItem.quantity) * Number(soldItem.priceHT)
                  )
                  .reduce(
                    (total, currentValue) => (total = total + currentValue),
                    0
                  )
              ) + " €"}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <Text14 display="inline-block" width="80px">
              Prix TTC:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {toFixed2(
                inputSoldItems
                  .map(
                    (soldItem) =>
                      (1 + Number(soldItem.tva) * 0.01) *
                      Number(soldItem.quantity) *
                      Number(soldItem.priceHT)
                  )
                  .reduce(
                    (total, currentValue) => (total = total + currentValue),
                    0
                  )
              ) + " €"}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Statut</InputLabel>
              <Select
                required
                fullWidth
                displayEmpty
                value={inputStatus}
                label="Statut"
                onChange={(e) => {
                  setInputStatus(
                    typeof e.target.value === "string" ? e.target.value : ""
                  );
                }}
              >
                <MenuItem value="Annulée">Annulée</MenuItem>
                <MenuItem value="Non validée">Non validée</MenuItem>
                <MenuItem value="Validée">Validée</MenuItem>
                <MenuItem value="Facturée">Facturée</MenuItem>
                <MenuItem value="Payée">Payée</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        displayEmpty
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"customFieldsSelect" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"customFieldsRadio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addSale();
            else setIsLoading(false);
          }}
        >
          Ajouter
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const AddSale = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
    clients: getClients(state),
    sales: getSales(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
        getClientsAction: getClientsAction,
        getSalesAction: getSalesAction,
      },
      dispatch
    )
)(_AddSale);

export default AddSale;
