import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SupplierOrderArchive } from "../../../../../models/supplierOrderArchive";
import { Dispatch, RootState } from "../../../../../store";
import { getSupplierOrdersArchive } from "../../../../../store/selectors";
import { getSupplierOrdersArchiveAction } from "../../../../../store/SupplierOrder/actions";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddSupplierOrderArchive from "../AddSupplierOrderArchive";
import DeleteSupplierOrderArchive from "../DeleteSupplierOrderArchive";
import EditSupplierOrderArchive from "../EditSupplierOrderArchive";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells: {
  id: keyof SupplierOrderArchive;
  label: string;
  disableSorting?: boolean;
}[] = [
  { id: "id", label: "N° commande" },
  { id: "label", label: "Libellé" },
  { id: "date", label: "Date" },
  { id: "price", label: "Coût total (€)" },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator(
  order: Order,
  orderBy: keyof SupplierOrderArchive
): (a: SupplierOrderArchive, b: SupplierOrderArchive) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface SupplierOrderArchiveTableProps {
  supplierOrderArchive: SupplierOrderArchive[];
  getSupplierOrdersArchiveAction: typeof getSupplierOrdersArchiveAction;
}

const _SupplierOrderArchiveTable: React.FC<SupplierOrderArchiveTableProps> = ({
  supplierOrderArchive,
  getSupplierOrdersArchiveAction,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState<keyof SupplierOrderArchive>("date");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  const [popupEditActive, setPopupEditActive] = useState<SupplierOrderArchive>({
    id: "",
    label: "",
    date: "",
    price: "",
    ingredientQuantityList: [],
  });

  useEffect(() => {
    getSupplierOrdersArchiveAction(inputSearch);
  }, [inputSearch, getSupplierOrdersArchiveAction]);

  const handleSortRequest = (cellId: keyof SupplierOrderArchive) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher une commande"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Créer une commande</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
              <TableCell key="Actions">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(
              supplierOrderArchive,
              getComparator(order, orderBy)
            ).map((supplierOrderArchive) => (
              <TableRow key={supplierOrderArchive.id}>
                <TableCell>{supplierOrderArchive.id}</TableCell>
                <TableCell>{supplierOrderArchive.label}</TableCell>
                <TableCell>{supplierOrderArchive.date}</TableCell>
                <TableCell>{supplierOrderArchive.price}</TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => setPopupEditActive(supplierOrderArchive)}
                    size="large"
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setPopupDeleteActive(supplierOrderArchive.id)
                    }
                    size="large"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddSupplierOrderArchive
          setPopupActive={setPopupAddActive}
          popupActive={popupAddActive}
          input=""
        />
      )}
      <DeleteSupplierOrderArchive
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive.id !== "" && (
        <EditSupplierOrderArchive
          input={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </Paper>
  );
};

export const SupplierOrderArchiveTable = connect(
  (state: RootState) => ({
    supplierOrderArchive: getSupplierOrdersArchive(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSupplierOrdersArchiveAction: getSupplierOrdersArchiveAction,
      },
      dispatch
    )
)(_SupplierOrderArchiveTable);

export default _SupplierOrderArchiveTable;
