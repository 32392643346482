import { OutlinedInputProps, TextField, Tooltip } from "@mui/material";
import React from "react";

export interface TextfieldTipProps {
  tipColor: string;
  tipPlacement:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top"
    | undefined;
  tipTitle: string;
  name: string;
  label: string;
  value: string;
  onChange:
    | React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  InputProps?: Partial<OutlinedInputProps> | undefined;
  disabled?: boolean;
}

const TextfieldTip: React.FC<TextfieldTipProps> = (props) => {
  return (
    <Tooltip
      color={props.tipColor}
      arrow
      placement={props.tipPlacement}
      title={props.tipTitle}
    >
      <TextField
        fullWidth
        variant="outlined"
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        InputProps={props.InputProps}
      />
    </Tooltip>
  );
};

export default TextfieldTip;
