import { combineEpics } from "redux-observable";
import { signinEpic } from "./Signin";
import { stockEpic } from "./Stock";
import { dishesEpic } from "./Dish";
import { quoteEpic } from "./Quote";
import { upfrontInvoiceEpic } from "./UpfrontInvoice";
import { invoiceEpic } from "./Invoice";
import { customerOrderEpic } from "./CustomerOrder";
import { supplierOrderEpic } from "./SupplierOrder";
import { cartItemsEpic } from "./Cart";
import { metricEpic } from "./Metric";
import { CrmEpic } from "./Crm";
import { RhEpic } from "./Rh";
import { salesEpic } from "./Sale";
import { routeEpic } from "./Route";
import { signupEpic } from "./Signup";

export const rootEpic = combineEpics(
  signupEpic,
  signinEpic,
  stockEpic,
  dishesEpic,
  quoteEpic,
  upfrontInvoiceEpic,
  invoiceEpic,
  customerOrderEpic,
  supplierOrderEpic,
  cartItemsEpic,
  metricEpic,
  CrmEpic,
  RhEpic,
  salesEpic,
  routeEpic,
);
