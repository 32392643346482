import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Divider, Grid } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Ingredient } from "../../../../../../models/ingredients";
import { StorageArea } from "../../../../../../models/storageArea";
import { Supplier } from "../../../../../../models/suppliers";
import { Dispatch, RootState } from "../../../../../../store";
import { getSuppliers } from "../../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { InlineBlockContainer } from "../../../../../../styles/InlineBlockContainer";
import { Text14, Text16, Text36 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getStorageUnitNameById } from "../../../../../Reusable/Utils";

let mockStorageAreas: StorageArea[] = [
  {
    id: "storageId1",
    name: "storage 1",
    description: "description 1",
    address: "address 1",
    categories: ["cat11", "cat12"],
    manager: "manager1",
  },
  {
    id: "storageId2",
    name: "storage 2",
    description: "description 2",
    address: "address 2",
    categories: ["cat21", "cat22"],
    manager: "manager2",
  },
];

export interface InformationsProps {
  ingredient: Ingredient;
  suppliers: Supplier[];
  getSuppliersAction: typeof getSuppliersAction;
}

const _Informations: React.FC<InformationsProps> = (props) => {
  const [imageSelected, setImageSelected] = useState("");

  useEffect(() => {
    setImageSelected(props.ingredient?.imageUrl);
  }, [props.ingredient]);

  const scroll = (direction: number) => {
    scrollLeft(
      document.getElementById("image-container"),
      300 * direction,
      1000
    );
  };

  function scrollLeft(element, change, duration) {
    var start = element.scrollLeft,
      currentTime = 0,
      increment = 20;

    var animateScroll = function () {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  let easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const getStorageUnitObjectById = (id: string) => {
    if (props.ingredient === undefined) return null;
    for (var unit of props.ingredient.storageUnits) {
      if (unit.id === id) return unit;
    }
    return null;
  };

  const getPurchaseUnitObjectById = (id: string) => {
    if (props.ingredient === undefined) return null;
    for (var unit of props.ingredient.purchaseUnits) {
      if (unit.id === id) return unit;
    }
    return null;
  };

  const getSupplierObjectById = (id: string) => {
    for (var supplier of props.suppliers) {
      if (supplier.id === id) return supplier;
    }
    return null;
  };

  const getPurchaseOptionObjectById = (id: string) => {
    for (var purchaseOption of props.ingredient.purchaseOptions) {
      if (purchaseOption.id === id) return purchaseOption;
    }
    return {
      id: "",
      imageUrl: "",
      name: "",
      purchaseUnitId: "",
      productCode: "",
      supplierId: "",
      price: "",
    };
  };

  const getUnitObjectById = (id: string) => {
    for (var unit of props.ingredient.storageUnits) {
      if (unit.id === id) {
        return unit;
      }
    }
    return {
      id: "",
      unit: "",
      customUnit: "",
      customUnitCorrespondanceValue: "",
      customUnitCorrespondanceUnit: "",
    };
  };

  useEffect(() => {
    props.getSuppliersAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.getSuppliersAction]);

  return (
    <FlexContainer padding="16px">
      <Box
        flex="1"
        alignItems="center"
        flexDirection="column"
        minWidth="0"
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <FlexContainer margin="0 16px 0 0" flexDirection="column">
          <BlockContainer
            sx={{
              width: {
                md: "175px",
                lg: "250px",
                xl: "350px",
              },
              height: {
                md: "175px",
                lg: "250px",
                xl: "350px",
              },
            }}
            border="rgba(0,0,0,0.1) solid 1px"
            justifyContent="center"
            position="relative"
            textAlign="center"
            backgroundColor="white"
          >
            <img
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                margin: "auto",
                position: "absolute",
                top: "0",
                right: "0",
                bottom: "0",
                left: "0",
              }}
              alt=""
              src={imageSelected}
            />
          </BlockContainer>
        </FlexContainer>
        <FlexContainer margin="8px 16px 0 0" alignItems="stretch" width="90%">
          <FlexContainer
            $cursorHover="pointer"
            $backgroundHover="rgba(0,0,0,0.1)"
            alignItems="center"
            justifyContent="center"
            onClick={(e) => scroll(-1)}
          >
            <ArrowBackIosNewIcon />
          </FlexContainer>
          <InlineBlockContainer
            id="image-container"
            overflow="auto hidden"
            width="100%"
            whiteSpace="nowrap"
          >
            <InlineBlockContainer
              height="75px"
              width="75px"
              margin="10px"
              border="rgba(0,0,0,0.1) solid 1px"
              justifyContent="center"
              position="relative"
              textAlign="center"
              backgroundColor="white"
              $cursorHover="pointer"
              onClick={(e) => setImageSelected(props.ingredient?.imageUrl)}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  margin: "auto",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  bottom: "0",
                  left: "0",
                }}
                alt=""
                src={props.ingredient?.imageUrl}
              />
            </InlineBlockContainer>
            {props.ingredient?.purchaseOptions.map((purchaseOption, index) => (
              <InlineBlockContainer
                height="75px"
                width="75px"
                margin="10px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
                $cursorHover="pointer"
                key={index}
                onClick={(e) => setImageSelected(purchaseOption.imageUrl)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={purchaseOption.imageUrl}
                />
              </InlineBlockContainer>
            ))}
          </InlineBlockContainer>
          <FlexContainer
            $cursorHover="pointer"
            $backgroundHover="rgba(0,0,0,0.1)"
            alignItems="center"
            justifyContent="center"
            onClick={(e) => scroll(1)}
          >
            <ArrowForwardIosIcon />
          </FlexContainer>
        </FlexContainer>
      </Box>
      <FlexContainer flex="2">
        <BlockContainer width="100%">
          <Text16 fontWeight="500">Informations générales</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Nom</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.ingredient?.name}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Catégorie</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.ingredient?.category}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">
                Durée d'expiration moyenne
              </Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.averageExpiryDelay !== ""
                  ? props.ingredient?.averageExpiryDelay +
                    " " +
                    props.ingredient?.averageExpiryDelayUnit
                  : ""}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">
                Suggéstion de commande par évènement
              </Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.suggestionPerEvent ? "Oui" : "Non"}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Vous vendez ce produit</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.ingredientForSale ? "Oui" : "Non"}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Unités</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Unités d'achat</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.purchaseUnits
                  .map((purchaseUnit) =>
                    purchaseUnit.unit === "Personnalisée"
                      ? purchaseUnit.customUnit +
                        "(" +
                        purchaseUnit.customUnitCorrespondanceValue +
                        " " +
                        purchaseUnit.customUnitCorrespondanceUnit +
                        ")"
                      : purchaseUnit.unit
                  )
                  .join(", ")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Unités de stockage</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.storageUnits
                  .map((storageUnit) =>
                    storageUnit.unit === "Personnalisée"
                      ? storageUnit.customUnit +
                        "(" +
                        storageUnit.customUnitCorrespondanceValue +
                        " " +
                        storageUnit.customUnitCorrespondanceUnit +
                        ")"
                      : storageUnit.unit
                  )
                  .join(", ")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Unités de production</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.productionUnits
                  .map((productionUnit) =>
                    productionUnit.unit === "Personnalisée"
                      ? productionUnit.customUnit +
                        "(" +
                        productionUnit.customUnitCorrespondanceValue +
                        " " +
                        productionUnit.customUnitCorrespondanceUnit +
                        ")"
                      : productionUnit.unit
                  )
                  .join(", ")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Alertes de stockage</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">
                Unités de stockage globale
              </Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {getStorageUnitObjectById(props.ingredient?.globalStorageUnitId)
                  ?.unit === "Personnalisée"
                  ? getStorageUnitObjectById(
                      props.ingredient?.globalStorageUnitId
                    )?.customUnit
                  : getStorageUnitObjectById(
                      props.ingredient?.globalStorageUnitId
                    )?.unit}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Quantité minimale</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.minimalQuantity +
                  " " +
                  getStorageUnitNameById(
                    props.ingredient,
                    props.ingredient?.globalStorageUnitId
                  )}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Quantité idéale</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.idealQuantity +
                  " " +
                  getStorageUnitNameById(
                    props.ingredient,
                    props.ingredient?.globalStorageUnitId
                  )}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Options d'achat</Text16>
          {props.ingredient?.purchaseOptions.length === 0 && (
            <FlexContainer>
              <Text14 color="rgba(0,0,0,0.5)" textAlign="center" width="100%">
                Aucune option d'achat créée
              </Text14>
            </FlexContainer>
          )}
          {props.ingredient?.purchaseOptions.length > 0 && (
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 3 }}
              columns={{ xs: 1, sm: 1, md: 3 }}
            >
              {props.ingredient?.purchaseOptions.map(
                (purchaseOption, index) => (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    display="flex"
                    justifyContent="center"
                    key={index}
                  >
                    <FlexContainer
                      flexDirection="column"
                      margin="16px 0"
                      elevation={3}
                      border="1px solid rgba(0,0,0,0.1)"
                      $borderRadius="10px"
                      alignItems="center"
                      padding="16px 8px"
                      width="100%"
                    >
                      <BlockContainer
                        width="150px"
                        height="150px"
                        border="rgba(0,0,0,0.1) solid 1px"
                        justifyContent="center"
                        position="relative"
                        textAlign="center"
                        backgroundColor="white"
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            margin: "auto",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                          }}
                          alt=""
                          src={purchaseOption.imageUrl}
                        />
                      </BlockContainer>
                      <Text16 margin="0" textAlign="center" fontWeight="500">
                        {purchaseOption.name}
                      </Text16>
                      <FlexContainer
                        $borderRadius="10px"
                        backgroundColor={CustomTheme.palette.secondary.light}
                        padding="0 8px"
                        margin="0 8px"
                      >
                        <Text14
                          margin="4px 0"
                          lineHeight="unset"
                          color={CustomTheme.palette.secondary.main}
                        >
                          {purchaseOption.productCode}
                        </Text14>
                      </FlexContainer>
                      <Text14
                        margin="0"
                        textAlign="center"
                        color="rgb(107, 114, 128)"
                      >
                        {purchaseOption.price +
                          " €/" +
                          (getPurchaseUnitObjectById(
                            purchaseOption.purchaseUnitId
                          )?.unit === "Personnalisée"
                            ? getPurchaseUnitObjectById(
                                purchaseOption.purchaseUnitId
                              )?.customUnit
                            : getPurchaseUnitObjectById(
                                purchaseOption.purchaseUnitId
                              )?.unit)}
                      </Text14>
                      <Text14
                        margin="0"
                        textAlign="center"
                        color="rgb(107, 114, 128)"
                      >
                        {"Acheté chez: " +
                          getSupplierObjectById(purchaseOption.supplierId)
                            ?.name}
                      </Text14>
                    </FlexContainer>
                  </Grid>
                )
              )}
            </Grid>
          )}

          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Composition (Pour 100g)</Text16>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Allergènes</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>{props.ingredient?.allergies.join(", ")}</Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Énergie (Kj)</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.energyKj +
                  (props.ingredient?.energyKj === "" ||
                  props.ingredient?.energyKj === "N/A"
                    ? ""
                    : " Kj")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Énergie (Kcal)</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.energyKcal +
                  (props.ingredient?.energyKcal === "" ||
                  props.ingredient?.energyKcal === "N/A"
                    ? ""
                    : " Kcal")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Protéines</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.proteins +
                  (props.ingredient?.proteins === "" ||
                  props.ingredient?.proteins === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Glucides</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.carbohydrates +
                  (props.ingredient?.carbohydrates === "" ||
                  props.ingredient?.carbohydrates === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Lipides</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.lipids +
                  (props.ingredient?.lipids === "" ||
                  props.ingredient?.lipids === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Sucres</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.sugar +
                  (props.ingredient?.sugar === "" ||
                  props.ingredient?.sugar === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Acides gras saturés</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.saturatedFattyAcids +
                  (props.ingredient?.saturatedFattyAcids === "" ||
                  props.ingredient?.saturatedFattyAcids === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Sel</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.salt +
                  (props.ingredient?.salt === "" ||
                  props.ingredient?.salt === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Calcium</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.calcium +
                  (props.ingredient?.calcium === "" ||
                  props.ingredient?.calcium === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Vitamine D</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.vitaminD +
                  (props.ingredient?.vitaminD === "" ||
                  props.ingredient?.vitaminD === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Fibres alimentaires</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.dietaryFiber +
                  (props.ingredient?.dietaryFiber === "" ||
                  props.ingredient?.dietaryFiber === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <FlexContainer>
            <FlexContainer flex="1">
              <Text14 color="rgb(107, 114, 128)">Cholestérol</Text14>
            </FlexContainer>
            <FlexContainer flex="2">
              <Text14>
                {props.ingredient?.cholesterol +
                  (props.ingredient?.cholesterol === "" ||
                  props.ingredient?.cholesterol === "N/A"
                    ? ""
                    : " g")}
              </Text14>
            </FlexContainer>
          </FlexContainer>
          <Divider sx={{ margin: "8px" }} />
          <Text16 fontWeight="500">Lots et espaces de stockages</Text16>
          {props.ingredient?.storageAreas.length === 0 && (
            <FlexContainer>
              <Text14 color="rgba(0,0,0,0.5)" textAlign="center" width="100%">
                Aucun lot créé
              </Text14>
            </FlexContainer>
          )}
          {mockStorageAreas.map((mockStorageArea, index) => (
            <Fragment key={index}>
              {props.ingredient?.storageAreas.filter(
                (storageArea) =>
                  storageArea.storageAreaId === mockStorageArea.id
              ).length !== 0 && (
                <FlexContainer flexDirection="column">
                  <Text16 fontWeight="500" color="rgb(107, 114, 128)">
                    {mockStorageArea.name}
                  </Text16>
                  <Grid
                    container
                    spacing={{ xs: 1, sm: 1, md: 3 }}
                    columns={{ xs: 1, sm: 1, md: 3 }}
                  >
                    {props.ingredient?.storageAreas.map(
                      (storageArea, index) =>
                        storageArea.storageAreaId === mockStorageArea.id && (
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            md={1}
                            display="flex"
                            justifyContent="center"
                            key={index}
                          >
                            <FlexContainer
                              flexDirection="column"
                              margin="16px 0"
                              elevation={3}
                              border="1px solid rgba(0,0,0,0.1)"
                              $borderRadius="10px"
                              alignItems="center"
                              padding="16px 8px"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <BlockContainer alignItems="center">
                                <BlockContainer
                                  width="150px"
                                  height="150px"
                                  border="rgba(0,0,0,0.1) solid 1px"
                                  justifyContent="center"
                                  position="relative"
                                  textAlign="center"
                                  backgroundColor="white"
                                  margin="auto"
                                >
                                  <img
                                    style={{
                                      maxWidth: "100%",
                                      maxHeight: "100%",
                                      margin: "auto",
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                      bottom: "0",
                                      left: "0",
                                    }}
                                    alt=""
                                    src={
                                      getPurchaseOptionObjectById(
                                        storageArea.productId
                                      ).imageUrl
                                    }
                                  />
                                </BlockContainer>
                                <Text16
                                  textAlign="center"
                                  fontWeight="500"
                                  margin="0"
                                >
                                  {
                                    getPurchaseOptionObjectById(
                                      storageArea.productId
                                    ).name
                                  }
                                </Text16>
                              </BlockContainer>
                              <FlexContainer
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <FlexContainer
                                  $borderRadius="10px"
                                  backgroundColor={
                                    CustomTheme.palette.primary.light
                                  }
                                  alignSelf="center"
                                  padding="16px"
                                  flexDirection="column"
                                  alignItems="center"
                                >
                                  <Text36
                                    color={CustomTheme.palette.primary.main}
                                    margin="4px 10px 0 10px"
                                  >
                                    {storageArea.quantity}
                                  </Text36>
                                  <Text16
                                    color={CustomTheme.palette.primary.main}
                                    margin="4px 10px 0 10px"
                                  >
                                    {getUnitObjectById(
                                      storageArea.storageUnitId
                                    ).unit === "Personnalisée"
                                      ? getUnitObjectById(
                                          storageArea.storageUnitId
                                        ).customUnit
                                      : getUnitObjectById(
                                          storageArea.storageUnitId
                                        ).unit}
                                  </Text16>
                                </FlexContainer>
                                {storageArea.expirationDate !== null && (
                                  <FlexContainer
                                    width="100%"
                                    justifyContent="center"
                                  >
                                    <Text16 margin="0px 0 4px 10px">
                                      D.E:
                                    </Text16>
                                    <Text16
                                      color={CustomTheme.palette.secondary.main}
                                      fontWeight="500"
                                      margin="0px 10px 4px 4px"
                                    >
                                      {dayjs(storageArea.expirationDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </Text16>
                                  </FlexContainer>
                                )}
                              </FlexContainer>
                            </FlexContainer>
                          </Grid>
                        )
                    )}
                  </Grid>
                </FlexContainer>
              )}
            </Fragment>
          ))}
        </BlockContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export const Informations = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_Informations);

export default _Informations;
