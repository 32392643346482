import { Invoice } from "../../models/invoice";
import { Template } from "../../models/template";

export const GET_INVOICES = "GET_INVOICES" as const;
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS" as const;
export const GET_INVOICES_FAILURE = "GET_INVOICES_FAILURE" as const;

export const GET_INVOICE_TEMPLATES = "GET_INVOICE_TEMPLATES" as const;
export const GET_INVOICE_TEMPLATES_SUCCESS = "GET_INVOICE_TEMPLATES_SUCCESS" as const;
export const GET_INVOICE_TEMPLATES_FAILURE = "GET_INVOICE_TEMPLATES_FAILURE" as const;

export const getInvoicesAction = (input: string, field?: string) => ({
  type: GET_INVOICES,
  input: input,
  field: field,
});
export type GetInvoicesAction = ReturnType<typeof getInvoicesAction>;

export const getInvoicesActionSuccess = (invoices: Invoice[]) => ({
  type: GET_INVOICES_SUCCESS,
  invoices: invoices,
});
export type GetInvoicesActionSuccess = ReturnType<
  typeof getInvoicesActionSuccess
>;

export const getInvoicesActionFailure = () => ({
  type: GET_INVOICES_FAILURE,
});
export type GetInvoicesActionFailure = ReturnType<
  typeof getInvoicesActionFailure
>;


export const getInvoiceTemplatesAction = () => ({
  type: GET_INVOICE_TEMPLATES
});
export type GetInvoiceTemplatesAction = ReturnType<typeof getInvoiceTemplatesAction>;

export const getInvoiceTemplatesActionSuccess = (templates: Template[]) => ({
  type: GET_INVOICE_TEMPLATES_SUCCESS,
  templates: templates
});
export type GetInvoiceTemplatesActionSuccess = ReturnType<
  typeof getInvoiceTemplatesActionSuccess
>;

export const getInvoiceTemplatesActionFailure = () => ({
  type: GET_INVOICE_TEMPLATES_FAILURE,
});
export type GetInvoiceTemplatesActionFailure = ReturnType<
  typeof getInvoiceTemplatesActionFailure
>;