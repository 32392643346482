import { RootAction } from "../types";
import { GET_SALES_SUCCESS} from "./actions";
import { SaleState } from "./types";

const initialState: SaleState = {
  sales: [],
};

export function saleReducer(
  state: SaleState = initialState,
  action: RootAction
): SaleState {
  switch (action.type) {
    case GET_SALES_SUCCESS:
      return {
        sales: action.sales
      };

    default:
      return state;
  }
}
