import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CartItem } from "../../../../models/cartItem";
import { AxiosHttpClient } from "../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../store";
import { getCartItemsAction } from "../../../../store/Cart/actions";
import { getCartItems } from "../../../../store/selectors";
import { ButtonMui } from "../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Image } from "../../../../styles/Image";
import { Text14, Text16, Text20 } from "../../../../styles/Text";
import { TextFieldMui } from "../../../../styles/TextFieldCustom";
import { CustomTheme } from "../../../../styles/Theme";
import { ThemeCustom } from "../../../../styles/Utils";
import { toFixed2 } from "../../../Reusable/Utils";

export interface CartProps {
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  cartItems: CartItem[];
  getCartItemsAction: typeof getCartItemsAction;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
    "& .Mui-active": { color: CustomTheme.palette.secondary.main },
    "& .Mui-completed": { color: CustomTheme.palette.secondary.main },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "1200px",
    maxWidth: "1200px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
  tooltip: {
    background: theme.palette.primary.main,
  },
  arrow: {
    "&::before": {
      background: theme.palette.primary.main,
    },
  },
}));

const _Cart: React.FC<CartProps> = ({
  popupActive,
  setPopupActive,
  cartItems,
  getCartItemsAction,
}) => {
  const editCart = () => {
    AxiosHttpClient.put("api/v1/weeventpro/order/cart/edit", cartItemsTmp)
      .then(() => {
        setPopupActive(false);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };
  const deleteCart = () => {
    AxiosHttpClient.delete("api/v1/weeventpro/order/cart/delete")
      .then(() => {
        getCartItemsAction();
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const addSupplierOrder = () => {
    AxiosHttpClient.post("api/v1/weeventpro/order/supplierOrder/add", {
      id: "",
      label: label,
      date: "",
      price: getTotalPrice(),
      ingredientQuantityList: cartItemsTmp
        .filter((cartItem, index) => {
          return cartItem.checked;
        })
        .map((cartItem) => {
          return {
            ingredientName: cartItem.ingredientName,
            unit: cartItem.unit,
            quantity: cartItem.quantity,
          };
        }),
    })
      .then(() => {
        setPopupActive(false);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const sendOrderToSupplier = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/order/cart/send",
      cartItemsTmp
        .filter((cartItem, index) => {
          return cartItem.checked;
        })
        .map((cartItem) => {
          return {
            ingredientName: cartItem.ingredientName,
            unit: cartItem.unit,
            quantity: cartItem.quantity,
          };
        })
    )
      .then(() => {
        setPopupActive(false);
      })
      .catch((err) => {
        //SetaddFailed(err.cause);
      });
  };

  const [label, setLabel] = useState("");

  const [cartItemsTmp, setCartItemsTmp] = useState(cartItems);

  //const [addFailed, SetaddFailed] = useState("");

  const commify = (n: number) => {
    var parts = toFixed2(n).toString().split(".");
    const numberPart = parts[0];
    const decimalPart = parts[1];
    const thousands = /\B(?=(\d{3})+(?!\d))/g;
    return (
      numberPart.replace(thousands, " ") +
      (decimalPart ? "." + decimalPart : "")
    );
  };

  const getTotalPrice = () => {
    let totalPrice = 0;
    for (var cartItem of cartItemsTmp.filter((cartItemToFilter, index) => {
      return cartItemToFilter.checked;
    })) {
      totalPrice += cartItem.price * cartItem.quantity;
    }
    return totalPrice;
  };

  useEffect(() => {
    getCartItemsAction();
  }, [getCartItemsAction]);

  useEffect(() => {
    setCartItemsTmp(cartItems);
  }, [cartItems]);

  const classes = useStyles();

  return (
    <Dialog
      open={popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Votre panier"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent style={{ position: "relative" }} dividers>
        {cartItems.length === 0 && (
          <FlexContainer
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              height="180px"
              width="180px"
              background={
                "url(https://" +
                process.env.REACT_APP_BUCKET_NAME! +
                ".s3.eu-west-3.amazonaws.com/icons/cart1.png)"
              }
            />
            <Text16>Votre panier est vide</Text16>
          </FlexContainer>
        )}

        {cartItems.length !== 0 && (
          <FlexContainer flexDirection="column">
            <TextFieldMui
              id="label"
              label="Libellé"
              style={{ margin: "0 0 25px 0", width: "90%" }}
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Grid
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {cartItemsTmp.map((cartItem, index) => (
                  <Grid item xs={2} sm={4} md={4} key={cartItem.ingredientName}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={cartItem.checked}
                            onClick={(e) => {
                              let newArr = [...cartItemsTmp];
                              newArr[index]["checked"] =
                                !cartItemsTmp[index]["checked"];
                              setCartItemsTmp(newArr);
                            }}
                          />
                        }
                        label={
                          <FlexContainer alignItems="center">
                            <FlexContainer flex="1">
                              <TextField
                                id="outlined-required"
                                defaultValue={cartItem.quantity}
                                value={cartItem.quantity}
                                onChange={(e) => {
                                  let newArr = [...cartItemsTmp];
                                  newArr[index]["quantity"] = Number(
                                    e.target.value
                                  );
                                  setCartItemsTmp(newArr);
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {cartItem.unit}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FlexContainer>
                            <FlexContainer flex="1">
                              <Text14>{cartItem.ingredientName}</Text14>
                            </FlexContainer>
                          </FlexContainer>
                        }
                      />
                    </FormGroup>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <div style={{ position: "absolute", bottom: "5%", right: "8%" }}>
              <Text20>Coût prévisionnel: {commify(getTotalPrice())} €</Text20>
            </div>
          </FlexContainer>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonMui
          onClick={() => {
            addSupplierOrder();
            sendOrderToSupplier();
            deleteCart();
          }}
          disabled={cartItemsTmp.length === 0}
          color="primary"
          variant="contained"
          size="large"
        >
          Commander
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            deleteCart();
          }}
          disabled={cartItemsTmp.length === 0}
          color="primary"
          variant="contained"
          size="large"
        >
          Vider le panier
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            editCart();
          }}
          disabled={cartItemsTmp.length === 0}
          color="primary"
          variant="contained"
          size="large"
        >
          Modifier
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
        >
          Retour
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const Cart = connect(
  (state: RootState) => ({
    cartItems: getCartItems(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getCartItemsAction: getCartItemsAction,
      },
      dispatch
    )
)(_Cart);

export default Cart;
