import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Alert, Collapse, Grid, IconButton, TextField } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { AssociatedContact } from "../../../../../../models/client";
import { Dispatch, RootState } from "../../../../../../store";
import { getClientsAction } from "../../../../../../store/Crm/actions";
import { getClients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import validator from "validator";

const Input = styled("input")({
  display: "none",
});

export interface AssociatedContactsProps {
  inputContacts: AssociatedContact[];
  setInputContacts: React.Dispatch<React.SetStateAction<AssociatedContact[]>>;
}

const _AssociatedContacts: React.FC<AssociatedContactsProps> = (props) => {
  const [sellOptionAddActive, setContactAddActive] = useState(false);

  const [sellOptionEditActive, setContactEditActive] = useState("");

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    "https://" +
      process.env.REACT_APP_BUCKET_NAME! +
      ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
  );
  const [inputFirstName, setInputFirstName] = useState("");

  const [inputFirstNameError, setInputFirstNameError] = useState(false);

  const [inputLastName, setInputLastName] = useState("");

  const [inputLastNameError, setInputLastNameError] = useState(false);

  const [inputEmail, setInputEmail] = useState("");

  const [inputPhoneNumber, setInputPhoneNumber] = useState("");

  const [inputJobTitle, setInputJobTitle] = useState("");

  const [inputEmailError, setInputEmailError] = useState(false);

  const [inputPhoneNumberError, setInputPhoneNumberError] = useState(false);

  const [inputJobTitleError, setInputJobTitleError] = useState(false);

  const [productImageHovered, setProductImageHovered] = useState(false);

  const [addFailed, setAddFailed] = useState("");

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputFirstNameError(false);
    setInputLastNameError(false);
    setInputEmailError(false);
    setInputPhoneNumberError(false);
    setInputJobTitleError(false);
  };

  const validateForm = () => {
    setInputFirstNameError(false);
    setInputLastNameError(false);
    setInputEmailError(false);
    setInputPhoneNumberError(false);
    setInputJobTitleError(false);
    let result = true;
    if (inputFirstName === "") {
      setInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      setInputLastNameError(true);
      result = false;
    }
    if (inputEmail !== "" && !validator.isEmail(inputEmail)) {
      setInputEmailError(true);
      result = false;
    }
    if (inputPhoneNumber !== "" && !validator.isMobilePhone(inputPhoneNumber)) {
      setInputPhoneNumberError(true);
      result = false;
    }
    if (inputJobTitle === "") {
      setInputJobTitleError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = () => {
    setInputFirstNameError(false);
    setInputLastNameError(false);
    setInputEmailError(false);
    setInputPhoneNumberError(false);
    setInputJobTitleError(false);
    let result = true;
    if (inputFirstName === "") {
      setInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      setInputLastNameError(true);
      result = false;
    }
    if (inputEmail !== "" && !validator.isEmail(inputEmail)) {
      setInputEmailError(true);
      result = false;
    }
    if (inputPhoneNumber !== "" && !validator.isMobilePhone(inputPhoneNumber)) {
      setInputPhoneNumberError(true);
      result = false;
    }
    if (inputJobTitle === "") {
      setInputJobTitleError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setContactAddActive(true);
            setContactEditActive("");
            setInputFirstName("");
            setInputLastName("");
            setInputEmail("");
            setInputPhoneNumber("");
            setInputJobTitle("");
            setInputImageUrl(
              "https://" +
                process.env.REACT_APP_BUCKET_NAME! +
                ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
            );
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter un contact
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={sellOptionAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setProductImageHovered(true)}
                onMouseLeave={() => setProductImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {productImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="firstName"
                label="Prénom"
                error={inputFirstNameError}
                value={inputFirstName}
                onChange={(e) => setInputFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="lastName"
                label="Nom"
                error={inputLastNameError}
                value={inputLastName}
                onChange={(e) => setInputLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="email"
                label="Email"
                error={inputEmailError}
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="phoneNumber"
                label="N° de téléphone"
                error={inputPhoneNumberError}
                value={inputPhoneNumber}
                onChange={(e) => setInputPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="jobTitle"
                label="Poste"
                error={inputJobTitleError}
                value={inputJobTitle}
                onChange={(e) => setInputJobTitle(e.target.value)}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputContacts([
                  ...props.inputContacts,
                  {
                    id: uuid(),
                    imageUrl: inputImageUrl,
                    firstName: inputFirstName,
                    lastName: inputLastName,
                    email: inputEmail,
                    phoneNumber: inputPhoneNumber,
                    jobTitle: inputJobTitle,
                  },
                ]);
                setContactAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setContactAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputContacts.map((contact, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer>
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={contact.imageUrl}
                />
              </BlockContainer>
              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600">
                  {contact.firstName + " " + contact.lastName}
                </Text16>
                <FlexContainer
                  $borderRadius="10px"
                  backgroundColor={CustomTheme.palette.secondary.light}
                  padding="0 8px"
                  margin="0 8px"
                >
                  <Text14
                    margin="4px 0"
                    lineHeight="unset"
                    color={CustomTheme.palette.secondary.main}
                  >
                    {contact.jobTitle}
                  </Text14>
                </FlexContainer>
                <Text14 margin="8px 10px 4px 10px">
                  {contact.phoneNumber}
                </Text14>
                <Text14 margin="0px 10px 0 10px">{contact.email}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setContactAddActive(false);
                  setContactEditActive(contact.id);
                  setInputImageUrl(contact.imageUrl);
                  setInputFirstName(contact.firstName);
                  setInputLastName(contact.lastName);
                  setInputEmail(contact.email);
                  setInputPhoneNumber(contact.phoneNumber);
                  setInputJobTitle(contact.jobTitle);
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputContacts];
                  newArr.splice(index, 1);
                  props.setInputContacts(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={sellOptionEditActive === contact.id}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  display="flex"
                  justifyContent="center"
                >
                  <BlockContainer
                    width="150px"
                    height="150px"
                    border="rgba(0,0,0,0.1) solid 1px"
                    justifyContent="center"
                    position="relative"
                    textAlign="center"
                    onMouseEnter={() => setProductImageHovered(true)}
                    onMouseLeave={() => setProductImageHovered(false)}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "auto",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                      }}
                      alt=""
                      src={inputImageUrl}
                    />
                    {productImageHovered && (
                      <FlexContainer
                        position="absolute"
                        top="0"
                        left="0"
                        width="150px"
                        height="150px"
                        backgroundColor="rgba(0,0,0,0.5)"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              imagePreviewHandler(e.target.files![0]);
                            }}
                          />
                          <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => {}}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <AddAPhotoIcon
                              sx={{ color: "white", fontSize: "32px" }}
                            />
                          </IconButton>
                        </label>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setInputImageUrl(
                              "https://" +
                                process.env.REACT_APP_BUCKET_NAME! +
                                ".s3.eu-west-3.amazonaws.com/DefaultImages/profil.png"
                            )
                          }
                          size="large"
                          style={{ padding: "4px", zIndex: "1" }}
                        >
                          <DeleteIcon
                            sx={{ color: "white", fontSize: "32px" }}
                          />
                        </IconButton>
                      </FlexContainer>
                    )}
                  </BlockContainer>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="firstName"
                    label="Prénom"
                    error={inputFirstNameError}
                    value={inputFirstName}
                    onChange={(e) => setInputFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="lastName"
                    label="Nom"
                    error={inputLastNameError}
                    value={inputLastName}
                    onChange={(e) => setInputLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="email"
                    label="Email"
                    error={inputEmailError}
                    value={inputEmail}
                    onChange={(e) => setInputEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="phoneNumber"
                    label="N° de téléphone"
                    error={inputPhoneNumberError}
                    value={inputPhoneNumber}
                    onChange={(e) => setInputPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="jobTitle"
                    label="Poste"
                    error={inputJobTitleError}
                    value={inputJobTitle}
                    onChange={(e) => setInputJobTitle(e.target.value)}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setContactAddActive(false);
                  resetValidation();
                  if (validateEditForm()) {
                    let tmpArray = [...props.inputContacts];
                    tmpArray[index] = {
                      id: contact.id,
                      imageUrl: inputImageUrl,
                      firstName: inputFirstName,
                      lastName: inputLastName,
                      email: inputEmail,
                      phoneNumber: inputPhoneNumber,
                      jobTitle: inputJobTitle,
                    };

                    props.setInputContacts(tmpArray);
                    setContactEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setContactEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const AssociatedContacts = connect(
  (state: RootState) => ({
    clients: getClients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getClientsAction: getClientsAction,
      },
      dispatch
    )
)(_AssociatedContacts);

export default _AssociatedContacts;
