import { Typography } from "@mui/material";
import React from "react";
import { BlockContainer } from "../../../styles/BlockContainer";
import { FlexContainer } from "../../../styles/FlexContainer";

export interface PageHeaderProps {
  title: string;
  subTitle: string;
  icon: JSX.Element;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subTitle, icon }) => {
  return (
    <FlexContainer padding="32px">
      <FlexContainer
        padding="16px"
        elevation={1}
        $borderRadius="4px"
        backgroundColor="white"
      >
        {icon}
      </FlexContainer>
      <BlockContainer padding="0 0 0 32px">
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="subtitle2" component="div" color="textSecondary">
          {subTitle}
        </Typography>
      </BlockContainer>
    </FlexContainer>
  );
};

export default PageHeader;
