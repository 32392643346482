import { Invoice } from "../../models/invoice";
import { Template } from "../../models/template";

export const GET_UPFRONT_INVOICES = "GET_UPFRONT_INVOICES" as const;
export const GET_UPFRONT_INVOICES_SUCCESS = "GET_UPFRONT_INVOICES_SUCCESS" as const;
export const GET_UPFRONT_INVOICES_FAILURE = "GET_UPFRONT_INVOICES_FAILURE" as const;

export const GET_UPFRONT_INVOICE_TEMPLATES = "GET_UPFRONT_INVOICE_TEMPLATES" as const;
export const GET_UPFRONT_INVOICE_TEMPLATES_SUCCESS = "GET_UPFRONT_INVOICE_TEMPLATES_SUCCESS" as const;
export const GET_UPFRONT_INVOICE_TEMPLATES_FAILURE = "GET_UPFRONT_INVOICE_TEMPLATES_FAILURE" as const;

export const getUpfrontInvoicesAction = (input: string, field?: string) => ({
  type: GET_UPFRONT_INVOICES,
  input: input,
  field: field,
});
export type GetUpfrontInvoicesAction = ReturnType<typeof getUpfrontInvoicesAction>;

export const getUpfrontInvoicesActionSuccess = (upfrontInvoices: Invoice[]) => ({
  type: GET_UPFRONT_INVOICES_SUCCESS,
  upfrontInvoices: upfrontInvoices,
});
export type GetUpfrontInvoicesActionSuccess = ReturnType<
  typeof getUpfrontInvoicesActionSuccess
>;

export const getUpfrontInvoicesActionFailure = () => ({
  type: GET_UPFRONT_INVOICES_FAILURE,
});
export type GetUpfrontInvoicesActionFailure = ReturnType<
  typeof getUpfrontInvoicesActionFailure
>;


export const getUpfrontInvoiceTemplatesAction = () => ({
  type: GET_UPFRONT_INVOICE_TEMPLATES
});
export type GetUpfrontInvoiceTemplatesAction = ReturnType<typeof getUpfrontInvoiceTemplatesAction>;

export const getUpfrontInvoiceTemplatesActionSuccess = (templates: Template[]) => ({
  type: GET_UPFRONT_INVOICE_TEMPLATES_SUCCESS,
  templates: templates
});
export type GetUpfrontInvoiceTemplatesActionSuccess = ReturnType<
  typeof getUpfrontInvoiceTemplatesActionSuccess
>;

export const getUpfrontInvoiceTemplatesActionFailure = () => ({
  type: GET_UPFRONT_INVOICE_TEMPLATES_FAILURE,
});
export type GetUpfrontInvoiceTemplatesActionFailure = ReturnType<
  typeof getUpfrontInvoiceTemplatesActionFailure
>;