import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish, SaleOption } from "../../../../../models/dish";
import { Employee } from "../../../../../models/employee";
import { Ingredient } from "../../../../../models/ingredients";
import { ExtraOption, Nomenclature } from "../../../../../models/nomenclature";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getIngredientsAction } from "../../../../../store/Stock/actions";
import {
  getDishes,
  getEmployees,
  getIngredients,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ThemeCustom } from "../../../../../styles/Utils";
import { getIngredientByName, getUnitByName } from "../../../../Reusable/Utils";
import MapFields from "./MapFields";
import UploadFile from "./UploadFile";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

export interface ImportDishesProps {
  inputSearch: string;
  popupActive: boolean;
  dishes: Dish[];
  employees: Employee[];
  ingredients: Ingredient[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getDishesAction: typeof getDishesAction;
  getEmployeesAction: typeof getEmployeesAction;
  getIngredientsAction: typeof getIngredientsAction;
}

const _ImportDishes: React.FC<ImportDishesProps> = (props) => {
  const addDishes = () => {
    AxiosHttpClient.post(
      "api/v1/weeventpro/catalog/multiple",
      constructedDishes
    )
      .then(() => {
        props.getDishesAction(props.inputSearch);
        props.setPopupActive(false);
      })
      .catch((err) => {});
  };

  const allergies = [
    "Arachides",
    "Céleri",
    "Crustacés",
    "Fruits à coques",
    "Gluten",
    "Lait",
    "Lupins",
    "Mollusques",
    "Moutarde",
    "Oeufs",
    "Poissons",
    "Soja",
    "Sulfites",
    "Sésame",
  ];

  const getConstructedDishes = () => {
    let errors: string[] = [];
    let dishes: Dish[] = [];

    for (let i = 1; i < fetchedValues.length; i++) {
      if (
        !["Entrée", "Plat", "Dessert", "Boisson", "Appéritif"].includes(
          getValue("Catégorie", i)
        )
      )
        errors.push(
          "L'ingrédient " +
            getValue("Nom", i) +
            ": La valeur " +
            getValue("Catégorie", i) +
            " spécifiée pour la catégorie est incorrecte!"
        );

      getValue("Cuisiniers", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(",")
        .forEach((employeeFullName) => {
          if (
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
            errors.push("Employé inconnu: " + employeeFullName);
        });

      getValue("Nomenclature", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(";")
        .forEach((nomenclatureItem) => {
          let nomenclatureItemParts = nomenclatureItem.split(",");
          const ingredient = getIngredientByName(
            nomenclatureItemParts[0],
            props.ingredients
          );
          if (nomenclatureItem === "") {
            return;
          }
          if (ingredient === null) {
            errors.push(
              "L'ingrédient: " + nomenclatureItemParts[0] + " n'existe pas"
            );
            return;
          }
          if (nomenclatureItemParts.length !== 3)
            if (!ingredient?.unitBased) {
              errors.push("Format de nomenclature incorrecte");
              return;
            }
          const productionUnit = getUnitByName(
            nomenclatureItemParts[2],
            ingredient.productionUnits,
            ingredient.unitBased
          );
          if (productionUnit === null)
            errors.push(
              "L'unité de production: " +
                nomenclatureItemParts[2] +
                " n'existe pas pour l'ingrédient " +
                nomenclatureItemParts[0]
            );
        });

      getValue("Options/Extras", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(";")
        .forEach((extraOptionItem) => {
          let extraOptionItemParts = extraOptionItem.split(",");
          const ingredient = getIngredientByName(
            extraOptionItemParts[0],
            props.ingredients
          );
          if (extraOptionItem === "") {
            return;
          }
          if (ingredient === null) {
            errors.push(
              "L'ingrédient: " + extraOptionItemParts[0] + " n'existe pas"
            );
            return;
          }
          if (extraOptionItemParts.length !== 4)
            if (!ingredient?.unitBased) {
              errors.push("Format des options/extras incorrecte");
              return;
            }
          const productionUnit = getUnitByName(
            extraOptionItemParts[2],
            ingredient.productionUnits,
            ingredient.unitBased
          );
          if (productionUnit === null)
            errors.push(
              "L'unité de production: " +
                extraOptionItemParts[2] +
                " n'existe pas pour l'ingrédient " +
                extraOptionItemParts[0]
            );
        });

      getValue("Options de vente", i)
        .replace(/^[ \t]+|[ \t]+$/g, "")
        .split(";")
        .forEach((sellOptionItem) => {
          let sellOptionItemParts = sellOptionItem.split(",");
          if (sellOptionItem === "") {
            return;
          }
          if (sellOptionItemParts.length !== 3)
            errors.push("Format des options de vente incorrecte");
        });

      getValue("Allergènes", i)
        .split(",")
        .forEach((allergy) => {
          if (
            !allergies.includes(allergy.replace(/^[ \t]+|[ \t]+$/g, "")) &&
            allergy.replace(/^[ \t]+|[ \t]+$/g, "") !== ""
          )
            errors.push(
              "L'allergene " +
                allergy.replace(/^[ \t]+|[ \t]+$/g, "") +
                " pour l'ingrédient " +
                getValue("Nom", i) +
                " est inconnu"
            );
        });

      let customFieldValues: CustomFieldValue[] = [];

      for (var customField of customFields) {
        if (getValue(customField.name, i) !== "") {
          customFieldValues.push({
            id: uuid(),
            customFieldId: customField.id,
            values: getValue(customField.name, i).split(","),
          });
        }
      }

      dishes.push({
        id: uuid(),
        imageUrl:
          "https://" +
          process.env.REACT_APP_BUCKET_NAME! +
          ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish",
        name: getValue("Nom", i),
        category: getValue("Catégorie", i),
        tags: getValue("Tags", i)
          .split(",")
          .map((tag) => tag.replace(/^[ \t]+|[ \t]+$/g, "")),
        internalCode: getValue("Code interne", i),
        forSale: getValue("En vente", i) === "Oui" ? true : false,
        stored: getValue("Stocké", i) === "Oui" ? true : false,
        preparationTime: getValue("Temps de préparation", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        instructions: getValue("Instructions de cuisson", i),
        chefsIds: getValue("Cuisiniers", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(",")
          .filter((employeeFullName) =>
            props.employees.find(
              (employee) =>
                employee.firstName + " " + employee.lastName ===
                  employeeFullName ||
                employee.lastName + " " + employee.firstName ===
                  employeeFullName
            )
          )
          .map(
            (employeeFullName) =>
              props.employees.find(
                (employee) =>
                  employee.firstName + " " + employee.lastName ===
                    employeeFullName ||
                  employee.lastName + " " + employee.firstName ===
                    employeeFullName
              )!.id
          ),
        productionQuantity: getValue("Quantité de production", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        productionUnit: getValue("Unité de production", i),
        cookingTime: getValue("Temps de cuisson", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        nomenclature: getValue("Nomenclature", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(";")
          .map((nomenclatureItem) => {
            let nomenclatureItemParts = nomenclatureItem.split(",");
            const ingredient = getIngredientByName(
              nomenclatureItemParts[0],
              props.ingredients
            );
            const productionUnit = getUnitByName(
              nomenclatureItemParts.length === 2
                ? ""
                : nomenclatureItemParts[2],
              ingredient!.productionUnits,
              ingredient!.unitBased
            );
            return {
              id: uuid(),
              ingredientId: ingredient!.id,
              quantity: Number(nomenclatureItemParts[1]),
              unitId: productionUnit!.id,
            } as Nomenclature;
          }),
        extraOptions: getValue("Options/Extras", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(";")
          .map((extraOptionItem) => {
            let nomenclatureItemParts = extraOptionItem.split(",");
            const ingredient = getIngredientByName(
              nomenclatureItemParts[0],
              props.ingredients
            );
            const productionUnit = getUnitByName(
              nomenclatureItemParts.length === 3
                ? ""
                : nomenclatureItemParts[2],
              ingredient!.productionUnits,
              ingredient!.unitBased
            );
            return {
              id: uuid(),
              ingredientId: ingredient!.id,
              quantity: nomenclatureItemParts[1],
              unitId: productionUnit!.id,
              price:
                nomenclatureItemParts.length === 4
                  ? nomenclatureItemParts[3]
                  : nomenclatureItemParts[2],
            } as ExtraOption;
          }),
        cookingTemperature: getValue("Température de cuisson", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        saleOptions: getValue("Options de vente", i)
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .split(";")
          .map((sellOptionItem) => {
            let sellOptionItemParts = sellOptionItem.split(",");
            return {
              id: uuid(),
              imageUrl: "",
              name: sellOptionItemParts[0],
              quantity: Number(sellOptionItemParts[1]),
              price: Number(sellOptionItemParts[2]),
              tva: 10,
            } as SaleOption;
          }),
        energyKj: getValue("Énergie (Kj)", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        energyKcal: getValue("Énergie (Kcal)", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        proteins: getValue("Protéines", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        carbohydrates: getValue("Glucides", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        lipids: getValue("Lipides", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        sugar: getValue("Sucres", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        saturatedFattyAcids: getValue("Acides gras saturés", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        salt: getValue("Sel", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        calcium: getValue("Calcium", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        vitaminD: getValue("Vitamine D", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        dietaryFiber: getValue("Fibres alimentaires", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        cholesterol: getValue("Cholestérol", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        allergens: getValue("Allergènes", i)
          .split(",")
          .map((tag) => tag.replace(/^[ \t]+|[ \t]+$/g, "")),
        expiryDelay: getValue("Durée d'expiration", i)
          .replace(",", ".")
          .replace(/^[ \t]+|[ \t]+$/g, "")
          .replace(/[^\d.-]/g, ""),
        customFields: customFieldValues,
        chefsNames: [],
        nomenclatureWithIngredientName: [],
        extraOptionsWithIngredientName: [],
      });
    }
    setOutputErrors(errors);
    setConstructedDishes(dishes);
  };

  const readImportFile = () => {
    const data = new FormData();
    data.append("importFile", inputTemplate);

    AxiosHttpClient.put<string[][]>(
      "api/v1/weeventpro/stock/dishes/readImportFile",
      data
    )
      .then((res) => {
        setFetchedValues(res);
      })
      .catch((err) => {});
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/dish"
    ).then((response) => {
      setCustomFields(response);
    });
  };

  const getValue = (fieldName: string, i: number) => {
    let headers = fetchedValues[0];
    let indexOfField = headers.indexOf(fieldName);
    return indexOfField === -1 ? "" : fetchedValues[i][indexOfField];
  };

  const validateFirstStep = () => {
    if (inputTemplate === null) {
      setFailMessage("Veuillez sélectionner un fichier!");
      return false;
    }
    return true;
  };

  const validateSecondStep = () => {
    if (outputErrors.length > 0) {
      setFailMessage(
        "Fichier non valide. Veuillez corriger les erreurs spécifiées ci-dessous et recommencer!"
      );
      return false;
    }
    return true;
  };

  const [constructedDishes, setConstructedDishes] = useState<Dish[]>([]);

  const [inputTemplate, setInputTemplate] = useState<any>(null);

  const [fetchedValues, setFetchedValues] = useState<string[][]>([[]]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [step, setStep] = useState(0);

  const [importDataFromDB, setImportDataFromDB] = useState(true);

  const [outputErrors, setOutputErrors] = useState<string[]>([]);

  const [failMessage, setFailMessage] = useState("");

  const classes = useStyles();

  useEffect(() => {
    getCustomFields();
    getEmployeesAction("");
    getIngredientsAction("");
  }, []);

  useEffect(() => {
    getConstructedDishes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedValues]);

  return (
    <Dialog
      open={props.popupActive}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(false);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Importer des ingrédients"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              props.setPopupActive(false);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui dividers>
        {failMessage !== "" && (
          <BlockContainer margin="4px">
            <Alert
              severity="error"
              onClose={() => {
                setFailMessage("");
              }}
            >
              {failMessage}
            </Alert>
          </BlockContainer>
        )}
        {step === 0 && (
          <UploadFile
            inputTemplate={inputTemplate}
            importDataFromDB={importDataFromDB}
            setInputTemplate={setInputTemplate}
            setImportDataFromDB={setImportDataFromDB}
          />
        )}
        {step === 1 && (
          <MapFields
            dishes={constructedDishes}
            fetchedValues={fetchedValues}
            outputErrors={outputErrors}
            popupActive={props.popupActive}
            setPopupActive={props.setPopupActive}
          />
        )}
      </DialogContentMui>
      <DialogActions>
        {step === 1 && (
          <ButtonMui
            onClick={() => {
              setStep(step - 1);
              setFailMessage("");
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Précédent
          </ButtonMui>
        )}
        {step === 1 ? (
          <ButtonMui
            onClick={() => {
              if (validateSecondStep()) addDishes();
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Importer
          </ButtonMui>
        ) : (
          <ButtonMui
            onClick={() => {
              if (validateFirstStep()) {
                setFailMessage("");
                readImportFile();
                setStep(step + 1);
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Suivant
          </ButtonMui>
        )}
        <ButtonMui
          onClick={() => props.setPopupActive(false)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const ImportDishes = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
    employees: getEmployees(state),
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
        getEmployeesAction: getEmployeesAction,
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_ImportDishes);

export default ImportDishes;
