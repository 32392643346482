import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { RouteName } from "../../../../../../../models/route";
import { BlockContainer } from "../../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteRouteNameProps {
  popupActive: RouteName | null;
  isDeleteRouteNameLoading: boolean;
  deleteRouteNameFailed: string;
  setIsDeleteRouteNameLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDeleteRouteNameFailed: React.Dispatch<React.SetStateAction<string>>;
  setPopupActive: React.Dispatch<React.SetStateAction<RouteName | null>>;
  deleteRouteName: (routeNameId: string) => void;
}

const DeleteRouteName: React.FC<DeleteRouteNameProps> = (props) => {
  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      {props.deleteRouteNameFailed !== "" && (
        <BlockContainer margin="8px">
          <Alert
            severity="error"
            onClose={() => {
              props.setDeleteRouteNameFailed("");
            }}
          >
            {props.deleteRouteNameFailed}
          </Alert>
        </BlockContainer>
      )}
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer la vue?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer la vue{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={props.isDeleteRouteNameLoading}
          loading={props.isDeleteRouteNameLoading}
          onClick={() => {
            props.deleteRouteName(props.popupActive!.id);
            props.setIsDeleteRouteNameLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => {
            props.setPopupActive(null);
            props.setDeleteRouteNameFailed("");
          }}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRouteName;
