import React from "react";
import { Provider } from "react-redux";
import App from "./components/App";
import { configureStore } from "./store";
import "./index.css";
import "./errorHandler.js";
import { createRoot } from "react-dom/client";

const myStore = configureStore();

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

root.render(
  <Provider store={myStore}>
    <App />
  </Provider>
);

export default myStore;
