import { AxiosHttpClient } from "./AxiosHttpService";

export type JwtObject = {
  accessToken: string;
  refreshToken: string;
};

export class SigninService {
  static signIn = (request: any) => {
    return AxiosHttpClient.postForNonAuth<JwtObject>(
      "api/v1/weeventpro/user/authenticate",
      request
    );
  };
}
