import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { SaleOption } from "../../../../../../models/dish";
import { Dispatch, RootState } from "../../../../../../store";
import { getSuppliersAction } from "../../../../../../store/Stock/actions";
import { getSuppliers } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { UserParameter } from "../../../../../../models/userParameters";
import {
  GLOBAL_TVA,
  getUserParameterValueByName,
  toFixed2,
} from "../../../../../Reusable/Utils";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";

const Input = styled("input")({
  display: "none",
});

export interface SellOptionsProps {
  inputSellOptions: SaleOption[];
  inputSellUnit: string;
  inputImageUrl: any;
  setInputSellOptions: React.Dispatch<React.SetStateAction<SaleOption[]>>;
}

const _SellOptions: React.FC<SellOptionsProps> = (props) => {
  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames: GLOBAL_TVA,
    }).then((res) => {
      setInputGlobalTVA(getUserParameterValueByName(GLOBAL_TVA, res));
    });
  };

  const [sellOptionAddActive, setSellOptionAddActive] = useState(false);

  const [sellOptionEditActive, setSellOptionEditActive] = useState("");

  const [inputImageUrl, setInputImageUrl] = useState<any>(props.inputImageUrl);

  const [inputSellOptionName, setInputSellOptionName] = useState("");

  const [inputSellOptionNameError, setInputSellOptionNameError] =
    useState(false);

  const [inputQuantity, setInputQuantity] = useState("");

  const [inputPrice, setInputPrice] = useState("");

  const [inputTVA, setInputTVA] = useState("");

  const [inputGlobalTVA, setInputGlobalTVA] = useState("");

  const [inputQuantityError, setInputQuantityError] = useState(false);

  const [inputPriceError, setInputPriceError] = useState(false);

  const [inputTVAError, setInputTVAError] = useState(false);

  const [productImageHovered, setProductImageHovered] = useState(false);

  const [addFailed, setAddFailed] = useState("");

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputSellOptionNameError(false);
  };

  const validateForm = () => {
    if (
      props.inputSellOptions.some(
        (purchaseOption) => purchaseOption.name === inputSellOptionName
      )
    ) {
      setAddFailed(
        "L'option de vente \"" + inputSellOptionName + '" existe déjà!'
      );
      return false;
    }
    setInputSellOptionNameError(false);
    setInputQuantityError(false);
    setInputPriceError(false);
    setInputTVAError(false);
    let result = true;
    if (inputSellOptionName === "") {
      setInputSellOptionNameError(true);
      result = false;
    }
    if (inputQuantity === "") {
      setInputQuantityError(true);
      result = false;
    }
    if (inputPrice === "") {
      setInputPriceError(true);
      result = false;
    }
    if (inputTVA === "") {
      setInputTVAError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (sellOption: SaleOption) => {
    if (sellOption.name !== inputSellOptionName) {
      if (
        props.inputSellOptions.some(
          (sellOption) => sellOption.name === inputSellOptionName
        )
      ) {
        setAddFailed(
          "L'option de vente \"" + inputSellOptionName + '" existe déjà!'
        );
        return false;
      }
    }
    setInputSellOptionNameError(false);
    setInputQuantityError(false);
    setInputPriceError(false);
    setInputTVAError(false);
    let result = true;
    if (inputSellOptionName === "") {
      setInputSellOptionNameError(true);
      result = false;
    }
    if (inputQuantity === "") {
      setInputQuantityError(true);
      result = false;
    }
    if (inputPrice === "") {
      setInputPriceError(true);
      result = false;
    }
    if (inputTVA === "") {
      setInputTVAError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            setSellOptionAddActive(true);
            setSellOptionEditActive("");
            setInputSellOptionName("");
            setInputQuantity("");
            setInputPrice("");
            setInputTVA(inputGlobalTVA);
            setInputImageUrl(props.inputImageUrl);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une option de vente
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={sellOptionAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setProductImageHovered(true)}
                onMouseLeave={() => setProductImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {productImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="productame"
                label="Intitulé"
                error={inputSellOptionNameError}
                value={inputSellOptionName}
                onChange={(e) => setInputSellOptionName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="quantity"
                label="Quantité"
                error={inputQuantityError}
                value={inputQuantity}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputQuantity(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {props.inputSellUnit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="price"
                label="Prix de vente"
                error={inputPriceError}
                value={inputPrice}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputPrice(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      €/{props.inputSellUnit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="tva"
                label="TVA"
                error={inputTVAError}
                value={inputTVA}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputTVA(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputSellOptions([
                  ...props.inputSellOptions,
                  {
                    id: uuid(),
                    imageUrl: inputImageUrl,
                    name: inputSellOptionName,
                    quantity: Number(inputQuantity),
                    price: Number(inputPrice),
                    tva: Number(inputTVA),
                  },
                ]);
                setSellOptionAddActive(false);
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              setSellOptionAddActive(false);
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputSellOptions.map((sellOption, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer>
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={sellOption.imageUrl}
                />
              </BlockContainer>
              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600">{sellOption.name}</Text16>
                <FlexContainer
                  $borderRadius="10px"
                  backgroundColor={CustomTheme.palette.secondary.light}
                  padding="0 8px"
                  margin="0 8px"
                >
                  <Text14
                    margin="4px 0"
                    lineHeight="unset"
                    color={CustomTheme.palette.secondary.main}
                  >
                    {sellOption.quantity + " " + props.inputSellUnit}
                  </Text14>
                </FlexContainer>
                <Text14 margin="0px 10px 4px 10px">
                  {"Prix de vente: " + sellOption.price + " €"}
                </Text14>
                <Text14 margin="4px 10px 0 10px">
                  {"TVA: " + sellOption.tva + "%"}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  setSellOptionAddActive(false);
                  setSellOptionEditActive(sellOption.name);
                  setInputImageUrl(sellOption.imageUrl);
                  setInputSellOptionName(sellOption.name);
                  setInputQuantity(toFixed2(sellOption.quantity));
                  setInputPrice(toFixed2(sellOption.price));
                  setInputTVA(toFixed2(sellOption.tva));
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputSellOptions];
                  newArr.splice(index, 1);
                  props.setInputSellOptions(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={sellOptionEditActive === sellOption.name}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  display="flex"
                  justifyContent="center"
                >
                  <BlockContainer
                    width="150px"
                    height="150px"
                    border="rgba(0,0,0,0.1) solid 1px"
                    justifyContent="center"
                    position="relative"
                    textAlign="center"
                    onMouseEnter={() => setProductImageHovered(true)}
                    onMouseLeave={() => setProductImageHovered(false)}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "auto",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                      }}
                      alt=""
                      src={inputImageUrl}
                    />
                    {productImageHovered && (
                      <FlexContainer
                        position="absolute"
                        top="0"
                        left="0"
                        width="150px"
                        height="150px"
                        backgroundColor="rgba(0,0,0,0.5)"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              imagePreviewHandler(e.target.files![0]);
                            }}
                          />
                          <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => {}}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <AddAPhotoIcon
                              sx={{ color: "white", fontSize: "32px" }}
                            />
                          </IconButton>
                        </label>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setInputImageUrl(
                              "https://" +
                                process.env.REACT_APP_BUCKET_NAME! +
                                ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                            )
                          }
                          size="large"
                          style={{ padding: "4px", zIndex: "1" }}
                        >
                          <DeleteIcon
                            sx={{ color: "white", fontSize: "32px" }}
                          />
                        </IconButton>
                      </FlexContainer>
                    )}
                  </BlockContainer>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="productame"
                    label="Intitulé"
                    error={inputSellOptionNameError}
                    value={inputSellOptionName}
                    onChange={(e) => setInputSellOptionName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="quantity"
                    label="Quantité"
                    value={inputQuantity}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputQuantity(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {props.inputSellUnit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="price"
                    label="Prix de vente"
                    value={inputPrice}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputPrice(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          €/{props.inputSellUnit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="tva"
                    label="TVA"
                    value={inputTVA}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputTVA(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  setSellOptionAddActive(false);
                  resetValidation();
                  if (validateEditForm(sellOption)) {
                    let tmpArray = [...props.inputSellOptions];
                    tmpArray[index] = {
                      id: sellOption.id,
                      imageUrl: inputImageUrl,
                      name: inputSellOptionName,
                      quantity: Number(inputQuantity),
                      price: Number(inputPrice),
                      tva: Number(inputTVA),
                    };

                    props.setInputSellOptions(tmpArray);
                    setSellOptionEditActive("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  setSellOptionEditActive("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const SellOptions = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_SellOptions);

export default _SellOptions;
