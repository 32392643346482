import { Paper, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddIcon from "@mui/icons-material/Add";
import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Template } from "../../../../../models/template";
import { Dispatch, RootState } from "../../../../../store";
import { getQuoteTemplatesAction } from "../../../../../store/Quote/actions";
import { getQuoteTemplates } from "../../../../../store/selectors";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ContentContainerMui } from "../../../../../styles/ContentContainerMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import AddQuoteTemplate from "./AddQuoteTemplate";
import DeleteQuoteTemplate from "./DeleteQuoteTemplate";
import TemplateItem from "./TemplateItem";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

function descendingComparator<T>(a: T, b: T) {
  if (a["name"] === "Default") {
    return -1;
  }
  if (b["name"] < a["name"]) {
    return 1;
  }
  if (b["name"] > a["name"]) {
    return -1;
  }
  return 0;
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = descendingComparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface QuoteTemplateListProps {
  templates: Template[];
  getQuoteTemplatesAction: typeof getQuoteTemplatesAction;
}

const _QuoteTemplateList: React.FC<QuoteTemplateListProps> = ({
  templates,
  getQuoteTemplatesAction,
}) => {
  const classes = useStyles();

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState("");

  useEffect(() => {
    getQuoteTemplatesAction();
  }, [getQuoteTemplatesAction]);

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter un template</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <ContentContainerMui>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {stableSort(templates, (a, b) => descendingComparator(a, b)).map(
              (template) => (
                <Grid item xs={2} sm={4} md={4} key={template.label}>
                  <TemplateItem
                    template={template}
                    setPopupDeleteActive={setPopupDeleteActive}
                  />
                </Grid>
              )
            )}
          </Grid>
        </Box>
      </ContentContainerMui>

      {popupAddActive && (
        <AddQuoteTemplate
          setPopupActive={setPopupAddActive}
          popupActive={popupAddActive}
        />
      )}
      <DeleteQuoteTemplate
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
    </Paper>
  );
};

export const QuoteTemplateList = connect(
  (state: RootState) => ({
    templates: getQuoteTemplates(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getQuoteTemplatesAction: getQuoteTemplatesAction,
      },
      dispatch
    )
)(_QuoteTemplateList);

export default _QuoteTemplateList;
