import { Sale } from "../models/sale";
import { AxiosHttpClient } from "./AxiosHttpService";

export class SaleService {
  static getSales = (input: any) => {
    return AxiosHttpClient.get<Sale[]>(
      "api/v1/weeventpro/sales",
      input
    );
  };
}
