import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../models/employee";
import { Invoice } from "../../../../../models/invoice";
import { InvoiceEmailTemplate } from "../../../../../models/invoiceEmailTemplate";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  CC_EMAILS,
  getUserParameterValueByName,
} from "../../../../Reusable/Utils";

export interface SendInvoiceProps {
  popupActive: Invoice | null;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<Invoice | null>>;
  getEmployeesAction: typeof getEmployeesAction;
  getInvoicesPage: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "800px",
    width: "95vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
}));

const _SendInvoice: React.FC<SendInvoiceProps> = ({
  popupActive,
  employees,
  getEmployeesAction,
  setPopupActive,
  getInvoicesPage,
}) => {
  const sendInvoice = () => {
    AxiosHttpClient.post<InvoiceEmailTemplate[]>(
      "api/v1/weeventpro/invoices/email",
      {
        invoicesIds: [popupActive?.id],
        to: inputToEmails.map((inputToEmails) =>
          typeof inputToEmails === "string"
            ? inputToEmails
            : inputToEmails.email
        ),
        cc: inputCCEmails.map((inputCCEmail) =>
          typeof inputCCEmail === "string" ? inputCCEmail : inputCCEmail.email
        ),
        subject: inputSubject,
        content: inputContent,
      }
    )
      .then((res) => {
        getInvoicesPage();
        setPopupActive(null);
      })
      .catch((err) => {
        setSendFailed(err.cause);
        setIsLoading(false);
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames: CC_EMAILS,
    }).then((res) => {
      setInputCCEmails(
        getUserParameterValueByName(CC_EMAILS, res)
          .split(",")
          .map((email) => {
            let employee = employees.find(
              (employee) => employee.email === email
            );
            if (employee) return employee;
            return email;
          })
      );
    });
  };

  const getInvoiceEmailTemplates = () => {
    AxiosHttpClient.get<InvoiceEmailTemplate[]>(
      "api/v1/weeventpro/parameters/template/invoice/email"
    )
      .then((res) => {
        setInvoiceEmailTemplates(res);
        if (res && res.length > 0) {
          setInputTemplateId(res[0].id);
        }
      })
      .catch((err) => {});
  };

  const [inputSubject, setInputSubject] = useState("");

  const [inputSubjectError, setInputSubjectError] = useState(false);

  const [inputContent, setInputContent] = useState("");

  const [inputContentError, setInputContentError] = useState(false);

  const [inputCCEmails, setInputCCEmails] = useState<(Employee | string)[]>([]);

  const [inputCCEmailsText, setInputCCEmailsText] = useState("");

  const [inputToEmails, setInputToEmails] = useState<(Employee | string)[]>([
    popupActive!.client ? popupActive!.client.email : "",
  ]);

  const [inputToEmailsText, setInputToEmailsText] = useState("");

  const [inputToError, setInputToError] = useState(false);

  const [inputTemplateId, setInputTemplateId] = useState("");

  const [invoiceEmailTemplates, setInvoiceEmailTemplates] = useState<
    InvoiceEmailTemplate[]
  >([]);

  const [sendFailed, setSendFailed] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();

  const filterEmployeesEmailsOptions = createFilterOptions<Employee>({
    stringify: (option) => option.email,
  });

  const validateForm = () => {
    setInputToError(false);
    setInputSubjectError(false);
    setInputContentError(false);
    let result = true;
    if (inputToEmails.length === 0) {
      setInputToError(true);
      result = false;
    }
    if (inputSubject === "") {
      setInputSubjectError(true);
      result = false;
    }
    if (inputContent === "") {
      setInputContentError(true);
      result = false;
    }
    if (!result) {
      setSendFailed("Champs manquants");
    }
    return result;
  };

  useEffect(() => {
    getInvoiceEmailTemplates();
    getEmployeesAction("");
    getUserParameters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let template: InvoiceEmailTemplate | null = null;
    for (var invoiceEmailTemplate of invoiceEmailTemplates) {
      if (invoiceEmailTemplate.id === inputTemplateId) {
        template = invoiceEmailTemplate;
      }
    }
    if (template) {
      setInputSubject(template.subject);
      setInputContent(template.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputTemplateId]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <div style={{ display: "flex" }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Envoyer par email"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        {sendFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setSendFailed("");
              }}
            >
              {sendFailed}
            </Alert>
          </BlockContainer>
        )}
        <form className={classes.root} autoComplete="off">
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="templateId">Template</InputLabel>
                <Select
                  labelId="templateId"
                  id="templateSelect"
                  value={inputTemplateId}
                  label="Template"
                  onChange={(e) => setInputTemplateId(e.target.value)}
                >
                  {invoiceEmailTemplates.length === 0 && (
                    <MenuItem disabled sx={{ color: "red" }} value="">
                      Aucun template créé
                    </MenuItem>
                  )}
                  {invoiceEmailTemplates.map((invoiceEmailTemplate, index) => (
                    <MenuItem value={invoiceEmailTemplate.id} key={index}>
                      {invoiceEmailTemplate.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete
                id="to"
                filterOptions={filterEmployeesEmailsOptions}
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                getOptionLabel={(option) => {
                  return typeof option === "string" ? option : option.email;
                }}
                options={employees}
                value={inputToEmails}
                onChange={(event, newValue) => {
                  setInputToEmails(newValue!);
                }}
                inputValue={inputToEmailsText}
                onInputChange={(event, newInputValue) => {
                  setInputToEmailsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    error={inputToError}
                    {...params}
                    variant="outlined"
                    name="to"
                    label="À"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <Autocomplete
                id="cc"
                filterOptions={filterEmployeesEmailsOptions}
                noOptionsText={"Pas de suggestions"}
                freeSolo
                fullWidth
                multiple
                filterSelectedOptions
                getOptionLabel={(option) => {
                  return typeof option === "string" ? option : option.email;
                }}
                options={employees}
                value={inputCCEmails}
                onChange={(event, newValue) => {
                  setInputCCEmails(newValue!);
                }}
                inputValue={inputCCEmailsText}
                onInputChange={(event, newInputValue) => {
                  setInputCCEmailsText(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="cc"
                    label="CC"
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="subject"
                label="Objet"
                error={inputSubjectError}
                value={inputSubject}
                onChange={(e) => setInputSubject(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                name="content"
                label="Corps du message"
                multiline
                rows={5}
                error={inputContentError}
                value={inputContent}
                onChange={(e) => setInputContent(e.target.value)}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) sendInvoice();
            else setIsLoading(false);
          }}
          color="primary"
          variant="contained"
          size="large"
        >
          Envoyer
        </ButtonMui>
        <ButtonMui
          onClick={() => {
            setPopupActive(null);
          }}
          color="primary"
          variant="outlined"
          size="large"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const SendInvoice = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_SendInvoice);

export default SendInvoice;
