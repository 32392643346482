import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { View } from "react-native";
import { FlexContainer } from "../../../styles/FlexContainer";

export interface ProgressStateWithLabelsProps {
  firstBreakpoint: number;
  secondBreakpoint: number;
  value: number;
  firstBreakpointText: string;
  secondBreakpointText: string;
  valueText: string;
  unit: string;
  showValueTooltip: boolean;
}

const ProgressStateWithLabels: React.FC<ProgressStateWithLabelsProps> = (
  props
) => {
  const [firstBreakpointHovered, setFirstBreakpointHovered] = useState(false);
  const [secondBreakpointHovered, setSecondBreakpointHovered] = useState(false);

  const totalPoints = 100;

  const totalValue =
    Math.max(props.firstBreakpoint + props.secondBreakpoint, props.value) +
    0.2 * Math.max(props.firstBreakpoint + props.secondBreakpoint, props.value);

  const position = (props.value * totalPoints) / totalValue;

  const minPosition = (totalPoints * props.firstBreakpoint) / totalValue;

  const idealPosition = (totalPoints * props.secondBreakpoint) / totalValue;

  return (
    <FlexContainer sx={{ justifyContent: "center", flex: 1, width: "100%" }}>
      <View style={{ height: 100, width: "100%", justifyContent: "center" }}>
        <FlexContainer sx={{ alignItems: "center", width: "100%" }}>
          <FlexContainer
            sx={{
              height: 20,
              width: Math.min(minPosition, position) + "%",
              background:
                "repeating-linear-gradient(-45deg,rgba(255,0,0,0.8),rgba(255,0,0,0.8) 10px,rgba(255,0,0,0.6) 10px,rgba(255,0,0,0.6) 20px)",
              borderTopLeftRadius: "10px",
              borderBottomLeftRadius: "10px",
            }}
          ></FlexContainer>
          {position < minPosition && (
            <FlexContainer
              sx={{
                height: 20,
                width: minPosition - position + "%",
                backgroundColor: "rgba(255,0,0,0.2)",
              }}
            ></FlexContainer>
          )}
          {position >= minPosition && (
            <FlexContainer
              sx={{
                height: 20,
                width:
                  Math.min(
                    idealPosition - minPosition,
                    position - minPosition
                  ) + "%",
                background:
                  "repeating-linear-gradient(-45deg,rgba(0,128,0,0.8),rgba(0,128,0,0.8) 10px,rgba(0,128,0,0.6) 10px,rgba(0,128,0,0.6) 20px)",
              }}
            ></FlexContainer>
          )}
          {position < idealPosition && (
            <FlexContainer
              sx={{
                height: 20,
                width:
                  Math.min(
                    idealPosition - position,
                    idealPosition - minPosition
                  ) + "%",
                backgroundColor: "rgba(0,128,0,0.2)",
              }}
            ></FlexContainer>
          )}
          {position >= idealPosition && (
            <FlexContainer
              sx={{
                height: 20,
                width: position - idealPosition + "%",
                background:
                  "repeating-linear-gradient(-45deg,rgba(242, 153, 74, 0.8),rgba(242, 153, 74, 0.8) 10px,rgba(242, 153, 74, 0.6) 10px,rgba(242, 153, 74, 0.6) 20px)",
              }}
            ></FlexContainer>
          )}
          <FlexContainer
            sx={{
              height: 20,
              width:
                Math.min(totalPoints - position, totalPoints - idealPosition) +
                "%",
              backgroundColor: "rgba(242, 153, 74, 0.2)",
              borderTopRightRadius: "10px",
              borderBottomRightRadius: "10px",
            }}
          ></FlexContainer>

          <Tooltip title={props.firstBreakpointText} placement="top" arrow>
            <FlexContainer
              $cursorHover="pointer"
              position="absolute"
              top={firstBreakpointHovered ? "32px" : "37px"}
              left={
                "calc(" +
                minPosition.toString() +
                "% - " +
                (firstBreakpointHovered ? "4" : "2") +
                "px)"
              }
              height={firstBreakpointHovered ? "36px" : "26px"}
              width={firstBreakpointHovered ? "8px" : "4px"}
              backgroundColor="black"
              $borderRadius="10px"
              onMouseEnter={() => setFirstBreakpointHovered(true)}
              onMouseLeave={() => setFirstBreakpointHovered(false)}
            ></FlexContainer>
          </Tooltip>
          <Tooltip title={props.secondBreakpointText} placement="top" arrow>
            <FlexContainer
              $cursorHover="pointer"
              position="absolute"
              top={secondBreakpointHovered ? "32px" : "37px"}
              left={
                "calc(" +
                idealPosition.toString() +
                "% - " +
                (secondBreakpointHovered ? "4" : "2") +
                "px)"
              }
              height={secondBreakpointHovered ? "36px" : "26px"}
              width={secondBreakpointHovered ? "8px" : "4px"}
              backgroundColor="black"
              $borderRadius="10px"
              onMouseEnter={() => setSecondBreakpointHovered(true)}
              onMouseLeave={() => setSecondBreakpointHovered(false)}
            ></FlexContainer>
          </Tooltip>
          <Tooltip
            title={props.valueText}
            placement="top"
            open={props.showValueTooltip}
            disableFocusListener
            disableTouchListener
            disableHoverListener
            arrow
            sx={{
              zIndex: "1000",
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  color: "purple",
                  backgroundColor: "white",
                  border: "1px solid black",
                  fontSize: "17px",
                },
              },
              popper: {
                sx: {
                  zIndex: "1000",
                  display: {
                    xs: "none",
                    sm: "block",
                  },
                },
              },
            }}
          >
            <FlexContainer
              $cursorHover="pointer"
              position="absolute"
              top="48px"
              left={position.toString() + "%"}
            ></FlexContainer>
          </Tooltip>
          <FlexContainer
            $cursorHover="pointer"
            position="absolute"
            top="65px"
            left={(minPosition - 5).toString() + "%"}
          >
            {props.firstBreakpoint + " " + props.unit}
          </FlexContainer>
          <FlexContainer
            $cursorHover="pointer"
            position="absolute"
            top="65px"
            left={(idealPosition - 5).toString() + "%"}
          >
            {props.secondBreakpoint + " " + props.unit}
          </FlexContainer>
        </FlexContainer>
      </View>
    </FlexContainer>
  );
};

export default ProgressStateWithLabels;
