import styled from "styled-components";
import { ThemeCustom } from "../Utils";

interface IHeadingStyled {
  textDecorationLine?: string;
  textAlign?: string;
  fontWeight?: string;
  margin?: string;
  display?: string;
  textJustify?: string;
  lineHeight?: string;
  width?: string;
  opacity?: string;
}

export const Text12 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "4px 10px")};
  text-decoration-line: ${(props) =>
    props.textDecorationLine ? props.textDecorationLine : "none"};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text14 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 14px;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "30px")};
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "4px 10px")};
  text-decoration-line: ${(props) =>
    props.textDecorationLine ? props.textDecorationLine : "none"};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text16 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 17px;
  line-height: 30px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "4px 10px")};
  text-decoration-line: ${(props) =>
    props.textDecorationLine ? props.textDecorationLine : "none"};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text20 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 20px;
  line-height: 30px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "4px 10px")};
  text-decoration-line: ${(props) =>
    props.textDecorationLine ? props.textDecorationLine : "none"};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text24 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 24px;
  line-height: 30px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "4px 10px")};
  text-decoration-line: ${(props) =>
    props.textDecorationLine ? props.textDecorationLine : "none"};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text28 = styled.p<IHeadingStyled>`
  font-family: "${ThemeCustom.fonts.primary}";
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 28px;
  line-height: 35px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "16px")};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;

export const Text36 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 36px;
  line-height: 40px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "16px")};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;


export const Text56 = styled.p<IHeadingStyled>`
  font-family: ${ThemeCustom.fonts.primary};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")};
  font-size: 56px;
  line-height: 70px;
  color: ${(props) => (props.color ? props.color : ThemeCustom.colors.black)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  margin: ${(props) => (props.margin ? props.margin : "16px")};
  display: ${(props) => props.display};
  text-justify: ${(props) => props.textJustify};
  width: ${(props) => props.width};
  opacity: ${(props) => props.opacity};
`;
