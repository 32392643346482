import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { isUserSigninSucceed } from "../../store/selectors";
import { Dispatch, RootState } from "../../store/types";
import ContentSection from "./ContentSection";
import { FeatureHeader } from "./FeatureHeader";
import SideNavMenu from "./SideNavMenu";
import { bindActionCreators } from "redux";
import { logOutAction } from "../../store/Signin/actions";
export interface FeatureProps {
  logOutAction: typeof logOutAction;
}

const _Feature: React.FC<FeatureProps> = ({ logOutAction }) => {
  const [sideNavShow, setSideNavShow] = useState(false);
  const authenticated = localStorage.getItem("jwtToken");

  useEffect(() => {
    if (!localStorage.getItem("jwtToken")) logOutAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!authenticated && <Navigate to="/signin" replace />}
      {authenticated && (
        <>
          <SideNavMenu
            sideNavShow={sideNavShow}
            setSideNavShow={setSideNavShow}
          />
          <FeatureHeader
            sideNavShow={sideNavShow}
            setSideNavShow={setSideNavShow}
          />
          <ContentSection sideNavShow={sideNavShow} />
        </>
      )}
    </>
  );
};

export const Feature = connect(
  (state: RootState) => ({
    isUserSigninSucceed: isUserSigninSucceed(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        logOutAction: logOutAction,
      },
      dispatch
    )
)(_Feature);

export default _Feature;
