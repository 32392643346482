import { Client } from "../../models/client";
import { Prospect } from "../../models/prospect";
import { Opportunity } from "../../models/opportunity";

export const GET_CLIENTS = "GET_CLIENTS" as const;
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS" as const;
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE" as const;

export const GET_PROSPECTS = "GET_PROSPECTS" as const;
export const GET_PROSPECTS_SUCCESS = "GET_PROSPECTS_SUCCESS" as const;
export const GET_PROSPECTS_FAILURE = "GET_PROSPECTS_FAILURE" as const;

export const GET_OPPORTUNITIES = "GET_OPPORTUNITIES" as const;
export const GET_OPPORTUNITIES_SUCCESS = "GET_OPPORTUNITIES_SUCCESS" as const;
export const GET_OPPORTUNITIES_FAILURE = "GET_OPPORTUNITIES_FAILURE" as const;

export const getClientsAction = (input: string, field?: string) => ({
  type: GET_CLIENTS,
  input: input,
  field: field,
});
export type GetClientsAction = ReturnType<typeof getClientsAction>;

export const getClientsActionSuccess = (clients: Client[]) => ({
  type: GET_CLIENTS_SUCCESS,
  clients: clients,
});
export type GetClientsActionSuccess = ReturnType<
  typeof getClientsActionSuccess
>;

export const getClientsActionFailure = () => ({
  type: GET_CLIENTS_FAILURE,
});
export type GetClientsActionFailure = ReturnType<
  typeof getClientsActionFailure
>;

export const getProspectsAction = (input: string) => ({
  type: GET_PROSPECTS,
  input: input,
});
export type GetProspectsAction = ReturnType<typeof getProspectsAction>;

export const getProspectsActionSuccess = (prospects: Prospect[]) => ({
  type: GET_PROSPECTS_SUCCESS,
  prospects: prospects,
});
export type GetProspectsActionSuccess = ReturnType<
  typeof getProspectsActionSuccess
>;

export const getProspectsActionFailure = () => ({
  type: GET_PROSPECTS_FAILURE,
});
export type GetProspectsActionFailure = ReturnType<
  typeof getProspectsActionFailure
>;


export const getOpportunitiesAction = (input: string) => ({
  type: GET_OPPORTUNITIES,
  input: input,
});
export type GetOpportunitiesAction = ReturnType<typeof getOpportunitiesAction>;

export const getOpportunitiesActionSuccess = (opportunities: Opportunity[]) => ({
  type: GET_OPPORTUNITIES_SUCCESS,
  opportunities: opportunities,
});
export type GetOpportunitiesActionSuccess = ReturnType<
  typeof getOpportunitiesActionSuccess
>;

export const getOpportunitiesActionFailure = () => ({
  type: GET_OPPORTUNITIES_FAILURE,
});
export type GetOpportunitiesActionFailure = ReturnType<
  typeof getOpportunitiesActionFailure
>;