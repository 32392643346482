import { Paper, TextField, Toolbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ApexOptions } from "apexcharts";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { CustomerOrderArchive } from "../../../../../models/customerOrderArchive";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { ContentContainerMui } from "../../../../../styles/ContentContainerMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../styles/Text";
import {
  commify,
  getCurrentDate,
  getDateAYearAgo,
} from "../../../../Reusable/Utils";
import GainsDetails from "../GainsDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      margin: "0 16px 0 0",
    },
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "45%",
  },
}));

export interface GainsOverviewProps {}

const GainsOverview: React.FC<GainsOverviewProps> = () => {
  const getGainsDetails = () => {
    AxiosHttpClient.get<CustomerOrderArchive[]>(
      "api/v1/weeventpro/statistics/gains/get",
      {
        startDate: inputStartDate,
        endDate: inputEndDate,
      }
    )
      .then((response) => {
        setCustomerOrderArchiveList(response);
      })
      .catch((err) => {});
  };

  const getFoodGainsPrice = () => {
    let total = 0;
    customerOrderArchiveList.forEach(
      (customerOrderArchive) =>
        (total += parseFloat(customerOrderArchive.price))
    );
    return total;
  };

  const getMaterialsGainsPrice = () => {
    return 2000;
  };

  const getOtherGainsPrice = () => {
    return 3000;
  };

  const classes = useStyles();

  const [inputStartDate, setInputStartDate] = useState(
    new Date(getDateAYearAgo()).toISOString()
  );

  const [inputEndDate, setInputEndDate] = useState(
    new Date(getCurrentDate()).toISOString()
  );

  const [popupDetailsActive, setPopupDetailsActive] = useState(false);

  const [hoveredElement, setHoveredElement] = useState("none");

  const [customerOrderArchiveList, setCustomerOrderArchiveList] = useState<
    CustomerOrderArchive[]
  >([]);

  useEffect(() => {
    getGainsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const series = [getFoodGainsPrice(), 2000, 3000];
  const data: ApexOptions = {
    chart: {
      width: 380,
      type: "pie",
      events: {
        dataPointMouseEnter: (event, chartContext, config) => {
          setHoveredElement(config.w.config.labels[config.dataPointIndex]);
          document.body.style.cursor = "pointer";
        },
        dataPointMouseLeave: (event, chartContext, config) => {
          setHoveredElement("none");
          document.body.style.cursor = "default";
        },
        dataPointSelection: (event, chartContext, config) => {
          setPopupDetailsActive(true);
        },
      },
    },
    labels: ["Alimentaire", "Location du matériel", "Autres"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    getGainsDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputStartDate, inputEndDate]);

  return (
    <>
      <Paper className={classes.pageContent}>
        <Toolbar className={classes.root}>
          <FlexContainer
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text16 margin="0 64px 0 0">Choisissez une période</Text16>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Du"
                value={inputStartDate}
                onChange={(value) => setInputStartDate(value!)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Au"
                value={inputEndDate}
                onChange={(value) => setInputEndDate(value!)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </FlexContainer>
        </Toolbar>
      </Paper>
      <ContentContainerMui>
        <FlexContainer width="100%" textAlign="start">
          <FlexContainer flex="1" justifyContent="center">
            <Chart options={data} series={series} type="pie" width={480} />
          </FlexContainer>
          <FlexContainer flex="1">
            <FlexContainer
              flexDirection="column"
              width="100%"
              padding="0 64px 0 64px"
            >
              <FlexContainer width="100%" margin="0 0 16px 0">
                <FlexContainer>
                  <Text16 fontWeight="600">Total:</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(
                      getFoodGainsPrice() +
                        getMaterialsGainsPrice() +
                        getOtherGainsPrice()
                    )}{" "}
                    €
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Alimentaire" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
                onClick={(e) => setPopupDetailsActive(true)}
              >
                <FlexContainer>
                  <Text16>Alimentaire</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getFoodGainsPrice()) + " "}€ (
                    {commify(
                      (getFoodGainsPrice() * 100) /
                        (getFoodGainsPrice() +
                          getMaterialsGainsPrice() +
                          getOtherGainsPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Matériel" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Location du matériel</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getMaterialsGainsPrice()) + " "}€ (
                    {commify(
                      (getMaterialsGainsPrice() * 100) /
                        (getFoodGainsPrice() +
                          getMaterialsGainsPrice() +
                          getOtherGainsPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
              <FlexContainer
                width="100%"
                backgroundColor="white"
                borderLeft="8px solid orange"
                padding="8px 0 8px 16px"
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin={hoveredElement === "Autres" ? "0 0 0 -8px" : "0"}
                marginHover="0 0 0 -8px"
                $cursorHover="pointer"
              >
                <FlexContainer>
                  <Text16>Autres</Text16>
                </FlexContainer>
                <FlexContainer flex="1" justifyContent="flex-end">
                  <Text16 fontWeight="600">
                    {commify(getOtherGainsPrice()) + " "}€ (
                    {commify(
                      (getOtherGainsPrice() * 100) /
                        (getFoodGainsPrice() +
                          getMaterialsGainsPrice() +
                          getOtherGainsPrice())
                    )}
                    %)
                  </Text16>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </ContentContainerMui>
      {popupDetailsActive && (
        <GainsDetails
          customerOrderArchiveList={customerOrderArchiveList}
          inputStartDate={inputStartDate}
          inputEndDate={inputEndDate}
          popupActive={popupDetailsActive}
          setPopupActive={setPopupDetailsActive}
        />
      )}
    </>
  );
};

export default GainsOverview;
