export const ThemeCustom = {
  colors: {
    opaquePink: "rgba(248, 76, 84, 0.2)",
    orange: "#F17707",
    lightOrange: "#F2994A",
    pink: "#F84C54",
    white: "#FFFFFF",
    whiteTransparent: "rgba(255,255,255,0.7)",
    black: "#000000",
    linearButton: "linear-gradient(180deg, #F84C54 0%, #F17707 100%)",
    reverseLinearButton: "linear-gradient(180deg, #F17707 0%, #F84C54 100%)",
    lightGray: "#F9F9F9",
    linearOrange:
      "linear-gradient(180deg, rgba(242, 153, 74, 1) 0%, #F17707 100%)",
    linearGray:
      "linear-gradient(180deg, #E5E5E5 0%, #F6F6F6 32.81%, #FFFFFF 55.73%, #F9F9F9 82.81%, #F4F4F4 100%)",
    gray: "#E5E5E5",
    orangeTableLight: "#FBD6B5",
    popupGray:
      "linear-gradient(180deg, #FFFFFF 0%, #F7F7F7 30.26%, #E5E5E5 100%)",
  },
  fonts: {
    appName: "Dr Sugiyama",
    primary: "Roboto",
  },
};
