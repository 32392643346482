import { RootAction } from "../types";
import { SET_EXPANDED_MENU} from "./actions";
import { ExpandedMenuItemState } from "./types";

const initialState: ExpandedMenuItemState = {
  expanded: "none",
};

export function expandedMenuItemReducer(
  state: ExpandedMenuItemState = initialState,
  action: RootAction
): ExpandedMenuItemState {
  switch (action.type) {
    case SET_EXPANDED_MENU:
      return {
        expanded: action.input
      };

    default:
      return state;
  }
}
