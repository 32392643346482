import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddDocument } from "./AddDocument";
import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import DocumentContent from "./DocumentContent";

export interface DocumentsProps {}

const Documents: React.FC<DocumentsProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Documents"
        subTitle="Liste des documents"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <DocumentContent />
      {popupActive && (
        <AddDocument
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Documents;
