import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { View } from "../../../../../../../models/view";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteViewProps {
  popupActive: View | null;
  isDeleteViewLoading: boolean;
  setIsDeleteViewLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPopupActive: React.Dispatch<React.SetStateAction<View | null>>;
  deleteView: (viewId: string) => void;
}

const DeleteView: React.FC<DeleteViewProps> = (props) => {
  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer la vue?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer la vue{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={props.isDeleteViewLoading}
          loading={props.isDeleteViewLoading}
          onClick={() => {
            props.deleteView(props.popupActive!.id);
            props.setIsDeleteViewLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteView;
