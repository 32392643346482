import { Search } from "@mui/icons-material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-scroll";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  OffResponse,
  Product,
  PurchaseOption,
  Unit,
} from "../../../../../../models/ingredients";
import { Supplier } from "../../../../../../models/suppliers";
import { Dispatch, RootState } from "../../../../../../store";
import { getSuppliers } from "../../../../../../store/selectors";
import { getSuppliersAction } from "../../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import AutocompleteInfiniteScroll from "../../../../../Reusable/AutocompleteInfiniteScroll";
import {
  getUnitNameById,
  getSupplierNameById,
} from "../../../../../Reusable/Utils";

const Input = styled("input")({
  display: "none",
});

export interface PurchaseOptionsProps {
  offSearchInputRef: React.RefObject<HTMLInputElement>;
  purchaseUnitInputRef: React.RefObject<HTMLInputElement>;
  inputPurchaseOptions: PurchaseOption[];
  suppliers: Supplier[];
  inputPurchaseUnits: Unit[];
  inputProduct: Product | null;
  purchaseOptionAddActive: boolean;
  isUnitBased: boolean;
  setInputEnergyKj: React.Dispatch<React.SetStateAction<string>>;
  setInputEnergyKcal: React.Dispatch<React.SetStateAction<string>>;
  setInputProteins: React.Dispatch<React.SetStateAction<string>>;
  setInputCarbohydrates: React.Dispatch<React.SetStateAction<string>>;
  setInputSugar: React.Dispatch<React.SetStateAction<string>>;
  setInputSaturatedFattyAcids: React.Dispatch<React.SetStateAction<string>>;
  setInputSalt: React.Dispatch<React.SetStateAction<string>>;
  setInputCalcium: React.Dispatch<React.SetStateAction<string>>;
  setInputVitaminD: React.Dispatch<React.SetStateAction<string>>;
  setInputDietaryFiber: React.Dispatch<React.SetStateAction<string>>;
  setInputCholesterol: React.Dispatch<React.SetStateAction<string>>;
  setInputLipids: React.Dispatch<React.SetStateAction<string>>;
  setInputProduct: React.Dispatch<React.SetStateAction<Product | null>>;
  setInputAllergies: React.Dispatch<React.SetStateAction<string[]>>;
  setInputPurchaseUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputPurchaseOptions: React.Dispatch<
    React.SetStateAction<PurchaseOption[]>
  >;
  getSuppliersAction: typeof getSuppliersAction;
  setPurchaseUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseOptionAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const _PurchaseOptions: React.FC<PurchaseOptionsProps> = (props) => {
  const [products, setProducts] = useState<Product[]>([]);

  const [inputOffSearch, setInputOffSearch] = useState("");

  const [inputImageUrl, setInputImageUrl] = useState<any>(
    "https://" +
      process.env.REACT_APP_BUCKET_NAME! +
      ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
  );

  const [inputProductName, setInputProductName] = useState("");

  const [inputProductNameError, setInputProductNameError] = useState(false);

  const [inputProductCode, setInputProductCode] = useState("");

  const [purchaseOptionEditActive, setPurchaseOptionEditActive] = useState("");

  const [inputPurchaseUnit, setInputPurchaseUnit] = useState("");

  const [inputPurchaseUnitError, setInputPurchaseUnitError] = useState(false);

  const [inputPurchasePrice, setInputPurchasePrice] = useState("");

  const [inputPurchasePriceError, setInputPurchasePriceError] = useState(false);

  const [productImageHovered, setProductImageHovered] = useState(false);

  const [hasMore, setHasMore] = useState(true);

  const [inputSupplierId, setInputSupplierId] = useState("");

  const [page, setPage] = useState(2);

  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);

  const [inputSearchBy, setInputSearchBy] = useState("productName");

  const [addFailed, setAddFailed] = useState("");

  const [selectPurchaseUnitOpen, setSelectPurchaseUnitOpen] = useState(false);

  const [selectWidth, setSelectWidth] = useState(0);

  const previousController = useRef<AbortController>();

  const getProducts = (page: number) => {
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    if (inputSearchBy === "barcode") {
      axios
        .get<OffResponse>(
          "https://world.openfoodfacts.org/api/v2/search?code=" +
            inputOffSearch +
            "&fields=product_name,code,brands,quantity,allergens,image_front_small_url,energy-kcal_100g,energy-kj_100g,fiber_100g,proteins_100g,saturated-fat_100g,carbohydrates_100g,salt_100g,sugars_100g,calcium_100g,vitamin-d_100g,cholesterol_100g&json=1&page=" +
            page.toString(),
          { signal: signal }
        )
        .then((response) => {
          setTotalNumberOfProducts(Number(response.data.count));
          page === 1
            ? setProducts([
                ...response.data.products.filter(
                  (product) => product.product_name !== undefined
                ),
              ])
            : setProducts([
                ...products,
                ...response.data.products.filter(
                  (product) => product.product_name !== undefined
                ),
              ]);
        })
        .catch(() => {});
    } else {
      axios
        .get<OffResponse>(
          "https://fr.openfoodfacts.org/cgi/search.pl?search_terms=" +
            inputOffSearch +
            "&search_simple=1&action=process&fields=product_name,code,brands,quantity,allergens,image_front_small_url,energy-kcal_100g,energy-kj_100g,fiber_100g,proteins_100g,saturated-fat_100g,carbohydrates_100g,salt_100g,sugars_100g,calcium_100g,vitamin-d_100g,cholesterol_100g&json=1&page=" +
            page.toString(),
          { signal: signal }
        )
        .then((response) => {
          setTotalNumberOfProducts(Number(response.data.count));
          page === 1
            ? setProducts([...response.data.products])
            : setProducts([...products, ...response.data.products]);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    setInputImageUrl(
      props.inputProduct?.image_front_small_url! === undefined
        ? "https://" +
            process.env.REACT_APP_BUCKET_NAME! +
            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
        : props.inputProduct?.image_front_small_url!
    );
    setInputProductName(
      props.inputProduct?.product_name === undefined
        ? ""
        : props.inputProduct?.product_name +
            (props.inputProduct?.brands === undefined
              ? ""
              : " - " + props.inputProduct?.brands.split(",")[0]) +
            (props.inputProduct?.quantity === undefined
              ? ""
              : " - " + props.inputProduct?.quantity)
    );
    setInputProductCode(
      props.inputProduct?.code! === undefined ? "" : props.inputProduct?.code!
    );
    if (props.inputProduct?.product_name! === undefined) {
      setInputSupplierId("");
      setInputPurchaseUnit("");
      setInputPurchasePrice("");
    }
  }, [props.inputProduct]);

  useEffect(() => {
    setHasMore(true);
    getProducts(1);
    setPage(2);
    if (document.getElementById("autocompletePaper") !== null)
      document
        .getElementById("autocompletePaper")!
        .scroll({ top: 0, behavior: "smooth" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputOffSearch, inputSearchBy]);

  let imagePreviewHandler = (file: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setInputImageUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  let fetchMoreData: any = () => {
    if (page > 1000 || page > totalNumberOfProducts / 24) {
      setHasMore(false);
      return;
    }
    getProducts(page);
    setPage(page + 1);
  };

  const resetValidation = () => {
    setAddFailed("");
    setInputProductNameError(false);
    setInputPurchaseUnitError(false);
    setInputPurchasePriceError(false);
  };

  const validateForm = () => {
    if (
      props.inputPurchaseOptions.some(
        (purchaseOption) => purchaseOption.name === inputProductName
      )
    ) {
      setAddFailed('Le produit "' + inputProductName + '" existe déjà!');
      return false;
    }
    if (
      props.inputPurchaseOptions.some(
        (purchaseOption) => purchaseOption.productCode === inputProductCode
      )
    ) {
      setAddFailed('Le code barre "' + inputProductCode + '" existe déjà!');
      return false;
    }
    setInputProductNameError(false);
    setInputPurchaseUnitError(false);
    setInputPurchasePriceError(false);
    let result = true;
    if (inputProductName === "") {
      setInputProductNameError(true);
      result = false;
    }
    if (inputPurchaseUnit === "" && !props.isUnitBased) {
      setInputPurchaseUnitError(true);
      result = false;
    }
    if (inputPurchasePrice === "") {
      setInputPurchasePriceError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (purchaseOption: PurchaseOption) => {
    if (purchaseOption.name !== inputProductName) {
      if (
        props.inputPurchaseOptions.some(
          (purchaseOption) => purchaseOption.name === inputProductName
        )
      ) {
        setAddFailed('Le produit "' + inputProductName + '" existe déjà!');
        return false;
      }
    }
    if (purchaseOption.productCode !== inputProductCode) {
      if (
        props.inputPurchaseOptions.some(
          (purchaseOption) => purchaseOption.productCode === inputProductCode
        )
      ) {
        setAddFailed('Le code barre "' + inputProductCode + '" existe déjà!');
        return false;
      }
    }
    setInputProductNameError(false);
    setInputPurchaseUnitError(false);
    setInputPurchasePriceError(false);
    let result = true;
    if (inputProductName === "") {
      setInputProductNameError(true);
      result = false;
    }
    if (inputPurchaseUnit === "" && !props.isUnitBased) {
      setInputPurchaseUnitError(true);
      result = false;
    }
    if (inputPurchasePrice === "") {
      setInputPurchasePriceError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  useEffect(() => {
    if (document.getElementById("purchaseOptionUnit") !== null)
      setSelectWidth(
        document.getElementById("purchaseOptionUnit")!.clientWidth
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.getElementById("purchaseOptionUnit")]);

  return (
    <React.Fragment>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          onClick={() => {
            props.setPurchaseOptionAddActive(true);
            setPurchaseOptionEditActive("");
            setInputProductName("");
            setInputImageUrl(
              "https://" +
                process.env.REACT_APP_BUCKET_NAME! +
                ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
            );
            props.setInputProduct(null);
            setInputOffSearch("");
            setInputProductCode("");

            if (props.suppliers.length !== 0)
              setInputSupplierId(props.suppliers[0].id);
            else setInputSupplierId("");

            if (props.inputPurchaseUnits.length !== 0)
              setInputPurchaseUnit(props.inputPurchaseUnits[0].id);
            else setInputPurchaseUnit("");
            setInputPurchasePrice("");
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une option d'achat
          </Text16>
        </FlexContainer>
      </FlexContainer>
      {/*Part Normal form*/}
      <Collapse orientation="vertical" in={props.purchaseOptionAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <AutocompleteInfiniteScroll
                inputRef={props.offSearchInputRef}
                inputValue={inputOffSearch}
                setValue={props.setInputProduct}
                name="expression"
                label="Rechercher dans Open Food Facts"
                options={products}
                fetchMoreData={fetchMoreData}
                hasMore={hasMore}
                onChange={(e) => {
                  setInputOffSearch(e.target.value);
                }}
                getOptionLabel={(product: Product) => (
                  <React.Fragment>
                    <FlexContainer
                      flex="1"
                      justifyContent="center"
                      marginRight="16px"
                    >
                      <img
                        style={{
                          maxWidth: "90px",
                          maxHeight: "90px",
                        }}
                        loading="lazy"
                        src={product.image_front_small_url}
                        alt=""
                      />
                    </FlexContainer>
                    <FlexContainer flex="4">
                      <Text14 textAlign="left">
                        {product.product_name +
                          (product.brands === undefined
                            ? ""
                            : " - " + product.brands.split(",")[0]) +
                          (product.quantity === undefined
                            ? ""
                            : " - " + product.quantity)}
                      </Text14>
                    </FlexContainer>
                  </React.Fragment>
                )}
                InputProps={{
                  autoComplete: "new-password",
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ marginRight: "16px" }}
                    >
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{
                        "& .MuiInputBase-root:before": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:after": {
                          borderBottom: "none",
                        },
                        "& .MuiInputBase-root:hover:before": {
                          borderBottom: "none !important",
                        },
                      }}
                    >
                      <Select
                        variant="standard"
                        sx={{
                          "& .MuiSelect-select:focus": {
                            borderBottom: "none",
                            backgroundColor: "white",
                          },
                        }}
                        value={inputSearchBy}
                        onChange={(e) => setInputSearchBy(e.target.value)}
                      >
                        <MenuItem key="productName" value="productName">
                          Par nom produit
                        </MenuItem>
                        <MenuItem key="barcode" value="barcode">
                          Par code barre
                        </MenuItem>
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <FlexContainer
                borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                width="50%"
              ></FlexContainer>
            </Grid>
            <Grid
              item
              xs={1}
              sm={1}
              md={2}
              display="flex"
              justifyContent="center"
            >
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                onMouseEnter={() => setProductImageHovered(true)}
                onMouseLeave={() => setProductImageHovered(false)}
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={inputImageUrl}
                />
                {productImageHovered && (
                  <FlexContainer
                    position="absolute"
                    top="0"
                    left="0"
                    width="150px"
                    height="150px"
                    backgroundColor="rgba(0,0,0,0.5)"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => {
                          imagePreviewHandler(e.target.files![0]);
                        }}
                      />
                      <IconButton
                        color="secondary"
                        component="span"
                        onClick={() => {}}
                        size="large"
                        style={{ padding: "4px", zIndex: "1" }}
                      >
                        <AddAPhotoIcon
                          sx={{ color: "white", fontSize: "32px" }}
                        />
                      </IconButton>
                    </label>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        setInputImageUrl(
                          "https://" +
                            process.env.REACT_APP_BUCKET_NAME! +
                            ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                        )
                      }
                      size="large"
                      style={{ padding: "4px", zIndex: "1" }}
                    >
                      <DeleteIcon sx={{ color: "white", fontSize: "32px" }} />
                    </IconButton>
                  </FlexContainer>
                )}
              </BlockContainer>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="productame"
                label="Nom du produit"
                error={inputProductNameError}
                value={inputProductName}
                onChange={(e) => setInputProductName(e.target.value)}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="productCode"
                label="Code produit"
                value={inputProductCode}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputProductCode(e.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="supplierLabelId">Fournisseur</InputLabel>
                <Select
                  labelId="supplierLabelId"
                  id="supplierSelect"
                  value={inputSupplierId}
                  label="Fournisseur"
                  onChange={(e) => setInputSupplierId(e.target.value)}
                >
                  {props.suppliers.length === 0 && (
                    <MenuItem disabled sx={{ color: "red" }} value="">
                      Aucun fournisseur créée!
                    </MenuItem>
                  )}
                  {props.suppliers.map((supplier, index) => (
                    <MenuItem value={supplier.id} key={index}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {!props.isUnitBased && (
              <Grid item xs={1} sm={1} md={1}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="purchaseUnit">Unité d'achat</InputLabel>
                  <Select
                    labelId="purchaseUnit"
                    id="purchaseOptionUnit"
                    required
                    open={selectPurchaseUnitOpen}
                    onClose={() => setSelectPurchaseUnitOpen(false)}
                    onOpen={() => setSelectPurchaseUnitOpen(true)}
                    MenuProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          width: selectWidth,
                        },
                        "& .MuiButtonBase-root": {
                          whiteSpace: "normal",
                        },
                      },
                    }}
                    error={inputPurchaseUnitError}
                    value={inputPurchaseUnit}
                    label="Unité d'achat"
                    onChange={(e) => setInputPurchaseUnit(e.target.value)}
                  >
                    {props.inputPurchaseUnits.length === 0 && (
                      <Link
                        to="purchaseUnitsTopId"
                        spy={true}
                        smooth={true}
                        duration={500}
                        containerId={"addIngredientDialogContentId"}
                      >
                        <MenuItem
                          sx={{ color: "red" }}
                          value=""
                          onClick={() => {
                            props.setPurchaseUnitAddActive(true);
                            setTimeout(function () {
                              setSelectPurchaseUnitOpen(false);
                              props.purchaseUnitInputRef.current?.focus({
                                preventScroll: true,
                              });
                            }, 100);
                            setTimeout(function () {
                              props.setPurchaseUnitOpen(true);
                            }, 500);
                          }}
                        >
                          Aucune unité d'achat créée. Cliquez ici pour en créer
                          une!
                        </MenuItem>
                      </Link>
                    )}
                    {props.inputPurchaseUnits.map((purchaseUnit, index) => (
                      <MenuItem value={purchaseUnit.id} key={index}>
                        {purchaseUnit.unit === "Personnalisée"
                          ? purchaseUnit.customUnit
                          : purchaseUnit.unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="purchasePrice"
                label="Prix d'achat"
                error={inputPurchasePriceError}
                value={inputPurchasePrice}
                onChange={(e) => {
                  if (
                    /^\d*\.?\d*$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setInputPurchasePrice(e.target.value);
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      €/
                      {getUnitNameById(
                        inputPurchaseUnit,
                        props.inputPurchaseUnits,
                        props.isUnitBased
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputPurchaseOptions([
                  ...props.inputPurchaseOptions,
                  {
                    id: uuid(),
                    imageUrl: inputImageUrl,
                    name: inputProductName,
                    purchaseUnitId: props.isUnitBased ? "1" : inputPurchaseUnit,
                    productCode: inputProductCode,
                    supplierId: inputSupplierId,
                    price: inputPurchasePrice,
                  },
                ]);
                props.setPurchaseOptionAddActive(false);
                setInputImageUrl(
                  "https://" +
                    process.env.REACT_APP_BUCKET_NAME! +
                    ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                );
                setInputOffSearch("");
                setInputProductCode("");
                setInputSupplierId("");
                setInputPurchaseUnit("");
                setInputPurchasePrice("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              props.setPurchaseOptionAddActive(false);
              setInputImageUrl(
                "https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
              );
              props.setInputProduct(null);
              setInputOffSearch("");
              setInputProductCode("");
              setInputSupplierId("");
              setInputPurchaseUnit("");
              setInputPurchasePrice("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
        {/*Part List of options*/}
      </Collapse>
      {props.inputPurchaseOptions.map((purchaseOption, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            margin="16px 0"
            elevation={3}
            border="1px solid rgba(0,0,0,0.1)"
            $borderRadius="10px"
            justifyContent="space-between"
            alignItems="center"
            padding="32px 16px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <FlexContainer>
              <BlockContainer
                width="150px"
                height="150px"
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={purchaseOption.imageUrl}
                />
              </BlockContainer>
              <FlexContainer flexDirection="column" alignItems="flex-start">
                <Text16 fontWeight="600">{purchaseOption.name}</Text16>
                <FlexContainer
                  $borderRadius="10px"
                  backgroundColor={CustomTheme.palette.secondary.light}
                  padding="0 8px"
                  margin="0 8px"
                >
                  <Text14
                    margin="4px 0"
                    lineHeight="unset"
                    color={CustomTheme.palette.secondary.main}
                  >
                    {purchaseOption.productCode}
                  </Text14>
                </FlexContainer>
                <Text14 margin="4px 10px 0 10px">
                  {"Acheté chez: " +
                    getSupplierNameById(
                      purchaseOption.supplierId,
                      props.suppliers
                    )}
                </Text14>
                <Text14 margin="0px 10px 4px 10px">
                  {"prix: " +
                    purchaseOption.price +
                    " €/" +
                    getUnitNameById(
                      purchaseOption.purchaseUnitId,
                      props.inputPurchaseUnits,
                      props.isUnitBased
                    )}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setPurchaseOptionAddActive(false);
                  setInputOffSearch("");
                  setPurchaseOptionEditActive(purchaseOption.name);
                  setInputImageUrl(purchaseOption.imageUrl);
                  setInputProductName(purchaseOption.name);
                  setInputSupplierId(purchaseOption.supplierId);
                  setInputProductCode(purchaseOption.productCode);
                  setInputPurchaseUnit(purchaseOption.purchaseUnitId);
                  setInputPurchasePrice(purchaseOption.price.toString());
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputPurchaseOptions];
                  newArr.splice(index, 1);
                  props.setInputPurchaseOptions(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          {/*Part Edit option*/}
          <Collapse
            orientation="vertical"
            in={purchaseOptionEditActive === purchaseOption.name}
          >
            {addFailed !== "" && (
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addFailed}</Alert>
              </BlockContainer>
            )}
            <FlexContainer>
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={2}>
                  <AutocompleteInfiniteScroll
                    inputValue={inputOffSearch}
                    setValue={props.setInputProduct}
                    name="expression"
                    label="Rechercher dans Open Food Facts"
                    options={products}
                    fetchMoreData={fetchMoreData}
                    hasMore={hasMore}
                    onChange={(e) => {
                      setInputOffSearch(e.target.value);
                    }}
                    getOptionLabel={(product: Product) => (
                      <React.Fragment>
                        <FlexContainer
                          flex="1"
                          justifyContent="center"
                          marginRight="16px"
                        >
                          <img
                            style={{
                              maxWidth: "90px",
                              maxHeight: "90px",
                            }}
                            loading="lazy"
                            src={product.image_front_small_url}
                            alt=""
                          />
                        </FlexContainer>
                        <FlexContainer flex="4">
                          <Text14 textAlign="left">
                            {product.product_name +
                              (product.brands === undefined
                                ? ""
                                : " - " + product.brands.split(",")[0]) +
                              (product.quantity === undefined
                                ? ""
                                : " - " + product.quantity)}
                          </Text14>
                        </FlexContainer>
                      </React.Fragment>
                    )}
                    InputProps={{
                      autoComplete: "new-password",
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          sx={{ marginRight: "16px" }}
                        >
                          <Search />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            "& .MuiInputBase-root:before": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-root:after": {
                              borderBottom: "none",
                            },
                            "& .MuiInputBase-root:hover:before": {
                              borderBottom: "none !important",
                            },
                          }}
                        >
                          <Select
                            variant="standard"
                            sx={{
                              "& .MuiSelect-select:focus": {
                                borderBottom: "none",
                                backgroundColor: "white",
                              },
                            }}
                            value={inputSearchBy}
                            onChange={(e) => setInputSearchBy(e.target.value)}
                          >
                            <MenuItem key="productName" value="productName">
                              Par nom produit
                            </MenuItem>
                            <MenuItem key="barcode" value="barcode">
                              Par code barre
                            </MenuItem>
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  display="flex"
                  justifyContent="center"
                >
                  <FlexContainer
                    borderBottom="1px solid rgba(0, 0, 0, 0.12)"
                    width="50%"
                  ></FlexContainer>
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  display="flex"
                  justifyContent="center"
                >
                  <BlockContainer
                    width="150px"
                    height="150px"
                    border="rgba(0,0,0,0.1) solid 1px"
                    justifyContent="center"
                    position="relative"
                    textAlign="center"
                    onMouseEnter={() => setProductImageHovered(true)}
                    onMouseLeave={() => setProductImageHovered(false)}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        margin: "auto",
                        position: "absolute",
                        top: "0",
                        right: "0",
                        bottom: "0",
                        left: "0",
                      }}
                      alt=""
                      src={inputImageUrl}
                    />
                    {productImageHovered && (
                      <FlexContainer
                        position="absolute"
                        top="0"
                        left="0"
                        width="150px"
                        height="150px"
                        backgroundColor="rgba(0,0,0,0.5)"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => {
                              imagePreviewHandler(e.target.files![0]);
                            }}
                          />
                          <IconButton
                            color="secondary"
                            component="span"
                            onClick={() => {}}
                            size="large"
                            style={{ padding: "4px", zIndex: "1" }}
                          >
                            <AddAPhotoIcon
                              sx={{ color: "white", fontSize: "32px" }}
                            />
                          </IconButton>
                        </label>
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            setInputImageUrl(
                              "https://" +
                                process.env.REACT_APP_BUCKET_NAME! +
                                ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                            )
                          }
                          size="large"
                          style={{ padding: "4px", zIndex: "1" }}
                        >
                          <DeleteIcon
                            sx={{ color: "white", fontSize: "32px" }}
                          />
                        </IconButton>
                      </FlexContainer>
                    )}
                  </BlockContainer>
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="productame"
                    label="Nom du produit"
                    error={inputProductNameError}
                    value={inputProductName}
                    onChange={(e) => setInputProductName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="productCode"
                    label="Code produit"
                    value={inputProductCode}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputProductCode(e.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="supplierLabelId">Fournisseur</InputLabel>
                    <Select
                      labelId="supplierLabelId"
                      id="supplierSelect"
                      value={inputSupplierId}
                      label="Fournisseur"
                      onChange={(e) => setInputSupplierId(e.target.value)}
                    >
                      {props.suppliers.map((supplier, index) => (
                        <MenuItem value={supplier.id} key={index}>
                          {supplier.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {!props.isUnitBased && (
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="purchaseUnit">Unité d'achat</InputLabel>
                      <Select
                        labelId="purchaseUnit"
                        id="purchaseUnit"
                        required
                        error={inputPurchaseUnitError}
                        value={inputPurchaseUnit}
                        label="Unité d'achat"
                        onChange={(e) => setInputPurchaseUnit(e.target.value)}
                      >
                        {props.inputPurchaseUnits.map((purchaseUnit, index) => (
                          <MenuItem value={purchaseUnit.id} key={index}>
                            {purchaseUnit.unit === "Personnalisée"
                              ? purchaseUnit.customUnit
                              : purchaseUnit.unit}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={1} sm={1} md={1}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="purchasePrice"
                    label="Prix d'achat"
                    error={inputPurchasePriceError}
                    value={inputPurchasePrice}
                    onChange={(e) => {
                      if (
                        /^\d*\.?\d*$/.test(e.target.value) ||
                        e.target.value === ""
                      ) {
                        setInputPurchasePrice(e.target.value);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          €/
                          {getUnitNameById(
                            inputPurchaseUnit,
                            props.inputPurchaseUnits,
                            props.isUnitBased
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </FlexContainer>
            <FlexContainer justifyContent="center">
              <ButtonMui
                margin="16px 4px 0 0"
                onClick={() => {
                  if (validateEditForm(purchaseOption)) {
                    let tmpArray = [...props.inputPurchaseOptions];
                    tmpArray[index] = {
                      id: purchaseOption.id,
                      imageUrl: inputImageUrl,
                      name: inputProductName,
                      productCode: inputProductCode,
                      supplierId: inputSupplierId,
                      purchaseUnitId: props.isUnitBased
                        ? "1"
                        : inputPurchaseUnit,
                      price: inputPurchasePrice,
                    };

                    props.setInputPurchaseOptions(tmpArray);
                    props.setPurchaseOptionAddActive(false);
                    setPurchaseOptionEditActive("");
                    setInputImageUrl(
                      "https://" +
                        process.env.REACT_APP_BUCKET_NAME! +
                        ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                    );
                    setInputProductName("");
                    setInputProductCode("");
                    setInputPurchaseUnit("");
                    setInputPurchasePrice("");
                    resetValidation();
                  }
                }}
                color="primary"
                variant="contained"
                size="large"
              >
                Modifier
              </ButtonMui>
              <ButtonMui
                margin="16px 0 0 4px"
                onClick={() => {
                  props.setPurchaseOptionAddActive(false);
                  setPurchaseOptionEditActive("");
                  setInputImageUrl(
                    "https://" +
                      process.env.REACT_APP_BUCKET_NAME! +
                      ".s3.eu-west-3.amazonaws.com/DefaultImages/default-dish"
                  );
                  setInputProductName("");
                  setInputProductCode("");
                  setInputPurchaseUnit("");
                  setInputPurchasePrice("");
                  resetValidation();
                }}
                color="primary"
                variant="outlined"
                size="large"
              >
                Annuler
              </ButtonMui>
            </FlexContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export const PurchaseOptions = connect(
  (state: RootState) => ({
    suppliers: getSuppliers(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getSuppliersAction: getSuppliersAction,
      },
      dispatch
    )
)(_PurchaseOptions);

export default _PurchaseOptions;
