import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Template } from "../../../../../../models/template";
import { AxiosHttpClient } from "../../../../../../services/AxiosHttpService";
import { Dispatch } from "../../../../../../store";
import { getQuoteTemplatesAction } from "../../../../../../store/Quote/actions";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../../styles/ImagePreview";
import { Text16 } from "../../../../../../styles/Text";

export interface TemplateItemProps {
  template: Template;
  setPopupDeleteActive: React.Dispatch<React.SetStateAction<string>>;
  getQuoteTemplatesAction: typeof getQuoteTemplatesAction;
}

const _TemplateItem: React.FC<TemplateItemProps> = (props) => {
  const pickTemplate = () => {
    AxiosHttpClient.put(
      "api/v1/weeventpro/quote/template/pick/" + props.template.label
    )
      .then(() => {
        getQuoteTemplatesAction();
        window.location.reload();
      })
      .catch((err) => {});
  };

  const margin = props.template.label === "Default" ? "0" : "0 0 0 10px";

  return (
    <FlexContainer flexDirection="column" alignItems="center">
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        $borderRadius="15px"
        border="black solid 1px"
        width="255px"
        overflow="hidden"
      >
        <ImagePreview
          src={
            "https://" +
            process.env.REACT_APP_BUCKET_NAME! +
            ".s3.eu-west-3.amazonaws.com/QuoteThumbnails/" +
            props.template.fileName +
            ".png"
          }
          height="360px"
          width="255px"
          borderBottom="rgba(0, 0, 0, 0.10) solid 1px"
          alt=""
        />
        <Text16>{props.template.label}</Text16>
        <FlexContainer margin="10px">
          <ButtonMui
            color="primary"
            variant="contained"
            margin={margin}
            disabled={props.template.used}
            onClick={() => pickTemplate()}
          >
            <Typography variant="button">
              {props.template.used ? "Choisi" : "Choisir"}
            </Typography>
          </ButtonMui>
          {props.template.label !== "Default" && (
            <ButtonMui
              color="secondary"
              variant="outlined"
              margin="0 0 0 10px"
              onClick={() => props.setPopupDeleteActive(props.template.label)}
            >
              <Typography variant="button">Supprimer</Typography>
            </ButtonMui>
          )}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export const TemplateItem = connect(null, (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getQuoteTemplatesAction: getQuoteTemplatesAction,
    },
    dispatch
  )
)(_TemplateItem);

export default TemplateItem;
