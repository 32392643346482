import React from "react";
import HomePageImageSection from "./HomePageImageSection";
import HomePageNav from "./HomePageNav";

export interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <div>
      <HomePageNav />
      <HomePageImageSection />
    </div>
  );
};

export default HomePage;
