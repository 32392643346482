import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  IconButton,
  InputAdornment,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../models/employee";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getEmployeesAction } from "../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../store/selectors";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddEmployee from "../AddEmployee";
import DeleteEmployee from "../DeleteEmployee";
import EditEmployee from "../EditEmployee";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells = [
  { id: "name", label: "Nom" },
  { id: "position", label: "Poste" },
  { id: "salary", label: "Salaire" },
  { id: "telNumber", label: "N° de téléphone" },
  { id: "email", label: "Email" },
  { id: "address", label: "Adresse" },
  { id: "actions", label: "Actions", disableSorting: true },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface EmployeeTableProps {
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
}

const _EmployeeTable: React.FC<EmployeeTableProps> = ({
  employees,
  getEmployeesAction,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<Employee>({
    id: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    telNumber: "",
    email: "",
    address: "",
    socialSituation: "",
    salary: "",
    gender: "",
    position: "",
    status: "",
    city: "",
    password: "",
    entityId: "",
  });

  const [popupEditActive, setPopupEditActive] = useState<Employee>({
    id: "",
    firstName: "",
    lastName: "",
    dateOfBirth: null,
    telNumber: "",
    email: "",
    address: "",
    socialSituation: "",
    salary: "",
    gender: "",
    position: "",
    status: "",
    city: "",
    password: "",
    entityId: "",
  });

  useEffect(() => {
    getEmployeesAction(inputSearch);
  }, [inputSearch, getEmployeesAction]);

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const exportEmployees = () => {
    AxiosHttpClient.getBlob("api/v1/weeventpro/rh/employees/export").then(
      (response) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "employees.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    );
  };

  const preActivateEmployeeAccount = (employeeId: string) => {
    AxiosHttpClient.put("api/v1/weeventpro/rh/employees/preactivate", {
      id: employeeId,
    })
      .then(() => {
        getEmployeesAction(inputSearch);
      })
      .catch((err) => {});
  };

  return (
    <Paper className={classes.pageContent}>
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher un employee"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            startIcon={<DownloadIcon />}
            onClick={() => exportEmployees()}
          >
            <Typography variant="button">Exporter</Typography>
          </ButtonMui>
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(employees, getComparator(order, orderBy)).map(
              (item) => (
                <TableRow
                  //component={Link}
                  //to={`/feature/employees/${item.id}`}
                  key={item.id}
                >
                  <TableCell>{item.firstName + " " + item.lastName}</TableCell>
                  <TableCell>{item.position}</TableCell>
                  <TableCell>{item.salary}</TableCell>
                  <TableCell>{item.telNumber}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.address}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        preActivateEmployeeAccount(item.id);
                        setOpen(true);
                      }}
                      size="large"
                    >
                      <CheckCircleOutlineIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        setPopupEditActive(item);
                      }}
                      size="large"
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setPopupDeleteActive(item);
                      }}
                      size="large"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddEmployee
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          input=""
        />
      )}
      <DeleteEmployee
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive.id !== "" && (
        <EditEmployee
          input={inputSearch}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={6000}
        open={open}
        onClose={(e) => setOpen(false)}
      >
        <FlexContainer elevation={3}>
          <Alert
            onClose={(e) => setOpen(false)}
            severity="success"
            sx={{ width: "100%" }}
          >
            Un mail a été envoyé aux employés concernés afin d'activer leurs
            comptes
          </Alert>
        </FlexContainer>
      </Snackbar>
    </Paper>
  );
};

export const EmployeeTable = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_EmployeeTable);

export default _EmployeeTable;
