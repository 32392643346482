import { Search } from "@mui/icons-material";
import { InputAdornment, TextField, useMediaQuery } from "@mui/material";
import React from "react";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface SearchInputProps {
  inputSearch: string;
  displayedTool: string | null;
  SetInputSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SearchInput: React.FC<SearchInputProps> = (props) => {
  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  return (
    <TextField
      variant="outlined"
      label="Rechercher un ingrédient"
      size={matches ? "medium" : "small"}
      sx={{
        margin: "8px 16px 8px 0",
        flex: "1",
        maxWidth: { sm: "50%" },
        width: { xs: "100%" },
        display: {
          xs: props.displayedTool === "search" ? "inline-flex" : "none",
          sm: "inline-flex",
        },
      }}
      value={props.inputSearch}
      onChange={(e) => props.SetInputSearch(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchInput;
