import styled from "styled-components";

interface IHeadingStyled {
  height?: string;
  width?: string;
  margin?: string;
  border?: string;
  borderBottom?: string;
  $borderRadius?: string;
}

export const ImagePreview = styled.img<IHeadingStyled>`
  width: ${(props) => (props.width ? props.width : "208px")};
  height: ${(props) => (props.height ? props.height : "176px")};
  border: ${(props) => props.border};
  margin: ${(props) => props.margin};
  border-bottom: ${(props) => props.borderBottom};
  border-radius: ${(props) => props.$borderRadius};  
`;
