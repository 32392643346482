import { combineEpics, Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { InvoiceService } from "../../services/InvoiceService";
import { RootAction } from "../types";
import { GetInvoicesActionFailure, getInvoicesActionFailure, GetInvoicesActionSuccess, getInvoicesActionSuccess, getInvoiceTemplatesActionFailure, GetInvoiceTemplatesActionFailure, getInvoiceTemplatesActionSuccess, GetInvoiceTemplatesActionSuccess, GET_INVOICES, GET_INVOICE_TEMPLATES } from "./actions";

export const invoiceListEpic: Epic<
  RootAction,
  GetInvoicesActionSuccess | GetInvoicesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INVOICES),
    switchMap((action) =>
      from(InvoiceService.getInvoices({searchInput: action.input, field: action.field ? action.field : ""})).pipe(
        mergeMap((res) => {
          return [getInvoicesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getInvoicesActionFailure()]))
        )
      )
    )
  );

  export const invoiceTemplateEpic: Epic<
  RootAction,
  GetInvoiceTemplatesActionSuccess | GetInvoiceTemplatesActionFailure,
  any,
  any
> = (action$, state, any) =>
  action$.pipe(
    ofType(GET_INVOICE_TEMPLATES),
    switchMap((action) =>
      from(InvoiceService.getInvoiceTemplates()).pipe(
        mergeMap((res) => {
          return [getInvoiceTemplatesActionSuccess(res)];
        }),
        catchError((error) =>
          of(error).pipe(mergeMap(() => [getInvoiceTemplatesActionFailure()]))
        )
      )
    )
  );

export const invoiceEpic = combineEpics<any>(invoiceListEpic, invoiceTemplateEpic);