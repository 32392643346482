import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Drawer } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dispatch, RootState } from "../../../store";
import { setExpandedMenu } from "../../../store/ExpandedMenuItem/actions";
import { getExpandedMenuItem } from "../../../store/selectors";
import {
  AccordionDetailsMui,
  AccordionMui,
  AccordionSummaryMui,
} from "../../../styles/AccordionMui";
import { AppName } from "../../../styles/AppName";
import { BlockContainer } from "../../../styles/BlockContainer";
import { ButtonAnchor } from "../../../styles/ButtonAnchor";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Image } from "../../../styles/Image";
import { SideNavMenuLink } from "../../../styles/SideNavMenuLink";
import { Text14 } from "../../../styles/Text";
import { ThemeCustom } from "../../../styles/Utils";
export interface SideNavMenuProps {
  sideNavShow: boolean;
  expanded: string;
  setExpandedMenu: typeof setExpandedMenu;
  setSideNavShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function DrawerWrapper(props) {
  return (
    <React.Fragment>
      <Drawer
        sx={{ display: { xs: "block", md: "none" } }}
        anchor="left"
        open={props.open}
        onClose={props.onClose}
      >
        {props.children}
      </Drawer>
      <Box sx={{ display: { xs: "none", md: "block" } }}>{props.children}</Box>
    </React.Fragment>
  );
}

const _SideNavMenu: React.FC<SideNavMenuProps> = ({
  sideNavShow,
  expanded,
  setSideNavShow,
  setExpandedMenu,
}) => {
  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>) => {
    setExpandedMenu(panel === expanded ? "none" : panel);
  };

  return (
    <DrawerWrapper open={sideNavShow} onClose={() => setSideNavShow(false)}>
      <Box
        sx={{
          background: ThemeCustom.colors.linearOrange,
          height: "100vh",
          width: "258px",
          top: {
            md: "0",
          },
          position: {
            md: "fixed",
          },
          zIndex: "1200",
        }}
      >
        <FlexContainer
          alignItems="center"
          justifyContent="center"
          height="70px"
          margin="0 0 43px 0"
        >
          <ButtonAnchor href="/feature">
            <FlexContainer>
              <AppName color={ThemeCustom.colors.pink}>We</AppName>
              <AppName color={ThemeCustom.colors.orange}>Event</AppName>
            </FlexContainer>
          </ButtonAnchor>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummaryMui
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/stock.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Stock/Fournisseurs
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/foodStock">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Stock alimentaire
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/materialStock">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Stock matériel
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/suppliers">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Fournisseurs
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummaryMui
              aria-controls="panel2d-content"
              id="panel2d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/commandes.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Commandes
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/sales">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Ventes
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/delivery">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Livraisons
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/supplierOrders">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Provisionnements
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <SideNavMenuLink to="/feature/catalog">
          <FlexContainer
            height="44px"
            $backgroundHover="rgb(255,0,0,0.1)"
            color={ThemeCustom.colors.whiteTransparent}
            $colorHover={ThemeCustom.colors.white}
            padding="0 16px"
          >
            <Image
              height="28px"
              width="28px"
              background={
                "url(https://" +
                process.env.REACT_APP_BUCKET_NAME! +
                ".s3.eu-west-3.amazonaws.com/icons/catalog.png)"
              }
            ></Image>
            <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
              Catalogue
            </Text14>
          </FlexContainer>
        </SideNavMenuLink>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummaryMui
              aria-controls="panel4d-content"
              id="panel4d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/devis.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Devis
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/quote">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Mes devis
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/quoteTemplates">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      templates
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummaryMui
              aria-controls="panel5d-content"
              id="panel5d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/factures.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Factures
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/upfrontInvoices">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Factures d'acompte
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/invoices">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Facture finale
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel6"}
            onChange={handleChange("panel6")}
          >
            <AccordionSummaryMui
              aria-controls="panel6d-content"
              id="panel6d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/factures.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                CRM
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/clients">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Clients
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/prospects">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Prospects
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/opportunities">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Opportunités
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel7"}
            onChange={handleChange("panel7")}
          >
            <AccordionSummaryMui
              aria-controls="panel7d-content"
              id="panel7d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/factures.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                RH
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/employees">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Employés
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/vacations">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Gestion des absences
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/documents">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Gestion des documents
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>

        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummaryMui
              aria-controls="panel8d-content"
              id="panel8d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/factures.png)"
                }
              ></Image>
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Statistiques
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/dashboard">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Tableau de bord
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/metrics">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Métriques
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/gains">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Gains
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/expenses">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Dépenses
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>
        <FlexContainer alignItems="center">
          <AccordionMui
            square
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummaryMui
              aria-controls="panel9d-content"
              id="panel9d-header"
              expandIcon={
                <ExpandMoreIcon
                  style={{ color: ThemeCustom.colors.whiteTransparent }}
                />
              }
            >
              <Image
                height="28px"
                width="28px"
                background={
                  "url(https://" +
                  process.env.REACT_APP_BUCKET_NAME! +
                  ".s3.eu-west-3.amazonaws.com/icons/marketing.png)"
                }
              />
              <Text14 margin="auto 9px" color={ThemeCustom.colors.white}>
                Marketing
              </Text14>
            </AccordionSummaryMui>
            <AccordionDetailsMui>
              <BlockContainer alignItems="center">
                <SideNavMenuLink to="/feature/webPage">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 69px" color="inherit">
                      Page web
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
                <SideNavMenuLink to="/feature/webPage">
                  <FlexContainer
                    height="44px"
                    $backgroundHover="rgb(255,0,0,0.1)"
                    color={ThemeCustom.colors.whiteTransparent}
                    $colorHover={ThemeCustom.colors.white}
                  >
                    <Text14 margin="auto 0 auto 69px" color="inherit">
                      Visibilité
                    </Text14>
                  </FlexContainer>
                </SideNavMenuLink>
              </BlockContainer>
            </AccordionDetailsMui>
          </AccordionMui>
        </FlexContainer>
      </Box>
    </DrawerWrapper>
  );
};

export const SideNavMenu = connect(
  (state: RootState) => ({
    expanded: getExpandedMenuItem(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        setExpandedMenu: setExpandedMenu,
      },
      dispatch
    )
)(_SideNavMenu);

export default SideNavMenu;
