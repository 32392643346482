import { RootAction } from "../types";
import { GET_INVOICES_SUCCESS, GET_INVOICE_TEMPLATES_SUCCESS } from "./actions";
import { InvoiceState, InvoiceTemplatesState } from "./types";

const initialInvoiceState: InvoiceState = {
  invoices: [],
};

const initialInvoiceTemplatesState: InvoiceTemplatesState = {
  templates: [],
};

export function invoiceReducer(
  state: InvoiceState = initialInvoiceState,
  action: RootAction
): InvoiceState {
  switch (action.type) {
    case GET_INVOICES_SUCCESS:
      return {
        invoices: action.invoices
      };

    default:
      return state;
  }
}

export function invoiceTemplateReducer(
  state: InvoiceTemplatesState = initialInvoiceTemplatesState,
  action: RootAction
): InvoiceTemplatesState {
  switch (action.type) {

    case GET_INVOICE_TEMPLATES_SUCCESS:
      return {
        templates: action.templates
      };

    default:
      return state;
  }
}
