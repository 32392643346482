import { createTheme, Theme } from "@mui/material";


declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const CustomTheme = createTheme({
    palette: {
        primary: {
            main: "#F2994A",
            light: "#FDF5E6",
            dark: "#F17707",
            contrastText: "#fff"
        },
        secondary: {
            main: "#F84C54",
            light: "#FEDBDD",
            dark: "#EA3F47",
            contrastText: "#fff"
        },
        background: {
            default: "#FFF3E8"
        }
    },
    typography: {
        button: {
          textTransform: 'none'
        }
    },
    components: {
      MuiSelect: {
        styleOverrides: {
            select: {
                "& .MuiSelect-select:focus": {
                    backgroundColor: "white",
                },
            }
        }
      }
    }
})