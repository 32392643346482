import dayjs, { Dayjs } from "dayjs";
import { Client } from "../../../models/client";
import { Dish } from "../../../models/dish";
import { Employee } from "../../../models/employee";
import { Ingredient, PurchaseOption, Unit } from "../../../models/ingredients";
import { Invoice } from "../../../models/invoice";
import { Material } from "../../../models/materials";
import { RouteName } from "../../../models/route";
import { Sale } from "../../../models/sale";
import { Supplier } from "../../../models/suppliers";
import { UserParameter } from "../../../models/userParameters";

export const CC_EMAILS = "CC_EMAILS";
export const GLOBAL_TVA = "GLOBAL_TVA";
export const SALE_INCREMENT = "SALE_INCREMENT";
export const SALE_NUMBER_OF_DIGITS = "SALE_NUMBER_OF_DIGITS";
export const SALE_FORMAT = "SALE_FORMAT";
export const UPFRONT_INVOICE_NUMBER_OF_DIGITS = "UPFRONT_INVOICE_NUMBER_OF_DIGITS";
export const UPFRONT_INVOICE_FORMAT = "UPFRONT_INVOICE_FORMAT";
export const INVOICE_INCREMENT = "INVOICE_INCREMENT";
export const INVOICE_NUMBER_OF_DIGITS = "INVOICE_NUMBER_OF_DIGITS";
export const INVOICE_FORMAT = "INVOICE_FORMAT";

export const SALE_TABLE_VIEW = "SALE_TABLE_VIEW";
export const SALE_TABLE_ORDER = "SALE_TABLE_ORDER";
export const SALE_TABLE_ORDER_BY = "SALE_TABLE_ORDER_BY";
export const SALE_TABLE_SEARCH_BY = "SALE_TABLE_SEARCH_BY";
export const ROUTE_TABLE_VIEW = "ROUTE_TABLE_VIEW";
export const ROUTE_TABLE_ORDER = "ROUTE_TABLE_ORDER";
export const ROUTE_TABLE_ORDER_BY = "ROUTE_TABLE_ORDER_BY";
export const ROUTE_TABLE_SEARCH_BY = "ROUTE_TABLE_SEARCH_BY";
export const DISH_TABLE_VIEW = "DISH_TABLE_VIEW";
export const DISH_TABLE_ORDER = "DISH_TABLE_ORDER";
export const DISH_TABLE_ORDER_BY = "DISH_TABLE_ORDER_BY";
export const DISH_TABLE_SEARCH_BY = "DISH_TABLE_SEARCH_BY";
export const INVOICE_TABLE_VIEW = "INVOICE_TABLE_VIEW";
export const INVOICE_TABLE_ORDER = "INVOICE_TABLE_ORDER";
export const INVOICE_TABLE_ORDER_BY = "INVOICE_TABLE_ORDER_BY";
export const INVOICE_TABLE_SEARCH_BY = "INVOICE_TABLE_SEARCH_BY";
export const CLIENT_TABLE_VIEW = "CLIENT_TABLE_VIEW";
export const CLIENT_TABLE_ORDER = "CLIENT_TABLE_ORDER";
export const CLIENT_TABLE_ORDER_BY = "CLIENT_TABLE_ORDER_BY";
export const CLIENT_TABLE_SEARCH_BY = "CLIENT_TABLE_SEARCH_BY";

export const commify = (n: number) => {
  var parts = toFixed2(n).toString().split(".");
  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;
  return (
    numberPart.replace(thousands, " ") +
    (decimalPart ? "." + decimalPart : "")
  );
};

export const getCurrentDate = () => {

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  
  return `${year}-${month<10?`0${month}`:`${month}`}-${date}`
}

export const getDateAYearAgo = () => {

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear() - 1;
  
  return `${year}-${month<10?`0${month}`:`${month}`}-${date}`
}

export const getStringFromDate = (date: Date) => {
  if (date === null || date === undefined )
    return null;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  
  return `${day}-${month<10?`0${month}`:`${month}`}-${year}`
}

export const getISODateFromString = (dateString: string) => {
  let date = new Date(dateString);
  return date.toLocaleDateString();
}

export const getTimeFromDate = (dateString: string) => {
  let date = new Date(dateString);

  let minutes = date.getHours();
  let seconds = date.getSeconds();
  
  return `${minutes<10?`0${minutes}`:`${minutes}`}:${seconds<10?`0${seconds}`:`${seconds}`}`
}

export const parseToDate = (dateStr) => {
  const parts = dateStr.split("/");
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;
  const year = parseInt(parts[2], 10);

  return new Date(year, month, day);
}

export const calculateAge = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
  {
      age_now--;
  }
  return age_now;
}

export const getPurchaseOptionObjectById = (id: string, purchaseOptions: PurchaseOption[]) => {
  for (var purchaseOption of purchaseOptions) {
    if (purchaseOption.id === id) return purchaseOption;
  }
  return {
    id: "",
    imageUrl: "",
    name: "",
    purchaseUnitId: "",
    productCode: "",
    supplierId: "",
    price: "",
  };
};

export const getIngredientNameById = (id: string, ingredients: Ingredient[]) => {
  for (var ingredient of ingredients) {
    if (ingredient.id === id) {
      return ingredient.name;
    }
  }
  return "";
};

export const getIngredientById = (id: string, ingredients: Ingredient[]) => {
  for (var ingredient of ingredients) {
    if (ingredient.id === id) {
      return ingredient;
    }
  }
  return null;
};

export const getIngredientByName = (name: string, ingredients: Ingredient[]) => {
  for (var ingredient of ingredients) {
    if (ingredient.name === name) {
      return ingredient;
    }
  }
  return null;
};

export const getDishById = (id: string, dishes: Dish[]) => {
  for (var dish of dishes) {
    if (dish.id === id) {
      return dish;
    }
  }
  return null;
};

export const getSaleById = (id: string, sales: Sale[]) => {
  for (var sale of sales) {
    if (sale.id === id) {
      return sale;
    }
  }
  return null;
};

export const getSaleOptionById = (id: string, dishes: Dish[]) => {
  for (var dish of dishes) {
    for (var saleOption of dish.saleOptions) {
      if (saleOption.id === id) {
        return saleOption; 
      }
    }
  }
  return null;
};

export const getDishBySaleOptionId = (id: string, dishes: Dish[]) => {
  for (var dish of dishes) {
    for (var saleOption of dish.saleOptions) {
      if (saleOption.id === id) {
        return dish; 
      }
    }
  }
  return null;
};

export const getInvoiceById = (id: string, invoices: Invoice[]) => {
  for (var invoice of invoices) {
    if (invoice.id === id) {
      return invoice;
    }
  }
  return null;
};

export const getUpfrontInvoiceById = (id: string, upfrontInvoices: Invoice[]) => {
  for (var upfrontInvoice of upfrontInvoices) {
    if (upfrontInvoice.id === id) {
      return upfrontInvoice;
    }
  }
  return null;
};

export const getClientById = (id: string, clients: Client[]) => {
  for (var client of clients) {
    if (client.id === id) {
      return client;
    }
  }
  return null;
};

export const getClientDescription = (client: Client | undefined | null) => {
  if (client) {
    if (client.type === "Particulier")
      return client.title + ". " + client.lastName + " " + client.firstName
    else
      return client.companyName;
  }
  return "";
};

export const getEmployeeDescription = (employee: Employee | undefined | null) => {
  if (employee) {
      return employee.lastName + " " + employee.firstName
  }
  return "";
};

export const getRouteNameName = (routeName: RouteName | undefined | null) => {
  if (routeName) {
    return routeName.name;
  }
  return "";
};


export const getMaterialNameById = (id: string, materials: Material[]) => {
  for (var material of materials) {
    if (material.id === id) {
      return material.name;
    }
  }
  return "";
};

export const getSupplierNameById = (id: string, suppliers: Supplier[]) => {
  for (var supplier of suppliers) {
    if (supplier.id === id) return supplier.name;
  }
  return "";
};

export const getUnitNameById = (id: string, units: Unit[], isUnitBased: boolean) => {
  if (isUnitBased) return "Unité(s)"
  if (units !== undefined) {
    for (var unit of units) {
      if (unit.id === id) {
        return unit.unit === "Personnalisée" ? unit.customUnit : unit.unit;
      }
    }
  }
  return "";
};

export const getUnitByName = (name: string, units: Unit[], isUnitBased: boolean) => {
  if (isUnitBased) return {
    id: 1,
    unit: "",
    customUnit: "",
    customUnitCorrespondanceValue: "",
    customUnitCorrespondanceUnit: "",
  }
  if (units !== undefined) {
    for (var unit of units) {
      if (unit.unit === "Personnalisée") {
        if (unit.customUnit === name)
          return unit;
      }
      else {
        if (unit.unit === name)
          return unit;
      }
    }
  }
  return null;
};

export const getUnitById = (id: string, units: Unit[]) => {
  if (units !== undefined) {
    for (var unit of units) {
      if (unit.id === id) {
        return unit;
      }
    }
  }
  return {
    id: "",
    unit: "",
    customUnit: "",
    customUnitCorrespondanceValue: "",
    customUnitCorrespondanceUnit: "",
  };
};

export const getUnitByIdOrElseNull = (id: string, units: Unit[]) => {
  if (units !== undefined) {
    for (var unit of units) {
      if (unit.id === id) {
        return unit;
      }
    }
  }
  return null;
};


export const getStorageUnitNameById = (ingredient: Ingredient, id: string) => {
  if (ingredient?.storageUnits !== undefined) {
    for (var unit of ingredient?.storageUnits) {
      if (unit.id === id) {
        return unit.unit === "Personnalisée" ? unit.customUnit : unit.unit;
      }
    }
  }
  return "";
};

export const getStorageUnitById = (ingredient: Ingredient, id: string) => {
  if (ingredient?.storageUnits !== undefined) {
    for (var unit of ingredient?.storageUnits) {
      if (unit.id === id) {
        return unit;
      }
    }
  }
  return {
    id: "",
    unit: "",
    customUnit: "",
    customUnitCorrespondanceValue: "",
    customUnitCorrespondanceUnit: "",
  };
};

export const getIngredientQuantity = (ingredient: Ingredient) => {
  let quantity = 0;
  if (ingredient?.storageAreas !== undefined) {
    if (ingredient.unitBased) {
      for (var storageArea of ingredient?.storageAreas!) {
        quantity +=
          Number(storageArea.quantity)
      }
    }
    else {
      for (var storageArea2 of ingredient?.storageAreas!) {
        let storageAreaUnit = getStorageUnitById(ingredient, storageArea2.storageUnitId);
        quantity +=
          Number(storageArea2.quantity) *
          convertUnitToUnit(
            storageAreaUnit!,
            getStorageUnitById(ingredient, ingredient.globalStorageUnitId)!
          );
      }
    }
  }
  return quantity;
};

export const getMaterialQuantity = (material: Material) => {
  let quantity = 0;
  if (material?.storageAreas !== undefined) {
    if (material.unitBased) {
      for (var storageArea of material?.storageAreas!) {
        quantity +=
          Number(storageArea.quantity)
      }
    }
    else {
      for (var storageArea2 of material?.storageAreas!) {
        let storageAreaUnit = getUnitById(storageArea2.storageUnitId, material.storageUnits);
        quantity +=
          Number(storageArea2.quantity) *
          convertUnitToUnit(
            storageAreaUnit!,getUnitById(material.globalStorageUnitId, material.storageUnits)!
          );
      }
    }
  }
  return quantity;
};

export const convertUnitToUnit = (unit1: Unit, unit2: Unit) => {
  return unitIsKgBased(unit1)
    ? convertUnitToMg(unit1) / convertUnitToMg(unit2)
    : convertUnitToML(unit1) / convertUnitToML(unit2);
};

export const unitIsKgBased = (unit: Unit) => {
  if (unit.unit === "Personnalisée") {
    if (["mg", "g", "Kg"].indexOf(unit.customUnitCorrespondanceUnit) > -1)
      return true;
    return false;
  } else {
    if (["mg", "g", "Kg"].indexOf(unit.unit) > -1) return true;
    return false;
  }
};

export const unitIsLBased = (unit: Unit) => {
  if (unit.unit === "Personnalisée") {
    if (["L", "mL"].indexOf(unit.customUnitCorrespondanceUnit) > -1)
      return true;
    return false;
  } else {
    if (["L", "mL"].indexOf(unit.unit) > -1) return true;
    return false;
  }
};

export const convertUnitToMg = (unit: Unit) => {
  if (unit.unit === "Personnalisée") {
    if (unit.customUnitCorrespondanceUnit === "Kg")
      return Number(unit.customUnitCorrespondanceValue) * 1000000;
    else if (unit.customUnitCorrespondanceUnit === "g")
      return Number(unit.customUnitCorrespondanceValue) * 1000;
    else return Number(unit.customUnitCorrespondanceValue);
  } else {
    if (unit.unit === "Kg") return 1000000;
    else if (unit.unit === "g") return 1000;
    else return 1;
  }
};

export const convertUnitToML = (unit: Unit) => {
  if (unit.unit === "Personnalisée") {
    if (unit.customUnitCorrespondanceUnit === "L")
      return Number(unit.customUnitCorrespondanceValue) * 1000;
    else return Number(unit.customUnitCorrespondanceValue);
  } else {
    if (unit.unit === "L") return 1000;
    else return 1;
  }
};

export const getHTPriceFromTTC = (priceTTC: number, tva: number): number => {
  return priceTTC / (1 + tva / 100);
};

export const getTTCPriceFromHT = (priceHT: number, tva: number): number => {
  return priceHT * (1 + tva / 100);
};

export const getUserParameterValueByName = (parameterName: string, userParameters: UserParameter[]) => {
  for (var userParameter of userParameters) {
    if (userParameter.parameterName === parameterName) {
      return userParameter.parameterValue; 
    }
  }
  return "";
};

export const isDateValid = (dayjsObject: Dayjs | null) => {
  if (!dayjsObject) return false;
  return dayjsObject instanceof dayjs && dayjsObject.isValid();
};

export const toFixed2 = (num: number | null | undefined) => {
  if (num === null || num === undefined || typeof num !== 'number' || isNaN(num)) {
    return '';
  }
  if (Math.abs(num) < 0.01) {
    return '0.00';
  }
  return (+(Math.round(+((num + 0.0001) + 'e' + 2)) + 'e' + -2)).toFixed(2);
}

export const toFixed2Number = (num: number | null | undefined) => {
  if (num === null || num === undefined || typeof num !== 'number' || isNaN(num)) {
    return 0;
  }
  if (Math.abs(num) < 0.01) {
    return 0;
  }
  return Number((+(Math.round(+((num + 0.0001) + 'e' + 2)) + 'e' + -2)).toFixed(2));
}