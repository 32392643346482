import { Route } from "../models/route";
import { AxiosHttpClient } from "./AxiosHttpService";

export class RouteService {
  static getRoutes = (input: any) => {
    return AxiosHttpClient.get<Route[]>(
      "api/v1/weeventpro/route",
      input
    );
  };
}
