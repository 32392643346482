import { Divider, Grid } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../../models/client";
import { Employee } from "../../../../../../models/employee";
import { Dispatch, RootState } from "../../../../../../store";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getClientDescription,
  getEmployeeDescription,
} from "../../../../../Reusable/Utils";

export interface InformationsProps {
  client: Client;
  employees: Employee[];
  getEmployeesAction: typeof getEmployeesAction;
}

const _Informations: React.FC<InformationsProps> = ({
  client,
  employees,
  getEmployeesAction,
}) => {
  useEffect(() => {
    getEmployeesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEmployeesAction]);

  return (
    <FlexContainer padding="16px">
      <BlockContainer width="100%">
        <Text16 fontWeight="500">Informations générales</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Intitulé</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{getClientDescription(client)}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Code client</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{client.clientCode}</Text14>
          </FlexContainer>
        </FlexContainer>
        {client.type === "Particulier" && (
          <>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">Date de naissance</Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>
                  {client.dateOfBirth
                    ? dayjs(client.dateOfBirth).format("DD/MM/YYYY")
                    : ""}
                </Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">Situation familiale</Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.maritalStatus}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">
                  Catégorie socio-professionnelle
                </Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.socioProfessionalCategory}</Text14>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
        {client.type === "Entreprise" && (
          <>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">Raison sociale</Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.businessName}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">Forme juridique</Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.legalForm}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">
                  Type de régime fiscal
                </Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.fiscalRegime}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">Numéro SIRET</Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.siretNumber}</Text14>
              </FlexContainer>
            </FlexContainer>
            <FlexContainer>
              <FlexContainer flex="1">
                <Text14 color="rgb(107, 114, 128)">
                  Numéro de TVA intracommunautaire
                </Text14>
              </FlexContainer>
              <FlexContainer flex="2">
                <Text14>{client.intracommunityVATNumber}</Text14>
              </FlexContainer>
            </FlexContainer>
          </>
        )}
        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Coordonnées</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Adresse e-mail</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{client.email}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">N° de téléphone</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{client.phoneNumber}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Adresse</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{client.address}</Text14>
          </FlexContainer>
        </FlexContainer>

        {client.type === "Entreprise" && (
          <>
            <Divider sx={{ margin: "8px" }} />
            <Text16 fontWeight="500">Contacts associés</Text16>
            {client.associatedContacts.length === 0 && (
              <FlexContainer>
                <Text14 color="rgba(0,0,0,0.5)" textAlign="center" width="100%">
                  Aucun contact créé
                </Text14>
              </FlexContainer>
            )}
            {client.associatedContacts.length > 0 && (
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 3 }}
                columns={{ xs: 1, sm: 1, md: 3 }}
              >
                {client.associatedContacts.map((associatedContact, index) => (
                  <Grid
                    item
                    xs={1}
                    sm={1}
                    md={1}
                    display="flex"
                    justifyContent="center"
                    key={index}
                  >
                    <FlexContainer
                      flexDirection="column"
                      margin="16px 0"
                      elevation={3}
                      border="1px solid rgba(0,0,0,0.1)"
                      $borderRadius="10px"
                      alignItems="center"
                      padding="16px 8px"
                      width="100%"
                    >
                      <BlockContainer
                        width="150px"
                        height="150px"
                        border="rgba(0,0,0,0.1) solid 1px"
                        justifyContent="center"
                        position="relative"
                        textAlign="center"
                        backgroundColor="white"
                      >
                        <img
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            margin: "auto",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            bottom: "0",
                            left: "0",
                          }}
                          alt=""
                          src={associatedContact.imageUrl}
                        />
                      </BlockContainer>
                      <Text16 margin="0" textAlign="center" fontWeight="500">
                        {associatedContact.lastName +
                          " " +
                          associatedContact.firstName}
                      </Text16>
                      <FlexContainer
                        $borderRadius="10px"
                        backgroundColor={CustomTheme.palette.secondary.light}
                        padding="0 8px"
                        margin="0 8px"
                      >
                        <Text14
                          margin="4px 0"
                          lineHeight="unset"
                          color={CustomTheme.palette.secondary.main}
                        >
                          {associatedContact.jobTitle}
                        </Text14>
                      </FlexContainer>
                      <Text14
                        margin="0"
                        textAlign="center"
                        color="rgb(107, 114, 128)"
                      >
                        {associatedContact.email}
                      </Text14>
                      <Text14
                        margin="0"
                        textAlign="center"
                        color="rgb(107, 114, 128)"
                      >
                        {associatedContact.phoneNumber}
                      </Text14>
                    </FlexContainer>
                  </Grid>
                ))}
              </Grid>
            )}
          </>
        )}
        <Divider sx={{ margin: "8px" }} />
        <Text16 fontWeight="500">Informations complémentaires</Text16>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Responsable</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{getEmployeeDescription(client.responsible)}</Text14>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <FlexContainer flex="1">
            <Text14 color="rgb(107, 114, 128)">Mode de paiement préféré</Text14>
          </FlexContainer>
          <FlexContainer flex="2">
            <Text14>{client.preferredPaymentMethod}</Text14>
          </FlexContainer>
        </FlexContainer>
      </BlockContainer>
    </FlexContainer>
  );
};

export const Informations = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_Informations);

export default _Informations;
