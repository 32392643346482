import { Client } from "../models/client";
import { InputSearch } from "../models/inputSearch";
import { Opportunity } from "../models/opportunity";
import { Prospect } from "../models/prospect";
import { AxiosHttpClient } from "./AxiosHttpService";

export class CrmService {
  static getClients = (input: any) => {
    return AxiosHttpClient.get<Client[]>(
      "api/v1/weeventpro/clients",
      input
    );
  };
  
  static getProspects = (input: InputSearch) => {
    return AxiosHttpClient.post<Prospect[]>(
      "api/v1/weeventpro/crm/prospects/get",
      input
    );
  };

  static getOpportunities = (input: InputSearch) => {
    return AxiosHttpClient.post<Opportunity[]>(
      "api/v1/weeventpro/crm/opportunities/get",
      input
    );
  };
}
