import { RootAction } from "../types";
import { GET_DOCUMENTS_SUCCESS, GET_EMPLOYEES_SUCCESS, GET_VACATIONS_SUCCESS } from "./actions";
import { RhState } from "./types";

const initialState: RhState = {
  employees: [],
  vacations: [],
  documents: []
};

export function rhReducer(
  state: RhState = initialState,
  action: RootAction
): RhState {
  switch (action.type) {

    case GET_EMPLOYEES_SUCCESS:
        return {
          employees: action.employees,
          vacations: state.vacations,
          documents: state.documents
        };

    case GET_VACATIONS_SUCCESS:
        return {
          employees: state.employees,
          vacations: action.vacations,
          documents: state.documents
        };

    case GET_DOCUMENTS_SUCCESS:
        return {
          employees: state.employees,
          vacations: state.vacations,
          documents: action.documents
        };

    default:
      return state;
  }
}
