import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dish } from "../../../../../models/dish";
import { Sale } from "../../../../../models/sale";
import { Dispatch, RootState } from "../../../../../store";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getDishes } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import { toFixed2 } from "../../../../Reusable/Utils";

export interface SaleProductsProps {
  popupActive: Sale | null;
  dishes: Dish[];
  setPopupActive: React.Dispatch<React.SetStateAction<Sale | null>>;
  getDishesAction: typeof getDishesAction;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    maxWidth: "850px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _SaleProducts: React.FC<SaleProductsProps> = ({
  popupActive,
  dishes,
  setPopupActive,
  getDishesAction,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getDishesAction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDishesAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Produits vendus"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addSaleDialogContentId" dividers>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14 display="inline-block" width="80px">
              Vente:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {popupActive!.description}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <Text14 display="inline-block" width="80px">
              Le:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {popupActive!.date
                ? dayjs(popupActive!.date).format("DD/MM/YYYY")
                : ""}
            </Text16>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="image">Image</TableCell>
                    <TableCell key="product">Produit</TableCell>
                    <TableCell key="code">Code</TableCell>
                    <TableCell key="quantity">Quantité</TableCell>
                    <TableCell key="pu">P.U</TableCell>
                    <TableCell key="pht">Prix H.T</TableCell>
                    <TableCell key="tva">TVA</TableCell>
                    <TableCell key="pttc">Prix TTC</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {popupActive!.soldItems.map((soldItem, index) => (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <BlockContainer
                            sx={{
                              width: {
                                xs: "60px",
                                sm: "60px",
                              },
                              height: {
                                xs: "60px",
                                sm: "60px",
                              },
                            }}
                            border="rgba(0,0,0,0.1) solid 1px"
                            justifyContent="center"
                            position="relative"
                            textAlign="center"
                            backgroundColor="white"
                            margin="auto"
                          >
                            <img
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                margin: "auto",
                                position: "absolute",
                                top: "0",
                                right: "0",
                                bottom: "0",
                                left: "0",
                              }}
                              alt=""
                              src={soldItem.imageUrl}
                            />
                          </BlockContainer>
                        </TableCell>
                        <TableCell>{soldItem.name}</TableCell>
                        <TableCell>{soldItem.internalCode}</TableCell>
                        <TableCell>{soldItem.quantity}</TableCell>
                        <TableCell>{soldItem.priceHT + " €"}</TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(soldItem.quantity) * Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                        <TableCell>{soldItem.tva}</TableCell>
                        <TableCell>
                          {toFixed2(
                            (1 + Number(soldItem.tva) * 0.01) *
                              Number(soldItem.quantity) *
                              Number(soldItem.priceHT)
                          ) + " €"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContentMui>
    </Dialog>
  );
};

export const SaleProducts = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
      },
      dispatch
    )
)(_SaleProducts);

export default SaleProducts;
