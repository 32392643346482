import { Typography } from "@mui/material";
import styled from "styled-components";

interface IHeadingStyled {
  color?: string;
}

export const TypographyMui = styled(Typography)<IHeadingStyled>`
  color: ${(props) => props.color};
`;
