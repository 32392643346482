import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { CustomField } from "../../../../../../../models/customField";
import { ButtonMui } from "../../../../../../../styles/ButtonMui";

export interface DeleteCustomFieldProps {
  popupActive: CustomField | null;
  isDeleteCustomFieldLoading: boolean;
  setIsDeleteCustomFieldLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPopupActive: React.Dispatch<React.SetStateAction<CustomField | null>>;
  deleteCustomField: (customFieldId: string) => void;
}

const DeleteCustomField: React.FC<DeleteCustomFieldProps> = (props) => {
  return (
    <Dialog
      open={props.popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        props.setPopupActive(null);
      }}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"Supprimer le champs personnalisé?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText display="inline">
          Etes-vous sûr de vouloir supprimer le champs personnalisé{" "}
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {props.popupActive?.name}
          </Typography>
          ?<br></br>
          Vous perderez
          <Typography
            variant="subtitle1"
            color="secondary"
            display="inline"
            component="span"
          >
            {" toutes les valeurs associées "}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <ButtonMui
          margin="8px 0"
          variant="contained"
          disabled={props.isDeleteCustomFieldLoading}
          loading={props.isDeleteCustomFieldLoading}
          onClick={() => {
            props.deleteCustomField(props.popupActive!.id);
            props.setIsDeleteCustomFieldLoading(true);
          }}
          color="primary"
        >
          Supprimer
        </ButtonMui>
        <ButtonMui
          margin="8px 0"
          variant="outlined"
          onClick={() => props.setPopupActive(null)}
          color="primary"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCustomField;
