import { Alert, Box, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import {
  removeSignUpActionFailure,
  signUpAction,
} from "../../../store/Signup/actions";
import {
  isUserSignupFailed,
  isUserSignupSucceed,
} from "../../../store/selectors";
import { Dispatch, RootState } from "../../../store/types";
import { AppName } from "../../../styles/AppName";
import { BlockContainer } from "../../../styles/BlockContainer";
import { ButtonMui } from "../../../styles/ButtonMui";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Text16 } from "../../../styles/Text";
import { CustomTheme } from "../../../styles/Theme";

export interface SignUpFormProps {
  isUserSignupSucceed: boolean;
  isUserSignupFailed: boolean;
  signUp: typeof signUpAction;
  removeSignUpFailure: typeof removeSignUpActionFailure;
}

const _SignUpForm: React.FC<SignUpFormProps> = ({
  isUserSignupSucceed,
  isUserSignupFailed,
  signUp,
  removeSignUpFailure,
}) => {
  const [inputEmail, SetInputEmail] = useState("");

  const [inputEmailError, SetInputEmailError] = useState(false);

  const [inputPassword, SetInputPassword] = useState("");

  const [inputPasswordError, SetInputPasswordError] = useState(false);

  const [inputConfirmPassword, SetInputConfirmPassword] = useState("");

  const [inputConfirmPasswordError, SetInputConfirmPasswordError] =
    useState(false);

  const [inputName, SetInputName] = useState("");

  const [inputNameError, SetInputNameError] = useState(false);

  const [inputFirstName, SetInputFirstName] = useState("");

  const [inputFirstNameError, SetInputFirstNameError] = useState(false);

  const [inputLastName, SetInputLastName] = useState("");

  const [inputLastNameError, SetInputLastNameError] = useState(false);

  const [signupFailed, setSignupFailed] = useState("");

  const validateForm = () => {
    SetInputEmailError(false);
    SetInputPasswordError(false);
    SetInputConfirmPasswordError(false);
    SetInputNameError(false);
    SetInputFirstNameError(false);
    SetInputLastNameError(false);
    let result = true;
    if (inputConfirmPassword !== "" && inputPassword !== inputConfirmPassword) {
      setSignupFailed("Les mots de passe sont différents!");
      return false;
    }
    if (inputEmail === "") {
      SetInputEmailError(true);
      result = false;
    }
    if (inputPassword === "") {
      SetInputPasswordError(true);
      result = false;
    }
    if (inputConfirmPassword === "") {
      SetInputConfirmPasswordError(true);
      result = false;
    }
    if (inputName === "") {
      SetInputNameError(true);
      result = false;
    }
    if (inputFirstName === "") {
      SetInputFirstNameError(true);
      result = false;
    }
    if (inputLastName === "") {
      SetInputLastNameError(true);
      result = false;
    }
    if (!result) {
      setSignupFailed("Champs manquants");
    }
    return result;
  };

  useEffect(() => {
    return () => {
      removeSignUpFailure();
    };
  }, [removeSignUpFailure]);

  return (
    <FlexContainer flex="1" elevation={5} backgroundColor="white">
      <Box boxShadow={5} width="100%">
        <FlexContainer flexDirection="column" margin="0 32px" textAlign="left">
          <FlexContainer margin="20px" alignSelf="center">
            <AppName color={CustomTheme.palette.secondary.dark}>We</AppName>
            <AppName color={CustomTheme.palette.primary.dark}>Event</AppName>
          </FlexContainer>
          {signupFailed !== "" && (
            <BlockContainer margin="8px">
              <Alert
                severity="error"
                onClose={() => {
                  setSignupFailed("");
                }}
              >
                {signupFailed}
              </Alert>
            </BlockContainer>
          )}
          <FlexContainer flex="3" flexDirection="column">
            <form autoComplete="off">
              <Grid
                container
                spacing={{ xs: 1, sm: 1, md: 2 }}
                columns={{ xs: 1, sm: 1, md: 2 }}
              >
                <Grid item xs={1} sm={1} md={2}>
                  <TextField
                    autoComplete="new-password"
                    error={inputEmailError}
                    value={inputEmail}
                    onChange={(e) => SetInputEmail(e.target.value)}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  sx={{ paddingTop: "0 !important" }}
                >
                  <TextField
                    autoComplete="new-password"
                    type="password"
                    fullWidth
                    error={inputPasswordError}
                    value={inputPassword}
                    onChange={(e) => SetInputPassword(e.target.value)}
                    label="Mot de passe"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  sx={{ paddingTop: "0 !important" }}
                >
                  <TextField
                    error={
                      (inputConfirmPassword !== "" &&
                        inputPassword !== inputConfirmPassword) ||
                      inputConfirmPasswordError
                    }
                    autoComplete="new-password"
                    type="password"
                    fullWidth
                    value={inputConfirmPassword}
                    onChange={(e) => SetInputConfirmPassword(e.target.value)}
                    label="Confirmer le mot de passe"
                    variant="outlined"
                    margin="normal"
                    helperText={
                      inputConfirmPassword !== "" &&
                      inputPassword !== inputConfirmPassword
                        ? "Les mots de passe sont différents!"
                        : ""
                    }
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={2}
                  sx={{ paddingTop: "0 !important" }}
                >
                  <TextField
                    autoComplete="new-password"
                    error={inputNameError}
                    value={inputName}
                    onChange={(e) => SetInputName(e.target.value)}
                    label="Nom de l'entité"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  sx={{ paddingTop: "0 !important" }}
                >
                  <TextField
                    autoComplete="new-password"
                    error={inputFirstNameError}
                    value={inputFirstName}
                    onChange={(e) => SetInputFirstName(e.target.value)}
                    label="Prénom"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  sx={{ paddingTop: "0 !important" }}
                >
                  <TextField
                    autoComplete="new-password"
                    error={inputLastNameError}
                    value={inputLastName}
                    onChange={(e) => SetInputLastName(e.target.value)}
                    label="Nom de famille"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          </FlexContainer>
          <FlexContainer margin="24px 0 16px">
            <ButtonMui
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                if (validateForm())
                  signUp(
                    uuid(),
                    inputEmail,
                    inputPassword,
                    inputName,
                    inputFirstName,
                    inputLastName
                  );
              }}
            >
              INSCRIPTION
            </ButtonMui>
          </FlexContainer>
          <Box>
            <Typography variant="body2" color="textSecondary" align="center">
              {"Copyright © "}
              <Link color="inherit" href="/">
                WeEvent
              </Link>{" "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
          </Box>
          {isUserSignupSucceed && <Navigate to="/" replace />}
          {isUserSignupFailed && <Text16>L'email existe déjà</Text16>}
        </FlexContainer>
      </Box>
    </FlexContainer>
  );
};

export const SignUpForm = connect(
  (state: RootState) => ({
    isUserSignupSucceed: isUserSignupSucceed(state),
    isUserSignupFailed: isUserSignupFailed(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        signUp: signUpAction,
        removeSignUpFailure: removeSignUpActionFailure,
      },
      dispatch
    )
)(_SignUpForm);

export default _SignUpForm;
