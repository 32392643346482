import { Link } from "react-router-dom";
import styled from "styled-components";

export const SideNavMenuLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  background: linear-gradient(180deg, #eb5757 0%, rgba(255, 255, 255, 0) 100%),
  &:hover {
    background: linear-gradient(180deg, #eb5757 0%, rgba(255, 255, 255, 0) 100%),
      #f2994a;
    border: 1px solid #f2994a;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;
