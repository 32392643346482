import ArticleIcon from "@mui/icons-material/Article";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Prospect } from "../../../../../models/prospect";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getProspectsAction } from "../../../../../store/Crm/actions";
import { getProspects } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import NavigationPageHeader from "../../../../Reusable/NavigationPageHeader";
import {
  getISODateFromString,
  getTimeFromDate,
} from "../../../../Reusable/Utils";

export interface ProspectDetailsProps {}

const useStyles = makeStyles({
  paper: {
    width: "100%",
    height: "100%",
  },
});

const _ProspectDetails: React.FC<ProspectDetailsProps> = (props) => {
  const classes = useStyles();
  const [prospect, setProspect] = useState<Prospect>();

  const mockActivities = [
    {
      date: "2022-05-03T22:00:00.000Z",
      description: "Vous avez envoyé au prospect une facture finale.",
    },
    {
      date: "2022-05-03T22:00:00.000Z",
      description: "Un contrat a été conclu avec le prospect.",
    },
    {
      date: "2022-05-01T22:00:00.000Z",
      description: "Un devis a été demandé par le prospect.",
    },
    {
      date: "2022-04-22T22:00:00.000Z",
      description: "Vous avez contacté ce prospect par téléphone.",
    },
  ];

  const mockMails = [
    {
      date: "2022-05-03T22:00:00.000Z",
      subject: "Re: Proposition de marché",
      content:
        "Bonjour, Je vous joint le contrat à signer avec la fiche information comme conv...",
    },
    {
      date: "2022-05-01T22:00:00.000Z",
      subject: "Proposition de marché",
      content:
        "Bonjour, Vous trouverez ci-joint le contrat signé avec la fiche information comp...",
    },
  ];

  const mockDocuments = [
    {
      type: "Devis",
      name: "Mariage Aymane et Joanna",
    },
    {
      type: "Facture finale",
      name: "Mariage Aymane et Joanna",
    },
    {
      type: "Devis",
      name: "Pot de départ",
    },
    {
      type: "Facture finale",
      name: "Pot de départ",
    },
  ];

  useEffect(() => {
    getProspectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProspectDetails = () => {
    AxiosHttpClient.get<Prospect>(
      "api/v1/weeventpro/crm/prospects/get/" + params.prospectId
    ).then((response) => {
      setProspect(response);
    });
  };

  const params = useParams();

  return (
    <BlockContainer>
      <NavigationPageHeader
        title="Prospects"
        subTitle={prospect ? prospect.name : ""}
        backPath="/feature/prospects"
      />
      <FlexContainer margin="0 24px 24px 24px">
        <Paper elevation={3} className={classes.paper}>
          <FlexContainer
            flexDirection="column"
            alignItems="start"
            padding="24px"
          >
            <FlexContainer width="100%">
              <Text20 fontWeight="600">{prospect?.name}</Text20>
            </FlexContainer>
            <FlexContainer
              borderBottom="1px solid rgba(0,0,0,0.2)"
              margin="8px 24px 0 0"
              width="calc(100% - 48px)"
            ></FlexContainer>
            <FlexContainer margin="24px 0 0 0">
              <BusinessCenterIcon fontSize="small" color="secondary" />
              <Text14>{prospect?.type + " - " + prospect?.company}</Text14>
            </FlexContainer>
            <FlexContainer margin="8px 0 0 0">
              <PhoneIcon fontSize="small" color="secondary" />
              <Text14>{prospect?.telNumber}</Text14>
            </FlexContainer>
            <FlexContainer margin="8px 0 0 0">
              <EmailIcon fontSize="small" color="secondary" />
              <Text14>{prospect?.email}</Text14>
            </FlexContainer>
            <FlexContainer margin="8px 0 0 0">
              <LocationOnIcon fontSize="small" color="secondary" />
              <Text14>{prospect?.address}</Text14>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>

      <FlexContainer margin="0 24px 24px 24px" height="500px">
        <FlexContainer flex="1" margin="0 12px 0 0">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Actualités</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 8px 0"
                width="calc(100% - 48px)"
              ></FlexContainer>

              <FlexContainer flexDirection="column" width="100%">
                {mockActivities.map((mockActivity, index) => (
                  <FlexContainer flexDirection="column">
                    {(index === 0 ||
                      getISODateFromString(mockActivities[index - 1].date) !==
                        getISODateFromString(mockActivity.date)) && (
                      <FlexContainer>
                        <Text16 fontWeight="600">
                          {getISODateFromString(mockActivity.date)}
                        </Text16>
                      </FlexContainer>
                    )}
                    <FlexContainer
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="7px 16px 7px 16px"
                      $backgroundHover="rgb(255,0,0,0.1)"
                      $cursorHover="pointer"
                    >
                      <FlexContainer alignItems="center">
                        <FlexContainer>
                          <RestaurantIcon color="primary" />
                        </FlexContainer>
                        <FlexContainer flexDirection="column">
                          <FlexContainer>
                            <Text14>
                              {getTimeFromDate(mockActivity.date)}
                            </Text14>
                          </FlexContainer>
                          <FlexContainer>
                            <Text14>{mockActivity.description}</Text14>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                ))}
              </FlexContainer>
            </FlexContainer>
          </Paper>
        </FlexContainer>
        <FlexContainer flex="1" margin="0 0 0 12px">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Opportunités</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 0 0"
                width="calc(100% - 48px)"
              ></FlexContainer>
            </FlexContainer>
          </Paper>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer margin="0 24px 24px 24px" height="500px">
        <FlexContainer flex="1" margin="0 12px 0 0">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Échanges</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 0 0"
                width="calc(100% - 48px)"
              ></FlexContainer>

              <FlexContainer
                flexDirection="column"
                width="100%"
                padding="32px 0"
              >
                {mockMails.map((mockMail, index) => (
                  <FlexContainer flexDirection="column">
                    <FlexContainer
                      width="100%"
                      justifyContent="space-between"
                      alignItems="center"
                      padding="7px 16px 7px 16px"
                      $backgroundHover="rgb(255,0,0,0.1)"
                      $cursorHover="pointer"
                    >
                      <FlexContainer alignItems="center">
                        <FlexContainer>
                          <FlexContainer>
                            <Text14 margin="4px 10px 4px 0">
                              {getISODateFromString(mockMail.date) +
                                " " +
                                getTimeFromDate(mockMail.date)}
                            </Text14>
                          </FlexContainer>
                        </FlexContainer>
                        <FlexContainer flexDirection="column">
                          <FlexContainer>
                            <Text14>{mockMail.subject}</Text14>
                          </FlexContainer>
                          <FlexContainer>
                            <Text14>{mockMail.content}</Text14>
                          </FlexContainer>
                        </FlexContainer>
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                ))}
              </FlexContainer>
            </FlexContainer>
          </Paper>
        </FlexContainer>
        <FlexContainer flex="1" margin="0 0 0 12px">
          <Paper elevation={3} className={classes.paper}>
            <FlexContainer
              flexDirection="column"
              alignItems="start"
              padding="24px"
            >
              <FlexContainer width="100%">
                <Text20 fontWeight="600">Documents</Text20>
              </FlexContainer>
              <FlexContainer
                borderBottom="1px solid rgba(0,0,0,0.2)"
                margin="8px 24px 32px 0"
                width="calc(100% - 48px)"
              ></FlexContainer>
              <Grid
                container
                spacing={{ xs: 5, md: 7 }}
                columns={{ xs: 1, sm: 2, md: 3 }}
              >
                {mockDocuments.map((mockDocument) => (
                  <Grid item xs={1} sm={1} md={1} key={mockDocument.name}>
                    <FlexContainer
                      flex="1"
                      flexDirection="column"
                      alignItems="center"
                      $backgroundHover="rgb(255,0,0,0.1)"
                      $cursorHover="pointer"
                      padding="16px 0"
                    >
                      <ArticleIcon fontSize="large" color="primary" />
                      <Text14>
                        {mockDocument.type + ": " + mockDocument.name}
                      </Text14>
                    </FlexContainer>
                  </Grid>
                ))}
              </Grid>
            </FlexContainer>
          </Paper>
        </FlexContainer>
      </FlexContainer>
    </BlockContainer>
  );
};

export const ProspectDetails = connect(
  (state: RootState) => ({
    prospects: getProspects(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getProspectsAction: getProspectsAction,
      },
      dispatch
    )
)(_ProspectDetails);

export default _ProspectDetails;
