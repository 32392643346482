import PeopleOutlineTwoToneIcon from "@mui/icons-material/PeopleOutlineTwoTone";
import React, { useState } from "react";
import { BlockContainer } from "../../../../styles/BlockContainer";
import PageHeader from "../../../Reusable/PageHeader";
import { AddVacation } from "./AddVacation";
import VacationContent from "./VacationContent";

export interface VacationsProps {}

const Vacations: React.FC<VacationsProps> = (props) => {
  const [popupActive, setPopupActive] = useState(false);

  return (
    <BlockContainer>
      <PageHeader
        title="Absences"
        subTitle="Liste des absences"
        icon={<PeopleOutlineTwoToneIcon fontSize="large" color="primary" />}
      />
      <VacationContent />
      {popupActive && (
        <AddVacation
          popupActive={popupActive}
          setPopupActive={setPopupActive}
          input=""
        />
      )}
    </BlockContainer>
  );
};

export default Vacations;
