import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Dish } from "../../../../../../models/dish";
import { Ingredient } from "../../../../../../models/ingredients";
import { Dispatch, RootState } from "../../../../../../store";
import { getIngredientsAction } from "../../../../../../store/Stock/actions";
import { getIngredients } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import {
  getIngredientById,
  getIngredientNameById,
  getUnitById,
} from "../../../../../Reusable/Utils";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MapFieldsProps {
  dishes: Dish[];
  ingredients: Ingredient[];
  fetchedValues: string[][];
  outputErrors: string[];
  popupActive: boolean;
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getIngredientsAction: typeof getIngredientsAction;
}

const _MapFields: React.FC<MapFieldsProps> = (props) => {
  let fields = [
    "Image",
    "Nom",
    "Catégorie",
    "Quantité",
    "Prix",
    "Allergies",
    "Quantité minimale",
    "Quantité idéale",
    "Suggestion par évènement",
    "À vendre",
    "Durée d'expiration",
    "Unités d'achat",
    "Unités de stockage",
    "Unités de production",
    "Énergie (Kj)",
    "Énergie (Kcal)",
    "Protéines",
    "Glucides",
    "Lipides",
    "Sucres",
    "Acides gras saturés",
    "Sel",
    "Calcium",
    "Vitamine D",
    "Fibres alimentaires",
    "Cholestérol",
  ];

  const getValueCells = (headValue: string, dish: Dish) => {
    let headValuesMap: Map<string, string | JSX.Element> = new Map([
      ["Nom", dish.name],
      ["Catégorie", dish.category],
      ["Tags", dish.tags.join(", ")],
      ["Code interne", dish.internalCode],
      ["En vente", dish.forSale ? "Oui" : "Non"],
      ["Stocké", dish.stored ? "Oui" : "Non"],
      ["Temps de préparation", dish.preparationTime],
      ["Instructions", dish.instructions],
      ["Chefs", dish.chefsIds.join(", ")],
      ["Quantité produite", dish.productionQuantity],
      ["Unité de production", dish.productionUnit],
      ["Temps de cuisson", dish.cookingTime],
      [
        "Nomenclature",
        dish.nomenclature
          .map(
            (nomenclatureItem) =>
              getIngredientNameById(
                nomenclatureItem.ingredientId,
                props.ingredients
              ) +
              "(" +
              nomenclatureItem.quantity +
              " " +
              getUnitById(
                nomenclatureItem.unitId,
                getIngredientById(nomenclatureItem.id, props.ingredients)!
                  .productionUnits
              )
          )
          .join(", "),
      ],
      [
        "Suppléments",
        dish.nomenclature
          .map(
            (nomenclatureItem) =>
              getIngredientNameById(
                nomenclatureItem.ingredientId,
                props.ingredients
              ) +
              "(" +
              nomenclatureItem.quantity +
              " " +
              getUnitById(
                nomenclatureItem.unitId,
                getIngredientById(nomenclatureItem.id, props.ingredients)!
                  .productionUnits
              ) +
              ")"
          )
          .join(", "),
      ],
      ["Température de cuisson", dish.cookingTemperature],
      [
        "Options de vente",
        dish.saleOptions
          .map((saleOption) => saleOption.name + "(" + saleOption.price + ")")
          .join(", "),
      ],
      [
        "Énergie (Kj)",
        dish.energyKj +
          (dish.energyKj === "" || dish.energyKj === "N/A" ? "" : " Kj"),
      ],
      [
        "Énergie (Kcal)",
        dish.energyKcal +
          (dish.energyKcal === "" || dish.energyKcal === "N/A" ? "" : " Kcal"),
      ],
      [
        "Protéines",
        dish.proteins +
          (dish.proteins === "" || dish.proteins === "N/A" ? "" : " g"),
      ],
      [
        "Glucides",
        dish.carbohydrates +
          (dish.carbohydrates === "" || dish.carbohydrates === "N/A"
            ? ""
            : " g"),
      ],
      [
        "Lipides",
        dish.lipids + (dish.lipids === "" || dish.lipids === "N/A" ? "" : " g"),
      ],
      [
        "Sucres",
        dish.sugar + (dish.sugar === "" || dish.sugar === "N/A" ? "" : " g"),
      ],
      [
        "Acides gras saturés",
        dish.saturatedFattyAcids +
          (dish.saturatedFattyAcids === "" || dish.saturatedFattyAcids === "N/A"
            ? ""
            : " g"),
      ],
      [
        "Sel",
        dish.salt + (dish.salt === "" || dish.salt === "N/A" ? "" : " g"),
      ],
      [
        "Calcium",
        dish.calcium +
          (dish.calcium === "" || dish.calcium === "N/A" ? "" : " g"),
      ],
      [
        "Vitamine D",
        dish.vitaminD +
          (dish.vitaminD === "" || dish.vitaminD === "N/A" ? "" : " g"),
      ],
      [
        "Fibres alimentaires",
        dish.dietaryFiber +
          (dish.dietaryFiber === "" || dish.dietaryFiber === "N/A" ? "" : " g"),
      ],
      [
        "Cholestérol",
        dish.cholesterol +
          (dish.cholesterol === "" || dish.cholesterol === "N/A" ? "" : " g"),
      ],
    ]);
    let headComplexValuesMap = new Map([
      [
        "Image",
        <BlockContainer
          width="100px"
          height="100px"
          border="rgba(0,0,0,0.1) solid 1px"
          justifyContent="center"
          position="relative"
          textAlign="center"
          backgroundColor="white"
          margin="auto"
        >
          <img
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              margin: "auto",
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
            }}
            alt=""
            src={dish?.imageUrl}
          />
        </BlockContainer>,
      ],
    ]);
    return headValuesMap.get(headValue) !== undefined
      ? headValuesMap.get(headValue)
      : headComplexValuesMap.get(headValue);
  };

  const classes = useStyles();

  return (
    <BlockContainer>
      <TableContainerMui $maxHeight="45vh">
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map((header, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.dishes.map((dish, index) => (
              <TableRow key={index}>
                {fields.map((field, index2) => (
                  <TableCell key={index2}>
                    {getValueCells(field, dish)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <BlockContainer
        margin="16px"
        border="1px solid rgba(0,0,0,0.2)"
        $borderRadius="4px"
        $maxHeight="15vh"
        overflow="auto"
      >
        {props.outputErrors.map((outputError, index) => (
          <Text14 color={CustomTheme.palette.secondary.main} key={index}>
            {outputError}
          </Text14>
        ))}
        {props.outputErrors.length === 0 && (
          <Text14>Fichier valide! Vous pouvez importer vos données</Text14>
        )}
      </BlockContainer>
    </BlockContainer>
  );
};

export const MapFields = connect(
  (state: RootState) => ({
    ingredients: getIngredients(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getIngredientsAction: getIngredientsAction,
      },
      dispatch
    )
)(_MapFields);

export default MapFields;
