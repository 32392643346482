import { RootAction } from "../types";
import { GET_CART_ITEMS_SUCCESS} from "./actions";
import { CartState } from "./types";

const initialState: CartState = {
  cartItems: [],
};

export function cartReducer(
  state: CartState = initialState,
  action: RootAction
): CartState {
  switch (action.type) {
    case GET_CART_ITEMS_SUCCESS:
      return {
        cartItems: action.cartItems
      };

    default:
      return state;
  }
}
