import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { bindActionCreators } from "redux";
import { Material } from "../../../../../models/materials";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getMaterials } from "../../../../../store/selectors";
import { getMaterialsAction } from "../../../../../store/Stock/actions";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { LinkCustom } from "../../../../../styles/LinkCustom";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import ProgressStateWithLabel from "../../../../Reusable/ProgressStateWithLabel";
import TabsDesktop from "../../../../Reusable/TabsDesktop";
import TabsMobile from "../../../../Reusable/TabsMobile";
import { getMaterialQuantity } from "../../../../Reusable/Utils";
import { Questions } from "../../WebPage/WebPageContent/Questions";
import DeleteMaterial from "../DeleteMaterial";
import EditMaterial from "../EditMaterial";
import { Informations } from "./Informations";

export interface MaterialDetailsProps {}

const _MaterialDetails: React.FC<MaterialDetailsProps> = (props) => {
  const [material, setMaterial] = useState<Material>();

  const [quantity, setQuantity] = useState(0);

  const [popupDeleteActive, setPopupDeleteActive] = useState<string[]>([]);

  const [popupEditActive, setPopupEditActive] = useState<Material | null>(null);

  const matches = useMediaQuery(CustomTheme.breakpoints.up("sm"));

  const getMaterialDetails = () => {
    AxiosHttpClient.get<Material>(
      "api/v1/weeventpro/stock/materials/" + params.materialId
    ).then((response) => {
      setMaterial(response);
    });
  };

  const getUnitNameById = (id: string) => {
    if (material?.storageUnits !== undefined) {
      for (var unit of material?.storageUnits) {
        if (unit.id === id) {
          return unit.unit === "Personnalisée" ? unit.customUnit : unit.unit;
        }
      }
    }
    return "";
  };

  useEffect(() => {
    getMaterialDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (material !== undefined && material !== null) {
      let totalQuantity = getMaterialQuantity(material!);
      if (quantity < totalQuantity) {
        setQuantity(quantity + totalQuantity / 40);
      } else if (quantity > totalQuantity) {
        setQuantity(totalQuantity);
      }
    }
  }, [material, quantity]);

  const params = useParams();

  return (
    <BlockContainer
      sx={{
        padding: {
          xs: "0 8px",
          sm: "32px",
        },
      }}
    >
      <FlexContainer
        sx={{
          margin: {
            xs: "16px",
            sm: "0",
          },
        }}
      >
        <LinkCustom to="/feature/materialStock">
          <FlexContainer alignItems="center">
            <ArrowBackIosIcon color="secondary" fontSize="small" />
            <Text20 color={CustomTheme.palette.secondary.main}>
              Ingrédients
            </Text20>
          </FlexContainer>
        </LinkCustom>
      </FlexContainer>
      <FlexContainer margin="16px 0">
        <FlexContainer
          flex="3"
          sx={{
            margin: {
              xs: "0",
              sm: "0 8px 0 0",
            },
          }}
          backgroundColor="white"
          padding="32px 16px"
          elevation={3}
          $borderRadius="4px"
        >
          <FlexContainer flex="1">
            <FlexContainer margin="0 16px 0 0">
              <BlockContainer
                sx={{
                  width: {
                    xs: "100px",
                    sm: "200px",
                  },
                  height: {
                    xs: "100px",
                    sm: "200px",
                  },
                }}
                border="rgba(0,0,0,0.1) solid 1px"
                justifyContent="center"
                position="relative"
                textAlign="center"
                backgroundColor="white"
              >
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    margin: "auto",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    bottom: "0",
                    left: "0",
                  }}
                  alt=""
                  src={material?.imageUrl}
                />
              </BlockContainer>
            </FlexContainer>
            <FlexContainer flexDirection="column">
              <Text20>{material?.name}</Text20>
              <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                {material?.category}
              </Text14>
              {!matches && (
                <Text14 color="rgba(0,0,0,0.5)" margin="0 10px">
                  {"En stock: " +
                    (material !== undefined && material !== null
                      ? getMaterialQuantity(material!)
                      : "0") +
                    " " +
                    getUnitNameById(material?.globalStorageUnitId!)}
                </Text14>
              )}
            </FlexContainer>
          </FlexContainer>
          <Box display={{ xs: "none", lg: "flex" }} flexDirection="column">
            <FlexContainer>
              <ButtonMui
                margin="8px 0"
                onClick={() => {
                  setPopupEditActive(material!);
                }}
                color="primary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Modifier le materiel
              </ButtonMui>
            </FlexContainer>
            <FlexContainer>
              <ButtonMui
                onClick={() => {
                  setPopupDeleteActive([material!.id]);
                }}
                color="secondary"
                variant="outlined"
                size="large"
                width="100%"
              >
                Supprimer le materiel
              </ButtonMui>
            </FlexContainer>
          </Box>
        </FlexContainer>
        <Paper
          elevation={3}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
            },
            flex: "2",
            margin: "0 0 0 8px",
            padding: "16px 16px",
            borderRadius: "4px",
          }}
        >
          <FlexContainer flexDirection="column" width="100%">
            <Text20>Aperçu du stock</Text20>
            {getMaterialQuantity(material!) <
              Number(material?.minimalQuantity) && (
              <FlexContainer
                alignSelf="center"
                alignItems="center"
                border="1px solid rgba(255,0,0,0.8)"
                $borderRadius="50px"
                padding="0 8px"
                margin="16px 0 0 0"
              >
                <ErrorOutlineIcon
                  sx={{ color: "rgba(255,0,0,0.8)" }}
                  fontSize="small"
                />
                <Text16 margin="0 0 0 4px" color="rgba(255,0,0,0.8)">
                  Insuffisant
                </Text16>
              </FlexContainer>
            )}
            {getMaterialQuantity(material!) >=
              Number(material?.minimalQuantity) &&
              getMaterialQuantity(material!) <=
                Number(material?.idealQuantity) && (
                <FlexContainer
                  alignSelf="center"
                  alignItems="center"
                  border="1px solid rgba(0,128,0,0.8)"
                  $borderRadius="50px"
                  padding="0 8px"
                  margin="16px 0 0 0"
                >
                  <CheckCircleOutlineIcon
                    sx={{ color: "rgba(0,128,0,0.8)" }}
                    fontSize="small"
                  />
                  <Text16 margin="0 0 0 4px" color="rgba(0,128,0,0.8)">
                    Normal
                  </Text16>
                </FlexContainer>
              )}
            {getMaterialQuantity(material!) >
              Number(material?.idealQuantity) && (
              <FlexContainer
                alignSelf="center"
                alignItems="center"
                border="1px solid rgba(242, 153, 74, 0.8)"
                $borderRadius="50px"
                padding="0 8px"
                margin="16px 0 0 0"
              >
                <WarningAmberIcon
                  sx={{ color: "rgba(242, 153, 74, 0.8)" }}
                  fontSize="small"
                />
                <Text16 margin="0 0 0 4px" color="rgba(242, 153, 74, 0.8)">
                  Surchargé
                </Text16>
              </FlexContainer>
            )}
            <FlexContainer margin="16px 0 0 0" alignSelf="center" width="100%">
              <ProgressStateWithLabel
                firstBreakpoint={Number(material?.minimalQuantity)}
                secondBreakpoint={Number(material?.idealQuantity)}
                value={quantity}
                firstBreakpointText={
                  "Quantité minimale: " +
                  material?.minimalQuantity +
                  " " +
                  getUnitNameById(material?.globalStorageUnitId!)
                }
                secondBreakpointText={
                  "Quantité idéale: " +
                  material?.idealQuantity +
                  " " +
                  getUnitNameById(material?.globalStorageUnitId!)
                }
                valueText={
                  "En stock: " +
                  quantity +
                  " " +
                  getUnitNameById(material?.globalStorageUnitId!)
                }
                unit={getUnitNameById(material?.globalStorageUnitId!)}
                showValueTooltip={quantity === getMaterialQuantity(material!)}
              />
            </FlexContainer>
            <FlexContainer alignSelf="center">
              <ButtonMui
                onClick={() => {}}
                color="primary"
                variant="outlined"
                size="large"
              >
                Mettre à jour
              </ButtonMui>
            </FlexContainer>
          </FlexContainer>
        </Paper>
      </FlexContainer>
      {matches && (
        <TabsDesktop
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations material={material!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      {!matches && (
        <TabsMobile
          tabs={["Informations", "Historique mouvements", "Recettes associées"]}
          components={[
            <Informations material={material!} />,
            <Questions />,
            <Questions />,
          ]}
        />
      )}
      <DeleteMaterial
        input={""}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
      {popupEditActive !== null && (
        <EditMaterial
          inputSearch={""}
          popupActive={popupEditActive}
          setPopupActive={setPopupEditActive}
        />
      )}
    </BlockContainer>
  );
};

export const MaterialDetails = connect(
  (state: RootState) => ({
    materials: getMaterials(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getMaterialsAction: getMaterialsAction,
      },
      dispatch
    )
)(_MaterialDetails);

export default _MaterialDetails;
