import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { BlockContainer } from "../../../../../../styles/BlockContainer";

export interface CreateCustomerOrderArchiveStep1Props {
  inputEventName: string;
  inputEventType: string;
  inputContactName: string;
  inputDate: string;
  inputGuestsNumber: string;
  inputReceptionPlace: string;
  inputTelNumber: string;
  setInputEventName: React.Dispatch<React.SetStateAction<string>>;
  setInputEventType: React.Dispatch<React.SetStateAction<string>>;
  setInputContactName: React.Dispatch<React.SetStateAction<string>>;
  setInputDate: React.Dispatch<React.SetStateAction<string>>;
  setInputGuestsNumber: React.Dispatch<React.SetStateAction<string>>;
  setInputReceptionPlace: React.Dispatch<React.SetStateAction<string>>;
  setInputTelNumber: React.Dispatch<React.SetStateAction<string>>;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.spacing(1),
    },
  },
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
    height: "750px",
    width: "920px",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  selectRoot: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
}));

const CreateCustomerOrderArchiveStep1: React.FC<
  CreateCustomerOrderArchiveStep1Props
> = (props) => {
  const classes = useStyles();
  return (
    <form className={classes.root} autoComplete="off">
      <Grid container>
        <Grid item xs={6}>
          <BlockContainer margin="20px 0 0 0">
            <TextField
              variant="outlined"
              name="name"
              label="Nom de l'évènement"
              value={props.inputEventName}
              onChange={(e) => props.setInputEventName(e.target.value)}
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                native
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={props.inputEventType}
                label="Type"
                onChange={(e) =>
                  props.setInputEventType(
                    typeof e.target.value === "string" ? e.target.value : ""
                  )
                }
              >
                <option aria-label="None" value="" />
                <option value="Mariage">Mariage</option>
                <option value="Pot de départ">Pot de départ</option>
                <option value="Funérailles">Funérailles</option>
                <option value="Anniversaire">Anniversaire</option>
                <option value="Autres">Autres</option>
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              name="ContactName"
              label="Nom du contact"
              value={props.inputContactName}
              type="string"
              onChange={(e) => props.setInputContactName(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                label="Date et horaire"
                value={props.inputDate}
                onChange={(value) => props.setInputDate(value!)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </BlockContainer>
        </Grid>
        <Grid item xs={6}>
          <BlockContainer margin="20px 0 0 0">
            <TextField
              variant="outlined"
              name="guestsNumber"
              label="Nombre d'invités"
              value={props.inputGuestsNumber}
              onChange={(e) => props.setInputGuestsNumber(e.target.value)}
            />
            <TextField
              variant="outlined"
              name="receptionPlace"
              label="Lieu"
              value={props.inputReceptionPlace}
              onChange={(e) => props.setInputReceptionPlace(e.target.value)}
            />
            <TextField
              variant="outlined"
              name="telNumber"
              label="N° de téléphone"
              value={props.inputTelNumber}
              onChange={(e) => props.setInputTelNumber(e.target.value)}
            />
          </BlockContainer>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateCustomerOrderArchiveStep1;
