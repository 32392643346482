import { CustomerOrder } from "../models/sale";
import { InputSearch } from "../models/inputSearch";
import { AxiosHttpClient } from "./AxiosHttpService";

export class CustomerOrderService {
  static getCustomerOrders = (input: InputSearch) => {
    return AxiosHttpClient.post<CustomerOrder[]>(
      "api/v1/weeventpro/order/customerOrder/get",
      input
    );
  };

  static getCustomerOrdersArchive = (input: InputSearch) => {
    return AxiosHttpClient.post<CustomerOrder[]>(
      "api/v1/weeventpro/order/customerOrder/archive/get",
      input
    );
  };
}
