import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Employee } from "../../../../../../models/employee";
import { Route } from "../../../../../../models/route";
import { Dispatch, RootState } from "../../../../../../store";
import { getEmployeesAction } from "../../../../../../store/Rh/actions";
import { getEmployees } from "../../../../../../store/selectors";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { TableContainerMui } from "../../../../../../styles/TableContainerMui";
import { Text14 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";
import { getEmployeeDescription } from "../../../../../Reusable/Utils";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    padding: theme.spacing(3),
  },
}));

export interface MapFieldsProps {
  routes: Route[];
  fetchedValues: string[][];
  outputErrors: string[];
  popupActive: boolean;
  employees: Employee[];
  setPopupActive: React.Dispatch<React.SetStateAction<boolean>>;
  getEmployeesAction: typeof getEmployeesAction;
}

const _MapFields: React.FC<MapFieldsProps> = (props) => {
  let fields = ["Date", "Nom", "Description", "Livreur", "Véhicule"];

  const getValueCells = (headValue: string, route: Route) => {
    let headValuesMap: Map<string, string | JSX.Element> = new Map([
      ["Date", route.date ? dayjs(route.date).format("DD/MM/YYYY") : ""],
      ["Nom", route.routeName ? route.routeName.name : ""],
      ["Description", route.description],
      ["Livreur", getEmployeeDescription(route.driver)],
      ["Véhicule", route.vehicle],
    ]);
    return headValuesMap.get(headValue);
  };

  const classes = useStyles();

  return (
    <BlockContainer>
      <TableContainerMui $maxHeight="45vh">
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {fields.map((header, index) => (
                <TableCell sx={{ whiteSpace: "nowrap" }} key={index}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.routes.map((route, index) => (
              <TableRow key={index}>
                {fields.map((field, index2) => (
                  <TableCell key={index2}>
                    {getValueCells(field, route)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainerMui>
      <BlockContainer
        margin="16px"
        border="1px solid rgba(0,0,0,0.2)"
        $borderRadius="4px"
        $maxHeight="15vh"
        overflow="auto"
      >
        {props.outputErrors.map((outputError, index) => (
          <Text14 color={CustomTheme.palette.secondary.main} key={index}>
            {outputError}
          </Text14>
        ))}
        {props.outputErrors.length === 0 && (
          <Text14>Fichier valide! Vous pouvez importer vos données</Text14>
        )}
      </BlockContainer>
    </BlockContainer>
  );
};

export const MapFields = connect(
  (state: RootState) => ({
    employees: getEmployees(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getEmployeesAction: getEmployeesAction,
      },
      dispatch
    )
)(_MapFields);

export default MapFields;
