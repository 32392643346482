import { Link } from "react-router-dom";
import styled from "styled-components";
import { ThemeCustom } from "../Utils";

export const Button = styled(Link)`
  text-decoration: none;
  background: ${(props) => (props.color ? props.color : ThemeCustom.colors.white)};
  border: 3px solid ${ThemeCustom.colors.pink};
  border-radius: 50px;
  margin: 4px;
  &:hover {
    background: ${(props) =>
      props.color ? ThemeCustom.colors.reverseLinearButton : ThemeCustom.colors.lightGray};
    cursor: pointer;
  }
`;
