import React, { useEffect, useState } from "react";
import { Employee } from "../../../../models/employee";
import { AxiosHttpClient } from "../../../../services/AxiosHttpService";
import { FlexContainer } from "../../../../styles/FlexContainer";
import { Text28 } from "../../../../styles/Text";
import { ThemeCustom } from "../../../../styles/Utils";

export interface FeatureHomeProps {}

const FeatureHome: React.FC<FeatureHomeProps> = () => {
  const getUserInfos = () => {
    AxiosHttpClient.get<Employee>("api/v1/weeventpro/user/infos").then(
      (res) => {
        setUser(res);
      }
    );
  };

  useEffect(() => {
    getUserInfos();
  }, []);

  const [user, setUser] = useState<Employee | null>(null);

  return (
    <FlexContainer
      alignItems="flex-start"
      margin="0 40px"
      flexDirection="column"
    >
      <Text28 textAlign="left" color={ThemeCustom.colors.pink}>
        Bienvenue
        {" " + (user == null ? "" : user.firstName + " " + user.lastName)}
      </Text28>
    </FlexContainer>
  );
};

export default FeatureHome;
