import AddCircleIcon from "@mui/icons-material/AddCircle";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { Unit } from "../../../../../../models/materials";
import { BlockContainer } from "../../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../../styles/FlexContainer";
import { Text16 } from "../../../../../../styles/Text";
import { CustomTheme } from "../../../../../../styles/Theme";

export interface StorageUnitsProps {
  inputRef: React.RefObject<HTMLInputElement>;
  enabledUnitOptions: string[];
  inputPurchaseUnits: Unit[];
  inputStorageUnits: Unit[];
  inputProductionUnits: Unit[];
  storageUnitAddActive: boolean;
  storageUnitOpen: boolean;
  setInputPurchaseUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputStorageUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setInputProductionUnits: React.Dispatch<React.SetStateAction<Unit[]>>;
  setStorageUnitAddActive: React.Dispatch<React.SetStateAction<boolean>>;
  setStorageUnitOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StorageUnits: React.FC<StorageUnitsProps> = (props) => {
  const [inputStorageUnit, setInputStorageUnit] = useState("");

  const [inputStorageUnitError, setInputStorageUnitError] = useState(false);

  const [storageUnitEditActive, setStorageUnitEditActive] = useState("");

  const [inputCustomStorageUnit, setInputCustomStorageUnit] = useState("");

  const [inputCustomStorageUnitError, setInputCustomStorageUnitError] =
    useState(false);

  const [
    inputCustomStorageUnitCorrespondanceValue,
    setInputCustomStorageUnitCorrespondanceValue,
  ] = useState("");

  const [
    inputCustomStorageUnitCorrespondanceValueError,
    setInputCustomStorageUnitCorrespondanceValueError,
  ] = useState(false);

  const [
    inputCustomStorageUnitCorrespondanceUnit,
    setInputCustomStorageUnitCorrespondanceUnit,
  ] = useState("");

  const [
    inputCustomStorageUnitCorrespondanceUnitError,
    setInputCustomStorageUnitCorrespondanceUnitError,
  ] = useState(false);

  const [addToPurchaseUnits, setAddToPurchaseUnits] = useState(true);

  const [addToProductionUnits, setAddToProductionUnits] = useState(true);

  const [addFailed, setAddFailed] = useState("");

  const resetValidation = () => {
    setAddFailed("");
    setInputStorageUnitError(false);
    setInputCustomStorageUnitError(false);
    setInputCustomStorageUnitCorrespondanceValueError(false);
    setInputCustomStorageUnitCorrespondanceUnitError(false);
  };

  const validateForm = () => {
    if (
      props.inputStorageUnits.some((storageUnit) => {
        if (storageUnit.unit === "Personnalisée")
          return storageUnit.customUnit === inputCustomStorageUnit;
        else return storageUnit.unit === inputStorageUnit;
      })
    ) {
      setAddFailed(
        "L'unité de stockage \"" +
          (inputStorageUnit === "Personnalisée"
            ? inputCustomStorageUnit
            : inputStorageUnit) +
          '" existe déjà!'
      );
      return false;
    }

    if (
      props.inputPurchaseUnits.some((purchaseUnit) => {
        if (purchaseUnit.unit === "Personnalisée")
          return purchaseUnit.customUnit === inputCustomStorageUnit;
        else return purchaseUnit.unit === inputStorageUnit;
      }) &&
      addToPurchaseUnits
    ) {
      setAddFailed(
        "L'unité d'achat \"" +
          (inputStorageUnit === "Personnalisée"
            ? inputCustomStorageUnit
            : inputStorageUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités d'achat!"
      );
      return false;
    }

    if (
      props.inputProductionUnits.some((productionUnit) => {
        if (productionUnit.unit === "Personnalisée")
          return productionUnit.customUnit === inputCustomStorageUnit;
        else return productionUnit.unit === inputStorageUnit;
      }) &&
      addToProductionUnits
    ) {
      setAddFailed(
        "L'unité de production \"" +
          (inputStorageUnit === "Personnalisée"
            ? inputCustomStorageUnit
            : inputStorageUnit) +
          "\" existe déjà! Veuillez décocher l'ajout aux unités de production!"
      );
      return false;
    }

    setInputStorageUnitError(false);
    setInputCustomStorageUnitError(false);
    setInputCustomStorageUnitCorrespondanceValueError(false);
    setInputCustomStorageUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputStorageUnit === "") {
      setInputStorageUnitError(true);
      result = false;
    }
    if (inputStorageUnit === "Personnalisée" && inputCustomStorageUnit === "") {
      setInputCustomStorageUnitError(true);
      result = false;
    }
    if (
      inputStorageUnit === "Personnalisée" &&
      inputCustomStorageUnitCorrespondanceValue === ""
    ) {
      setInputCustomStorageUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputStorageUnit === "Personnalisée" &&
      inputCustomStorageUnitCorrespondanceUnit === ""
    ) {
      setInputCustomStorageUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const validateEditForm = (storageUnit: Unit) => {
    if (
      storageUnit.unit !== inputStorageUnit ||
      storageUnit.customUnit !== inputCustomStorageUnit
    ) {
      if (
        props.inputStorageUnits.some((storageUnit) => {
          if (storageUnit.unit === "Personnalisée")
            return storageUnit.customUnit === inputCustomStorageUnit;
          else return storageUnit.unit === inputStorageUnit;
        })
      ) {
        setAddFailed(
          "L'unité de stockage \"" +
            (inputStorageUnit === "Personnalisée"
              ? inputCustomStorageUnit
              : inputStorageUnit) +
            '" existe déjà!'
        );
        return false;
      }
    }

    setInputStorageUnitError(false);
    setInputCustomStorageUnitError(false);
    setInputCustomStorageUnitCorrespondanceValueError(false);
    setInputCustomStorageUnitCorrespondanceUnitError(false);
    let result = true;
    if (inputStorageUnit === "") {
      setInputStorageUnitError(true);
      result = false;
    }
    if (inputStorageUnit === "Personnalisée" && inputCustomStorageUnit === "") {
      setInputCustomStorageUnitError(true);
      result = false;
    }
    if (
      inputStorageUnit === "Personnalisée" &&
      inputCustomStorageUnitCorrespondanceValue === ""
    ) {
      setInputCustomStorageUnitCorrespondanceValueError(true);
      result = false;
    }
    if (
      inputStorageUnit === "Personnalisée" &&
      inputCustomStorageUnitCorrespondanceUnit === ""
    ) {
      setInputCustomStorageUnitCorrespondanceUnitError(true);
      result = false;
    }
    if (!result) setAddFailed("Champs manquants");
    return result;
  };

  const isUnitDisabled = (unit: string) => {
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  const isUnitDisabledForEdit = (unit: string) => {
    if (
      props.inputPurchaseUnits.length +
        props.inputStorageUnits.length +
        props.inputProductionUnits.length <=
      1
    )
      return false;
    if (props.enabledUnitOptions.indexOf(unit) > -1) return false;
    else return true;
  };

  useEffect(() => {
    if (inputStorageUnit !== "Personnalisée") {
      setInputCustomStorageUnit("");
      setInputCustomStorageUnitCorrespondanceValue("");
      setInputCustomStorageUnitCorrespondanceUnit("");
    }
  }, [inputStorageUnit]);

  return (
    <React.Fragment>
      <BlockContainer margin="16px 0" id="storageUnitsTopId">
        <Text16 textAlign="left" margin="0">
          Unités de stockage
        </Text16>
      </BlockContainer>
      <FlexContainer margin="16px 0" alignItems="center">
        <FlexContainer
          $cursorHover="pointer"
          alignItems="center"
          onClick={() => {
            setStorageUnitEditActive("");
            setInputStorageUnit("");
            setInputCustomStorageUnit("");
            setInputCustomStorageUnitCorrespondanceValue("");
            setInputCustomStorageUnitCorrespondanceUnit("");
            props.setStorageUnitAddActive(true);
            resetValidation();
          }}
        >
          <AddCircleIcon color="secondary" sx={{ marginRight: "8px" }} />
          <Text16
            color={CustomTheme.palette.secondary.main}
            textAlign="left"
            margin="0"
          >
            Ajouter une unité de stockage
          </Text16>
        </FlexContainer>
      </FlexContainer>
      <Collapse orientation="vertical" in={props.storageUnitAddActive}>
        {addFailed !== "" && (
          <BlockContainer margin="0 0 8px 0">
            <Alert severity="error">{addFailed}</Alert>
          </BlockContainer>
        )}
        <FlexContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={1}>
              <FormControl
                fullWidth
                variant="outlined"
                error={inputStorageUnitError}
              >
                <InputLabel id="unitLabel">Unité</InputLabel>
                <Select
                  labelId="unitLabel"
                  id="unit"
                  inputRef={props.inputRef}
                  open={props.storageUnitOpen}
                  onClose={() => props.setStorageUnitOpen(false)}
                  onOpen={() => props.setStorageUnitOpen(true)}
                  required
                  value={inputStorageUnit}
                  label="Age"
                  onChange={(e) => setInputStorageUnit(e.target.value)}
                >
                  <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                    Kg
                  </MenuItem>
                  <MenuItem value="g" disabled={isUnitDisabled("g")}>
                    g
                  </MenuItem>
                  <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                    mg
                  </MenuItem>
                  <MenuItem value="L" disabled={isUnitDisabled("L")}>
                    L
                  </MenuItem>
                  <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                    mL
                  </MenuItem>
                  <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Nom personnalisé de votre unité"
              >
                <TextField
                  fullWidth
                  disabled={inputStorageUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputStorageUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomStorageUnitError}
                  variant="outlined"
                  name="label"
                  label="Libellé"
                  value={inputCustomStorageUnit}
                  onChange={(e) => setInputCustomStorageUnit(e.target.value)}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Valeur de votre unité personnalisée en unité internationale"
              >
                <TextField
                  fullWidth
                  disabled={inputStorageUnit !== "Personnalisée"}
                  sx={{
                    backgroundColor:
                      inputStorageUnit !== "Personnalisée"
                        ? "#e8e8e8"
                        : "white",
                  }}
                  error={inputCustomStorageUnitCorrespondanceValueError}
                  variant="outlined"
                  name="customUnitStorageCorrespondanceValue"
                  label="Valeur de correspondance"
                  value={inputCustomStorageUnitCorrespondanceValue}
                  onChange={(e) => {
                    if (
                      /^\d*\.?\d*$/.test(e.target.value) ||
                      e.target.value === ""
                    ) {
                      setInputCustomStorageUnitCorrespondanceValue(
                        e.target.value
                      );
                    }
                  }}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Tooltip
                color="primary"
                arrow
                placement="top"
                title="Unité internationale utilisée pour calculer la valeur de correspondance"
              >
                <FormControl
                  fullWidth
                  variant="outlined"
                  error={inputCustomStorageUnitCorrespondanceUnitError}
                >
                  <InputLabel id="customStorageUnitCorrespondanceUnitLabel">
                    Unité de correspondance
                  </InputLabel>
                  <Select
                    disabled={inputStorageUnit !== "Personnalisée"}
                    sx={{
                      backgroundColor:
                        inputStorageUnit !== "Personnalisée"
                          ? "#e8e8e8"
                          : "white",
                    }}
                    labelId="customStorageUnitCorrespondanceUnitLabel"
                    id="customStorageUnitCorrespondanceUnit"
                    required
                    value={inputCustomStorageUnitCorrespondanceUnit}
                    label="Unité de correspondance"
                    onChange={(e) =>
                      setInputCustomStorageUnitCorrespondanceUnit(
                        e.target.value
                      )
                    }
                  >
                    <MenuItem value="Kg" disabled={isUnitDisabled("Kg")}>
                      Kg
                    </MenuItem>
                    <MenuItem value="g" disabled={isUnitDisabled("g")}>
                      g
                    </MenuItem>
                    <MenuItem value="mg" disabled={isUnitDisabled("mg")}>
                      mg
                    </MenuItem>
                    <MenuItem value="L" disabled={isUnitDisabled("L")}>
                      L
                    </MenuItem>
                    <MenuItem value="mL" disabled={isUnitDisabled("mL")}>
                      mL
                    </MenuItem>
                  </Select>
                </FormControl>
              </Tooltip>
            </Grid>
            <FlexContainer flexDirection="column" padding="8px 0 0 16px">
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToPurchaseUnits}
                  onChange={(event) => {
                    setAddToPurchaseUnits(!addToPurchaseUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités d'achat
                </Text16>
              </FlexContainer>
              <FlexContainer>
                <Checkbox
                  color="secondary"
                  size="small"
                  checked={addToProductionUnits}
                  onChange={(event) => {
                    setAddToProductionUnits(!addToProductionUnits);
                  }}
                />
                <Text16 display="inline-block">
                  Ajouter aux unités de production
                </Text16>
              </FlexContainer>
            </FlexContainer>
          </Grid>
        </FlexContainer>
        <FlexContainer justifyContent="center">
          <ButtonMui
            margin="16px 4px 0 0"
            onClick={() => {
              if (validateForm()) {
                props.setInputStorageUnits([
                  ...props.inputStorageUnits,
                  {
                    id: uuid(),
                    unit: inputStorageUnit,
                    customUnit: inputCustomStorageUnit,
                    customUnitCorrespondanceValue:
                      inputCustomStorageUnitCorrespondanceValue,
                    customUnitCorrespondanceUnit:
                      inputCustomStorageUnitCorrespondanceUnit,
                  },
                ]);
                if (addToPurchaseUnits)
                  props.setInputPurchaseUnits([
                    ...props.inputPurchaseUnits,
                    {
                      id: uuid(),
                      unit: inputStorageUnit,
                      customUnit: inputCustomStorageUnit,
                      customUnitCorrespondanceValue:
                        inputCustomStorageUnitCorrespondanceValue,
                      customUnitCorrespondanceUnit:
                        inputCustomStorageUnitCorrespondanceUnit,
                    },
                  ]);
                if (addToProductionUnits)
                  props.setInputProductionUnits([
                    ...props.inputProductionUnits,
                    {
                      id: uuid(),
                      unit: inputStorageUnit,
                      customUnit: inputCustomStorageUnit,
                      customUnitCorrespondanceValue:
                        inputCustomStorageUnitCorrespondanceValue,
                      customUnitCorrespondanceUnit:
                        inputCustomStorageUnitCorrespondanceUnit,
                    },
                  ]);
                setAddToPurchaseUnits(true);
                setAddToProductionUnits(true);
                props.setStorageUnitAddActive(false);
                setInputStorageUnit("");
                setInputCustomStorageUnit("");
                setInputCustomStorageUnitCorrespondanceValue("");
                setInputCustomStorageUnitCorrespondanceUnit("");
                resetValidation();
              }
            }}
            color="primary"
            variant="contained"
            size="large"
          >
            Ajouter
          </ButtonMui>
          <ButtonMui
            margin="16px 0 0 4px"
            onClick={() => {
              props.setStorageUnitAddActive(false);
              setInputStorageUnit("");
              setInputCustomStorageUnit("");
              setInputCustomStorageUnitCorrespondanceValue("");
              setInputCustomStorageUnitCorrespondanceUnit("");
              resetValidation();
            }}
            color="primary"
            variant="outlined"
            size="large"
          >
            Annuler
          </ButtonMui>
        </FlexContainer>
      </Collapse>
      {props.inputStorageUnits.map((storageUnit, index) => (
        <React.Fragment key={index}>
          <FlexContainer
            border="1px solid rgba(0,0,0,0.1)"
            margin="8px 0"
            elevation={3}
            $borderRadius="10px"
            padding="8px 8px"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text16>
              {storageUnit.unit === "Personnalisée"
                ? storageUnit.customUnit +
                  " ( = " +
                  storageUnit.customUnitCorrespondanceValue +
                  " " +
                  storageUnit.customUnitCorrespondanceUnit +
                  " )"
                : storageUnit.unit}
            </Text16>
            <FlexContainer>
              <IconButton
                color="primary"
                onClick={() => {
                  props.setStorageUnitAddActive(false);
                  setInputStorageUnit(storageUnit.unit);
                  setInputCustomStorageUnit(storageUnit.customUnit);
                  setInputCustomStorageUnitCorrespondanceValue(
                    storageUnit.customUnitCorrespondanceValue === undefined
                      ? ""
                      : storageUnit.customUnitCorrespondanceValue.toString()
                  );
                  setInputCustomStorageUnitCorrespondanceUnit(
                    storageUnit.customUnitCorrespondanceUnit
                  );
                  setStorageUnitEditActive(
                    storageUnit.unit === "Personnalisée"
                      ? storageUnit.customUnit
                      : storageUnit.unit
                  );
                  resetValidation();
                }}
                size="large"
              >
                <EditOutlinedIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  let newArr = [...props.inputStorageUnits];
                  newArr.splice(index, 1);
                  props.setInputStorageUnits(newArr);
                }}
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </FlexContainer>
          </FlexContainer>
          <Collapse
            orientation="vertical"
            in={
              storageUnit.unit === "Personnalisée"
                ? storageUnitEditActive === storageUnit.customUnit
                : storageUnitEditActive === storageUnit.unit
            }
          >
            <BlockContainer margin="8px 0">
              {addFailed !== "" && (
                <BlockContainer margin="8px 0 16px 0">
                  <Alert severity="error">{addFailed}</Alert>
                </BlockContainer>
              )}
              <FlexContainer>
                <Grid
                  container
                  spacing={{ xs: 1, sm: 1, md: 2 }}
                  columns={{ xs: 1, sm: 1, md: 2 }}
                >
                  <Grid item xs={1} sm={1} md={1}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={inputStorageUnitError}
                    >
                      <InputLabel id="unitLabel">Unité</InputLabel>
                      <Select
                        labelId="unitLabel"
                        id="unit"
                        required
                        value={inputStorageUnit}
                        label="Age"
                        onChange={(e) => setInputStorageUnit(e.target.value)}
                      >
                        <MenuItem
                          value="Kg"
                          disabled={isUnitDisabledForEdit("Kg")}
                        >
                          Kg
                        </MenuItem>
                        <MenuItem
                          value="g"
                          disabled={isUnitDisabledForEdit("g")}
                        >
                          g
                        </MenuItem>
                        <MenuItem
                          value="mg"
                          disabled={isUnitDisabledForEdit("mg")}
                        >
                          mg
                        </MenuItem>
                        <MenuItem
                          value="L"
                          disabled={isUnitDisabledForEdit("L")}
                        >
                          L
                        </MenuItem>
                        <MenuItem
                          value="mL"
                          disabled={isUnitDisabledForEdit("mL")}
                        >
                          mL
                        </MenuItem>
                        <MenuItem value="Personnalisée">Personnalisée</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Nom personnalisé de votre unité"
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="label"
                        label="Libellé"
                        disabled={inputStorageUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputStorageUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomStorageUnitError}
                        value={inputCustomStorageUnit}
                        onChange={(e) =>
                          setInputCustomStorageUnit(e.target.value)
                        }
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Valeur de votre unité personnalisée en unité internationale"
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        name="customUnitStorageCorrespondanceValue"
                        label="Valeur de correspondance"
                        disabled={inputStorageUnit !== "Personnalisée"}
                        sx={{
                          backgroundColor:
                            inputStorageUnit !== "Personnalisée"
                              ? "#e8e8e8"
                              : "white",
                        }}
                        error={inputCustomStorageUnitCorrespondanceValueError}
                        value={inputCustomStorageUnitCorrespondanceValue}
                        onChange={(e) => {
                          if (
                            /^\d*\.?\d*$/.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setInputCustomStorageUnitCorrespondanceValue(
                              e.target.value
                            );
                          }
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1}>
                    <Tooltip
                      color="primary"
                      arrow
                      placement="top"
                      title="Unité internationale utilisée pour calculer la valeur de correspondance"
                    >
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={inputCustomStorageUnitCorrespondanceUnitError}
                      >
                        <InputLabel id="customStorageUnitCorrespondanceUnitLabel">
                          Unité de correspondance
                        </InputLabel>
                        <Select
                          labelId="customStorageUnitCorrespondanceUnitLabel"
                          id="customStorageUnitCorrespondanceUnit"
                          required
                          disabled={inputStorageUnit !== "Personnalisée"}
                          sx={{
                            backgroundColor:
                              inputStorageUnit !== "Personnalisée"
                                ? "#e8e8e8"
                                : "white",
                          }}
                          value={inputCustomStorageUnitCorrespondanceUnit}
                          label="Unité de correspondance"
                          onChange={(e) =>
                            setInputCustomStorageUnitCorrespondanceUnit(
                              e.target.value
                            )
                          }
                        >
                          <MenuItem
                            value="Kg"
                            disabled={isUnitDisabledForEdit("Kg")}
                          >
                            Kg
                          </MenuItem>
                          <MenuItem
                            value="g"
                            disabled={isUnitDisabledForEdit("g")}
                          >
                            g
                          </MenuItem>
                          <MenuItem
                            value="mg"
                            disabled={isUnitDisabledForEdit("mg")}
                          >
                            mg
                          </MenuItem>
                          <MenuItem
                            value="L"
                            disabled={isUnitDisabledForEdit("L")}
                          >
                            L
                          </MenuItem>
                          <MenuItem
                            value="mL"
                            disabled={isUnitDisabledForEdit("mL")}
                          >
                            mL
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Tooltip>
                  </Grid>
                </Grid>
              </FlexContainer>
              <FlexContainer justifyContent="center">
                <ButtonMui
                  margin="16px 4px 0 0"
                  onClick={() => {
                    if (validateEditForm(storageUnit)) {
                      let tmpArray = [...props.inputStorageUnits];
                      tmpArray[index] = {
                        id: storageUnit.id,
                        unit: inputStorageUnit,
                        customUnit: inputCustomStorageUnit,
                        customUnitCorrespondanceValue:
                          inputCustomStorageUnitCorrespondanceValue,
                        customUnitCorrespondanceUnit:
                          inputCustomStorageUnitCorrespondanceUnit,
                      };

                      props.setInputStorageUnits(tmpArray);
                      props.setStorageUnitAddActive(false);
                      setStorageUnitEditActive("");
                      setInputStorageUnit("");
                      setInputCustomStorageUnit("");
                      setInputCustomStorageUnitCorrespondanceValue("");
                      setInputCustomStorageUnitCorrespondanceUnit("");
                      resetValidation();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Modifier
                </ButtonMui>
                <ButtonMui
                  margin="16px 0 0 4px"
                  onClick={() => {
                    setStorageUnitEditActive("");
                    setInputStorageUnit("");
                    setInputCustomStorageUnit("");
                    setInputCustomStorageUnitCorrespondanceValue("");
                    setInputCustomStorageUnitCorrespondanceUnit("");
                    resetValidation();
                  }}
                  color="primary"
                  variant="outlined"
                  size="large"
                >
                  Annuler
                </ButtonMui>
              </FlexContainer>
            </BlockContainer>
          </Collapse>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

export default StorageUnits;
