import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import StylesProvider from "@mui/styles/StylesProvider";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CustomTheme } from "../../styles/Theme";
import { Feature } from "../Feature";
import Catalog from "../Feature/ContentSection/Catalog";
import Clients from "../Feature/ContentSection/Clients";
import CustomerOrderArchive from "../Feature/ContentSection/CustomerOrderArchive";
import Dashboard from "../Feature/ContentSection/Dashboard";
import Documents from "../Feature/ContentSection/Document";
import Employees from "../Feature/ContentSection/Employees";
import ActivateEmloyee from "../Feature/ContentSection/Employees/ActivateEmloyee";
import { EmployeeDetails } from "../Feature/ContentSection/Employees/EmployeeDetails";
import { SetPasswordSuccess } from "../Feature/ContentSection/Employees/SetPasswordSuccess";
import Expenses from "../Feature/ContentSection/Expenses";
import FeatureHome from "../Feature/ContentSection/FeatureHome";
import Gains from "../Feature/ContentSection/Gains";
import Invoice from "../Feature/ContentSection/Invoice";
import MaterialStock from "../Feature/ContentSection/MaterialStock";
import { MaterialDetails } from "../Feature/ContentSection/MaterialStock/MaterialDetails";
import Opportunities from "../Feature/ContentSection/Opportunities";
import { OpportunityDetails } from "../Feature/ContentSection/Opportunities/OpportunityDetails";
import Parameters from "../Feature/ContentSection/Parameters";
import Prospects from "../Feature/ContentSection/Prospects";
import { ProspectDetails } from "../Feature/ContentSection/Prospects/ProspectDetails";
import Quote from "../Feature/ContentSection/Quote";
import QuoteTemplates from "../Feature/ContentSection/QuoteTemplates";
import Sale from "../Feature/ContentSection/Sales";
import Metrics from "../Feature/ContentSection/StatsMetrics";
import SupplierOrder from "../Feature/ContentSection/SupplierOrder";
import SupplierOrderArchive from "../Feature/ContentSection/SupplierOrderArchive";
import SupplierStock from "../Feature/ContentSection/Suppliers";
import { SupplierDetails } from "../Feature/ContentSection/Suppliers/SupplierDetails";
import Vacations from "../Feature/ContentSection/Vacation";
import WebPage from "../Feature/ContentSection/WebPage";
import FoodStock from "../Feature/ContentSection/foodStock";
import { IngredientDetails } from "../Feature/ContentSection/foodStock/IngredientDetails";
import HomePage from "../HomePage";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import { ClientDetails } from "../Feature/ContentSection/Clients/ClientDetails";
import { CustomProvider } from "rsuite";
import frFR from "rsuite/locales/fr_FR";
import RouteModule from "../Feature/ContentSection/Routes";

export interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <StyledEngineProvider injectFirst>
      <StylesProvider>
        <ThemeProvider theme={CustomTheme}>
          <CustomProvider locale={frFR}>
            <Router>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
                <Route
                  path="/activate/:employeeIdAndEmail"
                  element={<ActivateEmloyee />}
                />
                <Route
                  path="/activate/success"
                  element={<SetPasswordSuccess />}
                />
                <Route path="/feature" element={<Feature />}>
                  <Route path="/feature" element={<FeatureHome />} />
                  <Route path="/feature/foodStock" element={<FoodStock />} />
                  <Route
                    path="/feature/ingredients/:ingredientId"
                    element={<IngredientDetails />}
                  />
                  <Route
                    path="/feature/materialStock"
                    element={<MaterialStock />}
                  />
                  <Route
                    path="/feature/materials/:materialId"
                    element={<MaterialDetails />}
                  />
                  <Route
                    path="/feature/suppliers"
                    element={<SupplierStock />}
                  />
                  <Route
                    path="/feature/suppliers/:supplierId"
                    element={<SupplierDetails />}
                  />
                  <Route path="/feature/catalog" element={<Catalog />} />
                  <Route path="/feature/quote" element={<Quote />} />
                  <Route
                    path="/feature/quoteTemplates"
                    element={<QuoteTemplates />}
                  />
                  <Route path="/feature/invoices" element={<Invoice />} />
                  <Route path="/feature/sales" element={<Sale />} />
                  <Route path="/feature/delivery" element={<RouteModule />} />
                  <Route
                    path="/feature/supplierOrders"
                    element={<SupplierOrder />}
                  />
                  <Route
                    path="/feature/customerOrdersArchive"
                    element={<CustomerOrderArchive />}
                  />
                  <Route
                    path="/feature/supplierOrdersArchive"
                    element={<SupplierOrderArchive />}
                  />
                  <Route path="/feature/metrics" element={<Metrics />} />
                  <Route path="/feature/dashboard" element={<Dashboard />} />
                  <Route path="/feature/expenses" element={<Expenses />} />
                  <Route path="/feature/gains" element={<Gains />} />
                  <Route path="/feature/clients" element={<Clients />} />
                  <Route
                    path="/feature/clients/:clientId"
                    element={<ClientDetails />}
                  />
                  <Route path="/feature/prospects" element={<Prospects />} />
                  <Route
                    path="/feature/prospects/:prospectId"
                    element={<ProspectDetails />}
                  />
                  <Route
                    path="/feature/opportunities"
                    element={<Opportunities />}
                  />
                  <Route
                    path="/feature/opportunities/:opportunityId"
                    element={<OpportunityDetails />}
                  />
                  <Route path="/feature/webPage" element={<WebPage />} />
                  <Route path="/feature/employees" element={<Employees />} />
                  <Route
                    path="/feature/employees/:employeeId"
                    element={<EmployeeDetails />}
                  />
                  <Route path="/feature/vacations" element={<Vacations />} />
                  <Route path="/feature/documents" element={<Documents />} />
                  <Route path="/feature/parameters" element={<Parameters />} />
                </Route>
              </Routes>
            </Router>
          </CustomProvider>
        </ThemeProvider>
      </StylesProvider>
    </StyledEngineProvider>
  );
};

export default App;
