import { Toolbar } from "@mui/material";
import React from "react";
import { AppName } from "../../../styles/AppName";
import { AuthenticationHeader } from "../../../styles/AuthenticationHeader";
import { Button } from "../../../styles/Button";
import { FlexContainer } from "../../../styles/FlexContainer";
import { Header } from "../../../styles/Header";
import { Text16 } from "../../../styles/Text";
import { ThemeCustom } from "../../../styles/Utils";

export interface HomePageNavProps {}

const HomePageNav: React.FC<HomePageNavProps> = () => {
  return (
    <Header elevation={2}>
      <Toolbar>
        <FlexContainer margin="0 80px">
          <AppName color={ThemeCustom.colors.pink}>We</AppName>
          <AppName color={ThemeCustom.colors.orange}>Event</AppName>
        </FlexContainer>
        <AuthenticationHeader>
          <Button to="/signup">
            <Text16 color={ThemeCustom.colors.orange}>Inscription</Text16>
          </Button>
          <Button color={ThemeCustom.colors.linearButton} to="/signin">
            <Text16 color={ThemeCustom.colors.white}>Connexion</Text16>
          </Button>
        </AuthenticationHeader>
      </Toolbar>
    </Header>
  );
};

export default HomePageNav;
