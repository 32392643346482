import { createFilterOptions } from "@mui/core";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import uuid from "react-uuid";
import { bindActionCreators } from "redux";
import { Client } from "../../../../../models/client";
import {
  CustomField,
  CustomFieldValue,
} from "../../../../../models/customField";
import { Dish } from "../../../../../models/dish";
import { AdditionalFee, Invoice } from "../../../../../models/invoice";
import { Sale } from "../../../../../models/sale";
import { UserParameter } from "../../../../../models/userParameters";
import { AxiosHttpClient } from "../../../../../services/AxiosHttpService";
import { Dispatch, RootState } from "../../../../../store";
import { getClientsAction } from "../../../../../store/Crm/actions";
import { getDishesAction } from "../../../../../store/Dish/actions";
import { getSalesAction } from "../../../../../store/Sale/actions";
import { getUpfrontInvoicesAction } from "../../../../../store/UpfrontInvoice/actions";
import {
  getClients,
  getDishes,
  getInvoices,
  getUpfrontInvoices,
} from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { DialogContentMui } from "../../../../../styles/DialogContentMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { Text14, Text16, Text20 } from "../../../../../styles/Text";
import { CustomTheme } from "../../../../../styles/Theme";
import { ThemeCustom } from "../../../../../styles/Utils";
import {
  INVOICE_FORMAT,
  INVOICE_INCREMENT,
  INVOICE_NUMBER_OF_DIGITS,
  getClientDescription,
  getHTPriceFromTTC,
  getTTCPriceFromHT,
  getUserParameterValueByName,
  isDateValid,
  toFixed2,
} from "../../../../Reusable/Utils";

export interface GenerateInvoiceProps {
  inputSearch: string;
  popupActive: Sale;
  dishes: Dish[];
  clients: Client[];
  upfrontInvoices: Invoice[];
  setPopupActive: React.Dispatch<React.SetStateAction<Sale | null>>;
  getDishesAction: typeof getDishesAction;
  getSalesAction: typeof getSalesAction;
  getClientsAction: typeof getClientsAction;
  getUpfrontInvoices: typeof getUpfrontInvoicesAction;
  actionsOnGenerate?: (() => void)[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  dialogWrapper: {
    padding: theme.spacing(2),
    position: "absolute",
    width: "850px",
    maxWidth: "100vw",
  },
  dialogTitle: {
    paddingRight: "0px",
  },
  button: {
    minWidth: 0,
    margin: theme.spacing(0.5),
  },
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      whiteSpace: "nowrap",
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
}));

const _GenerateInvoice: React.FC<GenerateInvoiceProps> = ({
  popupActive,
  clients,
  upfrontInvoices,
  setPopupActive,
  getDishesAction,
  getSalesAction,
  getClientsAction,
  getUpfrontInvoices,
  actionsOnGenerate,
}) => {
  /* TODO */
  const addInvoice = () => {
    let totalHt = getTotalHt();
    let totalTTC = getTotalTTC();
    AxiosHttpClient.post("api/v1/weeventpro/invoices", {
      id: "",
      invoiceNumber: inputInvoiceNumber,
      description: inputDescription,
      clientId: popupActive.client?.id,
      soldItems: popupActive.soldItems,
      emailSent: false,
      date: dayjs(inputInvoiceDate),
      dueDate: dayjs(inputDueDate),
      paymentMethods: inputPaymentMethods,
      associatedSalesIds: [popupActive.id],
      additionalFees: inputAdditionalFees,
      comments: inputComments,
      discount: inputDiscount,
      discountCalculation: inputDiscountCalculation,
      documentUrl: "",
      associatedUpfrontInvoicesIds: inputAssociatedUpfrontInvoices.map(
        (upfrontInvoice) => upfrontInvoice.id
      ),
      associatedCreditNotesIds: [],
      associatedInvoiceId: null,
      type: "Facture",
      payments: [],
      totalPriceHT: totalHt,
      totalPriceTVA: toFixed2(parseFloat(totalTTC) - parseFloat(totalHt)),
      totalPriceTTC: totalTTC,
      remainingPriceHT: totalHt,
      remainingPriceTVA: toFixed2(parseFloat(totalTTC) - parseFloat(totalHt)),
      remainingPriceTTC: totalTTC,
      globalTVA: toFixed2(
        (parseFloat(totalTTC) / parseFloat(totalHt) - 1) * 100
      ),
      customFields: customFieldValues,
    })
      .then(() => {
        setPopupActive(null);
        actionsOnGenerate?.forEach((action) => action());
      })
      .catch((err) => {
        setAddFailed(err.cause);
        setIsLoading(false);
        scroll.scrollToTop({
          duration: 500,
          smooth: true,
          containerId: "addInvoiceDialogContentId",
        });
      });
  };

  const getUserParameters = () => {
    AxiosHttpClient.get<UserParameter[]>("api/v1/weeventpro/parameters/user", {
      parameterNames:
        INVOICE_FORMAT +
        "," +
        INVOICE_NUMBER_OF_DIGITS +
        "," +
        INVOICE_INCREMENT,
    }).then((res) => {
      let incrementString = getUserParameterValueByName(INVOICE_INCREMENT, res);
      let incrementPadding = incrementString.padStart(
        Number(getUserParameterValueByName(INVOICE_NUMBER_OF_DIGITS, res)),
        "0"
      );

      let invoiceNumber = getUserParameterValueByName(
        INVOICE_FORMAT,
        res
      ).replace(/%increment%/g, incrementPadding);

      setInputInvoiceNumber(invoiceNumber);
    });
  };

  const getCustomFields = () => {
    AxiosHttpClient.get<CustomField[]>(
      "api/v1/weeventpro/parameters/customFields/invoice"
    ).then((response) => {
      setCustomFields(response);
      let arr: CustomFieldValue[] = [];
      response.map((customField) =>
        arr.push({
          id: uuid(),
          customFieldId: customField.id,
          values: customField.type === "Date" ? [""] : [],
        })
      );
      setCustomFieldValues(arr);
    });
  };

  const getInvoicesLastDate = () => {
    AxiosHttpClient.get<string>("api/v1/weeventpro/invoices/lastDate").then(
      (res) => {
        setLastInvoiceDate(dayjs(res));
        if (dayjs(res).isAfter(dayjs())) {
          setInputInvoiceDate(dayjs(res));
        }
      }
    );
  };

  const paymentMethodsList = [
    "Espèces",
    "Chèque",
    "Virement bancaire",
    "Carte de crédit",
    "Carte de débit",
    "PayPal",
    "Portefeuille numérique",
    "Prélèvement automatique",
  ];

  const validateAddAdditionalFeeForm = () => {
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeePriceTTCError(false);
    setInputAdditionalFeeTvaError(false);
    setAddAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHT) *
            (1 + Number(inputAdditionalFeeTva) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTC)
    ) {
      setAddAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeeDescription === "") {
      setInputAdditionalFeeDescriptionError(true);
      result = false;
    }
    if (inputAdditionalFeePriceHT === "") {
      setInputAdditionalFeePriceHTError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTC === "") {
      setInputAdditionalFeePriceTTCError(true);
      result = false;
    }
    if (inputAdditionalFeeTva === "") {
      setInputAdditionalFeeTvaError(true);
      result = false;
    }
    if (!result) {
      setAddAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateEditAdditionalFeeForm = () => {
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setEditAdditionalFeeItemFailed("");
    let result = true;
    if (
      Number(
        toFixed2(
          Number(inputAdditionalFeePriceHTEdit) *
            (1 + Number(inputAdditionalFeeTvaEdit) / 100)
        )
      ) !== Number(inputAdditionalFeePriceTTCEdit)
    ) {
      setEditAdditionalFeeItemFailed(
        "Les valeurs HT et TTC ne sont pas compatibles!"
      );
      return false;
    }
    if (inputAdditionalFeePriceHTEdit === "") {
      setInputAdditionalFeePriceHTEditError(true);
      result = false;
    }
    if (inputAdditionalFeeTvaEdit === "") {
      setInputAdditionalFeeTvaEditError(true);
      result = false;
    }
    if (inputAdditionalFeePriceTTCEdit === "") {
      setInputAdditionalFeePriceTTCEditError(true);
      result = false;
    }
    if (!result) {
      setEditAdditionalFeeItemFailed("Champs manquants");
    }
    return result;
  };

  const validateForm = () => {
    setInputDescriptionError(false);
    let result = true;
    if (inputDescription === "") {
      setInputDescriptionError(true);
      result = false;
    }
    if (
      !isDateValid(inputInvoiceDate) ||
      inputInvoiceDate!.isBefore(lastInvoiceDate, "day")
    ) {
      setAddFailed("Date invalide!");
      setInputInvoiceDateError(true);
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInvoiceDialogContentId",
      });
      return false;
    }
    if (!result) {
      setAddFailed("Champs manquants");
      scroll.scrollToTop({
        duration: 500,
        smooth: true,
        containerId: "addInvoiceDialogContentId",
      });
    }
    return result;
  };

  const resetAdditionalFeeForm = () => {
    setAddAdditionalFeeItemFailed("");
    setInputAdditionalFeeDescription("");
    setInputAdditionalFeePriceHT("");
    setInputAdditionalFeeTva("");
    setInputAdditionalFeePriceTTC("");
    setInputAdditionalFeeDescriptionError(false);
    setInputAdditionalFeePriceHTError(false);
    setInputAdditionalFeeTvaError(false);
    setInputAdditionalFeePriceTTCError(false);
  };

  const resetAdditionalFeeEditForm = () => {
    setEditAdditionalFeeItemFailed("");
    setInputAdditionalFeePriceHTEdit("");
    setInputAdditionalFeeTvaEdit("");
    setInputAdditionalFeePriceTTCEdit("");
    setInputAdditionalFeePriceHTEditError(false);
    setInputAdditionalFeeTvaEditError(false);
    setInputAdditionalFeePriceTTCEditError(false);
  };

  useEffect(() => {
    getCustomFields();
  }, []);

  useEffect(() => {
    let description = "";
    description += "Facture sur " + popupActive.saleNumber;
    if (popupActive.client !== null)
      description += " - " + getClientDescription(popupActive.client);
    setInputDescription(description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  const [inputDescription, setInputDescription] = useState("");

  const [inputDescriptionError, setInputDescriptionError] = useState(false);

  const [inputInvoiceNumber, setInputInvoiceNumber] = useState("");

  const [inputInvoiceDate, setInputInvoiceDate] = useState<Dayjs | null>(
    dayjs()
  );

  const [inputInvoiceDateError, setInputInvoiceDateError] = useState(false);

  const [inputDueDate, setInputDueDate] = useState<Dayjs | null>(null);

  const [inputPaymentMethods, setInputPaymentMethods] = useState<string[]>([]);

  const [inputComments, setInputComments] = useState("");

  const [inputAssociatedUpfrontInvoices, setInputAssociatedUpfrontInvoices] =
    useState<Invoice[]>([]);

  const [
    inputAssociatedUpfrontInvoicesText,
    setInputAssociatedUpfrontInvoicesText,
  ] = useState("");

  const [inputAdditionalFees, setInputAdditionalFees] = useState<
    AdditionalFee[]
  >([]);

  const [inputAdditionalFeeDescription, setInputAdditionalFeeDescription] =
    useState("");

  const [inputAdditionalFeePriceHT, setInputAdditionalFeePriceHT] =
    useState("");

  const [inputAdditionalFeeTva, setInputAdditionalFeeTva] = useState("");

  const [inputAdditionalFeePriceTTC, setInputAdditionalFeePriceTTC] =
    useState("");

  const [inputAdditionalFeePriceHTEdit, setInputAdditionalFeePriceHTEdit] =
    useState("");

  const [inputAdditionalFeeTvaEdit, setInputAdditionalFeeTvaEdit] =
    useState("");

  const [inputAdditionalFeePriceTTCEdit, setInputAdditionalFeePriceTTCEdit] =
    useState("");

  const [
    inputAdditionalFeeDescriptionError,
    setInputAdditionalFeeDescriptionError,
  ] = useState(false);

  const [inputAdditionalFeeTvaError, setInputAdditionalFeeTvaError] =
    useState(false);

  const [inputAdditionalFeePriceTTCError, setInputAdditionalFeePriceTTCError] =
    useState(false);

  const [inputAdditionalFeePriceHTError, setInputAdditionalFeePriceHTError] =
    useState(false);

  const [inputDiscount, setInputDiscount] = useState("");

  const [inputDiscountCalculation, setInputDiscountCalculation] =
    useState("percentage");

  const [addAdditionalFeeItemFailed, setAddAdditionalFeeItemFailed] =
    useState("");

  const [
    inputAdditionalFeePriceHTEditError,
    setInputAdditionalFeePriceHTEditError,
  ] = useState(false);

  const [
    inputAdditionalFeePriceTTCEditError,
    setInputAdditionalFeePriceTTCEditError,
  ] = useState(false);

  const [inputAdditionalFeeTvaEditError, setInputAdditionalFeeTvaEditError] =
    useState(false);

  const [editAdditionalFeeItemFailed, setEditAdditionalFeeItemFailed] =
    useState("");

  const [addFailed, setAddFailed] = useState("");

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [customFieldValues, setCustomFieldValues] = useState<
    CustomFieldValue[]
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const [additionalFeeItemEditActive, setAdditionalFeeItemEditActive] =
    useState("");

  const [lastInvoiceDate, setLastInvoiceDate] = useState<Dayjs | null>(null);

  const classes = useStyles();

  var Scroll = require("react-scroll");
  var scroll = Scroll.animateScroll;

  let removeAdditionalFeeItem = (index: number) => {
    let newArr = [...inputAdditionalFees];
    newArr.splice(index, 1);
    setInputAdditionalFees(newArr);
  };

  const getCustomFieldValuesByCustomFieldId = (id: string) => {
    if (customFieldValues !== null) {
      for (var customFieldValue of customFieldValues) {
        if (customFieldValue.customFieldId === id)
          return customFieldValue.values;
      }
    }
    return null;
  };

  const setCustomFieldValuesByCustomFieldId = (
    id: string,
    values: string[]
  ) => {
    let index = 0;
    for (var customFieldValue of customFieldValues) {
      if (customFieldValue.customFieldId === id) {
        let newArr = [...customFieldValues];
        newArr.splice(index, 1);
        setCustomFieldValues([
          ...newArr,
          {
            id: customFieldValue.id,
            customFieldId: customFieldValue.customFieldId,
            values: values,
          },
        ]);
      }
      index++;
    }
  };

  const filterAssociatedUpfontInvicesOptions = createFilterOptions<Invoice>({
    stringify: (option) => option.invoiceNumber,
  });

  const getTotalHtText = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = (totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return (
      toFixed2(totalHt) +
      (inputDiscount === ""
        ? ""
        : " - " +
          toFixed2(discountHT) +
          " = " +
          toFixed2(totalHt - discountHT)) +
      " €"
    );
  };

  const getTotalHt = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = (totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return toFixed2(totalHt - discountHT);
  };

  const getTotalHtBeforeUpfront = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    let totalHt = totalSoldItems + totalAdditionalFees;

    let discountHT = 0;

    if (inputDiscount.match("\\d+")) {
      if (inputDiscountCalculation === "percentage") {
        discountHT = (totalHt * parseFloat(inputDiscount)) / 100;
      } else if (inputDiscountCalculation === "valueHT") {
        discountHT = parseFloat(inputDiscount);
      } else if (inputDiscountCalculation === "valueTTC") {
        const totalTTC = parseFloat(getTotalTTCBeforeDiscount());
        discountHT =
          totalHt -
          (totalHt * (totalTTC - parseFloat(inputDiscount))) / totalTTC;
      }
    }

    return totalHt - discountHT;
  };

  const getTotalTTCBeforeDiscount = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    const totalTTC = totalSoldItems + totalAdditionalFees;

    return toFixed2(totalTTC);
  };

  const getTotalTTCText = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = (totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return (
      toFixed2(totalTTC) +
      (inputDiscount === ""
        ? ""
        : " - " +
          toFixed2(discountTTC) +
          " = " +
          toFixed2(totalTTC - discountTTC)) +
      " €"
    );
  };

  const getTotalTTC = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = (totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return toFixed2(totalTTC - discountTTC);
  };

  const getTotalTTCBeforeUpfront = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      const tvaRate = soldItem.tva / 100;
      return acc + priceHT * (1 + tvaRate);
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        const tvaRate = additionalFee.tva / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );

    let totalTTC = totalSoldItems + totalAdditionalFees;

    let discountTTC = 0;

    if (inputDiscount.match("\\d+")) {
      if ("percentage" === inputDiscountCalculation) {
        discountTTC = (totalTTC * parseFloat(inputDiscount)) / 100;
      } else if ("valueHT" === inputDiscountCalculation) {
        const totalHt = parseFloat(getTotalHtBeforeDiscount());
        discountTTC =
          totalTTC -
          (totalTTC * (totalHt - parseFloat(inputDiscount))) / totalHt;
      } else if ("valueTTC" === inputDiscountCalculation) {
        discountTTC = parseFloat(inputDiscount);
      }
    }

    return totalTTC - discountTTC;
  };

  const getTotalHtBeforeDiscount = () => {
    const totalSoldItems = popupActive.soldItems.reduce((acc, soldItem) => {
      const priceHT = soldItem.quantity * soldItem.priceHT;
      return acc + priceHT;
    }, 0);

    const totalAdditionalFees = inputAdditionalFees.reduce(
      (acc, additionalFee) => {
        const priceHT = additionalFee.priceHT;
        return acc + priceHT;
      },
      0
    );

    const totalHt = totalSoldItems + totalAdditionalFees;
    return toFixed2(totalHt);
  };

  const getRemainingPriceHTText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        return acc + priceHT;
      },
      0
    );
    const totalHtBeforeUpfront = getTotalHtBeforeUpfront();

    return (
      toFixed2(totalHtBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalHtBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  const getRemainingPriceTTCText = () => {
    const totalUpfrontInvoiceAmounts = inputAssociatedUpfrontInvoices.reduce(
      (acc, upfrontInvoice) => {
        const priceHT = upfrontInvoice.totalPriceHT;
        const tvaRate = upfrontInvoice.globalTVA / 100;
        return acc + priceHT * (1 + tvaRate);
      },
      0
    );
    const totalTTCBeforeUpfront = getTotalTTCBeforeUpfront();

    return (
      toFixed2(totalTTCBeforeUpfront) +
      " - " +
      toFixed2(totalUpfrontInvoiceAmounts) +
      " = " +
      toFixed2(totalTTCBeforeUpfront - totalUpfrontInvoiceAmounts) +
      " €"
    );
  };

  useEffect(() => {
    getDishesAction("");
    getSalesAction("");
    getUserParameters();
    getUpfrontInvoices("");
    getClientsAction("");
    getInvoicesLastDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDishesAction, getSalesAction, getClientsAction]);

  return (
    <Dialog
      open={popupActive !== null}
      onClose={(event, reason) => {
        if (reason && reason === "backdropClick") return;
        setPopupActive(null);
      }}
      classes={{ paper: classes.dialogWrapper }}
      aria-labelledby="responsive-dialog-title"
      sx={{
        "& .MuiPaper-root": {
          margin: "0",
          maxHeight: { xs: "100vh", sm: "calc(100% - 64px)" },
        },
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
        <FlexContainer textAlign="left" alignItems="center">
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {"Générer une facture finale"}
          </Typography>
          <ButtonMui
            className={classes.button}
            color="secondary"
            $backgroundColorHover={ThemeCustom.colors.opaquePink}
            onClick={() => {
              setPopupActive(null);
            }}
          >
            <CloseIcon />
          </ButtonMui>
        </FlexContainer>
      </DialogTitle>
      <DialogContentMui id="addInvoiceDialogContentId" dividers>
        {addFailed !== "" && (
          <BlockContainer margin="8px">
            <Alert
              severity="error"
              onClose={() => {
                setAddFailed("");
              }}
            >
              {addFailed}
            </Alert>
          </BlockContainer>
        )}
        <form id="formId" className={classes.root} autoComplete="off">
          <BlockContainer margin="16px 0">
            <Text20 fontWeight="600" textAlign="left" margin="0">
              Informations générales{" "}
            </Text20>
          </BlockContainer>
          <Grid
            container
            spacing={{ xs: 1, sm: 1, md: 2 }}
            columns={{ xs: 1, sm: 1, md: 2 }}
          >
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="description"
                label="Decription"
                error={inputDescriptionError}
                value={inputDescription}
                onChange={(e) => {
                  setInputDescription(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <TextField
                fullWidth
                variant="outlined"
                name="InvoiceNumber"
                label="Numéro de facture"
                disabled
                value={inputInvoiceNumber}
                onChange={(e) => {
                  setInputInvoiceNumber(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <Autocomplete
                fullWidth
                multiple
                id="paymentMethods"
                disableCloseOnSelect
                options={paymentMethodsList}
                value={inputPaymentMethods}
                getOptionLabel={(option) => (option ? option : "")}
                onChange={(event, newValue) => {
                  setInputPaymentMethods(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Modes de paiement" />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date de facturation"
                  inputFormat="DD/MM/YYYY"
                  minDate={lastInvoiceDate}
                  value={inputInvoiceDate}
                  onChange={(value) => setInputInvoiceDate(value!)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      error={inputInvoiceDateError}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={1}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Date d'échéance"
                  inputFormat="DD/MM/YYYY"
                  value={inputDueDate}
                  onChange={(value) => setInputDueDate(value!)}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1} sm={1} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                name="comments"
                label="Notes/Commentaires"
                multiline
                rows={4}
                value={inputComments}
                onChange={(e) => {
                  setInputComments(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Frais supplémentaires
          </Text20>
        </BlockContainer>
        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14>
              Veuillez ajouter des frais supplémentaires pour cette facture s'il
              en existe:
            </Text14>
          </Grid>
          {addAdditionalFeeItemFailed !== "" && (
            <Grid item xs={1} sm={1} md={2}>
              <BlockContainer margin="0 0 8px 0">
                <Alert severity="error">{addAdditionalFeeItemFailed}</Alert>
              </BlockContainer>
            </Grid>
          )}
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeeDescription"
              label="Description"
              error={inputAdditionalFeeDescriptionError}
              value={inputAdditionalFeeDescription}
              onChange={(e) => {
                setInputAdditionalFeeDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeeTva"
              label="TVA"
              error={inputAdditionalFeeTvaError}
              value={inputAdditionalFeeTva}
              onChange={(e) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  !isNaN(Number(inputAdditionalFeePriceHT))
                ) {
                  setInputAdditionalFeePriceTTC(
                    toFixed2(
                      getTTCPriceFromHT(
                        Number(inputAdditionalFeePriceHT),
                        Number(e.target.value)
                      )
                    )
                  );
                }
                setInputAdditionalFeeTva(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeePriceHT"
              label="Prix HT"
              error={inputAdditionalFeePriceHTError}
              value={inputAdditionalFeePriceHT}
              onChange={(e) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  !isNaN(Number(inputAdditionalFeeTva))
                ) {
                  setInputAdditionalFeePriceTTC(
                    toFixed2(
                      getTTCPriceFromHT(
                        Number(e.target.value),
                        Number(inputAdditionalFeeTva)
                      )
                    )
                  );
                }
                setInputAdditionalFeePriceHT(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="additionalFeePriceTTC"
              label="Prix TTC"
              error={inputAdditionalFeePriceTTCError}
              value={inputAdditionalFeePriceTTC}
              onChange={(e) => {
                if (
                  !isNaN(Number(e.target.value)) &&
                  !isNaN(Number(inputAdditionalFeeTva))
                ) {
                  setInputAdditionalFeePriceHT(
                    toFixed2(
                      getHTPriceFromTTC(
                        Number(e.target.value),
                        Number(inputAdditionalFeeTva)
                      )
                    )
                  );
                }
                setInputAdditionalFeePriceTTC(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            />
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={2}
            display="flex"
            justifyContent="center"
          >
            <FlexContainer>
              <ButtonMui
                color="primary"
                variant="contained"
                size="large"
                disabled={isLoading}
                onClick={() => {
                  if (validateAddAdditionalFeeForm()) {
                    resetAdditionalFeeForm();
                    setInputAdditionalFees([
                      ...inputAdditionalFees,
                      {
                        id: uuid(),
                        description: inputAdditionalFeeDescription,
                        priceHT: Number(inputAdditionalFeePriceHT),
                        tva: Number(inputAdditionalFeeTva),
                      },
                    ]);
                  }
                }}
              >
                Ajouter
              </ButtonMui>
            </FlexContainer>
          </Grid>

          <Grid item xs={1} sm={1} md={2}>
            <TableContainer>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell key="additionalFeeDescription">
                      Description
                    </TableCell>
                    <TableCell key="inputAdditionalFeePriceHT">
                      Prix HT
                    </TableCell>
                    <TableCell key="inputAdditionalFeeTva">TVA</TableCell>
                    <TableCell key="inputAdditionalFeePriceTTC">
                      Prix TTC
                    </TableCell>
                    <TableCell key="actions">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inputAdditionalFees.length === 0 && (
                    <TableRow key="empty">
                      <TableCell colSpan={5}>
                        <FlexContainer width="100%" justifyContent="center">
                          <Text14 color="grey" opacity="80%">
                            Aucun frais supplémentaire ajouté!
                          </Text14>
                        </FlexContainer>
                      </TableCell>
                    </TableRow>
                  )}
                  {inputAdditionalFees.map((additionalFee, index) => (
                    <React.Fragment key={"fragment" + additionalFee.id}>
                      <TableRow key={additionalFee.id}>
                        <TableCell>{additionalFee.description}</TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.priceHT)) + " €"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(Number(additionalFee.tva)) + "%"}
                        </TableCell>
                        <TableCell>
                          {toFixed2(
                            Number(additionalFee.priceHT) *
                              (1 + Number(additionalFee.tva) / 100)
                          ) + " €"}
                        </TableCell>
                        <TableCell>
                          <FlexContainer justifyContent="center">
                            <IconButton
                              color="primary"
                              onClick={(e) => {
                                setInputAdditionalFeePriceHTEdit(
                                  toFixed2(additionalFee.priceHT)
                                );
                                setInputAdditionalFeeTvaEdit(
                                  toFixed2(additionalFee.tva)
                                );
                                setInputAdditionalFeePriceTTCEdit(
                                  toFixed2(
                                    Number(additionalFee.priceHT) *
                                      (1 + Number(additionalFee.tva) / 100)
                                  )
                                );
                                setAdditionalFeeItemEditActive(
                                  additionalFee.id
                                );
                              }}
                              size="large"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                removeAdditionalFeeItem(index);
                              }}
                              size="large"
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </FlexContainer>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        key={"edit-" + additionalFee.id}
                        sx={{
                          display:
                            additionalFeeItemEditActive === additionalFee.id
                              ? "table-row"
                              : "none",
                        }}
                      >
                        <TableCell colSpan={5}>
                          <Collapse
                            orientation="vertical"
                            in={
                              additionalFeeItemEditActive === additionalFee.id
                            }
                          >
                            {additionalFeeItemEditActive ===
                              additionalFee.id && (
                              <Grid
                                container
                                spacing={{ xs: 1, sm: 1, md: 2 }}
                                columns={{ xs: 1, sm: 1, md: 2 }}
                              >
                                <Grid item xs={1} sm={1} md={2}>
                                  {editAdditionalFeeItemFailed !== "" && (
                                    <BlockContainer margin="0 0 8px 0">
                                      <Alert severity="error">
                                        {editAdditionalFeeItemFailed}
                                      </Alert>
                                    </BlockContainer>
                                  )}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeeTva"
                                    label="TVA"
                                    error={inputAdditionalFeeTvaEditError}
                                    value={inputAdditionalFeeTvaEdit}
                                    onChange={(e) => {
                                      if (
                                        !isNaN(Number(e.target.value)) &&
                                        !isNaN(
                                          Number(inputAdditionalFeePriceHTEdit)
                                        )
                                      ) {
                                        setInputAdditionalFeePriceTTCEdit(
                                          toFixed2(
                                            getTTCPriceFromHT(
                                              Number(
                                                inputAdditionalFeePriceHTEdit
                                              ),
                                              Number(e.target.value)
                                            )
                                          )
                                        );
                                      }
                                      setInputAdditionalFeeTvaEdit(
                                        e.target.value
                                      );
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceHT"
                                    label="Prix HT"
                                    error={inputAdditionalFeePriceHTEditError}
                                    value={inputAdditionalFeePriceHTEdit}
                                    onChange={(e) => {
                                      if (
                                        !isNaN(Number(e.target.value)) &&
                                        !isNaN(
                                          Number(inputAdditionalFeeTvaEdit)
                                        )
                                      ) {
                                        setInputAdditionalFeePriceTTCEdit(
                                          toFixed2(
                                            getTTCPriceFromHT(
                                              Number(e.target.value),
                                              Number(inputAdditionalFeeTvaEdit)
                                            )
                                          )
                                        );
                                      }
                                      setInputAdditionalFeePriceHTEdit(
                                        e.target.value
                                      );
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1} sm={1} md={1}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="additionalFeePriceTTC"
                                    label="Prix TTC"
                                    error={inputAdditionalFeePriceTTCEditError}
                                    value={inputAdditionalFeePriceTTCEdit}
                                    onChange={(e) => {
                                      if (
                                        !isNaN(Number(e.target.value)) &&
                                        !isNaN(
                                          Number(inputAdditionalFeeTvaEdit)
                                        )
                                      ) {
                                        setInputAdditionalFeePriceHTEdit(
                                          toFixed2(
                                            getHTPriceFromTTC(
                                              Number(e.target.value),
                                              Number(inputAdditionalFeeTvaEdit)
                                            )
                                          )
                                        );
                                      }
                                      setInputAdditionalFeePriceTTCEdit(
                                        e.target.value
                                      );
                                    }}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          €
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={1}
                                  sm={1}
                                  md={2}
                                  display="flex"
                                  justifyContent="center"
                                >
                                  <FlexContainer>
                                    <ButtonMui
                                      color="primary"
                                      variant="contained"
                                      size="large"
                                      disabled={isLoading}
                                      margin="0 4px 0 0"
                                      onClick={() => {
                                        if (validateEditAdditionalFeeForm()) {
                                          resetAdditionalFeeEditForm();
                                          let tmpArray = [
                                            ...inputAdditionalFees,
                                          ];
                                          tmpArray[index] = {
                                            id: additionalFee.id,
                                            description:
                                              additionalFee.description,
                                            priceHT: Number(
                                              inputAdditionalFeePriceHTEdit
                                            ),
                                            tva: Number(
                                              inputAdditionalFeeTvaEdit
                                            ),
                                          };
                                          setInputAdditionalFees(tmpArray);
                                          setAdditionalFeeItemEditActive("");
                                        }
                                      }}
                                    >
                                      Modifier
                                    </ButtonMui>
                                    <ButtonMui
                                      margin="0 0 0 4px"
                                      onClick={() => {
                                        resetAdditionalFeeEditForm();
                                        setAdditionalFeeItemEditActive("");
                                      }}
                                      color="primary"
                                      variant="outlined"
                                      size="large"
                                    >
                                      Annuler
                                    </ButtonMui>
                                  </FlexContainer>
                                </Grid>
                              </Grid>
                            )}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>

        <Divider sx={{ mt: "16px" }} />
        <BlockContainer margin="16px 0">
          <Text20 fontWeight="600" textAlign="left" margin="0">
            Tarification
          </Text20>
        </BlockContainer>

        <Grid
          container
          spacing={{ xs: 1, sm: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 2 }}
        >
          <Grid item xs={1} sm={1} md={2}>
            <Text14 display="inline-block" width="140px">
              Prix HT:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {getTotalHtText()}
            </Text16>
          </Grid>
          <Grid item xs={1} sm={1} md={2} sx={{ paddingTop: "0 !important" }}>
            <Text14 display="inline-block" width="140px">
              Prix TTC:
            </Text14>
            <Text16
              display="inline"
              color={CustomTheme.palette.primary.main}
              fontWeight="500"
            >
              {getTotalTTCText()}
            </Text16>
          </Grid>
          {inputAssociatedUpfrontInvoices.length > 0 && (
            <>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="140px">
                  Reste à payer HT:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getRemainingPriceHTText()}
                </Text16>
              </Grid>
              <Grid
                item
                xs={1}
                sm={1}
                md={2}
                sx={{ paddingTop: "0 !important" }}
              >
                <Text14 display="inline-block" width="140px">
                  Reste à payer TTC:
                </Text14>
                <Text16
                  display="inline"
                  color={CustomTheme.palette.primary.main}
                  fontWeight="500"
                >
                  {getRemainingPriceTTCText()}
                </Text16>
              </Grid>
            </>
          )}
          <Grid item xs={1} sm={1} md={1}>
            <TextField
              fullWidth
              variant="outlined"
              name="discount"
              label="Remise"
              value={inputDiscount}
              onChange={(e) => {
                if (
                  /^\d*\.?\d*$/.test(e.target.value) ||
                  e.target.value === ""
                ) {
                  setInputDiscount(e.target.value);
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      "& .MuiInputBase-root:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root:after": {
                        borderBottom: "none",
                      },
                      "& .MuiInputBase-root:hover:before": {
                        borderBottom: "none !important",
                      },
                    }}
                  >
                    <Select
                      variant="standard"
                      sx={{
                        "& .MuiSelect-select:focus": {
                          borderBottom: "none",
                          backgroundColor: "white",
                        },
                      }}
                      value={inputDiscountCalculation}
                      onChange={(e) =>
                        setInputDiscountCalculation(e.target.value)
                      }
                    >
                      <MenuItem key="percentage" value="percentage">
                        %
                      </MenuItem>
                      <MenuItem key="valueHT" value="valueHT">
                        Valeur HT
                      </MenuItem>
                      <MenuItem key="valueTTC" value="valueTTC">
                        Valeur TTC
                      </MenuItem>
                    </Select>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={1} sm={1} md={1}>
            <Autocomplete
              id="associatedUpfrontInvoices"
              filterOptions={filterAssociatedUpfontInvicesOptions}
              noOptionsText={"Pas de suggestions"}
              fullWidth
              multiple
              disableCloseOnSelect
              getOptionLabel={(option) => {
                return (
                  option.invoiceNumber +
                  " - " +
                  getClientDescription(option.client)
                );
              }}
              options={upfrontInvoices}
              value={inputAssociatedUpfrontInvoices}
              onChange={(event, newValue) => {
                setInputAssociatedUpfrontInvoices(newValue!);
              }}
              inputValue={inputAssociatedUpfrontInvoicesText}
              onInputChange={(event, newInputValue) => {
                setInputAssociatedUpfrontInvoicesText(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  name="associatedUpfrontInvoicesTextfield"
                  label="Factures d'acompte"
                />
              )}
            />
          </Grid>
        </Grid>
        {customFields.length > 0 && (
          <>
            <Divider sx={{ mt: "16px" }} />
            <BlockContainer margin="16px 0">
              <Text20 fontWeight="600" textAlign="left" margin="0">
                Champs personnalisés
              </Text20>
            </BlockContainer>
            <Grid
              container
              spacing={{ xs: 1, sm: 1, md: 2 }}
              columns={{ xs: 1, sm: 1, md: 2 }}
            >
              {customFields.map((customField, index) => (
                <Grid item xs={1} sm={1} md={1} key={customField.id}>
                  {customField.type === "Texte" && (
                    <TextField
                      fullWidth
                      variant="outlined"
                      name={customField.name}
                      label={customField.name}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0] === undefined
                          ? ""
                          : getCustomFieldValuesByCustomFieldId(
                              customField.id
                            )![0]
                      }
                      onChange={(e) =>
                        setCustomFieldValuesByCustomFieldId(customField.id, [
                          e.target.value,
                        ])
                      }
                    />
                  )}
                  {customField.type === "Date" && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label={customField.name}
                        inputFormat="DD/MM/YYYY"
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0 ||
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          )![0] === ""
                            ? null
                            : dayjs(
                                getCustomFieldValuesByCustomFieldId(
                                  customField.id
                                )![0],
                                "DD/MM/YYYY"
                              )
                        }
                        onChange={(value) =>
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            value === null ? "" : value!.format("DD/MM/YYYY"),
                          ])
                        }
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  )}
                  {customField.type === "Liste déroulante" && (
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        {customField.name}
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        displayEmpty
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        label={customField.name}
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                      >
                        {customField.values.map((choice, index) => (
                          <MenuItem
                            value={choice}
                            key={"select" + customField.id}
                          >
                            {choice}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {customField.type === "Boutton radio" && (
                    <FormControl>
                      <FormLabel>{customField.name}</FormLabel>
                      <RadioGroup
                        name={customField.name}
                        value={
                          getCustomFieldValuesByCustomFieldId(
                            customField.id
                          ) === null ||
                          getCustomFieldValuesByCustomFieldId(customField.id)
                            ?.length === 0
                            ? ""
                            : getCustomFieldValuesByCustomFieldId(
                                customField.id
                              )![0]
                        }
                        onChange={(e) => {
                          setCustomFieldValuesByCustomFieldId(customField.id, [
                            e.target.value,
                          ]);
                        }}
                        row
                      >
                        {customField.values.map((choice, index) => (
                          <FormControlLabel
                            value={choice}
                            control={<Radio />}
                            label={choice}
                            key={"radio" + customField.id}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  {customField.type === "Checkbox" && (
                    <Autocomplete
                      fullWidth
                      multiple
                      options={customField.values}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option}
                      value={
                        getCustomFieldValuesByCustomFieldId(customField.id) ===
                        null
                          ? []
                          : getCustomFieldValuesByCustomFieldId(customField.id)!
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Checkbox
                            style={{ marginRight: 8 }}
                            checked={
                              getCustomFieldValuesByCustomFieldId(
                                customField.id
                              ) === null
                                ? false
                                : getCustomFieldValuesByCustomFieldId(
                                    customField.id
                                  )!.includes(option)
                                ? true
                                : false
                            }
                          />
                          {option}
                        </li>
                      )}
                      onChange={(e, values) => {
                        setCustomFieldValuesByCustomFieldId(
                          customField.id,
                          values
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label={customField.name}
                        />
                      )}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </DialogContentMui>
      <DialogActions>
        <ButtonMui
          color="primary"
          variant="contained"
          size="large"
          disabled={isLoading}
          loading={isLoading}
          onClick={() => {
            setIsLoading(true);
            if (validateForm()) addInvoice();
            else setIsLoading(false);
          }}
        >
          Générer
        </ButtonMui>
        <ButtonMui
          onClick={() => setPopupActive(null)}
          color="primary"
          variant="outlined"
          size="large"
          margin="0 0 0 8px"
        >
          Annuler
        </ButtonMui>
      </DialogActions>
    </Dialog>
  );
};

export const GenerateInvoice = connect(
  (state: RootState) => ({
    dishes: getDishes(state),
    clients: getClients(state),
    invoices: getInvoices(state),
    upfrontInvoices: getUpfrontInvoices(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDishesAction: getDishesAction,
        getClientsAction: getClientsAction,
        getSalesAction: getSalesAction,
        getUpfrontInvoices: getUpfrontInvoicesAction,
      },
      dispatch
    )
)(_GenerateInvoice);

export default GenerateInvoice;
