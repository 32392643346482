import { Sale } from "../../models/sale";

export const GET_SALES = "GET_SALES" as const;
export const GET_SALES_SUCCESS = "GET_SALES_SUCCESS" as const;
export const GET_SALES_FAILURE = "GET_SALES_FAILURE" as const;


export const getSalesAction = (input: string, field?: string) => ({
  type: GET_SALES,
  input: input,
  field: field,
});
export type GetSalesAction = ReturnType<typeof getSalesAction>;

export const getSalesActionSuccess = (sales: Sale[]) => ({
  type: GET_SALES_SUCCESS,
  sales: sales,
});
export type GetSalesActionSuccess = ReturnType<
  typeof getSalesActionSuccess
>;

export const getSalesActionFailure = () => ({
  type: GET_SALES_FAILURE,
});
export type GetSalesActionFailure = ReturnType<
  typeof getSalesActionFailure
>;