import { AxiosHttpClient } from "./services/AxiosHttpService";

window.onerror = function (message, source, lineno, colno, error) {
    console.log(error.stack);
    AxiosHttpClient.post("api/v1/weeventpro/logs/error", {
        message: message,
        error: error.stack,
      })
        .then(() => {
        })
        .catch((err) => {
        });
}