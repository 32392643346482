import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Document } from "../../../../../models/document";
import { Dispatch, RootState } from "../../../../../store";
import { getDocumentsAction } from "../../../../../store/Rh/actions";
import { getDocuments } from "../../../../../store/selectors";
import { BlockContainer } from "../../../../../styles/BlockContainer";
import { ButtonMui } from "../../../../../styles/ButtonMui";
import { FlexContainer } from "../../../../../styles/FlexContainer";
import { ImagePreview } from "../../../../../styles/ImagePreview";
import { TableContainerMui } from "../../../../../styles/TableContainerMui";
import AddDocument from "../AddDocument";
import DeleteDocument from "../DeleteDocument";

const useStyles = makeStyles((theme) => ({
  table: {
    "& thead th": {
      textAlign: "center",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    "& tbody td": {
      textAlign: "center",
      fontWeight: "300",
    },
    "& tbody a:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    "& tbody a": {
      textDecoration: "#fffbf2",
    },
  },
  pageContent: {
    margin: theme.spacing(3),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
}));

const headCells = [
  { id: "thumbnail", label: "" },
  { id: "name", label: "Nom" },
  { id: "actions", label: "Actions", disableSorting: true },
];

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface DocumentTableProps {
  documents: Document[];
  getDocumentsAction: typeof getDocumentsAction;
}

const _DocumentTable: React.FC<DocumentTableProps> = ({
  documents,
  getDocumentsAction,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState<"desc" | "asc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [inputSearch, SetInputSearch] = useState("");

  const [popupAddActive, setPopupAddActive] = useState(false);

  const [popupDeleteActive, setPopupDeleteActive] = useState<Document>({
    id: "",
    name: "",
    url: "",
  });

  useEffect(() => {
    getDocumentsAction(inputSearch);
  }, [inputSearch, getDocumentsAction]);

  const handleSortRequest = (cellId: string) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  return (
    <BlockContainer padding="24px">
      <Toolbar>
        <TextField
          variant="outlined"
          label="Rechercher un document"
          className={classes.searchInput}
          value={inputSearch}
          onChange={(e) => SetInputSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        <FlexContainer flex="1" justifyContent="flex-end">
          <ButtonMui
            color="primary"
            variant="outlined"
            margin="0 0 0 10px"
            startIcon={<AddIcon />}
            onClick={() => setPopupAddActive(true)}
          >
            <Typography variant="button">Ajouter</Typography>
          </ButtonMui>
        </FlexContainer>
      </Toolbar>
      <TableContainerMui>
        <Table stickyHeader className={classes.table}>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(documents, getComparator(order, orderBy)).map(
              (item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    <ImagePreview
                      src={
                        "https://" +
                        process.env.REACT_APP_BUCKET_NAME! +
                        ".s3.eu-west-3.amazonaws.com/DocumentsThumbnails/" +
                        item.url +
                        ".png"
                      }
                      height="150px"
                      width="100px"
                      margin="7px auto"
                    />
                  </TableCell>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                      size="large"
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        setPopupDeleteActive(item);
                      }}
                      size="large"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainerMui>
      {popupAddActive && (
        <AddDocument
          popupActive={popupAddActive}
          setPopupActive={setPopupAddActive}
          input={inputSearch}
        />
      )}
      <DeleteDocument
        input={inputSearch}
        popupActive={popupDeleteActive}
        setPopupActive={setPopupDeleteActive}
      />
    </BlockContainer>
  );
};

export const DocumentTable = connect(
  (state: RootState) => ({
    documents: getDocuments(state),
  }),
  (dispatch: Dispatch) =>
    bindActionCreators(
      {
        getDocumentsAction: getDocumentsAction,
      },
      dispatch
    )
)(_DocumentTable);

export default _DocumentTable;
